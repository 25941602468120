import { StorageService } from '../common/storage-service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CommonService } from '../common/common.service';
import { retry, catchError } from 'rxjs/operators';

@Injectable()
export class ContentService {

    private apiUrl = '';
    public author = '';
    public authorNew = '';   

    constructor(private http: HttpClient, private commonService: CommonService, private localStorageService: StorageService) {
        this.apiUrl = this.commonService.getApiUrl();
    }

    // Get Student Details
    GetStudentDetails(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
            })
        };
        return this.http.get(this.apiUrl + '/api/Students/GetStudent',
            httpOptions).pipe(retry(3), catchError(this.handleError));
    }
    GetPackagesById(uid): Observable<any> {
        return this.http.get
            (this.apiUrl + '/api/Payments/GetPackagesById?uid=' + uid).pipe(retry(3), catchError(this.handleError));
    }

    getCareeBySearchInput(searchtext): Observable<any> {
        // alert("search query in service is " + searchtext);
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
            })
        };
        return this.http.get(this.apiUrl + '/api/Contents/SearchAllCareers?careerPath=' + searchtext, httpOptions).pipe(retry(3), catchError(this.handleError));
    }

    // Get Subscription Packages
    GetSubscriptionPackages(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
            })
        };
        return this.http.get(this.apiUrl + '/api/Payments/GetSubscriptionPackages', httpOptions).pipe(retry(3), catchError(this.handleError));
    }


    GetAllnewsroom(newsFilterObj) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        let body = JSON.stringify(newsFilterObj);
        return this.http.post(this.apiUrl + '/api/Contents/SearchFeed', body, httpOptions).pipe(retry(3), catchError(this.handleError));
    }


    getAllUniversities(): Observable<any> {
        return this.http.get(this.apiUrl + '/api/Contents/GetAllUniversitiesList').pipe(retry(3), catchError(this.handleError));
    }

    getUnivBySearchInput(searchUObj): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let body = JSON.stringify(searchUObj);
        return this.http.post(this.apiUrl + '/api/Contents/SearchUniversity', body, httpOptions)
            .pipe(retry(3), catchError(this.handleError));
    }

    getNewsroomById(newsUId) {
        return this.http.get
            (this.apiUrl + '/api/Contents/GetFeedById?feedUId=' + newsUId).pipe(retry(3), catchError(this.handleError));
    }

    sendStudentMsg(msgObj) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let body = JSON.stringify(msgObj);
        return this.http.post(this.apiUrl + '/api/Accounts/ContactUs', body, httpOptions).pipe(retry(3), catchError(this.handleError));
    }

    GetAllBlogs(blogsFilterObj) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let body = JSON.stringify(blogsFilterObj);
        return this.http.post(this.apiUrl + '/api/Contents/SearchFeed', body, httpOptions).pipe(retry(3), catchError(this.handleError));
    }

    savePartnershipRequests(request): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let body = JSON.stringify(request);
        return this.http.post(this.apiUrl + '/api/Admin/SavePartnershipRequests', body, httpOptions).pipe(retry(3), catchError(this.handleError));
    }

    getAllPackages(): Observable<any> {
        return this.http.get(this.apiUrl + '/api/Payments/GetAllSubscriptionPackages').pipe(retry(3), catchError(this.handleError));
    } GetSubscribedPackage(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
            })
        };
        return this.http.get(this.apiUrl + '/api/Payments/GetCurrentPackage',
            httpOptions).pipe(retry(3), catchError(this.handleError));
    }
    // SaveCandidateInfo api
    saveCandidateInfo(formData) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
            })
        };
        return this.http.post(this.apiUrl + '/api/Admin/SaveCandidateInfo', formData, httpOptions).pipe(retry(3), catchError(this.handleError));
    }


    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(error);
    }

    /*****************************************************************
   subscribeToWorkshop()
   purpose : this method will add Email id for subscribtion in career workshop in API
   *******************************************************************/
    subscribeToWorkshop(Object) {                
        return this.http.get(this.apiUrl + '/api/CareerReadyWorkshop/SubscribeToWorkshops?email=' + Object);
    }

    /*****************************************************************
     getCareeWorkshopEvents()
     purpose : this method will get all the event for calendar from API
     *******************************************************************/
    getCareeWorkshopEvents() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        return this.http.get(this.apiUrl + '/api/CareerReadyWorkshop/getAllWorkshops', httpOptions);
    }

    /*****************************************************************
     registerToWorkshops()
     purpose : this method will Add user detail for workshop
     *******************************************************************/
    registerToWorkshops(Object) {      
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        const body = JSON.stringify(Object);
        return this.http.post(this.apiUrl + '/api/CareerReadyWorkshop/RegisterForWorkshop', body, options);
    }

    /*****************************************************************
     saveWhatsapp()
     purpose : this method will Add user WhatsApp Number
     *******************************************************************/
    saveWhatsapp(registrationId, whatsAppNumber) {        
        return this.http.get(this.apiUrl + '/api/CareerReadyWorkshop/SaveWhatsapp?registrationId=' + registrationId + '&whatsApp=' + whatsAppNumber);
    }

    /*****************************************************************
    saveCallRequests()
    purpose : this method will Add call detail for counsellor
    *******************************************************************/
    saveCallRequests(Object) {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };
        const body = JSON.stringify(Object);
        return this.http.post(this.apiUrl + '/api/Admin/SaveCallRequests', body, options);
    }
    /**
     * Get career List
     */
     GetCareerList(key) {                
        return this.http.get(this.apiUrl + '/api/Contents/SearchAllCareersByGrouping?careerPath=' + key);
    }
   /******************************************************************************************
    * getPreferenceForStudentId()
    * purpose - to get the preferece of user for three step popup
    ******************************************************************************************/
   getPreferenceForStudentId() {
     const httpOptions = {
       headers: new HttpHeaders({
         'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
       })
     };
     return this.http.get(this.apiUrl + '/api/Students/GetCareerSessionPreference',
       httpOptions).pipe(retry(3), catchError(this.handleError));;
   }
    /***********************************************************************************************
    * for-counsellors-new Page 
    * SaveCounsellorLead
   *************************************************************************************************/
   saveCounsellorLead(formData) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
            })
        };
        // var body=JSON.stringify(formData)
        return this.http.post(this.apiUrl + '/api/CounsellorLead/SaveCounsellorLead', JSON.stringify(formData), httpOptions);
    }

    getCounsellorLeadByPhoneNo(PhoneNo) {
        return this.http.get
            (this.apiUrl + '/api/CounsellorLead/GetCounsellorLeadByPhoneNumber?phoneNo=' + PhoneNo).pipe(retry(3), catchError(this.handleError));
    }
}



