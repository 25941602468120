import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { VerificationComponent } from './verification/verification.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SetToRollComponent } from './set-to-roll/set-to-roll.component';
import { PostLoginComponent } from './post-login/post-login.component';
import { LoginErrorComponent } from './login-error/login-error.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { LeadFormComponent } from './lead-form/lead-form.component';
import { NewLoginComponent } from './new-login/new-login.component';
import { NewRegistrationComponent } from './new-registration/new-registration.component';
import { NewRegisterationComponent } from './new-registeration/new-registeration.component';



const accountsRoutes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login33', component: LoginComponent },

    { path: 'register', component: NewRegistrationComponent },
    { path: 'new-register', component: NewRegistrationComponent },
    { path: 'login', component: NewLoginComponent },
    { path: 'onboarding', component: OnboardingComponent },
    { path: 'verification', component: VerificationComponent },
    { path: 'all-set-to-roll', component: SetToRollComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'post-login', component: PostLoginComponent },
    { path: 'error', component: LoginErrorComponent },
    { path: 'get-started', component: GetStartedComponent },
    { path: 'lead-form', component: LeadFormComponent },

    // { path: 'ims', component: ImsRegisterComponent }
];

@NgModule({
    imports: [
        RouterModule.forChild(accountsRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class AccountsRoutingModule { }
