import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Pipe({ name: 'SpecialCareerPipe' })
export class SpecialCareerPipe implements PipeTransform {
    public specCareer;
    constructor(private sanitized: DomSanitizer) { }
    transform(value) {
        var specCareerPrefix = value.relatedCareerPath.replace(/[^\w]/gi, '-');
        var career = value.linkId.replace(specCareerPrefix + '-', '');
        this.specCareer = career.replace(/-/g, ' ');
        return this.specCareer;
    }
}
