import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/common/common.service';
import { StorageService } from 'src/app/common/storage-service';

@Component({

  selector: 'app-new-footer',
  templateUrl: './new-footer.component.html',
  styleUrls: ['./new-footer.component.css']
})
export class NewFooterComponent implements OnInit {
  // links: any = ['8th-9th Students',
  //   '10th-12th Students',
  //   'College Graduates',
  //   'Working Professionals',
  //   'Study Abroad',
  //   'CV Building',
  //   'Corporate Partnerships',
  //   'School Partnerships',
  //   'CounsellorTraining & Certifications']
  //     locations:any=["Career Counselling in Delhi","Career Counselling in Gurgaon","Career Counselling in Chandigarh",
  //   "Career Counselling in Jaipur","Career Counselling in Mumbai","Career Counselling in Thane","Career Counselling in Pune","Career Counselling in Bangalore","Career Counselling in Chennai","Career Counselling in Indore","Career Counselling in Nagpur","Career Counselling in Ahmedabad","Career Counselling in Kolkata","Career Counselling in Vadodara","Career Counselling in Surat"
  // ]
  links: any = [{ name: '8th-9th Students', route: '/ClassEightNine' }, { name: '10th-12th Students', route: '/ClassTenTwelve' }, { name: 'College Graduates', route: '/CollegeGraduates' }, { name: 'Working Professionals', route: '/WorkingProfessionals' }, { name: 'College Guidance', route: '/CollegeAdmissions' }, { name: 'CV Building', route: '/JobReadyPlans' }, { name: 'Corporate Partnerships', route: '/partnerships/educators' }, { name: 'School Partnerships', route: '/partnerships/corporates' },
  { name: 'CounsellorTraining & Certifications', route: '/partnerships/counsellors' }]
  locations: any = [{ name: "Career Counselling in Delhi", route: '/career-counselling-in-delhi' }, { name: "Career Counselling in Hyderabad", route: '/career-counselling-in-hyderabad' }, { name: "Career Counselling in Chandigarh", route: '/career-counselling-in-chandigarh' }, { name: "Career Counselling in Jaipur", route: '/career-counselling-in-jaipur' }, { name: "Career Counselling in Mumbai", route: '/career-counselling-in-mumbai' }, { name: "Career Counselling in Pune", route: '/career-counselling-in-pune' }, { name: "Career Counselling in Bangalore", route: '/career-counselling-in-bangalore' }, { name: "Career Counselling in Chennai", route: '/career-counselling-in-chennai' }, { name: "Career Counselling in Indore", route: '/career-counselling-in-indore' }, { name: "Career Counselling in Nagpur", route: '/career-counselling-in-nagpur' }, { name: "Career Counselling in Ahmedabad", route: '/career-counselling-in-ahmedabad' }, { name: "Career Counselling in Kolkata", route: '/career-counselling-in-kolkata' }, { name: "Career Counselling in Vadodara", route: '/career-counselling-in-vadodara' }, { name: "Career Counselling in Surat", route: '/career-counselling-in-surat' }
  ]
  public blogUrlToken = "https://blog.mentoria.com"

  public careerURL: any = "https://blog.mentoria.com/career"
  public authToken: any = ''
  public _opened: boolean = false;

  constructor(public commonService: CommonService, private storageService: StorageService,) { }

  ngOnInit() {


    let authToken = this.storageService.get('access_token');
    if (authToken !== '') {
      this.blogUrlToken = this.blogUrlToken + "?token=" + authToken
      this.careerURL = this.careerURL + "?token=" + authToken
    }
  }

  public _toggleOpened(): void {
    this._opened = !this._opened;
  }
  getPreLoginLink(path) {
    debugger
    this.authToken = this.storageService.get("access_token") || '';
    let url = this.commonService.getpreloginAppURL()

    let newPath = url + '/redirect?authToken=' + this.authToken + '&redirectURl=' + encodeURI(url + path);
    return newPath
  }

}
