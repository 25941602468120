import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css','../newcareer/newcareer.component.css','../career-overview-rewamp.module.css']
})
export class RolesComponent implements OnInit {
  @Input() path
  colorsArray: any[];
  
  constructor() { }

  ngOnInit() {
    this.colorsArray = [
      '#9c56b8',
      '#2d97de',
      '#ff7a3c',
      '#ff6396',
      '#58dd9e',
      '#2ddede',
      '#ff7a3c',
      '#ff6396',
      '#9c56b8',
      '#2d97de',
      '#ff7a3c',
      '#ff6396',
      '#58dd9e',
      '#2ddede',
      '#ff7a3c',
      '#ff6396',
      '#9c56b8',
      '#2d97de',
      '#ff7a3c',
      '#ff6396',
      '#58dd9e',
      '#2ddede',
      '#ff7a3c',
      '#ff6396'
    ];
    console.log("app-roles=>",this.path)
  }

}
