import { StorageService } from '../common/storage-service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { CommonService } from '../common/common.service';
import { catchError, retry } from 'rxjs/operators';

@Injectable()
export class ImsService {

  private apiUrl = '';
  private stsUrl = '';

  constructor(private http: HttpClient, private commonService: CommonService, private localStorageService: StorageService ) {
    this.apiUrl = this.commonService.getApiUrl();
    this.stsUrl = this.commonService.getStsUrl();
  }

// Login API
  public login(username, password) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };
    let data = 'username=' + username + '&password=' + password +
      '&grant_type=password&client_id=Mentoriaapi&client_secret=secret' +
      '&scope=openid Mentoriaapi profile idmgr roles';
    return this.http.post(this.stsUrl + '/core/connect/token', data, httpOptions).pipe(retry(3), catchError(this.handleError));
  }
// Registration API
  RegisterStudent (studentObj) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
      let body = JSON.stringify(studentObj);
    return this.http.post(this.apiUrl + '/api/Accounts/RegisterStudent',
      body, httpOptions).pipe(retry(3), catchError(this.handleError));
  }

// Check if email already exist
 public CheckEmailExists (email) {
   return this.http.get
     (this.apiUrl + '/api/Accounts/IsEmailExist?emailId=' + email).pipe(retry(3), catchError(this.handleError));
  }

// Check if phone already exist
 public CheckPhoneExists (phone) {
   return this.http.get
     (this.apiUrl + '/api/Accounts/IsPhoneExist?phoneNo=' + phone).pipe(retry(3), catchError(this.handleError));
  }

  async emailExists(email): Promise<boolean> {
    const response = await this.http.get(this.apiUrl + '/api/Accounts/IsEmailExist?emailId=' + email).toPromise();
      if (response['Message'] === 'True') {
          return true;
        } else {
          return false;
        }
}
  async phoneExists (phone): Promise<boolean> {
    const response = await this.http.get(this.apiUrl + '/api/Accounts/IsPhoneExist?phoneNo=' + phone).toPromise();
      if (response['Message'] === 'True') {
          return true;
        } else {
          return false;
        }
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

}

