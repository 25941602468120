import { StorageService } from '../../common/storage-service';
import { Component, PLATFORM_ID, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { isPlatformBrowser } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { CommonService } from "../../common/common.service";
import { DomSanitizer } from '@angular/platform-browser';
import { CareerOverviewRewampService } from '../career-overview-rewamp.service';
import { ContentService } from '../../content/content.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-newcareer',
  templateUrl: './newcareer.component.html',
  styleUrls: ['./newcareer.component.css', '../career-overview-rewamp.module.css']
})
export class NewcareerComponent implements OnInit {


  specCareer: any;
  colorsArray: any[];
  public careerId;
  public pathObj;
  public URL1: any;
  // public careerpath: any[] = [];
  public path;
  public authToken;
  public careerNum;
  public accType;
  public searchObj;
  public path1;
  public path2;
  public path3;
  public student;
  public platformId;
  public desc;
  public careerArr;
  public LinkedIn;
  public activelink
  public serachRelatedCareer
  public relatedCareers
  public isBrowser: boolean;
  public overviewTitle = ''
  public showPage1 = false
  public showPage2 = false

  faceBookObj = {
    faceBook: "../../../assets/images/icons/facebook.png",
    faceBookHover: "../../../assets/images/icons/facebook-hover.png",
    faceBookBlue: false
  }
  InstaObj = {
    Insta: "../../../assets/images/icons/insta.png",
    InstaHover: "../../../assets/images/icons/insta-hover.png",
    InstaBlue: false
  }
  linkedInObj = {
    linkedIn: "../../../assets/images/icons/linkedin.png",
    linkedInHover: ".../../../assets/images/icons/linkedin-hover.png",
    linkedInBlue: false
  }

  twitterObj = {
    twitter: "../../../assets/images/icons/twitter.png",
    twitterHover: "../../../assets/images/icons/twitte-hover.png",
    twitterBlue: false
  }
  public relatedCareersGrouping
  constructor(private route: ActivatedRoute,
    private localStorage: StorageService,
    private commonService: CommonService,
    private careerOverviewService: CareerOverviewRewampService, private sanitizer: DomSanitizer,
    private router: Router, @Inject(PLATFORM_ID) platformId: string, private titleService: Title,
    private metaService: Meta, public contentService: ContentService,public sharedService:SharedService) {
    this.platformId = platformId;

    this.isBrowser = isPlatformBrowser(platformId);


    this.colorsArray = [
      '#9c56b8',
      '#2d97de',
      '#ff7a3c',
      '#ff6396',
      '#58dd9e',
      '#2ddede',
      '#ff7a3c',
      '#ff6396',
      '#9c56b8',
      '#2d97de',
      '#ff7a3c',
      '#ff6396',
      '#58dd9e',
      '#2ddede',
      '#ff7a3c',
      '#ff6396',
      '#9c56b8',
      '#2d97de',
      '#ff7a3c',
      '#ff6396',
      '#58dd9e',
      '#2ddede',
      '#ff7a3c',
      '#ff6396'
    ];
    this.platformId = platformId;


    this.authToken = this.localStorage.get('access_token');


    this.accType = this.localStorage.get('account_type');
    console.log(this.accType);

    this.careerArr = this.localStorage.get('career_arr');

    this.route.params.forEach((params: Params) => {
      this.careerId = params['id'];
      this.serachRelatedCareer = this.careerId.split("-")[0];

      // this.titleService.setTitle(this.careerId + ' - Career Information');
    });
    if (this.commonService.pathData !== '') {
      this.path = this.commonService.pathData;
      if (this.path.relatedCareerPath != undefined) {
        var specCareerPrefix = this.path.relatedCareerPath.replace(/[^\w]/gi, '-');
      }
      if (this.path.linkId != undefined) {
        var career = this.path.linkId.replace(specCareerPrefix + '-', '');
        this.specCareer = career.replace(/-/g, ' ');
      }
      this.setTags();
    } else {

      this.careerOverviewService.getStaticContentByCareerLinkId(this.careerId).subscribe(
        response => {
          this.path = response;
          if (this.path.relatedCareerPath != undefined) {
            var specCareerPrefix = this.path.relatedCareerPath.replace(/[^\w]/gi, '-');
          }
          if (this.path.linkId != undefined) {
            var career = this.path.linkId.replace(specCareerPrefix + '-', '');
            this.specCareer = career.replace(/-/g, ' ');
          }
          this.platformId = platformId;
          this.isBrowser = isPlatformBrowser(platformId);
          this.titleService.setTitle(this.path.MetaTitle);
          // let attributeSelector: string = 'name="description"';
          // this.metaService.removeTag(attributeSelector);
          // this.metaService.updateTag({
          //   name: 'description',
          //   content: this.path.MetaDescription

          // });
          this.setTags();
          console.log("metatata->", this.path.MetaDescription)
          let attributeSelector: string = 'name="description"';
          this.metaService.removeTag(attributeSelector);
          console.log("path=>", this.path)
          this.metaService.updateTag({
            name: 'description',
            content: this.path.MetaDescription
          });
          this.URL1 = commonService.getAppUrl() + '/career/' + this.path.linkId;
        });

    }




  }

  ngOnInit() {
    this.getStudent();
    // this.getSearchedCareerList();
    this.callRelatedArticlesGroupingApi(this.serachRelatedCareer);
    this.titleService.setTitle(this.path.MetaTitle);

    let attributeSelector: string = 'name="description"';
    this.metaService.removeTag(attributeSelector);

    // this.metaService.updateTag({
    //   name: "description",
    //   content: "Meet India's #1 Online Career Counselling & Career Guidance Platform. Get Counselling From Mentoria's Experts to Discover the Best Career Choice for you"
    // });
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  public explore(career) {
    console.log("career", career)
    let baseurl = this.commonService.getAppUrl();
    if (career.dType && career.dType === 'newStaticContent') {
      let url = baseurl + '/careers-new/' + career.linkId;
      console.log(" let url ", url)
      // window.location.href = url;
      return (url)
    } else {
      let url = baseurl + '/career/' + career.linkId;
      console.log(" let url ", url)
      // window.location.href = url;
      return (url)
    }
  }

  public setTags() {
    console.log(this.path.OverviewDescription);
    // this.titleService.setTitle(this.path.careerPath + ' - Career Information');
    var a = this.path.OverviewDescription.indexOf('<p');
    var b = this.path.OverviewDescription.indexOf('</p>');
    this.desc = this.path.OverviewDescription.slice(a + 3, b);
    console.log(this.desc);
    let attributeSelector: string = 'name="description"';
    // this.metaService.removeTag(attributeSelector);

    // this.metaService.addTag({ name: 'description', content: this.desc }, true);
    if (this.path.OverviewTitle != undefined) {
      let title = this.path.OverviewTitle.replace('<p>', '');

      title = title.replace('</p>', '');
      let OverviewTitle1 = title.toLowerCase();
      OverviewTitle1 = OverviewTitle1.split("who is")
      this.overviewTitle = OverviewTitle1[1];

      // this.titleService.setTitle(title);
      // this.metaService.addTag({ property: 'og:title', content: title });
    }
    // let title = this.path.OverviewTitle.replace('<p>', '');
    // title = title.replace('</p>', '');

    // this.metaService.addTag({ property: 'og:title', content: this.path.OverviewTitle });
    // this.metaService.addTag({ property: 'og:description', content: this.path.OverviewDescription });

    this.metaService.addTag({ property: 'og:description', content: this.desc });
    if (this.path.CareerPathImage !== null) {
      this.metaService.addTag({ property: 'og:image', content: this.path.CareerPathImage });
    }

    this.metaService.addTag({ property: 'og:url', content: this.commonService.getAppUrl() + '/career/' + this.careerId });
    this.metaService.addTag({ property: 'og:site_name', content: 'Mentoria Overview' });
    this.metaService.addTag({ property: 'og:locale', content: 'en_US' });
    this.metaService.addTag({ property: 'og:type', content: 'overview' });
    if (this.path.CareerPathImage !== null) {
      this.metaService.addTag({ property: 'og:image', content: this.path.CareerPathImage });
    }

    this.metaService.addTag({ property: 'twitter:card', content: 'summary' });
    this.metaService.addTag({ property: 'twitter:description', content: this.path.OverviewDescription });
    this.metaService.addTag({ property: 'twitter:title', content: this.path.OverviewTitle });
    if (this.path.CareerPathImage !== null) {
      this.metaService.addTag({ property: 'og:image', content: this.path.CareerPathImage });
    }
  }

  public getStudent() {
    if (this.authToken !== '' && this.accType === 'student') {
      this.careerOverviewService.GetStudentDetails().subscribe(
        response => {
          let anyObj1 = response;
          this.student = anyObj1;
          if (this.student.selectedCareerPaths.length === 1) {
            this.path1 = this.student.selectedCareerPaths[0].CareerPathUId;
          }
          if (this.student.selectedCareerPaths.length === 2) {
            this.path1 = this.student.selectedCareerPaths[0].CareerPathUId;
            this.path2 = this.student.selectedCareerPaths[1].CareerPathUId;
          }
          if (this.student.selectedCareerPaths.length === 3) {
            this.path1 = this.student.selectedCareerPaths[0].CareerPathUId;
            this.path2 = this.student.selectedCareerPaths[1].CareerPathUId;
            this.path3 = this.student.selectedCareerPaths[2].CareerPathUId;
          }
          console.log(this.student);
        },
        error => {

        });
    }
  }

  public goToCareers() {
    console.log(this.accType);
    if (this.accType === 'student' && this.careerArr === '3') {
      this.router.navigate(['/feeds/my-careers']);
    }
    if (this.accType === 'counsellor') {
      this.router.navigate(['/counsellor/discover/careers']);
    }
    if (this.accType === null || this.authToken === null || this.careerArr === '0') {
      // this.router.navigate(['/career']);
      this.sharedService.redirectNavURL('/career')
    }

  }

  home() {
    if (isPlatformBrowser(this.platformId)) {
      // console.log('user agent --- ' + window.navigator.userAgent );
      let ua = window.navigator.userAgent;

      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
        console.log('route changing to Mobile view');
        this.router.navigate(['home-mobile']);
      }
      else {
      //  this.router.navigate([this.commonService.getpreloginAppURL()]);
      let url = this.commonService.getpreloginAppURL()
      let newPath = url + '/redirect?authToken=' + this.localStorage.get('access_token') + '&redirectURl=' + encodeURI(url + '/login');
      window.open(newPath, "_self")
      }
    }
  }
  scroll(id) {
    this.activelink = id;
    if (id == 1) {

      window.scrollTo(0, document.getElementById("overview").offsetTop - 130);
    }
    if (id == 2) {

      window.scrollTo(0, document.getElementById("roles").offsetTop - 130);
    }
    if (id == 3) {

      window.scrollTo(0, document.getElementById("skills").offsetTop - 130);
    }
    if (id == 4) {

      window.scrollTo(0, document.getElementById("workenv").offsetTop - 130);
    }
    if (id == 5) {
      window.scrollTo(0, document.getElementById("growth").offsetTop - 130);
    }
    if (id == 6) {
      window.scrollTo(0, document.getElementById("payscale").offsetTop - 130);
    }
    if (id == 7) {
      window.scrollTo(0, document.getElementById("path").offsetTop - 130);
    }

  }
  transparentImageArray = [
    { id: 0, text: 'facebook', icon: '../../../assets/images/icons/facebook.png', green: '../../../assets/images/icons/facebook-hover.png', value: 'transparent' },
    { id: 1, text: 'insta', icon: '../../../assets/images/icons/insta.png', green: '../../../assets/images/icons/insta-hover.png', value: 'transparent' },
    { id: 2, text: 'linkedin', icon: '../../../assets/images/icons/linkedin.png', green: '../../../assets/images/icons/linkedin-hover.png', value: 'transparent' },
    { id: 3, text: 'twitter', icon: '../../../assets/images/icons/twitter.png', green: '../../../assets/images/icons/twitte-hover.png', value: 'transparent' },

  ]

  hoverImageArray = [
    { id: 0, text: 'facebook', icon: '../../../assets/images/icons/facebook.png', green: '../../../assets/images/icons/facebook-hover.png', value: 'transparent' },
    { id: 1, text: 'insta', icon: '../../../assets/images/icons/insta.png', green: '../../../assets/images/icons/insta-hover.png', value: 'transparent' },
    { id: 2, text: 'linkedin', icon: '../../../assets/images/icons/linkedin.png', green: '../../../assets/images/icons/linkedin-hover.png', value: 'transparent' },
    { id: 3, text: 'twitter', icon: '../../../assets/images/icons/twitter.png', green: '../../../assets/images/icons/twitte-hover.png', value: 'transparent' },

  ]
  setGreenImage(id, index) {
    let element = this.transparentImageArray.find(d => d.id === index);
    if (id === 0 || id === 1 || id === 2 || id === 3) {
      element.icon = JSON.parse(JSON.stringify(element.green));
      element.value = 'green';
      this.transparentImageArray.splice(index, 1);
      this.transparentImageArray.splice(index, 0, element);
    }
  }


  setTransparentImage(id, index) {
    let element = this.transparentImageArray.find(d => d.id === index);
    let greenElement = this.hoverImageArray.find(d => d.id === index);
    if (id === 0 || id === 1 || id === 2 || id === 3) {
      element.icon = JSON.parse(JSON.stringify(greenElement.icon));
      element.value = 'transparent';
      this.transparentImageArray.splice(index, 1);
      this.transparentImageArray.splice(index, 0, element);
    }
  }
  /**
   * call search career api to ge related careers
   */
  getSearchedCareerList() {

    let career: any;
    this.contentService.GetCareerList(this.serachRelatedCareer).subscribe(data => {
      career = data;
      this.relatedCareers = career.Result;
      console.log("Career List==>", career.Result)
      this.relatedCareers.forEach(element => {
        console.log("***", element.linkId)
      });

    })

  }

  /**Method To call getrelated articles grouping */
  callRelatedArticlesGroupingApi(career) {
    let result

    this.careerOverviewService.getRelatedArticlesGrouping(career).subscribe(data => {
      result = data
      this.relatedCareersGrouping = result.Result

    })

  }


}


