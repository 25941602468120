import { StorageService } from '../common/storage-service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CommonService } from '../common/common.service';
import { catchError, retry } from 'rxjs/operators';

@Injectable()
export class CareerOverviewRewampService {

  private apiUrl = '';
  private stsUrl = '';

  constructor(private http: HttpClient, private commonService: CommonService, private localStorageService: StorageService) {
    this.apiUrl = this.commonService.getApiUrl();
    this.stsUrl = this.commonService.getStsUrl();
  }


  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  //  getCareerPathBySearch(pathObj) {
  //       let headers = new Headers();
  //    headers.append('Content-Type', 'application/json');
  //   let authToken = this.localStorage.get('access_token');
  //   console.log(authToken);
  //   headers.append('Authorization', 'Bearer ' + authToken);
  //    let body = JSON.stringify(pathObj);
  //   console.log('body' + body);
  //   return this.http.post(this.apiUrl + '/api/Contents/SearchCareers', body,httpOptions)
  //     .map(this.extractData)
  //     .catch(this.handleError);
  // }
  getCareerPathBySearch(careerPathUId) {
    return this.http.get
      (this.apiUrl + '/api/Contents/GetStaticContent?careerPathUId=' + careerPathUId).pipe(retry(3), catchError(this.handleError));
  }
  getCareerPathForCounsellor(searchObj) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    let body = JSON.stringify(searchObj);
    console.log('body' + body);
    return this.http.post(this.apiUrl + '/api/Contents/Search', body, httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  // for GetStaticContent
  getStaticContentByCareerLinkId(careerId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    console.log(careerId);
    return this.http.get(this.apiUrl + '/api/Contents/GetStaticContentByLinkId?linkId=' + careerId, httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  // Get Student Details
  GetStudentDetails() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/api/Students/GetStudent',
      httpOptions).pipe(retry(3), catchError(this.handleError));
  }
  /**API to get the related articles grouping */
  getRelatedArticlesGrouping(career) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };

    return this.http.get(this.apiUrl + '/api/Contents/GetRelatedCareersOfGrouping?careerPath=' + career, httpOptions).pipe(retry(3), catchError(this.handleError));
  }


}
