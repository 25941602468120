import { StorageService } from '../common/storage-service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CommonService } from '../common/common.service';
import { catchError, retry } from 'rxjs/operators';
@Injectable()
export class BlogsService {
    private apiUrl = '';
    private stsUrl = '';
    public author = '';
    public authorNew = '';
    public position = 0;

    public currentCategory = '';

    constructor(private http: HttpClient, private commonService: CommonService, private localStorageService: StorageService) {
        this.apiUrl = this.commonService.getApiUrl();
        this.stsUrl = this.commonService.getStsUrl();
    }

    // Get Student Details
    GetStudentDetails() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
            })
        };
        return this.http.get(this.apiUrl + '/api/Students/GetStudent', httpOptions).pipe(retry(3), catchError(this.handleError));
    }
    private extractData(res: Response) {
        let body = res.json();
        return body || {};
    }

    // Get counsellor details
    getCounsellorDetails() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
            })
        };
        return this.http.get(this.apiUrl + '/api/Counsellors/GetCounsellor',
            httpOptions).pipe(retry(3), catchError(this.handleError));
    }

    getWordpressBlogs() {
        return this.http.get('https://blog.mentoria.com/wp-json/wp/v2/posts');

    }


    // get where to redirect student after login
    GetStudentPath() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
            })
        };
        return this.http.get(this.apiUrl + '/api/Students/GetStudentPath',
            httpOptions).pipe(retry(3), catchError(this.handleError));
    }

    GetAllCareerPaths() {
        return this.http.get(this.apiUrl + '/api/Contents/GetAllCareerPaths').pipe(retry(3), catchError(this.handleError));

    }

    GetAllBlogs(blogsFilterObj) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let body = JSON.stringify(blogsFilterObj);
        return this.http.post(this.apiUrl + '/api/Contents/SearchFeed', body, httpOptions).pipe(retry(3), catchError(this.handleError));
    }

    GetAllBlogList(blogsFilterObj) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let body = JSON.stringify(blogsFilterObj);
        return this.http.get(this.apiUrl + '/api/contents/searchfeedbygetmethod?query=' + body, httpOptions).pipe(retry(3), catchError(this.handleError));
    }

    GetBlogFilters() {
        return this.http.get(this.apiUrl + '/api/Contents/GetBlogFilters').pipe(retry(3), catchError(this.handleError));
    }

    GetBlogFiltersForCategory(category) {
        return this.http.get(this.apiUrl + '/api/Contents/GetBlogFiltersForCategory?category=' + category).pipe(retry(3), catchError(this.handleError));
    }

    getBlogCategories() {
        return this.http.get(this.apiUrl + '/api/Contents/getBlogCategories').pipe(retry(3), catchError(this.handleError));
    }

    getBlogById(newsUId) {
        return this.http.get
            (this.apiUrl + '/api/Contents/GetFeedById?feedUId=' + newsUId).pipe(retry(3), catchError(this.handleError));
    }

    getTopTrendingTags() {
        return this.http.get
            (this.apiUrl + '/api/Contents/GetTopTrendingTags?').pipe(retry(3), catchError(this.handleError));
    }

    getBlogByLinkId(linkId) {
        const httpOptions = {
            headers: new HttpHeaders({
                Authorization:
                    "Bearer " + this.localStorageService.get("access_token")
            })
        };
        return this.http.get
            (this.apiUrl + '/api/Contents/GetFeedByLinkId?linkId=' + linkId, httpOptions).pipe(retry(3), catchError(this.handleError));
    }

    savePartnershipRequests(request): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let body = JSON.stringify(request);
        return this.http.post(this.apiUrl + '/api/Admin/SavePartnershipRequests', body, httpOptions).pipe(retry(3), catchError(this.handleError));
    }

    SaveContentFeedback(feedback) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let body = JSON.stringify(feedback);
        return this.http.post(this.apiUrl + '/api/Students/SaveContentFeedback', body, httpOptions).pipe(retry(3), catchError(this.handleError));
    }




    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    }
    serachRelatedFeed(id) {
        let obj = {}
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        let body = JSON.stringify(obj);
        return this.http.post(this.apiUrl + '/api/Contents/SearchRelatedFeed?feedUId=' + id, body, httpOptions).pipe(retry(3), catchError(this.handleError));

    }
}
