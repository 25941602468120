import { StorageService } from "../../common/storage-service";
import { CommonService } from "./../../common/common.service";
import {
  Component,
  OnInit,
  Injector,
  Inject,
  PLATFORM_ID
} from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { model } from "./registration.model";
import {
  NgbModal,
  ModalDismissReasons,
  NgbModalRef
} from "@ng-bootstrap/ng-bootstrap";
import { AccountsService } from "../accounts.service";
import { isPlatformBrowser } from "@angular/common";
import { Title, Meta, MetaDefinition } from "@angular/platform-browser";
import {
  NG_VALIDATORS,
  Validator,
  Validators,
  AbstractControl,
  ValidatorFn
} from "@angular/forms";
import { AppService } from "../../app.service";

@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.css", "../accounts.module.css"]
})
export class RegistrationComponent implements OnInit {
  public steponecompleted = false;
  public account: model = new model();
  public cPassword = "";
  public tcChecked = false;
  public phoneExists = false;
  public emailExists = false;
  public loading = false;
  public accounttype;
  public id;
  public PackageId;
  public PackageType;
  public statusTitle;
  public statusValue;

  public platformId;
  public modalService;
  closeResult: string;
  public educational1;
  private modalRef: NgbModalRef;
  constructor(
    private router: Router,
    private accountService: AccountsService,
    private injector: Injector,
    @Inject(PLATFORM_ID) platformId: string,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private localStorageService: StorageService,
    private titleService: Title,
    private metaService: Meta,
    private appService: AppService
  ) {
    this.platformId = platformId;
    this.titleService.setTitle("Create Account - Mentoria");

    let attributeSelector: string = 'name="description"';
    this.metaService.removeTag(attributeSelector);

    this.metaService.updateTag(
      {
        name: "description",
        content:
          "Create an Account with Us with some easy steps to Discover and Begin your Career Journey so that you can Excel in your Career Path without any hesitation"
      },
    );

    if (isPlatformBrowser(this.platformId)) {
      this.modalService = this.injector.get(NgbModal);
    }
  }
  ngOnInit() {
    this.accounttype = "counsellor";
    this.route.queryParams.subscribe(params => {
      // Defaults to 0 if no query param provided. || 0
      this.PackageId = params["package"] || "";
      this.PackageType = params["packageType"] || "";
      const referralcode = params["referralCode"] || "";
      if (referralcode !== "") {
        this.account.ReferralCode = referralcode;
      }
    });

    if (this.PackageType === "basic") {
      this.statusValue = "8th-9th";
      this.statusTitle = "Study";
    } else if (this.PackageType === "advance") {
      this.statusValue = "10th-12th";
      this.statusTitle = "Study";
    }

    if (this.accounttype === "counsellor") {
      this.SignUpCounselor();
    } else {
      this.SignUpStudent();
    }
  }

  public signUpWithGoogle() {
    // const clientId = encodeURI("Mentoria.elp");
    // const scope = encodeURI("Mentoriaapi");
    // const redirectUri = encodeURI(
    //   this.commonService.getAppUrl() + "/accounts/post-login"
    // );
    // const responseType = encodeURI("token");
    // const acrValues = encodeURI("idp:Google");
    // const action = encodeURI("register");
    // const url =
    //   this.commonService.getStsUrl() +
    //   "/core/connect/authorize?client_id=" +
    //   clientId +
    //   "&scope=" +
    //   scope +
    //   "&redirect_uri=" +
    //   redirectUri +
    //   "&response_type=" +
    //   responseType +
    //   "&acr_values=" +
    //   acrValues +
    //   "&action=" +
    //   action;
    // if (isPlatformBrowser(this.platformId)) {
    //   window.location.href = url;
    // }
  }

  public signUpWithFacebook() {
    // const clientId = encodeURI("Mentoria.elp");
    // const scope = encodeURI("Mentoriaapi");
    // const redirectUri = encodeURI(
    //   this.commonService.getAppUrl() + "/accounts/post-login"
    // );
    // const responseType = encodeURI("token");
    // const acrValues = encodeURI("idp:Facebook");
    // const action = encodeURI("register");
    // const url =
    //   this.commonService.getStsUrl() +
    //   "/core/connect/authorize?client_id=" +
    //   clientId +
    //   "&scope=" +
    //   scope +
    //   "&redirect_uri=" +
    //   redirectUri +
    //   "&response_type=" +
    //   responseType +
    //   "&acr_values=" +
    //   acrValues +
    //   "&action=" +
    //   action;
    // if (isPlatformBrowser(this.platformId)) {
    //   window.location.href = url;
    // }
  }

  SignUpCounselor() {
    this.localStorageService.set("account_type", "counsellor");
    this.accounttype = this.localStorageService.get("account_type");
  }
  SignUpStudent() {
    // this.localStorageService.set("account_type", "student");
    // this.accounttype = this.localStorageService.get("account_type");
  }

  public hello() {
    // alert('hello');
    this.statusValue = "";
  }
  public hello1() {
    this.account.CurrentClass = "";
  }

  public Signup() {
    this.loading = true;
    // this.appService.subscribeToNotification('register component');
    this.accounttype = this.localStorageService.get("account_type");
    if (this.accounttype === "counsellor") {
      this.accountService.RegisterCounsellor(this.account).subscribe(
        res => {
          this.accountService
            .login(this.account.Mobile, this.account.Password)
            .subscribe(
              response => {
                this.loading = false;
                
                this.localStorageService.set(
                  "access_token",
                  response["access_token"]
                );
                this.localStorageService.set("account_type", "counsellor");
                this.commonService.login();
                this.router.navigate(["/accounts/verification"]);
              },
              error => {
                this.loading = false;
                
              }
            );
        },
        error => {
          this.loading = false;
          
        }
      );
    } else {
      // for new education status

      if (this.statusTitle === "Study") {
        if (
          this.statusValue === "10th-12th" ||
          this.statusValue === "8th-9th"
        ) {
          this.account.CurrentClass = this.statusValue;
        } else if (
          this.statusValue === "College/University" ||
          this.statusValue === "Certificate/Diploma"
        ) {
          this.account.CurrentClass = "College Graduate";
        }

        this.account.CurrentStatus = this.statusTitle + "-" + this.statusValue;
      } else if (this.statusTitle === "Work") {
        this.account.CurrentClass = "Working Professional";
        this.account.CurrentStatus = this.statusTitle + "-" + this.statusValue;
      } else if (this.statusTitle === "None") {
        this.account.CurrentClass = "Other";
        this.account.CurrentStatus = "On a Break";
      }

      // new education status ends here
      this.accountService.RegisterStudent(this.account).subscribe(
        res => {
          console.log(res);
          this.accountService
            .login(this.account.Mobile, this.account.Password)
            .subscribe(
              response => {
                this.loading = false;
                
                this.localStorageService.set(
                  "access_token",
                  response["access_token"]
                );
                this.localStorageService.set("account_type", "student");
                this.router.navigate(["/accounts/verification"]);
              },
              error => {
                this.loading = false;
                
              }
            );
        },
        error => {
          this.loading = false;
          
        }
      );
    }
  }
  
  public checkPhoneExists() {
    this.accountService.CheckPhoneExists(this.account.Mobile).subscribe(
      response => {
        if (response["Message"] === "True") {
          this.phoneExists = true;
        } else {
          this.phoneExists = false;
        }
      },
      error => {
        
      }
    );
  }

  public checkEmailExists() {
    this.accountService.CheckEmailExists(this.account.Email).subscribe(
      response => {
        if (response["Message"] === "True") {
          this.emailExists = true;
        } else {
          this.emailExists = false;
        }
      },
      error => {
        
      }
    );
  }
  onlyNumber() {
    this.account.Mobile = this.account.Mobile.replace(/[^0-9]/g, "");
  }
  open(content) {
    this.modalRef = this.modalService.open(content, { size: "lg" });
    this.modalRef.result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
