import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { CommonService } from '../common/common.service';
import { ContentService } from '../content/content.service';
import { Meta, Title } from "@angular/platform-browser";
@Component({
  selector: 'app-career-list',
  templateUrl: './career-list.component.html',
  styleUrls: ['./career-list.component.css']
})
export class CareerListComponent implements OnInit {
  sections: any = []
  searchPath: any = ""
  scrollid
  loading: boolean = false
  platformId: string;

  constructor(public contentService: ContentService, public commonService: CommonService, @Inject(PLATFORM_ID) platformId: string, private titleService: Title, private metaService: Meta) {
    this.platformId = platformId;
    this.titleService.setTitle("350+ Best Career Options for the Future [2022] - Mentoria");

    let attributeSelector: string = 'name="description"';
    this.metaService.removeTag(attributeSelector);

    this.metaService.updateTag(
      {
        name: "description",
        content: "Choosing the best career option can be tough. We've collected a complete list of career options to help select the right career for you. Check it out!"
      });
  }

  ngOnInit() {
    this.getSearchedCareerList();
  }


  checkPressedKeyIsEnter(event) {
    if (event.keyCode === 13) {
      this.getSearchedCareerList();
    }
  }

  getSearchedCareerList() {
    this.loading = true;
    let career: any;
    this.contentService.GetCareerList(this.searchPath).subscribe(data => {
      career = data;
      this.sections = career.Result;
      this.loading = false
    })
  }
  /***
   * make href link
   */
  public explore(career) {

    let baseurl = 'https://app.mentoria.com';
    if (career.dType && career.dType === 'newStaticContent') {
      let url = baseurl + '/careers-new/' + career.linkId;
      // window.location.href = url;
      return (url)
    } else {
      let url = baseurl + '/career/' + career.linkId;
      // window.location.href = url;
      return (url)
    }
  }

  scrollToDiv(path, i) {
    let scrollToid = path + "-" + i;
    this.scrollid = path;
    window.scrollTo(0, document.getElementById(scrollToid).offsetTop - 180);
  }
}
