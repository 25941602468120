import { isPlatformBrowser, Location } from '@angular/common';
//  import { StudentService } from '../../../student/student.service';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, Inject, PLATFORM_ID, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule, DomSanitizer, Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { BlogsService } from '../blog.service';
import { StorageService } from '../../common/storage-service';
import { RoutingState } from '../../common/routingState';
import { CommonService } from '../../common/common.service';
import swal from 'sweetalert2';
import { MatDialog, MatDatepickerInputEvent } from '@angular/material';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NgForm } from '@angular/forms';

declare var $: any;
@Component({
  selector: 'app-blog-article-detail',
  templateUrl: './blog-article-detail.component.html',
  styleUrls: ['./blog-article-detail.component.css']
})

export class BlogArticleDetailComponent implements OnInit {

  @Input() blog: any
  timing = [
    '09:00 am - 09:30 am',
    '09:30 am - 10:00 am',
    '10:00 am - 10:30 am',
    '10:30 am - 11:00 am',
    '11:00 am - 11:30 am',
    '11:30 am - 12:00 pm',
    '12:00 pm - 12:30 pm',
    '12:30 pm - 01:00 pm',
    '01:00 pm - 01:30 pm',
    '01:30 pm - 02:00 pm',
    '02:00 pm - 02:30 pm',
    '02:30 pm - 03:00 pm',
    '03:00 pm - 03:30 pm',
    '03:30 pm - 04:00 pm',
    '04:00 pm - 04:30 pm',
    '04:30 pm - 05:00 pm',
    '05:00 pm - 05:30 pm',
    '05:30 pm - 06:00 pm',
    '06:00 pm - 06:30 pm',
    '06:30 pm - 07:00 pm',
    '07:00 pm - 07:30 pm',
    '07:30 pm - 08:00 pm',
    '08:00 pm - 08:30 pm',
    '08:30 pm - 09:00 pm'
  ];

  public loading = false;
  public partnerType;
  public requestType;
  public cdateArray = [];
  public showErrorMessage: boolean;

  public callPartnerType;
  public callRequestType;

  public emailPartnerType;
  public emailRequestType;


  // For Get a call for meeting
  public eFullName;
  public eDesignation;
  public eOrganization;
  public eCity;
  public ePhoneNumber;
  public eDateRequested;
  public eTimeRequested;
  public eEmailId;

  public dateRequested;

  //For get a call from us

  public callFullName;
  public callDesignation;
  public callOrganization;
  public callCity;
  public callPhoneNumber;

  //For email

  public emailFullName;
  public emailDesignation;
  public emailCity;
  public emailOrganization;
  public Email;

  public isHovering = 0;
  BlogsL: any;
  public url;
  public str;
  public authToken;
  public uid;
  public title;
  public currentPath;
  public feed; contentImageUrl;
  public sharing: number;
  public facebookCommentUrl = '';
  public platformId;
  closeResult: string;
  fbButton;
  twitterButton;
  public accType;
  public careerArr;
  // public status: StudentStatus = new StudentStatus();
  public testGiven;
  public previousRoute;
  empty: boolean;
  public isSidebarOpened = false;

  public feedBack = {
    'studentName': '',
    'studentEmail': '',
    'studentMobile': '',
    'rating': 0,
    'comment': ''
  };
  linkId: any;
  public currentCategory = '';
  public isBrowser: boolean
  faceBookObj = {
    faceBook: "../../../assets/images/icons/facebook.png",
    faceBookHover: "../../../assets/images/icons/facebook-hover.png",
    faceBookBlue: false
  }
  InstaObj = {
    Insta: "../../../assets/images/icons/insta.png",
    InstaHover: "../../../assets/images/icons/insta-hover.png",
    InstaBlue: false
  }
  linkedInObj = {
    linkedIn: "../../../assets/images/icons/linkedin.png",
    linkedInHover: ".../../../assets/images/icons/linkedin-hover.png",
    linkedInBlue: false
  }

  twitterObj = {
    twitter: "../../../assets/images/icons/twitter.png",
    twitterHover: "../../../assets/images/icons/twitte-hover.png",
    twitterBlue: false
  }
  public URL1: any;

  public showPage1 = false
  public showPage2 = false

  constructor(private modalService: NgbModal, private router: Router, public blogService: BlogsService,
    private titleService: Title, private metaService: Meta, private breakpointObserver: BreakpointObserver, private route: ActivatedRoute, public sanitizer: DomSanitizer, private localStorage: StorageService, @Inject(PLATFORM_ID) platformId: string, private routingState: RoutingState, private location: Location, public commonService: CommonService) {
    this.platformId = platformId;
    this.route.params.subscribe(params => {
      let uId = params['id'] || '';
      if (uId !== '') {
        this.uid = uId;

        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0, 0);
        }
      }
    });

    this.route.queryParams.subscribe((params: Params) => {
      let category = params['category'] || '';
      if (category !== '') {
        this.currentCategory = category;
      }
      // this.titleService.setTitle(this.uid);
    });

    this.careerArr = this.localStorage.get('career_arr');

    this.authToken = this.localStorage.get('access_token');
    if (this.authToken) {
      this.blogUrlToken = this.blogUrlToken + "?token=" + this.authToken
      window.location.href = this.blogUrlToken
    }
    else {
      window.location.href = this.blogUrlToken
    }
    this.accType = this.localStorage.get('account_type');

    this.currentPath = 'timeLine';
    routingState.loadRouting();
  }

  public blogFilterObj;
  public Blogs;
  public relatedLinks;
  public relatedBlogList = [];
  public newlink;
  appUrl
  blogId
  public blogUrlToken = "https://blog.mentoria.com"
  blogRelatedArticle: any;

  ngOnInit() {
    this.blogUrlToken = 'https://blog.mentoria.com/' + this.blog.linkId
    this.previousRoute = this.routingState.getPreviousUrl();
    this.appUrl = this.commonService.getAppUrl()
    this.getBlogArticleDetails();
    console.log("data=>", this.blog)
    this.URL1 = this.commonService.getAppUrl() + '/blog/' + this.blog.linkId + '?category=' + this.blogService.currentCategory

    this.blogId = this.blog.id;
    this.titleService.setTitle(this.blog.metaTitle);
    let attributeSelector: string = 'name="description"';
    this.metaService.removeTag(attributeSelector);
    this.metaService.updateTag({

      name: 'description',

      content: this.blog.metaDescription

    });
    this.searchRelatedFeed()




  }

  sendGAEventForPartnershipTracking() {
    (<any>window).gtag('event', 'conversion',
      {
        'send_to': 'AW-849928441/cEXECPv2iqgBEPnBo5UD'
      });

  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.facebookCommentUrl = window.location.href;
        (function (d, s, id) {
          let js, fjs = d.getElementsByTagName(s)[0];
          js = d.createElement(s); js.id = id;
          js.src = '//connect.facebook.net/en_US/all.js#xfbml=1&version=v2.4&appId=114215202075258';
          if (d.getElementById(id)) {
            delete (<any>window).FB;
            fjs.parentNode.replaceChild(js, fjs);
            // return;
          } else {
            fjs.parentNode.insertBefore(js, fjs);
          }
        }(document, 'script', 'facebook-jssdk'));
      }, 2000);
    }
  }

  ngOnDestroy() {
    this.blogService.currentCategory = '';
    this.commonService.saveFeedback('blog', this.blog);

  }


  getBlogArticleDetails() {


    this.titleService.setTitle(this.blog.title);
    // this.metaService.addTag({ property: 'description', content: this.blog.title });
    this.metaService.addTag({ property: 'og:title', content: this.blog.title }); this.blog.snippet = this.blog.snippet.replace('<p>', '');
    this.blog.snippet = this.blog.snippet.replace('</p>', '');
    this.metaService.addTag({ property: 'og:description', content: this.blog.snippet });
    this.metaService.updateTag({ property: 'og:image', content: this.blog.imageUrl });
    this.metaService.addTag({ property: 'og:image:width', content: '533' });
    this.metaService.addTag({ property: 'og:image:height', content: '320' });
    this.metaService.updateTag({ property: 'og:image:type', content: 'image/jpeg' });
    this.metaService.addTag({ property: 'og:url', content: this.commonService.getAppUrl() + '/blog/' + this.blog.linkId });
    this.metaService.addTag({ property: 'og:site_name', content: 'The Mentoria Blog' });
    this.metaService.addTag({ property: 'og:locale', content: 'en_US' });
    this.metaService.addTag({ property: 'og:type', content: 'website' });
    this.metaService.addTag({ property: 'og:image:secure_url', content: this.blog.imageUrl });

    this.metaService.addTag({ property: 'twitter:card', content: 'summary' });
    this.metaService.addTag({ property: 'twitter:description', content: this.blog.snippet });
    this.metaService.addTag({ property: 'twitter:title', content: this.blog.title });
    this.metaService.addTag({ property: 'twitter:image', content: this.blog.imageUrl });
    this.getAllBlogsData();
    var contententArray = this.blog.content.split('</p>');
    this.contentImageUrl = contententArray[2];

    for (var i = 0; i < this.blog.related.length; i++) {
      if (this.blog.related[i] === '' || this.blog.related[i] === ' ') {
        this.empty = true;
      } else {
        this.empty = false;
        break;
      }
    }

  }

  getAllBlogsData() {
    let date = Date.now();
    const epoch = Math.ceil(date / 1000);
    this.blogFilterObj = {
      'QueryString': 'tagid:blogs || tagid:blog',
      Take: 1000,
      Skip: 0,
      "Filters": [
        {
          "FilterSets": [
            {
              "Name": "isPublished",
              "Operator": "eq",
              "LOperator": "OR",
              "Type": "STRING",
              "Value": "true"

            }, {
              'Name': 'publishDate',
              'Operator': 'le',
              'LOperator': 'AND',
              'Value': epoch.toString(),
              'Type': 2
            }
          ]
        }
      ]
    };

    this.blogService.GetAllBlogs(this.blogFilterObj).subscribe(
      data1 => {
        this.Blogs = data1['Result'];
        this.createBlogHREF()
        this.relatedLinks = this.blog.related;
        if (this.relatedLinks.length !== 0) {
          this.relatedLinks.forEach(link => {
            this.newlink = link.replace(' ', '_');
            if (link !== '') {
              var Blogs = this.Blogs.filter(blog => blog.linkId === link);
              this.relatedBlogList = this.relatedBlogList.concat(Blogs);
              console.log("Related Career=>", this.relatedBlogList)
            }
          });
        }
      },
      error => {

      });


  }

  createBlogHREF() {
    // let appUrl = 'http://localhost:4200'
    let appUrl = this.commonService.getAppUrl()
    this.Blogs.forEach(blog => {
      blog['blogHref'] = appUrl + '/blog/' + blog.linkId + '?category=' + this.blogService.currentCategory
    })
  }

  showAuthor(name) {
    console.log(name);
    this.router.navigate(['blog/author'], { queryParams: { name: name } });
  }

  closeShareIt(id) {
    this.sharing = -1;
  }

  goToSearch(tagId) {
    this.commonService.blogTag = tagId;
    this.router.navigate(['/blog'], { queryParams: { tag: tagId } });
  }

  saveFeedback(uid) {
    this.blogService.SaveContentFeedback(this.feedBack).subscribe(
      response => {

      },
      error => {

      });
  }

  openfeedbackModal(feedbackModal) {
    this.modalService.open(feedbackModal, { size: 'lg' });
  }

  public onDateChange(event: MatDatepickerInputEvent<Date>) {
    this.cdateArray = [];
    const selectedDate = event.value;

    let date = new Date(selectedDate);
    let miliseconds = date.getTime();
    this.dateRequested = miliseconds / 1000;
    this.cdateArray.push(this.dateRequested);
  }

  public getCallForMeeting(name, designation, organization, city, phonenumber, date, time, meetForm: NgForm) {
    this.loading = true;

    this.partnerType = 'Corporate';
    this.requestType = 'Get a call for meeting';
    if (name === undefined || designation === undefined || organization === undefined || city === undefined || phonenumber === undefined || date === undefined || time === undefined) {
      swal({
        type: 'error',
        text: 'Please fill out all fields.',
        confirmButtonClass: 'submit-purple-swal-btn'
      });

      this.loading = false;
    } else {
      this.eFullName = name;
      this.eDesignation = designation;
      this.eOrganization = organization;
      this.ePhoneNumber = phonenumber;
      this.eCity = city;
      this.eDateRequested = this.cdateArray[0];
      this.eTimeRequested = time;

      const educatorRequestData = {
        partnerType: this.partnerType,
        requestType: this.requestType,
        fullName: this.eFullName,
        designation: this.eDesignation,
        organization: this.eOrganization,
        city: this.eCity,
        phoneNumber: this.ePhoneNumber,
        requestedDate: this.eDateRequested,
        timeSlot: this.eTimeRequested
      }

      this.blogService.savePartnershipRequests(educatorRequestData).subscribe(
        response => {

          let data = response;
          console.log(data);

          this.partnerType = '';
          this.requestType = '';
          this.eFullName = '';
          this.eDesignation = '';
          this.eOrganization = '';
          this.eCity = '';
          this.ePhoneNumber = '';
          this.eDateRequested = '';
          this.eTimeRequested = '';

          this.cdateArray = [];
          this.loading = false;
          this.showErrorMessage = false;
          meetForm.resetForm();


          swal({
            type: 'success',
            text: 'Thank you for sharing your details. We’ll get in touch with you soon!',
            confirmButtonClass: 'submit-purple-swal-btn'
          }).then(() => {
            this.sendGAEventForPartnershipTracking()
          });
        },
        error => {

        });
    }

  }

  public getCallFromUs(n, d, o, c, p, callForm: NgForm) {
    this.loading = true;
    this.callPartnerType = 'Corporate';
    this.callRequestType = 'Get a call from us';
    if (n === undefined || d === undefined || c === undefined || o === undefined || p === undefined) {
      swal({
        type: 'error',
        text: 'Please fill out all fields.',
        confirmButtonClass: 'submit-purple-swal-btn'
      });
      this.loading = false;
    } else {
      this.callFullName = n;
      this.callDesignation = d;
      this.callOrganization = o;
      this.callCity = c;
      this.callPhoneNumber = p;

      const educatorRequestData = {

        partnerType: this.callPartnerType,
        requestType: this.callRequestType,

        fullName: this.callFullName,
        designation: this.callDesignation,
        organization: this.callOrganization,
        city: this.callCity,
        phoneNumber: this.callPhoneNumber,
      }

      this.blogService.savePartnershipRequests(educatorRequestData).subscribe(
        response => {

          let data = response;
          console.log(data);

          this.callPartnerType = '';
          this.callRequestType = '';
          this.callFullName = '';
          this.callDesignation = '';
          this.callOrganization = '';
          this.callCity = '';
          this.callPhoneNumber = '';
          callForm.resetForm();

          swal({
            type: 'success',
            text: 'Thank you for sharing your details. We’ll get in touch with you soon!',
            confirmButtonClass: 'submit-purple-swal-btn'
          }).then(() => {
            this.sendGAEventForPartnershipTracking()
          });
          this.loading = false;
          this.showErrorMessage = false;
        },
        error => {

        });
    }

  }

  public getEmail(name, des, org, city, email, emailform: NgForm) {
    this.loading = true;
    this.emailPartnerType = 'Corporate';
    this.emailRequestType = 'Get an email from us';
    if (name === undefined || des === undefined || org === undefined || city === undefined || email === undefined) {
      swal({
        type: 'error',
        text: 'Please fill out all fields.',
        confirmButtonClass: 'submit-purple-swal-btn'
      });
      this.loading = false;
    } else {
      this.emailFullName = name;
      this.emailDesignation = des;
      this.emailOrganization = org;
      this.emailCity = city;
      this.Email = email;

      const educatorRequestData = {

        partnerType: this.emailPartnerType,
        requestType: this.emailRequestType,
        fullName: this.emailFullName,
        designation: this.emailDesignation,
        organization: this.emailOrganization,
        city: this.emailCity,
        email: this.Email,
      }

      this.blogService.savePartnershipRequests(educatorRequestData).subscribe(
        response => {

          let data = response;
          console.log(data);

          this.emailPartnerType = '';
          this.emailRequestType = '';
          this.emailFullName = '';
          this.emailDesignation = '';
          this.emailCity = '';
          this.emailOrganization = '';
          this.Email = '';
          emailform.resetForm();

          swal({
            type: 'success',
            text: 'Thank you for sharing your details. We’ll get in touch with you soon!',
            confirmButtonClass: 'submit-purple-swal-btn'
          }).then(() => {
            this.sendGAEventForPartnershipTracking()
          });
          this.loading = false;
          this.showErrorMessage = false;
        },
        error => {

        });
    }
  }

  get isMobile() {
    return this.breakpointObserver.isMatched('(max-width: 959px)');
  }


  public tabChangedEvent(index: number): void {
    console.log('Tab number:', index);
  }

  scrollToTitle(title, i) {
    let targetSection = title + '-' + i
    console.log(targetSection)
    window.scrollTo(0, document.getElementById(targetSection).offsetTop - 180);
  }
  transparentImageArray = [
    { id: 0, text: 'facebook', icon: '../../../assets/images/icons/facebook.png', green: '../../../assets/images/icons/facebook-hover.png', value: 'transparent' },
    { id: 1, text: 'insta', icon: '../../../assets/images/icons/insta.png', green: '../../../assets/images/icons/insta-hover.png', value: 'transparent' },
    { id: 2, text: 'linkedin', icon: '../../../assets/images/icons/linkedin.png', green: '../../../assets/images/icons/linkedin-hover.png', value: 'transparent' },
    { id: 3, text: 'twitter', icon: '../../../assets/images/icons/twitter.png', green: '../../../assets/images/icons/twitte-hover.png', value: 'transparent' },

  ]

  hoverImageArray = [
    { id: 0, text: 'facebook', icon: '../../../assets/images/icons/facebook.png', green: '../../../assets/images/icons/facebook-hover.png', value: 'transparent' },
    { id: 1, text: 'insta', icon: '../../../assets/images/icons/insta.png', green: '../../../assets/images/icons/insta-hover.png', value: 'transparent' },
    { id: 2, text: 'linkedin', icon: '../../../assets/images/icons/linkedin.png', green: '../../../assets/images/icons/linkedin-hover.png', value: 'transparent' },
    { id: 3, text: 'twitter', icon: '../../../assets/images/icons/twitter.png', green: '../../../assets/images/icons/twitte-hover.png', value: 'transparent' },

  ]
  setGreenImage(id, index) {
    let element = this.transparentImageArray.find(d => d.id === index);
    if (id === 0 || id === 1 || id === 2 || id === 3) {
      element.icon = JSON.parse(JSON.stringify(element.green));
      element.value = 'green';
      this.transparentImageArray.splice(index, 1);
      this.transparentImageArray.splice(index, 0, element);
    }
  }


  setTransparentImage(id, index) {
    let element = this.transparentImageArray.find(d => d.id === index);
    let greenElement = this.hoverImageArray.find(d => d.id === index);
    if (id === 0 || id === 1 || id === 2 || id === 3) {
      element.icon = JSON.parse(JSON.stringify(greenElement.icon));
      element.value = 'transparent';
      this.transparentImageArray.splice(index, 1);
      this.transparentImageArray.splice(index, 0, element);
    }
  }

  searchRelatedFeed() {
    this.blogService.serachRelatedFeed(this.blogId).subscribe(data => {
      let res: any;
      res = data;
      console.log("Related Blogs=>", res)
      this.blogRelatedArticle = res.Result;
    })
  }
}