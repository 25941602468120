import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
// import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatExpansionModule, MatSidenavModule, MatToolbarModule, MatSelectModule, MatCardModule, MatInputModule, MatNativeDateModule, MatDividerModule, MatTabsModule, MatDatepickerModule, MatIconModule } from '@angular/material';
import { SwiperModule, SwiperConfigInterface,
    SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { ImsRegisterComponent } from './ims-register/ims-register.component';
import { ImsService } from './ims.service';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        NgbModule,
        SharedModule,
        SwiperModule,
        FlexLayoutModule,
        MatExpansionModule,
        MatToolbarModule,
        MatSelectModule, MatCardModule, MatInputModule,
        MatNativeDateModule,
        MatDividerModule,
        MatTabsModule,
        MatIconModule
    ],
    providers: [ImsService],
    declarations: [ImsRegisterComponent]
})
export class ImsModule {}

