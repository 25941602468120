import { Component, OnInit, PLATFORM_ID, Inject, HostListener, Input} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { StorageService } from '../../common/storage-service';
import { CommonService } from '../../common/common.service';
// import { MainService } from '../../main/main.service';
import { DomSanitizer, Title, Meta } from '@angular/platform-browser';
import { PaginationInstance } from 'ngx-pagination';
import { DOCUMENT } from '@angular/platform-browser';
import { WINDOW } from '../../common/window.service';
import { BlogsService } from '../blog.service';
import { isPlatformBrowser } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-blog-categories',
  templateUrl: './blog-categories.component.html',
  styleUrls: ['./blog-categories.component.css']
})
  

// export class BlogsComponent implements OnInit, AfterContentInit, AfterContentChecked {
export class BlogCategoriesComponent implements OnInit {

  public isSidebarOpened: boolean = false;
  public currentSidebarView = '';
  public loading: boolean = false;

  public platformId = '';
  public blogFilterObj;
  public blogSearchQueryText = '';
  public blogsSearchResult = [];

  public currentScroll: number = 0;
  public noOfItemsInOneScroll = 12;
  public todayEpoch;

  public blogCategory = '';

  public firstBlog: any;
  public selectedFilters = [];

  public topTrendingTags;
  
  constructor(@Inject(PLATFORM_ID) platformId: string, @Inject(DOCUMENT) private document: Document, @Inject(WINDOW) private window: Window, private router: Router, private route: ActivatedRoute, private titleService: Title, private metaService: Meta, public commonService: CommonService, private blogService: BlogsService) {

    this.titleService.setTitle('Career Guidance and Advice Blogs - Mentoria');

    let attributeSelector: string = 'name="description"';
    this.metaService.removeTag(attributeSelector);

    this.metaService.addTag({
      name: 'description',
      content: 'Get the best advice and guidance to achive you perfect career. Read the blogs'
    }, true);
    this.platformId = platformId;
    let date = new Date();
    this.todayEpoch = date.toISOString();

    this.route.queryParams.subscribe(params => {
      let blogCategory = params["type"] || "";
      if (blogCategory !== '') {
        this.blogCategory = blogCategory;
        this.blogService.currentCategory = this.blogCategory;
        this.firstBlog = null;
        this.currentScroll = 0;
        this.blogsSearchResult = [];
        this.selectedFilters = [];
        this.constructBlogFilterObject();
        this.getAllBlogsData(this.blogFilterObj);
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0, 0);
        }
      }
    });
  }

  ngOnInit() {
    this.getTopTrendingTags();
  }  

  public getTopTrendingTags() {
    this.blogService.getTopTrendingTags().subscribe(
      response => {
        this.topTrendingTags = response["tags"];
      },
      error => {
        
      });
  }

  public constructBlogFilterObject() {
    this.blogFilterObj = {
      'QueryString': 'tagid:blogs || tagid:blog',
      Take: 12,
      Skip: this.noOfItemsInOneScroll * this.currentScroll,
      "Filters": [{
        'FilterSets': [{
          'Name': 'isPublished',
          'Operator': 'eq',
          'LOperator': 'AND',
          'Value': 'true',
          'Type': 'Id'
        }
        ]
      },
      {
        'FilterSets': [{
          'Name': 'publishDate',
          'Operator': 'le',
          'LOperator': 'AND',
          'Value': this.todayEpoch,
          'Type': 'Date'
        }
        ]
      }, {
        'FilterSets': [{
          'Name': 'category',
          'Operator': 'eq',
          'LOperator': 'AND',
          'Value': this.blogCategory,
          'Type': 'Id'
        }
        ]   
      }]
    };
      if (this.selectedFilters && this.selectedFilters.length > 0) {
        let filters = {
          FilterSets: []
        }
        this.selectedFilters.forEach(filter => {
          let f = {
            'Name': 'filter',
            'Operator': 'eq',
            'LOperator': 'OR',
            'Value': filter,
            'Type': 'Id'
          }
          filters.FilterSets.push(JSON.parse(JSON.stringify(f)));
        })
        this.blogFilterObj.Filters.push((JSON.parse(JSON.stringify(filters))));
      }
  }

  public getAllBlogsData(filterObj) {
    this.blogService.GetAllBlogs(filterObj).subscribe(
      response => {
        let data = response['Result'];
        let result = [];
        if (this.firstBlog && this.firstBlog.uid) {
         
          if (!data.some(d => d.uid === this.firstBlog.uid)) {
            result.push(this.firstBlog);
          } 
        }
        result = result.concat(this.blogsSearchResult);
        result = result.concat(data);
        let firstBlogArray = result.slice(0, 1);
        this.firstBlog = firstBlogArray[0];
        this.blogsSearchResult = result.slice(1);
        this.createBlogHREF()
        this.loading = false;
      },
      error => {
        
      });
  }

  
  createBlogHREF(){
    // let appUrl = 'http://localhost:4200'
    let appUrl =  this.commonService.getAppUrl()
    this.blogsSearchResult.forEach(blog => {
        blog['blogHref'] = appUrl + '/blog/' + blog.linkId + '?category=' + this.blogService.currentCategory
      })
    }
  
  public searchBlog() {
    if (this.blogSearchQueryText !== '' && this.blogSearchQueryText.length > 2) {
      this.constructBlogFilterObject();
      this.blogFilterObj.QueryString = this.blogSearchQueryText + '*' + ' && (tagid:blogs || tagid:blog)';
      this.loading = true;
      this.blogsSearchResult = [];
      this.firstBlog = null;
      this.getAllBlogsData(this.blogFilterObj);
    }
  }

  public openTag(tag) {
    this.router.navigate(['/blog'], { queryParams: { tag: tag } });
  }

  public clearSerchText() {
    this.blogSearchQueryText = '';
    this.blogsSearchResult = [];
    this.firstBlog = null;
    this.constructBlogFilterObject();
    this.getAllBlogsData(this.blogFilterObj);
  }

  public onScroll() {
    this.currentScroll += 1;
    this.constructBlogFilterObject();
    this.getAllBlogsData(this.blogFilterObj);
  }

  public filterChanged(filters) {
    this.selectedFilters = filters;
    this.currentScroll = 0;
    this.blogsSearchResult = [];
    this.firstBlog = null;
    this.constructBlogFilterObject();
    this.getAllBlogsData(this.blogFilterObj);
  }

  shareBlogForFacebook(blog) {
    this.titleService.setTitle(blog.title);
    this.metaService.addTag({ property: 'description', content: blog.title });
    this.metaService.addTag({ property: 'og:title', content: blog.title });
    this.metaService.addTag({ property: 'og:description', content: blog.snippet });
    this.metaService.updateTag({ property: 'og:image', content: blog.imageUrl });
    this.metaService.updateTag({ property: 'og:image:width', content: '533' });
    this.metaService.updateTag({ property: 'og:image:height', content: '320' });
    this.metaService.updateTag({ property: 'og:image:type', content: 'image/jpeg' });
    // og:image:width and og:image:height
    this.metaService.addTag({ property: 'og:url', content: this.commonService.getAppUrl() + '/blog/' + blog.linkId });
    this.metaService.addTag({ property: 'og:type', content: 'article' });
    this.metaService.addTag({ property: 'og:site_name', content: 'The Mentoria Blog' });
    this.metaService.addTag({ property: 'og:locale', content: 'en_US' });
    this.metaService.addTag({ property: 'og:type', content: 'article' });
    this.metaService.addTag({ property: 'og:image:secure_url', content: blog.imageUrl });
  }

  shareBlogForTwitter(blog) {
    this.titleService.setTitle(blog.title);
    this.metaService.addTag({ property: 'description', content: blog.title });
    this.metaService.addTag({ property: 'og:title', content: blog.title });
    this.metaService.addTag({ property: 'og:description', content: blog.snippet });
    this.metaService.addTag({ property: 'og:image', content: blog.imageUrl });
    this.metaService.addTag({ property: 'og:url', content: this.commonService.getAppUrl() +  '/blog/' + blog.linkId });
    this.metaService.addTag({ property: 'og:site_name', content: 'The Mentoria Blog' });
    this.metaService.addTag({ property: 'og:locale', content: 'en_US' });
    this.metaService.addTag({ property: 'og:type', content: 'website' });
    this.metaService.addTag({ property: 'og:image:secure_url', content: blog.imageUrl });

    this.metaService.addTag({ property: 'twitter:card', content: 'summary' });
    // this.metaService.addTag({ property: 'twitter:site', content: '@mymentoria' });

    // this.metaService.addTag({ property: 'twitter:description', content: blog.snippet });
    // this.metaService.addTag({ property: 'twitter:title', content: blog.title });
    // this.metaService.addTag({ property: 'twitter:image', content: blog.imageUrl });
  }

  shareBlogForWhatsapp(blog) {
    this.titleService.setTitle(blog.title);
    this.metaService.addTag({ property: 'description', content: blog.title });
    this.metaService.addTag({ property: 'og:title', content: blog.title });
    this.metaService.addTag({ property: 'og:description', content: '' }); // blog.snippet
    this.metaService.addTag({ property: 'og:image', content: blog.imageUrl });
    this.metaService.addTag({ property: 'og:url', content: this.commonService.getAppUrl() + '/blog/' + blog.linkId });
    this.metaService.addTag({ property: 'og:site_name', content: 'The Mentoria Blog' });
    this.metaService.addTag({ property: 'og:locale', content: 'en_US' });
    this.metaService.addTag({ property: 'og:type', content: 'website' });
    this.metaService.addTag({ property: 'og:image:secure_url', content: blog.imageUrl });
  }

}