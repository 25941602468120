import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AudiencePageRoutingModule } from './audience-page-routing.module';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCard, MatDialogRef, MatExpansionModule, MatIconModule, MAT_DIALOG_DATA } from '@angular/material';
import { SwiperConfigInterface, SwiperModule, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { ContentService } from '../content/content.service';
import { DialogOverviewExampleDialog, MainPageComponent } from './main-page/main-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountdownModule } from 'ngx-countdown';
import { AccountsModule } from '../accounts/accounts.module';
import { AccountsService } from '../accounts/accounts.service';
import { AudienceService } from './audience.service';
import { SafePipe } from '../shared/SafePipe.pipe';
import { SharedModule } from '../shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { PaymentModule } from '../payment/payment.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: 'horizontal',
  threshold: 50,
  spaceBetween: 5,
  slidesPerView: 1,
  centeredSlides: true
};

@NgModule({
  imports: [
    CommonModule,
    AudiencePageRoutingModule,
    FlexLayoutModule,
    MatStepperModule,
    SwiperModule,
    MatExpansionModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    CountdownModule,
    AccountsModule,
    SharedModule,
    HttpClientModule,
    PaymentModule,
    NgbModule,
    InfiniteScrollModule
  ],
  declarations: [ MainPageComponent, DialogOverviewExampleDialog],
  entryComponents: [DialogOverviewExampleDialog],
  exports: [MainPageComponent],
  providers: [{
    provide: SWIPER_CONFIG,
    useValue: DEFAULT_SWIPER_CONFIG
  },
    ContentService, {
    provide: SWIPER_CONFIG,
    useValue: DEFAULT_SWIPER_CONFIG
  },
    AudienceService,
    { provide: MatDialogRef, useValue: {} },
{ provide: MAT_DIALOG_DATA, useValue: [] },

  ]
})
export class AudiencePageModule { }
