import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BlogsService } from '../blog.service';

@Component({
  selector: 'app-blog-filter',
  templateUrl: './blog-filter.component.html',
  styleUrls: ['./blog-filter.component.css']
})
export class BlogFilterComponent implements OnInit {

  public isTarget;
  public blogFilters;
  public currentBlogCategory = '';

  @Input() selectedFilters? = [];

  @Output() sidenavClose = new EventEmitter();

  constructor(private router: Router, private blogService: BlogsService, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      let blogCategory = params["type"] || "";
      if (blogCategory !== '') {
        this.blogFilters = [];
        this.currentBlogCategory = blogCategory;
        this.getBlogFilters();
      }
    });
   }

  ngOnInit() {  
  }

  public getBlogFilters() {
    let category = encodeURI(this.currentBlogCategory);
    this.blogService.GetBlogFiltersForCategory(category).subscribe(
      response => {
        this.blogFilters = response['filters'];
      },
      error => {
        
      });
  }

  checkboxClicked(event) {
    let filter = event.source.value;
    if (this.selectedFilters.indexOf(filter) < 0) {
      this.selectedFilters.push(filter);
    } else {
      let index = this.selectedFilters.indexOf(filter);
      this.selectedFilters.splice(index, 1);
    }
    this.sidenavClose.emit(this.selectedFilters);
  }

  filterClicked(filter) {
    if (this.selectedFilters.indexOf(filter) < 0) {
      this.selectedFilters.push(filter);
    } else {
      let index = this.selectedFilters.indexOf(filter);
      this.selectedFilters.splice(index, 1);
    }
  }


  isFilterSelected(filter) {
    let value = false;
    if (this.selectedFilters) {
      if (this.selectedFilters.indexOf(filter) > -1) {
        value = true;;
      }
    }
    return value;
  }

}
