import { Component, Input, OnInit } from '@angular/core';
// import { faq } from './faq.json';

@Component({
  selector: 'app-faq-shared',
  templateUrl: './faq-shared.component.html',
  styleUrls: ['./faq-shared.component.css']
})
export class FaqSharedComponent implements OnInit {
  @Input() faqData:any;
  // panelOpenState:Array<boolean>[]=[[]]
  panelOpenState:boolean[][]=[]

  // faq=faq
  // faqData:any;
  constructor() { }

  ngOnInit(): void {    
    for(let i=0;i<this.faqData.faq.length;i++)
      this.panelOpenState[i]=[]
  }

  faqAutoCloseBox(index:any,id:Number){
    for(let i=0;i<this.panelOpenState[index].length;i++)
      if(this.panelOpenState[index][i]==true && i!=id)
        this.panelOpenState[index][i]=false

        console.log(this.panelOpenState[index])
  }

}
