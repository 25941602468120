import { StorageService } from '../common/storage-service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CommonService } from '../common/common.service';
import { catchError, retry } from 'rxjs/operators';

@Injectable()
export class PaymentService {
  private apiUrl = '';
  private stsUrl = '';
  private accessCode = '';

  constructor(private http: HttpClient, private commonService: CommonService, private localStorageService: StorageService) {
    this.apiUrl = this.commonService.getApiUrl();
    this.stsUrl = this.commonService.getStsUrl();
    this.accessCode = 'AVRB71EF15AB60BRBA';
  }

  // Get Subscription Packages
  GetSubscriptionPackages(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/api/Payments/GetSubscriptionPackages', httpOptions).pipe(retry(3), catchError(this.handleError));
  }
  getAllPackages(): Observable<any> {
    return this.http.get(this.apiUrl + '/api/Payments/GetAllSubscriptionPackages').pipe(retry(3), catchError(this.handleError));
  }

  // get where to redirect student after login
  GetStudentPath(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/api/Students/GetStudentPath', httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  // get where to redirect student after login
  SaveClass(currentClass: string, currentStatus: string): Observable<any> {
    const cc = encodeURI(currentClass);
    const cs = encodeURI(currentStatus);
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/api/Accounts/SaveClass?currentClass=' + cc + '&currentStatus=' + cs,
      httpOptions).pipe(retry(3), catchError(this.handleError));
  }


  // Get Packages By Id
  GetPackagesById(uid): Observable<any> {
    return this.http.get
      (this.apiUrl + '/api/Payments/GetPackagesById?uid=' + uid).pipe(retry(3), catchError(this.handleError));
  }
  // Check student status
  // getStudentStatus() {
  //   let headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   let authToken = this.localStorage.get('access_token');
  //   headers.append('Authorization', 'Bearer ' + authToken);
  //   return this.http.get(this.apiUrl + '/api/Students/GetStudentStatus',
  //     httpOptions)
  //     .map(this.extractData)
  //     .catch(this.handleError);
  // }
  // Api for prePayment
  prePayment(prePaymentObj): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    let body = JSON.stringify(prePaymentObj);
    return this.http.post(this.apiUrl + '/api/Payments/PrePayment',
      body, httpOptions).pipe(retry(3), catchError(this.handleError));
  }
  // Api for post payment
  PostPayment(prePaymentObj): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    let body = JSON.stringify(prePaymentObj);
    return this.http.post(this.apiUrl + '/api/Payments/PostPayment',
      body, httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  // Apply coupon
  ApplyCoupon(code, packageId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/api/Payments/ApplyCoupon?couponCode=' + code + '&packageUId=' + packageId,
      httpOptions).pipe(retry(3), catchError(this.handleError));
  }
  //GetStudentDetails
  GetStudentDetails(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/api/Students/GetStudent',
      httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  // Get Payments History
  PaymentsHistory(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.get
      (this.apiUrl + '/api/Payments/PaymentsHistory',
        httpOptions).pipe(retry(3), catchError(this.handleError));
  }



  // Apply coupon
  MakePaymentTemp() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/api/Students/MarkPaymentProcessCompleted',
      httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  EncryptData(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    let body = JSON.stringify(data);
    return this.http.post(this.apiUrl + '/api/Payments/EncryptData', body,
      httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  getPaymentData(id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/api/Payments/GetPayment?id=' + id,
      httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  getPaymentLink(id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/api/Payments/getPaymentLink?id=' + id,
      httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  sharePaymentLink(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token'),
        'Content-Type': 'application/json'
      })
    };
    let body = JSON.stringify(data);
    return this.http.post(this.apiUrl + '/api/Payments/sharePaymentLink', body,
      httpOptions).pipe(retry(3), catchError(this.handleError));
  }


  DecryptData(data): Observable<any> {
    return this.http.get(this.apiUrl + '/api/Payments/DecryptData?data=' + data).pipe(retry(3), catchError(this.handleError));
  }

  GetSubscribedPackage(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/api/Payments/GetCurrentPackage',
      httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  GetStudentByUid(studentUId) {
    return this.http.get(this.apiUrl + '/api/students/GetStudentById?uid=' + studentUId).pipe(retry(3), catchError(this.handleError));
  }

  // GetPaymentMethods(amount) {
  //   const url = 'https://secure.ccavenue.com/transaction/transaction.do?command=getJsonData&access_code=' + this.accessCode + '&currency=INR&amount=' + amount;
  //   let params = new URLSearchParams();
  //   params.set('dataType', 'jsonp');
  //   params.set('jsonp', 'false');
  //   params.set('callback', 'processData');

  //   return this.jsonp
  //     .get(url, { search: params })
  //     .map(data => {
  //       console.log(data);
  //     })
  //     .catch(this.handleError);
  //  }

  public processData(data) {
    console.log(data);
  }

  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

  // private handleError(error: Response | any) {

  //   let errMsg: string;
  //   if (error instanceof Response) {
  //     const body = error.json() || '';
  //     const err = body.error || JSON.stringify(body);
  //     errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
  //   } else {
  //     errMsg = error.message ? error.message : error.toString();
  //   }
  //   console.error(errMsg);
  //   return Observable.throw(errMsg);
  // }


  isEligibleForLazyPay(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token'),
        'Content-Type': 'application/json'
      })
    };
    let body = JSON.stringify(data);
    return this.http.post(this.apiUrl + '/api/Payments/IsEligibleForLazypay', body,
      httpOptions).pipe(retry(1), catchError(this.handleError));
  }

  initiateLazyPay(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token'),
        'Content-Type': 'application/json'
      })
    };
    let body = JSON.stringify(data);
    return this.http.post(this.apiUrl + '/api/Payments/InitiateLazyPayTransaction', body,
      httpOptions).pipe(retry(1), catchError(this.handleError));
  }

  GetPaymentDetailsByToken(transactionId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/api/Payments/GetPaymentDetailsByToken?token=' + transactionId,
      httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  CreateRPOrder(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    let body = JSON.stringify(data);
    return this.http.post(this.apiUrl + '/api/Payments/CreateRPOrder', body,
      httpOptions).pipe(retry(1), catchError(this.handleError));
  }

  ConfirmRPOrder(data): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    let body = JSON.stringify(data);
    return this.http.post(this.apiUrl + '/api/Payments/ConfirmRPOrder', body,
      httpOptions).pipe(retry(1), catchError(this.handleError));
  }
  getRPPaymentData(id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/api/Payments/getRPPaymentData?id=' + id,
      httpOptions).pipe(retry(3), catchError(this.handleError));
  }
}
