import { StorageService } from '../../common/storage-service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CareerOverviewService } from '../career-overview.service';
import { Component, PLATFORM_ID, Inject, OnInit, Output, EventEmitter } from '@angular/core';
import { CareerOverviewModel } from '../career-overview.model';
import { CommonService } from '../../common/common.service';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { SharedService } from 'src/app/shared/shared.service';


@Component({
  selector: 'app-career-loading',
  templateUrl: './career-loading.component.html',
  styleUrls: ['./career-loading.component.css', '../career-overview.module.css']
})
export class CareerLoadingComponent implements OnInit {
  specCareer: any;
  cp: any;
  @Output() NewEvent = new EventEmitter()
  show
  public careerId;
  // public careerpath: any[] = [];
  public path;
  public authToken;
  public q1 = false;
  public q2 = false;
  public q3 = false;
  public careerNum;
  public accType;
  public searchObj;
  public platformId;
  public careerArr;
  public buttonn = false;
  public careerPathName;
  constructor(private route: ActivatedRoute,
    private commonService: CommonService,
    private careerOverviewService: CareerOverviewService, @Inject(PLATFORM_ID) platformId: string,
    private router: Router, private localStorageService: StorageService, private titleService: Title,
    private metaService: Meta,public sharedService:SharedService) {
    this.platformId = platformId;
    this.authToken = this.localStorageService.get('access_token');


    this.accType = this.localStorageService.get('account_type');
    console.log(this.accType);

    this.careerArr = this.localStorageService.get('career_arr');
    this.route.params.forEach((params: Params) => {
      this.careerId = params['id'];
      this.careerNum = this.localStorageService.get('careerNo') || 1;
      this.careerPathName = this.careerId.replace(/-/g, ' ');
    });
    //      this.searchObj = {
    //       // QueryString: this.careerId,
    //           'Filters': [
    //     {
    //       'FilterSets': [
    //         {
    //           'Name': 'linkId',
    //           'Operator': 'eq',
    //           'LOperator': 'and',
    //           'Value': this.careerId,
    //           'Type': 'Id'
    //         }
    //       ]
    //     }
    //   ],
    //       Skip: 0,
    //       Take: 100,
    //     };

    //  this.careerOverviewService.getCareerPathForCounsellor(this.searchObj).subscribe(
    //       response => {
    //         this.path = response.Result[0];
    //        this.titleService.setTitle(this.path.careerPath);

    //       });
    this.careerOverviewService.getStaticContentByCareerLinkId(this.careerId).subscribe(
      response => {
        this.path = response;
        if (this.path.relatedCareerPath != undefined) {
          var specCareerPrefix = this.path.relatedCareerPath.replace(/[^\w]/gi, '-');
          var career = this.path.linkId.replace(specCareerPrefix + '-', '');
          this.specCareer = career.replace(/-/g, ' ');
        }

        if (this.path.CareerPathUId !== null) {
          this.titleService.setTitle(this.path.careerPath);
        }
        else {
          this.titleService.setTitle(this.specCareer.charAt(0).toUpperCase() + this.specCareer.slice(1));
        }

      });
    this.showFirst();
    // this.q1 = true;
    // this.q2 = false;
    // this.q3 = false;

    //  setTimeout('showSecond', 5000);

    //  setTimeout(() => {
    //    this.showSecond();
    //   }, 5000);

  }

  ngOnInit() {
  }

  public goToOverview() {
    this.commonService.pathData = this.path;
    this.router.navigate(['/career', this.careerId, 'overview']);
  }

  public showFirst() {
    this.q1 = true;
    this.q2 = false;
    this.q3 = false;
    setTimeout(() => {
      this.showSecond();
    }, 5000);
  }

  public showSecond() {
    this.q1 = false;
    this.q2 = true;
    this.q3 = false;
    setTimeout(() => {
      this.showThird();
    }, 5000);
    //   setTimeout('showThird', 5000);
  }

  public showThird() {
    this.q1 = false;
    this.q2 = false;
    this.q3 = true;
    setTimeout(() => {
      //  this.goToOverview();
      this.buttonn = true;
    }, 1000);
  }
  public goToCareers() {
    console.log(this.accType);
    if (this.accType === 'student' && this.careerArr === '3') {
      this.router.navigate(['/feeds/my-careers']);
    } else if (this.accType === 'counsellor') {
      this.router.navigate(['/counsellor/discover/careers']);
    } else {
      this.sharedService.redirectNavURL('/career')
    }

  }
  gotomain(value) {
    this.NewEvent.emit(value);
    console.log(value);
  }

  home() {
    if (isPlatformBrowser(this.platformId)) {
      // console.log('user agent --- ' + window.navigator.userAgent );
      let ua = window.navigator.userAgent;

      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
        console.log('route changing to Mobile view');
        this.router.navigate(['home-mobile']);
      }
      else {
        // this.router.navigate([this.commonService.getpreloginAppURL()]);
        let url = this.commonService.getpreloginAppURL()
        let newPath = url + '/redirect?authToken=' + this.localStorageService.get('access_token') + '&redirectURl=' + encodeURI(url + '/login');
        window.open(newPath, "_self")
      }
    }
  }
}
