
import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules, RouteReuseStrategy, NoPreloading } from '@angular/router';
import { HomepageComponent } from './home/homepage/homepage.component';


import { ImsRegisterComponent } from './IMS/ims-register/ims-register.component';
import { PageNotFoundComponent } from './main/page-not-found/page-not-found.component';
import { AuthGuard } from './common/auth-guard';
import { SitemapComponent } from './main/sitemap/sitemap.component';
import { BlogListComponent } from './blogs/blog-list/blog-list.component';
import { BlogCategoriesComponent } from './blogs/blog-categories/blog-categories.component';
import { BlogAuthorComponent } from './blogs/blog-author/blog-author.component';;
import { BlogDetailsPageComponent } from './blogs/blog-details-page/blog-details-page.component';
import { CareerListComponent } from './career-list/career-list.component';
import { RedirectComponent } from './redirect/redirect.component';


export const appRoutes: Routes = [
  //new landing page
  { path: '', component: HomepageComponent },
  { path: 'ims', component: ImsRegisterComponent },
  { path: 'redirect', component: RedirectComponent },
  { path: 'accounts', loadChildren: './accounts/accounts.module#AccountsModule' },
  { path: 'assessment', loadChildren: './test/test.module#TestModule', canActivate: [AuthGuard] },
  // { path: 'career/:id', loadChildren: './career-overview/career-overview.module#CareerOverviewModule' },
  { path: 'counselling', loadChildren: './counselling/counselling.module#CounsellingModule', canActivate: [AuthGuard] },
  { path: 'counsellor', loadChildren: './counsellor/counsellor.module#CounsellorModule', canActivate: [AuthGuard] },
  { path: 'student', loadChildren: './student/student.module#StudentModule', canActivate: [AuthGuard] },
  { path: 'payment', loadChildren: './payment/payment.module#PaymentModule' },
  { path: 'feeds', loadChildren: './feeds/feeds.module#FeedsModule', canActivate: [AuthGuard] },
  { path: 'events', loadChildren: './sme-events/sme-events.module#SmeEventsModule' },
  { path: 'university', loadChildren: './university/university.module#UniversityModule' },

  { path: 'webinar', loadChildren: './webinar/webinar.module#WebinarModule' },
  { path: 'career/:id', loadChildren: './career-overview-rewamp/career-overview-rewamp.module#CareerOverviewRewampModule' },
  { path: '', loadChildren: './content/content.module#ContentModule' },

  { path: 'careers-new', loadChildren: './static-content/static-content.module#StaticContentModule' },
  { path: 'career-brainstorm', loadChildren: './career-brainstorm/career-brainstorm.module#CareerBrainstormModule' },
  { path: 'sitemap', component: SitemapComponent },
  { path: 'blog/page/:id', component: BlogListComponent },
  { path: 'blog', component: BlogListComponent },

  { path: 'blog/category', component: BlogCategoriesComponent, pathMatch: 'full' },
  { path: 'blog/author', component: BlogAuthorComponent },
  // { path: 'blog/:id', component: BlogArticleComponent },
  // {path:'blog/detail',component:BlogArticleDetailComponent},

  { path: 'blog/:id', component: BlogDetailsPageComponent },

  // { path: 'exam-ready', loadChildren: './career-ready/career-ready.module#CareerReadyModule' },


  // { path: 'location'  , loadChildren: './locationpage/locationpage.module#LocationpageModule'},
  // { path: 'audience'  , loadChildren: './audience-page/audience-page.module#AudiencePageModule'},

  { path: 'career', component: CareerListComponent },

  { path: '**', component: PageNotFoundComponent },





];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { useHash: false })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }

