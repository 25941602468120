import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.css']
})
export class OnboardingComponent implements OnInit {
public stepone: Boolean;
public steptwo: Boolean;
public stepthree: Boolean;
public stepfour: Boolean;
  constructor( private router: Router) { }

  ngOnInit() {

this.stepone = true;
this.steptwo = false;
this.stepthree = false;
this.stepfour = false;
  }

   GotoStepOne(){
this.stepone = true;
this.steptwo = false;
this.stepthree = false;
     this.stepfour = false;
  }

  GotoStepTwo(){
this.stepone = false;
this.steptwo = true;
this.stepthree = false;
    this.stepfour = false;
  }
  GotoStepThree(){
 this.stepone = false;
this.steptwo = false;
this.stepthree = true;
    this.stepfour = false;
  }
  GotoStepFour() {
    this.stepone = false;
    this.steptwo = false;
    this.stepthree = false ;
    this.stepfour = true;
  }
  Skip() {
 //this.router.navigate(['/welcome-reward']);
    this.router.navigate(['/payment/package']);
  }


}
