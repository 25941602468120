import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { PaymentService } from '../payment.service';
import { Router, ActivatedRoute } from '@angular/router';
import { WindowRefService } from 'src/app/common/window-ref.service';
@Component({
  selector: 'app-make-payment',
  templateUrl: './make-payment.component.html',
  styleUrls: ['./make-payment.component.css', '../payment.module.css']
})
export class MakePaymentComponent implements OnInit, OnDestroy {

  @ViewChild('ccaForm') form: ElementRef;

  public ccaForm;
  public encRequest = '';
  public isCCAvenueForm = false;

  // public accessCode = 'AVRB71EF15AB60BRBA';  // for localhost:4200 (secure.ccavenue)
  // public accessCode = 'AVIB71EF24AM40BIMA'; // for mentoriaweb-new(secure.ccavenue)
  public accessCode = 'AVCH67DJ23AF65HCFA'; // for mentoria.com
  private sub: any;

  //public razorPayKeyId = 'rzp_test_Oio24LUFhgWD9x'; //For Test
  public razorPayKeyId = 'rzp_live_ZDRBsLXKmZI6Gu'  //For Production
  public paymentData: any;

  constructor(private router: Router, private paymentService: PaymentService, private route: ActivatedRoute, private winRef: WindowRefService) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.encRequest = params['token'];
      if (this.encRequest != null && this.encRequest != undefined && this.encRequest != "") {
        if (!this.encRequest.startsWith("order") && !this.encRequest.startsWith("sub")) {
          this.isCCAvenueForm = true;
        }
      }
    });
    console.log(this.encRequest, this.isCCAvenueForm);
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  public ngAfterContentInit() {
    if (this.isCCAvenueForm) {
      this.ccaForm = this.form.nativeElement;
      this.form.nativeElement.elements['encRequest'].value = this.encRequest;
      this.form.nativeElement.elements['access_code'].value = this.accessCode;
      this.ccaForm.submit();
    } else {
      this.paymentService.getRPPaymentData(this.encRequest).subscribe(
        response => {
          console.log(response);
          this.paymentData = response;
          this.payWithRazor();
        },
        error => { });
    }
  }

  payWithRazor() {
    let options: any = {
      key: this.razorPayKeyId,
    }

    if (this.encRequest.startsWith("order_")) {
      options = {
        key: this.razorPayKeyId,
        amount: this.paymentData.amount, // amount should be in paise format to display Rs 1255 without decimal point
        currency: 'INR',
        name: 'Mentoria', // company name or product name
        description: "Mentoria package purchase",  // product description
        image: 'http://mentoriastorage.blob.core.windows.net/profileimages/MentoriaLogoBlue-2.png', // company logo or product image
        order_id: this.paymentData.orderId, // order_id created by you in backend
        modal: {
          escape: false
        },
        notes: {
          // include notes if any
        },
        prefill: {
          name: this.paymentData.studentName
        },
        theme: {
          color: '#0c238a'
        }
      };
    } else if (this.encRequest.startsWith("sub_")) {
      options = {
        key: this.razorPayKeyId,
        subscription_id: this.paymentData.orderId,
        name: 'Mentoria',
        description: "Mentoria package subscription",
        image: 'http://mentoriastorage.blob.core.windows.net/profileimages/MentoriaLogoBlue-2.png', // company logo or product image
        prefill: {
          name: this.paymentData.studentName
        },
        theme: {
          "color": "#F37254"
        }
      };
    }
    options.handler = ((response, error) => {
      options.response = response;
      console.log(response);
      console.log(options);
      this.paymentService.ConfirmRPOrder(response).subscribe(
        response => {
          // todo: Payment successful. Do the next step.
          this.router.navigate(['/payment/status', this.paymentData.id]);
        },
        error => { });
    });
    if (this.encRequest.startsWith("order_")) {
      options.modal.ondismiss = (() => {
        // todo: handle the case when user closes the razorpay-popup
        console.log('Transaction cancelled.');
        this.router.navigate(['/payment/status', this.paymentData.id]);
      });
    }
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open();
  }
}
