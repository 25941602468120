import { Component, OnInit } from '@angular/core';
import swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-error',
  templateUrl: './login-error.component.html',
  styleUrls: ['./login-error.component.scss']
})
export class LoginErrorComponent implements OnInit {

  constructor(private router:Router) { }

  ngOnInit() {
    swal({
      position: 'center',
      allowOutsideClick: false,
      allowEscapeKey: false,
      type: 'warning',
      text: 'Sorry, Login Failed!',
      confirmButtonText: 'Login',
      showCloseButton: true,
      confirmButtonClass: 'submit-purple-swal-btn',
      cancelButtonClass: 'cancel-danger-swal-btn', //only if two buttons
      buttonsStyling: true,

    }).then((result) => {
      this.router.navigate(['accounts', 'login']);
      });
  }
}
