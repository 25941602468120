import { Title, Meta } from '@angular/platform-browser';
import { StorageService } from '../../common/storage-service';
import { Component, OnInit, ViewChild, ElementRef, Inject, PLATFORM_ID, Injector } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PaymentService } from '../payment.service';
import { Package } from '../package.model';
import swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isPlatformBrowser } from '@angular/common';
import { CommonService } from 'src/app/common/common.service';

@Component({
  selector: 'app-package-list',
  templateUrl: './package-list.component.html',
  styleUrls: ['./package-list.component.css', '../payment.module.css']
})
export class PackageListComponent implements OnInit {
  Pkgname: any;
  public packages: Package[] = [];
  public Package2: Package = new Package();
  public Package1: Package = new Package();
  public Package0: Package = new Package();
  public isLoggedIn = false;
  public sub;
  public method = '';

  public packageType;
  public statusTitle;
  public statusValue;
  private modalService;
  private platformId

  @ViewChild('selectClass') selectClassModal: ElementRef;

  constructor(private router: Router, private route: ActivatedRoute, private paymentService: PaymentService, private localStorage: StorageService, private titleService: Title, private metaService: Meta, @Inject(PLATFORM_ID) platformId: string, private injector: Injector, public commonService: CommonService) {

    this.titleService.setTitle('Mentoria Career Pricing - Start Today');

    let attributeSelector: string = 'name="description"';
    this.metaService.removeTag(attributeSelector);

    this.metaService.updateTag({
      name: 'description',
      content: 'Make a one-time investment today, for a lifetime of Happiness. We have our pricing packages for students, graduates and professionals.Choose one and get started to discover a perfect career.'
    });

    this.platformId = platformId;
    if (isPlatformBrowser(this.platformId)) { this.modalService = this.injector.get(NgbModal); }
  }

  ngOnInit() {
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.method = params['id'] || 0;
      });
    let authToken = this.localStorage.get('access_token');
    if (authToken !== '') {
      this.GetSubscriptionPackages();
      this.GetSubscribedPackage();
      this.isLoggedIn = true;
    } else {
      this.getAllPackages();
    }
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  select(id, packageType) {
    if (this.isLoggedIn) {
      this.router.navigate(['/payment/select-package', id]);
    } else {
      this.router.navigate(['/accounts/register'], { queryParams: { packageType: packageType } });
    }
  }


  select1(id, packageType) {
    if (this.isLoggedIn) {
      // sweetalert code added here

      if (id === '239a2369-d631-41a9-9ab7-c25d36f7f475') {
        swal({
          html: 'We currently have face-to-face counselling sessions available in Mumbai and Pune. If you are looking for counselling sessions in cities other than these, please select the "Discover Online" package. Please click "Proceed" to continue with the face-to-face package or select "Cancel" and choose our online one.',
          // 'We currently have face-to-face counselling sessions available in Mumbai, Delhi, Pune and Bengaluru. If you are looking for counselling sessions in cities other than these, please select online (video calling) package. Please click "Proceed" to coutinue with face-to-face package or select "Cancel" and choose online package.',
          // title: 'What you want to do?',
          // text: "You won't be able to revert this!",
          // type: 'question',
          title: 'NOTIFICATION :',
          showCancelButton: true,
          confirmButtonColor: '#4caf50',
          cancelButtonColor: '#d33',
          confirmButtonText: 'PROCEED',
          cancelButtonText: 'CANCEL',
          // confirmButtonClass: 'success',
          // cancelButtonClass: 'cancel',
          confirmButtonClass: 'submit-purple-swal-btn',
          cancelButtonClass: 'cancel-danger-swal-btn',
          buttonsStyling: true,
          reverseButtons: false,
          width: '80%'
        }).then((result) => {
          if (result.value) {
            this.router.navigate(['/payment/select-package', id]);
          } else {
          }
        });
      }
      // sweetalert code ends here
      if (id === '0d0adda5-0a52-4926-81c0-197ba8ef092a') {
        swal({
          html: 'We currently have face-to-face counselling sessions available in Mumbai and Pune. If you are looking for counselling sessions in cities other than these, please select the "Achieve Online" package. Please click "Proceed" to continue with the face-to-face package or select "Cancel" and choose our online one.',
          // 'We currently have face-to-face counselling sessions available in Mumbai, Delhi, Pune and Bengaluru. If you are looking for counselling sessions in cities other than these, please select online (video calling) package. Please click "Proceed" to coutinue with face-to-face package or select "Cancel" and choose online package.',
          // title: 'What you want to do?',
          // text: "You won't be able to revert this!",
          // type: 'question',
          title: 'NOTIFICATION :',
          showCancelButton: true,
          confirmButtonColor: '#4caf50',
          cancelButtonColor: '#d33',
          confirmButtonText: 'PROCEED',
          cancelButtonText: 'CANCEL',
          // confirmButtonClass: 'success',
          // cancelButtonClass: 'cancel',
          // buttonsStyling: true,
          confirmButtonClass: 'submit-purple-swal-btn',
          cancelButtonClass: 'cancel-danger-swal-btn',
          buttonsStyling: true,
          reverseButtons: false,
          width: '80%'
        }).then((result) => {
          if (result.value) {
            this.router.navigate(['/payment/select-package', id]);
          }
          //  if (result.dismiss === 'cancel') 
          else {
          }
        });
      }
      if (id === 'c33a6c9c-596e-480a-9eae-fd90a7c20740') {
        swal({
          html: 'We currently have face-to-face counselling sessions available in Mumbai and Pune. If you are looking for counselling sessions in cities other than these, please select the "Ascend Online" package. Please click "Proceed" to continue with the face-to-face package or select "Cancel" and choose our online one.',
          // title: 'What you want to do?',
          title: 'NOTIFICATION :',
          showCancelButton: true,
          confirmButtonColor: '#4caf50',
          cancelButtonColor: '#d33',
          confirmButtonText: 'PROCEED',
          cancelButtonText: 'CANCEL',
          // confirmButtonClass: 'success',
          // cancelButtonClass: 'cancel',
          // buttonsStyling: true,
          confirmButtonClass: 'submit-purple-swal-btn',
          cancelButtonClass: 'cancel-danger-swal-btn',
          buttonsStyling: true,
          reverseButtons: false,
          width: '80%'
        }).then((result) => {
          if (result.value) {
            this.router.navigate(['/payment/select-package', id]);
          } else {
          }
        });
      }
      // sweetalert code ends here
    } else {
      this.router.navigate(['/accounts/register'], { queryParams: { packageType: packageType } });
    }
  }

  public saveClass() {
    let currentClass = '';
    let currentStatus = '';
    if (this.statusTitle === 'Study') {
      if (this.statusValue === '10th-12th' || this.statusValue === '8th-9th') {
        currentClass = this.statusValue;
      } else if (this.statusValue === 'College/University' || this.statusValue === 'Certificate/Diploma') {
        currentClass = 'Other';
      }
      currentStatus = this.statusTitle + '-' + this.statusValue;
    } else if (this.statusTitle === 'Work') {
      currentClass = 'Other';
      currentStatus = this.statusTitle + '-' + this.statusValue;
    } else if (this.statusTitle === 'None') {
      currentClass = 'Other';
      currentStatus = 'On a Break';
    }
    console.log(currentClass, currentStatus);

    this.paymentService.SaveClass(currentClass, currentStatus).subscribe(
      response => {
        this.GetSubscriptionPackages();
      },
      error => {

      });

  }

  GetSubscriptionPackages() {
    this.paymentService.GetSubscriptionPackages().subscribe(
      response => {
        this.packages = response;
        if (this.packages.length > 0) {
          this.Package0 = this.packages[0];
          console.log(this.Package0);
        } else {

        }
        if (this.packages.length > 1) {
          this.Package1 = this.packages[1];
        } else {
          this.modalService.open(this.selectClassModal, { size: 'lg', backdrop: 'static', keyboard: false });
        }
        if (this.packages.length > 2) {
          this.Package2 = this.packages[2];
        }
      },
      error => {

      });
  }
  getAllPackages() {
    this.paymentService.getAllPackages().subscribe(
      response => {
        this.packages = response;
        this.Package0 = this.packages.find(d => d.amount === 0);
        this.ShowBasicPackage();
      }, error => { });
  }
  ShowBasicPackage() {
    this.packageType = 'basic';
    this.Package1 = this.packages.find(d => d.name === 'Discover Offline');
    this.Package2 = this.packages.find(d => d.name === 'Discover Online');

  }

  ShowAdvPackage() {
    this.packageType = 'advance';
    this.Package1 = this.packages.find(d => d.name === 'Achieve Offline');
    this.Package2 = this.packages.find(d => d.name === 'Achieve Online');

  }

  ShowHigherPackage() {
    this.packageType = 'higher';
    this.Package1 = this.packages.find(d => d.name === 'Ascend Offline');
    this.Package2 = this.packages.find(d => d.name === 'Ascend Online');

  }
  Home() {
    // this.router.navigate([this.commonService.getpreloginAppURL()]);
    let url = this.commonService.getpreloginAppURL()
    let newPath = url + '/redirect?authToken=' + this.localStorage.get('access_token') + '&redirectURl=' + encodeURI(url + '/login');
    window.open(newPath, "_self")
  }

  public GetSubscribedPackage() {
    this.paymentService.GetSubscribedPackage().subscribe(response => {

      this.Pkgname = response['packageName'];
    }, err => {
      alert('Some error occured, please login again.');
      this.router.navigate(['/accounts/login']);
    });
  }
}

