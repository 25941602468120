
import { Component, OnInit, PLATFORM_ID, Inject, HostListener, AfterViewChecked, AfterContentChecked } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../common/common.service';
import { BlogsService } from '../blog.service';
// import { MainService } from '../../main/main.service';
import { Title, Meta } from '@angular/platform-browser';

declare var $: any;
@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.css'],
  //  changeDetection: ChangeDetectionStrategy.OnPush
})
// export class BlogsComponent implements OnInit, AfterContentInit, AfterContentChecked {
export class BlogListComponent implements OnInit, AfterContentChecked {

  public isSidebarOpened: boolean = false;
  public loading: boolean = false;

  public platformId = '';
  public blogFilterObj;
  public blogSearchQueryText = '';
  public blogsSearchResult = [];

  public currentScroll: number = 0;
  public noOfItemsInOneScroll = 12;
  public todayEpoch;
  public currentPage: number = 0;
  public currentTag = ''
  public totalRecords: any
  public selectedPage: number;
  public pageId

  constructor(@Inject(PLATFORM_ID) platformId: string, private router: Router, private route: ActivatedRoute, private blogService: BlogsService, private titleService: Title, private metaService: Meta, public commonService: CommonService) {
    debugger
    
    this.platformId = platformId;
    let date = new Date();
    this.todayEpoch = date.toISOString();

    this.route.queryParams.subscribe(params => {
      let tag = params["tag"] || "";
      if (tag !== '') {
        this.currentTag = tag;
      }
    });
this.pageId=this.route.snapshot.params.id;

if(this.pageId!==null && this.pageId!==undefined && this.pageId!=="" && this.pageId!='')
{
  this.titleService.setTitle("Career Blog by Mentoria | page" +" "+this.pageId+" "+"of 21");

  let attributeSelector: string = 'name="description"';
  this.metaService.removeTag(attributeSelector);

  this.metaService.addTag({
    name: 'description',
    content: "Read Our Educational Blogs for Students and Working Professionals which hosts some Informative Tips and Current Trends which will be Beneficial for your Career." + " "+ this.pageId
  }, true);
}
else
{
  this.titleService.setTitle("Career Blog by Mentoria");

    let attributeSelector: string = 'name="description"';
    this.metaService.removeTag(attributeSelector);

    this.metaService.addTag({
      name: 'description',
      content: "Read Our Educational Blogs for Students and Working Professionals which hosts some Informative Tips and Current Trends which will be Beneficial for your Career."
    }, true);
}
  }

  ngOnInit() {
    window.location.href = 'https://blog.mentoria.com/'
    this.blogsSearchResult = [];
    this.constructBlogFilterObject1(15);
    this.getAllBlogsData(this.blogFilterObj);
    console.log("ROUTE=>", this.route.snapshot.url)
    let pageNo = this.route.snapshot.params.id
    this.selectedPage = pageNo;
    console.log("page number=>", pageNo)
    if (pageNo !== null || pageNo !== undefined) {
      this.blogFilterObj = {
        'QueryString': 'tagid:blogs || tagid:blog',
        Take: 15,
        Skip: (pageNo - 1) * 15,
        "Filters": [{
          'FilterSets': [{
            'Name': 'isPublished',
            'Operator': 'eq',
            'LOperator': 'AND',
            'Value': 'true',
            'Type': 'Id'
          }
          ]
        },
        {
          'FilterSets': [{
            'Name': 'publishDate',
            'Operator': 'le',
            'LOperator': 'AND',
            'Value': this.todayEpoch,
            'Type': 'Date'
          }
          ]
        }
        ]
      };
    }
    this.getAllBlogsData(this.blogFilterObj);
  }
  ngAfterContentChecked(): void {

  }
  public constructBlogFilterObject() {
    this.blogFilterObj = {
      'QueryString': 'tagid:blogs || tagid:blog',
      Take: 12,
      Skip: this.noOfItemsInOneScroll * this.currentScroll,
      "Filters": [{
        'FilterSets': [{
          'Name': 'isPublished',
          'Operator': 'eq',
          'LOperator': 'AND',
          'Value': 'true',
          'Type': 'Id'
        }
        ]
      },
      {
        'FilterSets': [{
          'Name': 'publishDate',
          'Operator': 'le',
          'LOperator': 'AND',
          'Value': this.todayEpoch,
          'Type': 'Date'
        }
        ]
      }
      ]
    };

    if (this.currentTag !== '') {
      this.blogFilterObj.Filters.push({
        'FilterSets': [{
          'Name': 'tagid',
          'Operator': 'eq',
          'LOperator': 'AND',
          'Value': this.currentTag,
          'Type': 'Id'
        }
        ]
      })
    }
  }

  public searchBlog() {
    if (this.blogSearchQueryText !== '' && this.blogSearchQueryText.length > 2) {
      this.constructBlogFilterObject();
      this.blogFilterObj.QueryString = this.blogSearchQueryText + '*' + ' && (tagid:blogs || tagid:blog)';
      this.blogsSearchResult = [];
      this.getAllBlogsData(this.blogFilterObj);
    }
  }

  public clearSerchText() {
    this.blogSearchQueryText = '';
    this.constructBlogFilterObject();
    this.getAllBlogsData(this.blogFilterObj);
  }

  public getAllBlogsData(filterObj) {
    this.loading = true;
    this.blogService.GetAllBlogs(filterObj).subscribe(
      response => {
        console.log(response)
        window.scroll(0, 0)
        let obj: any;
        obj = response;
        this.totalRecords = obj.TotalRecords;

        let data = response['Result'];
        // this.blogsSearchResult = this.blogsSearchResult.concat(data);
        this.blogsSearchResult = data;
        this.createBlogHREF()
        this.loading = false;
      },
      error => {
        
      });
  }


  createBlogHREF() {
    // let appUrl = 'http://localhost:4200'
    let appUrl = this.commonService.getAppUrl()
    this.blogsSearchResult.forEach(blog => {
      blog['blogHref'] = appUrl + '/blog/' + blog.linkId + '?category=' + this.blogService.currentCategory
    })
  }


  public toggleSidebar(): void {
    this.isSidebarOpened = !this.isSidebarOpened;
  }

  public onScroll() {
    this.currentScroll += 1;
    this.constructBlogFilterObject();
    this.getAllBlogsData(this.blogFilterObj);
  }


  public constructBlogFilterObject1(take) {
    this.blogFilterObj = {
      'QueryString': 'tagid:blogs || tagid:blog',
      Take: take,
      Skip: this.currentPage * take,
      "Filters": [{
        'FilterSets': [{
          'Name': 'isPublished',
          'Operator': 'eq',
          'LOperator': 'AND',
          'Value': 'true',
          'Type': 'Id'
        }
        ]
      },
      {
        'FilterSets': [{
          'Name': 'publishDate',
          'Operator': 'le',
          'LOperator': 'AND',
          'Value': this.todayEpoch,
          'Type': 'Date'
        }
        ]
      }
      ]
    };

    if (this.currentTag !== '') {
      this.blogFilterObj.Filters.push({
        'FilterSets': [{
          'Name': 'tagid',
          'Operator': 'eq',
          'LOperator': 'AND',
          'Value': this.currentTag,
          'Type': 'Id'
        }
        ]
      })
    }
  }

  pageChange(object) {
    debugger
    console.log("pagechange Object",object)
    this.currentPage = object.pageNumber - 1;
    // console.log(this.currentPage)



    this.constructBlogFilterObject1(object.Take);
    this.getAllBlogsData(this.blogFilterObj);

    if (object.pageNumber !== null && object.pageNumber !== undefined) {

      this.router.navigate(['/blog/page/', this.currentPage + 1])


      // {
      //   queryParams:
      //   {
      //     page: this.currentPage+1
      //   },
      // }
      // );
    }
    else {
      this.router.navigate(['/blog'])
    }
  }


}
//   let Obj = {

// type: "pageChange",
//   Skip: (pageNo - 1) * 3,
//     Take: 3,
//       pageNumber: pageNo

//     };









