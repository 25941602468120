import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NewRegistrationComponent } from './new-registration/new-registration.component';
import { LoginComponent } from './login/login.component';
import { RegistrationComponent } from './registration/registration.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { AccountsService } from './accounts.service';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RouterModule } from '@angular/router';
import { VerificationComponent } from './verification/verification.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SetToRollComponent } from './set-to-roll/set-to-roll.component';
import { WelcomeRewardComponent } from './welcome-reward/welcome-reward.component';
import { SuccessRewardComponent } from './success-reward/success-reward.component';
import { AccountsRoutingModule } from './accounts-routing.module';
import { PostLoginComponent } from './post-login/post-login.component';
import { LoginErrorComponent } from './login-error/login-error.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LeadFormComponent } from './lead-form/lead-form.component';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CountdownModule } from 'ngx-countdown';
import { MatButtonToggleModule, MatCardModule, MatProgressSpinnerModule } from '@angular/material';
import { NewLoginComponent } from './new-login/new-login.component';
import { NewRegisterationComponent } from './new-registeration/new-registeration.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatCardModule,
    FormsModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    NgbModule,
    SharedModule,MatSelectModule,
    CountdownModule,
    AccountsRoutingModule,
    FlexLayoutModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule
  ],
  providers: [AccountsService],
  declarations: [LoginComponent,NewRegistrationComponent,NewLoginComponent, RegistrationComponent, OnboardingComponent, ForgotPasswordComponent, ResetPasswordComponent, VerificationComponent, SetToRollComponent, WelcomeRewardComponent, SuccessRewardComponent, PostLoginComponent, LoginErrorComponent, GetStartedComponent, LeadFormComponent,NewRegisterationComponent],

})
export class AccountsModule { }
