import { Component, ElementRef, HostListener, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import {
  SwiperComponent, SwiperDirective, SwiperConfigInterface,
  SwiperPaginationInterface, SwiperAutoplayInterface
} from 'ngx-swiper-wrapper';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { counsellorsNew, LocationData, pricing } from './../LocationData.json';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; import { AudienceService } from '../../../app/audience-page/audience.service';
import swal from 'sweetalert2';
import { StorageService } from '../../../app/common/storage-service';
import { CommonService } from '../../../app/common/common.service';
import { AccountsService } from '../../../app/accounts/accounts.service'
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { SharedService } from '../../../app/shared/shared.service';
import { MatDialog, MatDialogRef, MatStepper, MAT_DIALOG_DATA } from '@angular/material';
import { Meta, Title } from '@angular/platform-browser';
import { ahmedabadData, bangaloreData, bhopalData, chandigarhData, chennaiData, delhiData, gurgaonData, hyderabadData, indoreData, jaipurData, kolkataData, mumbaiData, nagpurData, puneData, suratData, vadodaraData } from '../locationSchemaData.json';
import { PaymentService } from '../../../app/payment/payment.service';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { googleReviews } from '../google-reviews.json';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { countryCodes } from '../../../app/shared/country-code.json';


export interface DialogData {
  route: string;
  message: string;
}


@Component({
  selector: 'app-location-page',
  templateUrl: './location-page.component.html',
  styleUrls: ['./location-page.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-100%)' }),
        animate(500, style({ opacity: 1, transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate(500, style({ opacity: 0, transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class LocationPageComponent implements OnInit {

  @ViewChild(SwiperComponent) componentRef: SwiperComponent;
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
  @ViewChild('stepper') private myStepper: MatStepper;
  @ViewChild('testDiv1')
  private testDiv1: ElementRef<HTMLDivElement>;
  isTestDivScrolledIntoView1: boolean;
  isAlreadyViewed: boolean = false
  continueousFunction1
  stepperIndex = 1
  nextToStepper: any = false
  public form1error: any = ""
  public formPerror
  public form2error
  public form1OtpSent
  public formPOtpSent
  public form2Otpsent
  public shownewPage1 = false;
  public shownewPage2 = false;
  public shownewPage3 = false;
  public shownewPage4 = false;
  public shownewPage5 = false;
  public shownewPage6 = false;
  public shownewPage7 = false;
  public shownewPage8 = false;
  public shownewPage9 = false;
  public shownewPage10 = false;
  public shownewPage11 = false;
  public shownewPage12 = false;
  public pricingId
  public stackMyPlanOnly: boolean
  endOfreviews: boolean = false
  public showMainVideo = false
  public showImagesForLoaction = false

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false
  };
  private autoplay: SwiperAutoplayInterface = {
    delay: 900000,
    disableOnInteraction: false
  };
  public Corporateconfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: false,
    pagination: this.pagination,
    loop: true,
    autoplay: this.autoplay
  };
  public videoConfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: false,
    pagination: this.pagination,
    loop: true,
    autoplay: this.autoplay,

  };
  public registerloading2: any = false;
  public verifyOTPloading2: any = false
  userName
  public isLoggedIn = false;
  userEmail
  category
  platformId: string
  class
  packages
  panelOpenState: boolean[] = [];
  cityNameRoute
  cityData
  testimonials
  counselorsData
  activeIndex2 = 0;
  public studentPath: any;
  registerloadingp: boolean;
  existErrorp: boolean = false;
  resenOtpp: boolean = false;
  otpSentp: boolean = false;
  disableResendOTpp: boolean = true;
  showTimerp: boolean;
  verifyOTPloadingp: boolean;
  public registerloading: any = false;
  public verifyOTPloading: any = false
  public registerForm: FormGroup
  firstFormGroup: FormGroup;
  registerFormpopup: FormGroup
  public otpSent = false;
  resenOtp: any = false;
  disableResendOTp: any = true;
  public indianPhonepattern = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$"
  public internationalPhonePattern = '[0-9]+'
  showTimer: boolean;
  public Counsellorconfig: SwiperConfigInterface = {
    direction: 'horizontal',
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: this.pagination,
    autoplay: this.autoplay,
    slidesPerView: 'auto',
    spaceBetween: 2.5,
    loop: true
  };
  public otpSent2 = false;
  resenOtp2: any = false;
  disableResendOTp2: any = true;
  showTimer2: boolean;
  public studentCareerPathArray
  public data: any;
  selectedCountryCode2 = "+91"
  selectedCountryCode = "+91"
  selectedClass1 = "class"
  selectedClass2 = "class"
  public registerForm2: FormGroup
  public pricing: any;
  public packagesNew: any;
  public testCompleted
  public paidPackageSelected = false
  public studentClass = ""
  selectedCountryCodep = "+91"
  selectedClassp = "class"
  sendOtpOn = 'mobile'

  public classes: any = [{ name: '8th-9th', value: '8th-9th' }, { name: '10th-12th', value: '10th-12th' }, { name: 'College Graduate', value: 'College Graduate' }, { name: 'Working Professional', value: 'Working Professional' }]
  public classWithPackIdArr = [{
    class: '8th-9th', buyId: '1e109467-419d-4310-a661-235d7797ea3d',
    buyIdPlus: "1e109467-419d-4310-41-235d7797ea3d",
  }, {
    class: '10th-12th', buyId: '4b7db883-94c8-4a07-a30e-509b99fe51c3',
    buyIdPlus: "4b7d883-94c8-4a07-a30e-509b99fe51c3"
  }, {
    class: 'Working Professional', buyId: 'fe7808b1-97e5-4f28-a954-5f01b795aeef',
    buyIdPlus: "fe78081-97e5-4f28-a954-5f01b795aeef",
  }, {
    class: 'College Graduate', buyId: 'fe7808b1-97e5-4f28-a954-5f01b795aeef',
    buyIdPlus: "fe78081-97e5-4f28-a954-5f01b795aeef"
  }]
  currentVideosList = ['https://www.youtube.com/embed/YP3eozTzVcA', 'https://www.youtube.com/embed/VsbzpZiofHk', 'https://www.youtube.com/embed/-_xdakrRH9U']
  videoSection = [{ id: 'student', state: true, videos: ['https://www.youtube.com/embed/YP3eozTzVcA', 'https://www.youtube.com/embed/VsbzpZiofHk', 'https://www.youtube.com/embed/-_xdakrRH9U'] }, { id: 'Parents', state: false, videos: ['https://www.youtube.com/embed/a97ZWwJF_ek', 'https://www.youtube.com/embed/3aQW-IkVlro'] }, { id: 'CollegeGraduates', state: false, videos: ['https://www.youtube.com/embed/Mv6t4fwkm7I'] }, { id: 'WorkingProfessionals', state: false, videos: ['https://www.youtube.com/embed/DydqD7WkN_g', 'https://www.youtube.com/embed/i9SAR-_1E6c', 'https://www.youtube.com/embed/IeL5ywgC7TU'] }, { id: 'Educators', state: false, videos: ['https://www.youtube.com/embed/SYryhAI4N2Y', 'https://www.youtube.com/embed/XMZ3Q4AHMt4', 'https://www.youtube.com/embed/9EvKZFm4Ggo'] }, { id: 'CorporatePartners', state: false, videos: ['https://www.youtube.com/embed/U4qi-MvgHbU', 'https://www.youtube.com/embed/RpGBZC7tnu0', 'https://www.youtube.com/embed/ZhjCtJdipmE'] }]
  countryCodes: { name: string; dial_code: string; code: string; }[];
  existError: boolean = false;
  existError2: boolean = false;
  errorMsg = '';
  errorMsg2 = '';
  errorMsgP = '';
  public reviewArr: any = ['ONE', 'TWO', 'THREE', 'FOUR', 'FIVE']
  jsonParseDataKolkata
  jsonParseDataDelhi
  jsonParseDataGurgaon
  jsonParseDataMumbai
  jsonParseDataPune
  jsonParseDataNagpur
  jsonParseDataBangalaore
  jsonParseDataJaipur
  jsonParseDataChandigarh
  jsonParseDataIndore
  jsonParseDataBhopal
  jsonParseDataChennai
  jsonParseDataVadodara
  jsonParseDataSurat
  jsonparseDataHyderabad
  jsonParseDataAhmedabad
  public googleReview=[]
  paymentData
  paymentId
  popUpSrc: any;
  starWidth
  disableToolTip: any = true;
  activeDiv = ''
  form1NameError = false
  form1LastNameError = false
  form1EmailError = false
  form1PhoneError = false
  form2NameError = false
  form2LastNameError = false
  form2EmailError = false
  form2PhoneError = false
  formPNameError = false
  formPLastNameError = false
  formPEmailError = false
  formPPhoneError = false
  class2error = false
  class1error = false
  classperror = false
  public initialPage = 1
  public googleReviewsTotalCount
  public firstNameErrorMsg = "Please enter first name."
  public lastNameErrorMsg = "Please enter last name."
  public emailErrorMsg = "Please enter valid email."
  public phoneErrorMsg = "Please enter valid phone no."
  @ViewChild('testDivA')
  private testDivA: ElementRef<HTMLDivElement>;
  @ViewChild('testDivB')
  private testDivB: ElementRef<HTMLDivElement>;
  @ViewChild('testDivC')
  private testDivC: ElementRef<HTMLDivElement>;
  @ViewChild('testDivD')
  private testDivD: ElementRef<HTMLDivElement>;
  @ViewChild('testDivE')
  private testDivE: ElementRef<HTMLDivElement>;
  @ViewChild('tt') mytooltip: NgbTooltip;


  constructor(private route: ActivatedRoute, public fb: FormBuilder, public audienceService: AudienceService, private localStorageService: StorageService, private commonService: CommonService, public accountService: AccountsService, public router: Router, private sharedService: SharedService, public dialog: MatDialog, private titleService: Title, private metaService: Meta, private paymentService: PaymentService, private renderer: Renderer2, private scroller: ViewportScroller) {
    window.scroll(0, 0)
    this.initializeFormGroup()
    this.route.params.forEach((params: Params) => {
      this.cityNameRoute = params['type'];
    });

    if (this.router.url.indexOf('?') !== -1) {
      var routeUrl = this.router.url.replace("/", "")
      this.cityNameRoute = routeUrl.substring(0, routeUrl.indexOf('?'));

    } else {
      this.cityNameRoute = this.router.url.replace("/", "")
    }

    this.counselorsData = counsellorsNew;
    this.pricing = pricing
    this.countryCodes = countryCodes
    // this.googleReview = googleReviews
    this.cityData = LocationData.find(t => t.route == this.cityNameRoute)

  }

  ngOnInit() {
    this.setSchemMarkupCode()
    this.titleService.setTitle(this.cityData.metaTitle);
    let attributeSelector: string = 'name="description"';
    this.metaService.removeTag(attributeSelector);
    this.metaService.updateTag({

      name: 'description',

      content: this.cityData.metaDesc

    });
    this.category = this.cityData.testimonials.subSections[0].category;
    this.changeClassForPricing(this.cityData.pricing.subSections[0].class);
    for (let i = 0; i < this.cityData.faq.subSections[0].questions.length; i++) {
      this.panelOpenState.push(false);
    }
    let authToken = this.localStorageService.get('access_token');
    if (authToken !== '') {


      this.isLoggedIn = true;
      this.callGetStudenApi()
      this.getStudentPath()
      // this.getStudent();
    }

  }


  ngAfterViewInit() {
    this.route.queryParams.subscribe(params => {
      this.paymentId = params['payment'];
    });
    if (this.paymentId != undefined) {
      this.getPaymentData(this.paymentId);

    }
    this.getStartedFun()
  }

  /**setting schema markup code  */
  setSchemMarkupCode() {
    if (this.cityNameRoute == 'career-counselling-in-kolkata') {
      this.jsonParseDataKolkata = kolkataData;
      let script1 = this.renderer.createElement('script'); script1.type = 'application/ld+json'; script1.text = `${JSON.stringify(this.jsonParseDataKolkata)}`; this.renderer.appendChild(document.body, script1);
    }

    else if (this.cityNameRoute == 'career-counselling-in-delhi') {
      this.jsonParseDataDelhi = delhiData;
      let script2 = this.renderer.createElement('script'); script2.type = 'application/ld+json'; script2.text = `${JSON.stringify(this.jsonParseDataDelhi)}`; this.renderer.appendChild(document.body, script2);
    }


    else if (this.cityNameRoute == 'career-counselling-in-mumbai') {
      this.jsonParseDataMumbai = mumbaiData
      let script4 = this.renderer.createElement('script'); script4.type = 'application/ld+json'; script4.text = `${JSON.stringify(this.jsonParseDataMumbai)}`; this.renderer.appendChild(document.body, script4);
    }

    else if (this.cityNameRoute == 'career-counselling-in-pune') {
      this.jsonParseDataPune = puneData
      let script5 = this.renderer.createElement('script'); script5.type = 'application/ld+json'; script5.text = `${JSON.stringify(this.jsonParseDataPune)}`; this.renderer.appendChild(document.body, script5);
    }
    else if (this.cityNameRoute == 'career-counselling-in-nagpur') {
      this.jsonParseDataNagpur = nagpurData
      let script6 = this.renderer.createElement('script'); script6.type = 'application/ld+json'; script6.text = `${JSON.stringify(this.jsonParseDataNagpur)}`; this.renderer.appendChild(document.body, script6);
    }
    else if (this.cityNameRoute == 'career-counselling-in-bangalore') {
      this.jsonParseDataBangalaore = bangaloreData
      let script7 = this.renderer.createElement('script'); script7.type = 'application/ld+json'; script7.text = `${JSON.stringify(this.jsonParseDataBangalaore)}`; this.renderer.appendChild(document.body, script7);
    }

    else if (this.cityNameRoute == 'career-counselling-in-jaipur') {
      this.jsonParseDataJaipur = jaipurData
      let script8 = this.renderer.createElement('script'); script8.type = 'application/ld+json'; script8.text = `${JSON.stringify(this.jsonParseDataJaipur)}`; this.renderer.appendChild(document.body, script8);

    }

    else if (this.cityNameRoute == 'career-counselling-in-chandigarh') {
      this.jsonParseDataChandigarh = chandigarhData
      let script9 = this.renderer.createElement('script'); script9.type = 'application/ld+json'; script9.text = `${JSON.stringify(this.jsonParseDataChandigarh)}`; this.renderer.appendChild(document.body, script9);
    }

    else if (this.cityNameRoute == 'career-counselling-in-indore') {
      this.jsonParseDataIndore = indoreData
      let script10 = this.renderer.createElement('script'); script10.type = 'application/ld+json'; script10.text = `${JSON.stringify(this.jsonParseDataIndore)}`; this.renderer.appendChild(document.body, script10);
    }

    else if (this.cityNameRoute == 'career-counselling-in-bhopal') {
      this.jsonParseDataBhopal = bhopalData
      let script11 = this.renderer.createElement('script'); script11.type = 'application/ld+json'; script11.text = `${JSON.stringify(this.jsonParseDataBhopal)}`; this.renderer.appendChild(document.body, script11);

    }
    else if (this.cityNameRoute == 'career-counselling-in-chennai') {
      this.jsonParseDataChennai = chennaiData
      let script12 = this.renderer.createElement('script'); script12.type = 'application/ld+json'; script12.text = `${JSON.stringify(this.jsonParseDataChennai)}`; this.renderer.appendChild(document.body, script12);
    }
    else if (this.cityNameRoute == 'career-counselling-in-vadodara') {
      this.jsonParseDataVadodara = vadodaraData
      let script13 = this.renderer.createElement('script'); script13.type = 'application/ld+json'; script13.text = `${JSON.stringify(this.jsonParseDataVadodara)}`; this.renderer.appendChild(document.body, script13);
    }

    else if (this.cityNameRoute == 'career-counselling-in-surat') {
      this.jsonParseDataSurat = suratData
      let script14 = this.renderer.createElement('script'); script14.type = 'application/ld+json'; script14.text = `${JSON.stringify(this.jsonParseDataSurat)}`; this.renderer.appendChild(document.body, script14);
    }
    else if (this.cityNameRoute == 'career-counselling-in-ahmedabad') {
      this.jsonParseDataAhmedabad = ahmedabadData
      let script15 = this.renderer.createElement('script'); script15.type = 'application/ld+json'; script15.text = `${JSON.stringify(this.jsonParseDataAhmedabad)}`; this.renderer.appendChild(document.body, script15);
    }
    else if (this.cityNameRoute == 'career-counselling-in-hyderabad') {
      this.jsonparseDataHyderabad = hyderabadData
      let script16 = this.renderer.createElement('script'); script16.type = 'application/ld+json'; script16.text = `${JSON.stringify(this.jsonparseDataHyderabad)}`; this.renderer.appendChild(document.body, script16);
    }
  }

//to call google reviews api
  callGooglereviewsAPI(pageno) {
    this.initialPage = pageno
    if (!this.endOfreviews) {
      this.audienceService.getAllGooglereviews(pageno).subscribe(data => {
     
        let res: any = data
        this.googleReviewsTotalCount=res.TotalRatings
        this.starWidth = (res.AvgRating / 5) * 100;
        if (res.Reviews.length>0) {
          this.googleReview = this.googleReview.concat(res.Reviews);
          console.log("google revives=>", this.googleReview)
        }
        else {
          this.endOfreviews = true
          console.log("page end=>",this.initialPage)
        }
      })
    }
  }

  //on scrolling down to google reviews section
  onScrollDown(e) {
    console.log("down=>", e)

  }

  //on scrolling up to google reviews section
  onScrollUp(e) {
    console.log("UP=>", e)
  }
  
  initializeFormGroup() {
    this.registerForm2 = this.fb.group({
      registerName: ['', [Validators.required]],
      registerlName: ['', [Validators.required]],
      dialCode: [this.selectedCountryCode2, [Validators.required]],
      emailId: ['', [Validators.required, Validators.pattern(this.indianPhonepattern)]],
      otpdigit1: ['', []],
      otpdigit2: ['', []],
      otpdigit3: ['', []],
      otpdigit4: ['', []],
      otp: ['', []],
      phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]+')]],
      selectedClass: [this.selectedClass1, [Validators.required]]

    })
    this.firstFormGroup = this.fb.group({
      firstCtrl: ['', Validators.required],
    });
    this.registerForm = this.fb.group({
      registerName: ['', [Validators.required]],
      registerlName: ['', [Validators.required]],
      dialCode: [this.selectedCountryCode, [Validators.required]],
      emailId: ['', [Validators.required, Validators.pattern(this.indianPhonepattern)]],
      otpdigit1: ['', []],
      otpdigit2: ['', []],
      otpdigit3: ['', []],
      otpdigit4: ['', []],
      otp: ['', []],
      phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]+')]],
      selectedClass: [this.selectedClass2, [Validators.required]]
    })
    this.registerFormpopup = this.fb.group({
      registerName: ['', [Validators.required]],
      registerlName: ['', [Validators.required]],
      dialCode: [this.selectedCountryCodep, [Validators.required]],
      emailId: ['', [Validators.required, Validators.pattern(this.indianPhonepattern)]],
      otpdigit1: ['', []],
      otpdigit2: ['', []],
      otpdigit3: ['', []],
      otpdigit4: ['', []],
      otp: ['', []],
      phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]+')]],
      selectedClass: [this.selectedClassp, [Validators.required]]


    })
  }
  /**For opening google reviews tooltip */
  showTooltip() {
    this.disableToolTip = false;
    this.mytooltip.open();

  }

  /**for closing google reviews tooltip */
  hideTooltip() {
    this.mytooltip.close();
    this.disableToolTip = true;
  }

  /**For stepper cred ui like design */
  @HostListener('window:scroll', [])
  onWindowScroll() {

    if (this.testDiv1 && !this.isAlreadyViewed) {
      const rect1 = this.testDiv1.nativeElement.getBoundingClientRect();
      const topShown = rect1.top >= 0;
      const bottomShown = rect1.bottom <= window.innerHeight;
      this.isTestDivScrolledIntoView1 = topShown && bottomShown

      if (this.isTestDivScrolledIntoView1) {
        this.isAlreadyViewed = true
        this.startSTepperScroll()
      }
    }

    const rectA = this.testDivA.nativeElement.getBoundingClientRect();
    const rectB = this.testDivB.nativeElement.getBoundingClientRect();
    const rectC = this.testDivC.nativeElement.getBoundingClientRect();
    const rectD = this.testDivD.nativeElement.getBoundingClientRect();
    const rectE = this.testDivE.nativeElement.getBoundingClientRect();

    if (rectA.top < window.innerHeight) {
      if (rectA.top > 0 && rectA.top < (window.innerHeight / 1.5) && rectA.bottom > 0 && rectA.bottom < (window.innerHeight * 2))
        this.activeDiv = 'blockOne'
      else if (rectB.top > 0 && rectB.top < (window.innerHeight / 1.5) && rectB.bottom > 0 && rectB.bottom < (window.innerHeight * 2))
        this.activeDiv = 'blockTwo'
      else if (rectC.top > 0 && rectC.top < (window.innerHeight / 1.5) && rectC.bottom > 0 && rectC.bottom < (window.innerHeight * 2))
        this.activeDiv = 'blockThree'
      else if (rectD.top > 0 && rectD.top < (window.innerHeight / 1.5) && rectD.bottom > 0 && rectD.bottom < (window.innerHeight * 2))
        this.activeDiv = 'blockFour'
      else if (rectE.top > 0 && rectE.top < (window.innerHeight / 1.5) && rectE.bottom > 0 && rectE.bottom < (window.innerHeight * 2))
        this.activeDiv = 'blockFive'
      else
        this.activeDiv = ''
    }
  }


  /**for changing index of steps according to paticular time interval */
  startSTepperScroll() {
    this.stepperIndex = 1
    this.continueousFunction1 = setInterval(() => {
      this.setStepperIndex()
    }, 5000);
  }

  //setting index to step
  setStepperIndex() {
    if (this.stepperIndex < 5) {
      this.myStepper.selectedIndex = this.stepperIndex;
      this.stepperIndex++;
    } else {
      this.nextToStepper = true;
      clearInterval(this.continueousFunction1);

      setTimeout(() => {
        var element = document.getElementById("nextToStepper");

        element.scrollIntoView({ behavior: 'smooth', block: "start", inline: 'start' });
        // window.scrollTo(0, document.getElementById("nextToStepper").offsetTop - 130);
      }, 100)
    }
  }
  getStarted(userName, userEmail) {

  }

  //if selected country code is other than +91 then validation will get chage here
  changeValidation(form, code) {
    if (form == 'top' && code !== '+91') {
console.log(form)
      this.registerForm.controls["phone"].setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(14), Validators.pattern(this.internationalPhonePattern)]);
    }
    if (form == 'bottom' && code !== '+91') {
      console.log(form)
      this.registerForm2.controls["phone"].setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(14), Validators.pattern(this.internationalPhonePattern)]);
    }
    if (form == 'popup' && code !== '+91') {
      console.log(form)
      this.registerFormpopup.controls["phone"].setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(14), Validators.pattern(this.internationalPhonePattern)]);
    }
  }

  /**For changing the videos category of testimonial */
  changeCategoryForTestimonial(category) {
    this.category = category;
  }

  /**For changing the pricing data according to class selected */
  changeClassForPricing(sectionClass) {
    this.class = sectionClass;
    this.packagesNew = this.pricing.subSections.find(c => c.class == this.class).packages;
  }


  /**get the package data of the selected class from if user wants to register from pricing */
  changeClassForPricingbyEducationalBG(sectionClass) {
    this.packagesNew = this.pricing.subSections.find(c => c.eduClass == sectionClass).packages;
    this.class = this.pricing.subSections.find(c => c.eduClass == sectionClass).class;
  }

  /**for setting the particular categories videos in videolist of active testimonial */
  getVideosByCategory(str) {
    let category = str;
    this.videoSection.forEach(element => {

      if (element.id == category) {

        element.state = true;
        this.currentVideosList = element.videos;
      }
      else {
        element.state = false;
      }

    });

  }
/**regiter from top form */
  registerTop() {
    this.form1NameError = false
    this.form1LastNameError = false
    this.form1EmailError = false
    this.form1PhoneError = false
    this.class1error = false
    this.form1error = ""
 
    this.existError = false
    this.registerloading = true;

    let obj =
    {
      firstName: this.registerForm.value.registerName,
      lastName: this.registerForm.value.registerlName,
      Mobile: this.registerForm.value.phone,
      educationalClass: this.selectedClass1,
      emailId: this.registerForm.value.emailId,
      countryCode: this.selectedCountryCode,
      Source: "Location :" + " " + "/" + this.cityNameRoute,
      utm_source: this.cityData.cityName,
      sendOtpOn: this.sendOtpOn
    }

    if (obj.educationalClass !== 'class') {

      this.callRegisterApi(obj, 'top')
    }
    else {
      this.registerloading = false;
      // swal({ text: "Please select class." })
      this.form1error = "Please select class."
      this.class1error = true
    }
  }
  /**verify otp for top form */
  verifyOTPtop() {
    this.form1OtpSent = ""
    this.verifyOTPloading = true;
    this.registerForm.value.otp = this.registerForm.value.otpdigit1 + "" + "" + this.registerForm.value.otpdigit2 + "" + "" + this.registerForm.value.otpdigit3 + "" + "" + this.registerForm.value.otpdigit4
    if (this.registerForm.value.otp) {
      let obj = {
        firstName: this.registerForm.value.registerName,
        lastName: this.registerForm.value.registerlName,
        Mobile: this.registerForm.value.phone,
        educationalClass: this.selectedClass1,
        emailId: this.registerForm.value.emailId,
        countryCode: this.selectedCountryCode,
        VerificationCode: this.registerForm.value.otp,
        utm_source: this.cityData.cityName,
        sendOtpOn: this.sendOtpOn
      }
       this.callVerifyOTPApi(obj, 'top')
    }
    else {
      this.verifyOTPloading = false
      swal({
        type: "error",
        title: "Invalid.",
        text: "Please enter OTP",
      })
    }
    this.resenOtp = true;

  }
  //resend otp for top form
  resendtop(sendOtpOn) {
    this.sendOtpOn = sendOtpOn
    this.disableResendOTp = true
    this.otpSent = true;
    this.resenOtp = false;
    setTimeout(() => {
      this.disableResendOTp = false
      this.showTimer = false

    }, 30000)

    this.showTimer = true
    this.registerTop();
  }

//register for bottom form
  registerBottom() {
    this.existError2 = false
    this.registerloading2 = true;
    this.form2NameError = false
    this.form2LastNameError = false
    this.form2EmailError = false
    this.form2PhoneError = false
    this.class2error = false
    this.form2error = ""
    let obj =
    {
      firstName: this.registerForm2.value.registerName,
      lastName: this.registerForm2.value.registerlName,
      Mobile: this.registerForm2.value.phone,
      educationalClass: this.selectedClass2,
      emailId: this.registerForm2.value.emailId,
      countryCode: this.selectedCountryCode2,
      Source: "Location :" + " " + "/" + this.cityNameRoute,
      utm_source: this.cityData.cityName
    }

    if (obj.educationalClass !== 'class') {
      this.callRegisterApi(obj, 'bottom')
    }
    else {
      this.registerloading2 = false;

      this.form2error = "Please select background."
      this.class2error = true

    }
  }

  //verifyotpforbottomform
  verifyOTPbottom() {
    this.form2Otpsent = ""
    this.verifyOTPloading2 = true;
   
    this.registerForm2.value.otp = this.registerForm2.value.otpdigit1 + "" + "" + this.registerForm2.value.otpdigit2 + "" + "" + this.registerForm2.value.otpdigit3 + "" + "" + this.registerForm2.value.otpdigit4
    if (this.registerForm2.value.otp) {
      let obj = {
        firstName: this.registerForm2.value.registerName,
        lastName: this.registerForm2.value.registerlName,
        Mobile: this.registerForm2.value.phone,
        educationalClass: this.selectedClass2,
        emailId: this.registerForm2.value.emailId,
        countryCode: this.selectedCountryCode2,
        VerificationCode: this.registerForm2.value.otp,
        utm_source: this.cityData.cityName
      }
      this.callVerifyOTPApi(obj, 'bottom')
    }
    else {
      this.verifyOTPloading2 = false
      swal({
        type: "error",
        title: "Invalid.",
        text: "Please enter OTP",
      })
    }
    this.resenOtp2 = true;

  }
 
//resend otp for bottom form
  resendBottom(sendOtpOn) {
    this.sendOtpOn = sendOtpOn
    this.disableResendOTp2 = true
    this.otpSent2 = true;
    this.resenOtp2 = false;
    setTimeout(() => {
      this.disableResendOTp2 = false
      this.showTimer2 = false

    }, 30000)

    this.showTimer2 = true
    this.registerBottom();
  }
  //get complete student path
  getStudentPath() {
    this.sharedService.GetStudentPath().subscribe(data => {
      let res: any = data;
      this.studentPath = res;
    })
  }


//on registering any out of 3 form verify form fields and give appropriate message 
  registerAlert(registerForm, formType) {
    this.form1NameError = false
    this.form1LastNameError = false
    this.form1EmailError = false
    this.form1PhoneError = false
    this.form2NameError = false
    this.form2LastNameError = false
    this.form2EmailError = false
    this.form2PhoneError = false
    this.formPNameError = false
    this.formPLastNameError = false
    this.formPEmailError = false
    this.formPPhoneError = false

    if (registerForm.controls.registerName.status == 'INVALID') {
      if (formType == 'topForm') {
        this.form1error = this.firstNameErrorMsg
        this.form1NameError = true
      }
      if (formType == 'popupForm') {
        this.formPerror = this.firstNameErrorMsg
        this.formPNameError = true
      }
      if (formType == 'bottomForm') {
        this.form2error = this.firstNameErrorMsg
        this.form2NameError = true
      }

    }
    else if (registerForm.controls.registerlName.status == 'INVALID') {
      if (formType == 'topForm') {
        this.form1error = this.lastNameErrorMsg
        this.form1LastNameError = true
      }
      if (formType == 'popupForm') {
        this.formPerror = this.lastNameErrorMsg
        this.formPLastNameError = true
      }
      if (formType == 'bottomForm') {
        this.form2error = this.lastNameErrorMsg
        this.form2LastNameError = true
      }

    }
    else if (registerForm.controls.emailId.status == 'INVALID') {
      if (formType == 'topForm') {
        this.form1EmailError = true
        this.form1error = this.emailErrorMsg
      }
      if (formType == 'popupForm') {
        this.formPerror = this.emailErrorMsg
        this.formPEmailError = true
      }
      if (formType == 'bottomForm') {
        this.form2error = this.emailErrorMsg
        this.form2EmailError = true
      }

    }
    else if (registerForm.controls.phone.status == 'INVALID') {
      if (formType == 'topForm') {
        this.form1PhoneError = true
        this.form1error = this.phoneErrorMsg
      }
      if (formType == 'popupForm') {
        this.formPPhoneError = true
        this.formPerror = this.phoneErrorMsg
      }
      if (formType == 'bottomForm') {
        this.form2PhoneError = true
        this.form2error = this.phoneErrorMsg

      }

    }

  }
  //scroll to the top of the window
  getStartedFun() {

    window.scrollTo(0, 0)


  }
  //redirects to the payment gateway according to the package selected from pricing section
  buyPackageCv(id) {
    debugger
    if (this.isLoggedIn) {
      var pricing = this.pricing.subSections.find(c => c.eduClass == this.studentClass);
      if (id == pricing.buyId || id == pricing.buyIdPlus) {
        this.router.navigate(['/payment/select-package', id], { queryParams: { redirect: 'Location : /' + this.cityNameRoute } });
      } else {
        this.router.navigate(['/payment/select-package', id], { queryParams: { multipackage: true, redirect: 'Location : /' + this.cityNameRoute } });
      }

      // this.router.navigate(['/payment/select-package', id]);
    }
    else {
      this.getStartedFun();
    }
  }
 //redirect to appropriate path according to the current status of the studen
  redirectToStudentPath() {
    this.router.navigate([this.studentPath])
  }

  //to call the getstudent api useful for getting details for the student
  callGetStudenApi() {
    this.sharedService.GetStudentDetails().subscribe(
      data1 => {
        let res: any = data1

        this.testCompleted = res.testCompleted;
        this.paidPackageSelected = res.paidPackageSelected;
        this.studentClass = res.educationalBackground;
        this.changeClassForPricingbyEducationalBG(res.educationalBackground)
      },
      error => {

        if (error["status"] === 401) {
        }
      }
    );
  }
  //registering from the popup
  registerPopup() {
    this.registerloadingp = true;
    this.existErrorp = false
    this.formPNameError = false
    this.formPLastNameError = false
    this.formPEmailError = false
    this.formPPhoneError = false
    this.classperror = false
    this.formPerror = ""
 
    let obj =
    {
      firstName: this.registerFormpopup.value.registerName,
      lastName: this.registerFormpopup.value.registerlName,
      Mobile: this.registerFormpopup.value.phone,
      educationalClass: this.selectedClassp,
      emailId: this.registerFormpopup.value.emailId,
      countryCode: this.selectedCountryCodep,
      Source: "Location :" + " " + "/" + this.cityNameRoute,
      utm_source: this.cityData.cityName,
      sendOtpOn: this.sendOtpOn
    }


    if (this.localStorageService.get("utm_source") !== '') {
      obj['utm_source'] = this.localStorageService.get("utm_source");
    }
    if (this.localStorageService.get("gclid") !== '') {
      obj['gclid'] = this.localStorageService.get("gclid");
    }
    if (this.localStorageService.get("utm_medium") !== '') {
      obj['utm_medium'] = this.localStorageService.get("utm_medium");
    }
    if (this.localStorageService.get("utm_campaign") !== '') {
      obj['utm_campaign'] = this.localStorageService.get("utm_campaign");
    }
    if (this.localStorageService.get("utm_adgroup") !== '') {
      obj['utm_adgroup'] = this.localStorageService.get("utm_adgroup");
    }
    if (this.localStorageService.get("utm_device") !== '') {
      obj['utm_device'] = this.localStorageService.get("utm_device");
    }
    if (this.localStorageService.get("utm_term") !== '') {
      obj['utm_term'] = this.localStorageService.get("utm_term");
    }
    if (this.localStorageService.get("utm_matchtype") !== '') {
      obj['utm_matchtype'] = this.localStorageService.get("utm_matchtype");
    }
    if (this.localStorageService.get("utm_location") !== '') {
      obj['utm_location'] = this.localStorageService.get("utm_location");
    }
    if (this.localStorageService.get("utm_placement") !== '') {
      obj['utm_placement'] = this.localStorageService.get("utm_placement");
    }

    if (obj.educationalClass !== 'class') {
      this.callRegisterApi(obj, 'popup')
    }
    else {
      this.registerloadingp = false;
      this.formPerror = "Please select class."
      this.classperror = true
    }
  }

//verification of otp after regiatering from the popup
  verifyOTPpopup() {
    this.formPOtpSent = ""
    this.formPerror = ""
  
    this.registerFormpopup.value.otp = this.registerFormpopup.value.otpdigit1 + "" + "" + this.registerFormpopup.value.otpdigit2 + "" + "" + this.registerFormpopup.value.otpdigit3 + "" + "" + this.registerFormpopup.value.otpdigit4
    this.verifyOTPloadingp = true;
    if (this.registerFormpopup.value.otp) {
      let obj = {
        firstName: this.registerFormpopup.value.registerName,
        lastName: this.registerFormpopup.value.registerlName,
        Mobile: this.registerFormpopup.value.phone,
        educationalClass: this.selectedClassp,
        emailId: this.registerFormpopup.value.emailId,
        countryCode: this.selectedCountryCodep,
        VerificationCode: this.registerFormpopup.value.otp,
        utm_source: this.cityData.cityName
      }
      this.callVerifyOTPApi(obj, 'popup')
    }
    else {
      this.verifyOTPloadingp = false
      swal({
        type: "error",
        title: "Invalid.",
        text: "Please enter OTP",
      })
    }
    this.resenOtpp = true;

  }

  //resend otp for popup registeration
  resendpPopup(sendOtpOn) {
    this.sendOtpOn = sendOtpOn
    this.disableResendOTpp = true
    this.otpSentp = true;
    this.resenOtpp = false;
    setTimeout(() => {
      this.disableResendOTpp = false
      this.showTimerp = false

    }, 30000)

    this.showTimerp = true
    this.registerPopup();
  }

  //for opening the dialog
  openDialog(registerModal, src): void {
    this.popUpSrc = src;
    if (src !== 0) {
      this.pricingId = src

    }

    const dialogRef = this.dialog.open(registerModal, {
      width: '60%',
      disableClose: false,
      data: { src: src },
      panelClass: "custom-modalbox"
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }

  //if the selected class while registering from popup of pricing and the package selected for buy now belongs to same class then redirects to only the respective package's payment gateway instead of multipackage
  buyPackageCvOnlyNew(id) {
    this.router.navigate(['/payment/select-package', id], { queryParams: { redirect: 'Location : /' + this.cityNameRoute } });

  }

  //if the selected class while registering from popup of pricing and the package selected for buy now is not belongs to same class then redirects to multipackage of that respective class payment gateway
  showStackedPlans(myClass) {
    let data = this.classWithPackIdArr.find(x => x.class = myClass)
    let id = data.buyId
    this.router.navigate(['/payment/select-package', id], { queryParams: { multipackage: true, redirect: 'Location : /' + this.cityNameRoute } });

  }

  //for closing dialog
  closeDialog(): void {
    this.dialog.closeAll();
  }

  //after postpayment for opening dialog box for futher instructions
  openPaymentRedirectDialog(): void {
    let data: any;
    if (this.isLoggedIn && !this.testCompleted) {
      data = "Begin the psychometric test and book your session with a counsellor"
    }
    else if (this.isLoggedIn && !this.paidPackageSelected) {
      data = "Book your session with a counsellor"
    }
    setTimeout(() => {
      const dialogRef = this.dialog.open(DialogOverviewExample2Dialog, {
        width: '40%',
        data: { route: this.studentPath, message: data }
      });

    }, 2000)

  }
  public getPaymentData(id) {

    if (id == 'viaCoupon') {
      setTimeout(() => {
        this.openPaymentRedirectDialog()
      }, 1000)
    }
    else {
      this.paymentService.getPaymentData(id).subscribe(
        response => {
          this.paymentData = response;

          if (this.paymentData != null && this.paymentData.orderStatus === 'Success') {
            this.sendEvent(this.paymentData.uId);
            this.openPaymentRedirectDialog()
          } else {
            swal({
              type: "error",
              title: "Oops.",
              text:
                "Transaction did not complete successfully."
            });
          }
        },
        error => {

        });
    }

  }

  sendEvent(trackingId) {

    (<any>window).gtag('event', 'conversion',

      {

        'send_to': 'AW-849928441/5TxpCNm8pagBEPnBo5UD',

        'value': this.paymentData.amount,

        'currency': 'INR',

        'transaction_id': trackingId

      });

    (<any>window).fbq('track', 'Purchase', { value: this.paymentData.amount, currency: 'INR' });

  }

  //for shiting to the previous input box like back and forth according to keypress or backspace
  onDigitInput(event) {

    let element;

    var key = event.keyCode || event.charCode;
    if (event.code !== 'Backspace')
      element = event.srcElement.nextElementSibling;

    if (event.code === 'Backspace' || key == 8 || key == 46)
      element = event.srcElement.previousElementSibling;

    if (element == null)
      return;
    else
      element.focus();
  }

  //for removing the error on focus
  removeErrorBoredrOnFocus(form, error) {
    /**
     * form=1 indicates top form
     * form->2 indicates bottom form
     * form->3 indicates popup form
     * error->1 indicates firstName error 
     * error->2 indicates last name error 
     * error->3 indicates phone no. error
     * error->4 indicates email error
     * error ->5 indicates class error
     */

    if (form == 1) {
      if (error == 1) {
        this.form1NameError = false
      }
      else if (error == 2) {
        this.form1LastNameError = false
      }
      else if (error == 3) {
        this.form1PhoneError = false
      }
      else if (error == 4) {
        this.form1EmailError = false
      }
      else if (error == 5) {
        this.class1error = false
      }
    }
    if (form == 2) {
      if (error == 1) {
        this.form2NameError = false
      }
      if (error == 2) {

        this.form2LastNameError = false
      }
      else if (error == 3) {
        this.form2PhoneError = false
      }
      else if (error == 4) {
        this.form2EmailError = false
      }
      else if (error == 5) {
        this.class2error = false
      }
    }
    if (form == 3) {
      if (error == 1) {
        this.formPNameError = false
      }
      else if (error == 2) {
        this.formPLastNameError = false
      }
      else if (error == 3) {
        this.formPPhoneError = false
      }
      else if (error == 4) {
        this.formPEmailError = false
      }
      else if (error == 5) {
        this.classperror = false
      }
    }
  }

  //to paste the otp 
  pasteOTP(event: ClipboardEvent, id) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    let arr = pastedText.split('')
    if (id == 1) {
      this.registerForm.controls['otpdigit1'].setValue(arr[0]);
      this.registerForm.controls['otpdigit2'].setValue(arr[1]);
      this.registerForm.controls['otpdigit3'].setValue(arr[2]);
      this.registerForm.controls['otpdigit4'].setValue(arr[3]);

    }
    if (id == 2) {
      this.registerForm2.controls['otpdigit1'].setValue(arr[0]);
      this.registerForm2.controls['otpdigit2'].setValue(arr[1]);
      this.registerForm2.controls['otpdigit3'].setValue(arr[2]);
      this.registerForm2.controls['otpdigit4'].setValue(arr[3]);

    }
    if (id == 3) {
      this.registerFormpopup.controls['otpdigit1'].setValue(arr[0]);
      this.registerFormpopup.controls['otpdigit2'].setValue(arr[1]);
      this.registerFormpopup.controls['otpdigit3'].setValue(arr[2]);
      this.registerFormpopup.controls['otpdigit4'].setValue(arr[3]);

    }
  }
  //for debugging defreload no other use
  loadfun(secid) {

  }

  //for trackby in ngFor SEO purpose
  trackByFn(index, item) {
    return index;
  }

  //calling registerViaMobileOrPhone Api
  callRegisterApi(obj, formtype) {

    if (this.localStorageService.get("utm_medium") !== '') {
      obj['utm_medium'] = this.localStorageService.get("utm_medium");
    }
    if (this.localStorageService.get("utm_campaign") !== '') {
      obj['utm_campaign'] = this.localStorageService.get("utm_campaign");
    }
    if (this.localStorageService.get("utm_adgroup") !== '') {
      obj['utm_adgroup'] = this.localStorageService.get("utm_adgroup");
    }
    if (this.localStorageService.get("utm_device") !== '') {
      obj['utm_device'] = this.localStorageService.get("utm_device");
    }
    if (this.localStorageService.get("utm_term") !== '') {
      obj['utm_term'] = this.localStorageService.get("utm_term");
    }
    if (this.localStorageService.get("utm_matchtype") !== '') {
      obj['utm_matchtype'] = this.localStorageService.get("utm_matchtype");
    }
    if (this.localStorageService.get("utm_location") !== '') {
      obj['utm_location'] = this.localStorageService.get("utm_location");
    }
    if (this.localStorageService.get("utm_placement") !== '') {
      obj['utm_placement'] = this.localStorageService.get("utm_placement");
    }

    this.audienceService.registerViaMobileOrEmailNew(obj).subscribe(data => {

      if (formtype == 'top') {
        this.registerloading = false;
        let res: any = data
        if (res.message == "success") {
          if (res.response == "email" || this.selectedCountryCode !== "+91") {
            this.form1OtpSent = "OTP has been Sent Successfully on Email."
          }
          else {

            this.form1OtpSent = "OTP Sent! Check your mobile phone."
          }
          this.otpSent = true;
          this.resenOtp = true;
          setTimeout(() => {
            this.disableResendOTp = false
            this.showTimer = false
          }, 30000)
          this.showTimer = true
        }
        else {

          this.existError = true;
          this.errorMsg = res.response
          this.registerloading = false;
        }

      }
      else if (formtype == 'bottom') {
        this.registerloading2 = false;



        let res: any = data
        if (res.message == "success") {
          if (res.response == "email" || this.selectedCountryCode2 !== "+91") {

            this.form2Otpsent = "OTP has been Sent Successfully on Email."
          }
          else {

            this.form2Otpsent = "OTP Sent! Check your mobile phone"
          }
          this.otpSent2 = true;
          this.resenOtp2 = true;
          setTimeout(() => {
            this.disableResendOTp2 = false
            this.showTimer2 = false
          }, 30000)
          this.showTimer2 = true
        }
        else {

          this.existError2 = true;
          this.errorMsg2 = res.response;
          this.registerloading2 = false;
        }
      }
      else if (formtype == 'popup') {



        this.registerloadingp = false;
        let res: any = data
        if (res.message == "success") {
          if (res.response == "email" || this.selectedCountryCode2 !== "+91") {

            this.formPOtpSent = "OTP has been Sent Successfully on Email."
          }
          else {

            this.formPOtpSent = "OTP Sent! Check your mobile phone"

          }
          this.otpSentp = true;
          this.resenOtpp = true;
          setTimeout(() => {
            this.disableResendOTpp = false
            this.showTimerp = false
          }, 30000)
          this.showTimerp = true
        }
        else {
          this.existErrorp = true;
          this.errorMsgP = res.response;
          this.registerloadingp = false;
        }

      }
    }), error => {
      this.registerloading = false;
      swal({
        type: "error",
        title: "OOPS.",
        text:
          "Something went wrong Please try after sometime."
      });
    }
  }

  public sendTrackingEvent() {
    (<any>window).gtag('event', 'conversion',
      {
        'send_to': 'AW-849928441/Pz4mCL74ppIBEPnBo5UD',
        'value': this.localStorageService.get('mobile')
      });

    (<any>window).fbq('track', 'Lead');
  }

  //calling verify otp api
  callVerifyOTPApi(obj, formtype) {
    this.localStorageService.set('mobile', obj.Mobile);
    if (this.localStorageService.get("utm_medium") !== '') {
      obj['utm_medium'] = this.localStorageService.get("utm_medium");
    }
    if (this.localStorageService.get("utm_campaign") !== '') {
      obj['utm_campaign'] = this.localStorageService.get("utm_campaign");
    }
    if (this.localStorageService.get("utm_adgroup") !== '') {
      obj['utm_adgroup'] = this.localStorageService.get("utm_adgroup");
    }
    if (this.localStorageService.get("utm_device") !== '') {
      obj['utm_device'] = this.localStorageService.get("utm_device");
    }
    if (this.localStorageService.get("utm_term") !== '') {
      obj['utm_term'] = this.localStorageService.get("utm_term");
    }
    if (this.localStorageService.get("utm_matchtype") !== '') {
      obj['utm_matchtype'] = this.localStorageService.get("utm_matchtype");
    }
    if (this.localStorageService.get("utm_location") !== '') {
      obj['utm_location'] = this.localStorageService.get("utm_location");
    }
    if (this.localStorageService.get("utm_placement") !== '') {
      obj['utm_placement'] = this.localStorageService.get("utm_placement");
    }
    this.audienceService.verifyOTPViaMobileOrEmailNew(obj).subscribe(data => {

      if (formtype == 'top') {
        let response: any = data;
        this.verifyOTPloading = false
        if (response.message !== 'failed') {

          let res: any = data

          let token: any = res.reponse.access_token

          if (res.message == 'success') {
            this.localStorageService.set("access_token", token);
            this.commonService.login();
            this.sendTrackingEvent()
            // this.appService.subscribeToNotification('login component');
            this.accountService.getAccountType().subscribe(
              accountType => {
                this.localStorageService.set("account_type", accountType);

                if (accountType === "student") {
                  //get student object and save in common service
                  // this.commonService.getStudent();
                  this.accountService.getStudentDetails().subscribe(
                    studentVar => {
                      this.studentCareerPathArray =
                        studentVar["selectedCareerPaths"];

                      if (!this.studentCareerPathArray) {
                        // this.commonService.careerArr = false;
                        this.localStorageService.set(
                          "career_arr",
                          this.studentCareerPathArray.length
                        );
                      } else {
                        this.localStorageService.set(
                          "career_arr",
                          this.studentCareerPathArray.length
                        );
                      }
                    },
                    err => {
                      alert("Some error occured.");
                    }
                  );

                  this.accountService.GetStudentPath().subscribe(
                    path => {

                      this.router.navigate([path]);
                    },
                    err => {
                      alert("Some error occured, Please login again.");
                    }
                  );
                } else if (accountType === "counsellor") {
                  this.accountService.GetCounsellorPath().subscribe(
                    path => {

                      this.router.navigate([path]);
                    },
                    err => {
                      alert("Some error occured, Please login again.");
                    }
                  );
                } else {
                  alert("Invalid User");
                }
              },
              err => {

              }
            );
          }
          else {
            swal({
              type: "error",
              title: "Oop's.",
              text:
                "Try again after sometime."
            });
          }
        }
        else {
          this.verifyOTPloading = false
          swal({
            type: "error",
            title: "Oop's.",
            text:
              "Incorrect OTP entered."
          });
        }

      }
      else if (formtype == 'bottom') {
        let response: any = data;
        this.verifyOTPloading2 = false
        if (response.message !== 'failed') {

          let res: any = data

          let token: any = res.reponse.access_token

          if (res.message == 'success') {



            this.localStorageService.set("access_token", token);
            this.commonService.login();

            this.accountService.getAccountType().subscribe(
              accountType => {
                this.localStorageService.set("account_type", accountType);

                if (accountType === "student") {

                  this.accountService.getStudentDetails().subscribe(
                    studentVar => {

                      this.studentCareerPathArray =
                        studentVar["selectedCareerPaths"];

                      if (!this.studentCareerPathArray) {
                        this.localStorageService.set(
                          "career_arr",
                          this.studentCareerPathArray.length
                        );
                      } else {
                        this.localStorageService.set(
                          "career_arr",
                          this.studentCareerPathArray.length
                        );
                      }
                    },
                    err => {
                      alert("Some error occured.");
                    }
                  );

                  this.accountService.GetStudentPath().subscribe(
                    path => {

                      this.router.navigate([path]);
                    },
                    err => {
                      alert("Some error occured, Please login again.");
                    }
                  );
                } else if (accountType === "counsellor") {
                  this.accountService.GetCounsellorPath().subscribe(
                    path => {

                      this.router.navigate([path]);
                    },
                    err => {
                      alert("Some error occured, Please login again.");
                    }
                  );
                } else {
                  alert("Invalid User");
                }
              },
              err => {

              }
            );
          }
          else {
            swal({
              type: "error",
              title: "Oop's.",
              text:
                "Error Try Again After Sometime."
            });
          }
        }
        else {
          this.verifyOTPloading2 = false
          swal({
            type: "error",
            title: "Oop's.",
            text:
              "Incorrect OTP ."
          });
        }
      }
      else if (formtype == 'popup') {
        let response: any = data;
        this.verifyOTPloadingp = false
        if (response.message !== 'failed') {

          let res: any = data

          let token: any = res.reponse.access_token

          if (res.message == 'success') {



            this.localStorageService.set("access_token", token);
            this.commonService.login();
            // this.appService.subscribeToNotification('login component');
            this.accountService.getAccountType().subscribe(
              accountType => {
                this.localStorageService.set("account_type", accountType);

                if (accountType === "student") {
                  //get student object and save in common service
                  // this.commonService.getStudent();
                  this.accountService.getStudentDetails().subscribe(
                    studentVar => {

                      this.studentCareerPathArray =
                        studentVar["selectedCareerPaths"];

                      if (!this.studentCareerPathArray) {
                        // this.commonService.careerArr = false;
                        this.localStorageService.set(
                          "career_arr",
                          this.studentCareerPathArray.length
                        );
                      } else {
                        this.localStorageService.set(
                          "career_arr",
                          this.studentCareerPathArray.length
                        );
                      }
                    },
                    err => {
                      alert("Some error occured.");
                    }
                  );

                  if (this.pricingId) {

                    debugger
                    this.dialog.closeAll();
                    // this.changeClassForPricingbyEducationalBG(this.selectedClassp)
                    this.isLoggedIn = true;

                    this.stackMyPlanOnly = true
                    this.classWithPackIdArr.forEach(element => {
                      if (element.class == obj.educationalClass && (element.buyId == this.pricingId || element.buyIdPlus == this.pricingId)) {
                        this.buyPackageCvOnlyNew(this.pricingId)
                        this.stackMyPlanOnly = false
                      }
                    });
                    if (this.stackMyPlanOnly) {
                      this.showStackedPlans(obj.educationalClass)
                    }

                  } else {
                    this.accountService.GetStudentPath().subscribe(
                      path => {
                        this.dialog.closeAll();
                        this.router.navigate([path]);
                      },
                      err => {
                        alert("Some error occured, Please login again.");
                      }
                    );
                  }
                } else if (accountType === "counsellor") {
                  this.accountService.GetCounsellorPath().subscribe(
                    path => {

                      this.router.navigate([path]);
                    },
                    err => {
                      alert("Some error occured, Please login again.");
                    }
                  );
                } else {
                  alert("Invalid User");
                }
              },
              err => {

              }
            );
          }
          else {
            swal({
              type: "error",
              title: "Oop's.",
              text:
                "Error Try Again After Sometime."
            });
          }
        }
        else {
          this.verifyOTPloadingp = false
          swal({
            type: "error",
            title: "Oop's.",
            text:
              "Incorrect OTP ."
          });
        }
      }
    }), error => {
      this.registerloading = false;
      swal({
        type: "error",
        title: "OOPS.",
        text:
          "Something went wrong Please try after sometime."
      });
    }
  }
  gottoPreloginLink(link)
  {
    this.sharedService.redirectNavURL(link)
  }
}




@Component({
  selector: 'dialog-overview-example2-dialog',
  templateUrl: '../../audience-page/main-page/dialog-overview-example-dialog.html',
})
export class DialogOverviewExample2Dialog {
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExample2Dialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public router: Router
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
    this.router.navigate([this.data.route])

  }

  /**BOTTOM REGISTERATION FORM */

}