import { Component, OnInit } from '@angular/core';
import { AccountsService } from '../accounts.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css', '../accounts.module.css', '../../shared/shared.module.scss']
})
export class ResetPasswordComponent implements OnInit {
  pass: string;
  code: string;
  public resetObj;
  public resetSuccess = false;
  public errorMsg = false;
  constructor(private accountService: AccountsService, private router: Router) { }

  ngOnInit() {
  }
  public ResetRequest(pass, code) {
    this.resetObj = {
      verificationCode: code,
      NewPassword: pass
    };


    if (pass === undefined || pass === '' || pass === null) {
    }
    else if (pass !== undefined && pass.length < 6) {
    }
    else if (code === undefined || code === '' || code === null) {
    }
    else {
      this.accountService.PasswordReset(this.resetObj).subscribe(
        data => {
          this.resetSuccess = true;
          this.pass = '';
          this.code = '';
          this.errorMsg = false;
          this.router.navigate(['/accounts/login']);
        },
        err => {
          //  alert('password reset error!');
          this.errorMsg = true;
        }
      );
    }
  }

}
