
import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { MainService } from './main.service';

import { SharedModule } from './../shared/shared.module';

import { RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HttpClientModule } from '@angular/common/http';
import { SitemapComponent } from './sitemap/sitemap.component';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    SharedModule,
    ],
  providers: [MainService],
  declarations: [ PageNotFoundComponent, SitemapComponent]
})
export class MainModule { }
