import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-old-banner',
  templateUrl: './old-banner.component.html',
  styleUrls: ['./old-banner.component.css']
})
export class OldBannerComponent implements OnInit {
  currentRoute: any
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    let activeRoute: any = this.route.snapshot
    console.log("Current Route=>", activeRoute.routeConfig.path)
    this.currentRoute=activeRoute.routeConfig.path
  }

}
