import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../common/storage-service';
import { SharedService } from '../shared.service';
import { Router } from '@angular/router';
import { CommonService } from '../../common/common.service';

@Component({
  selector: 'app-c-header',
  templateUrl: './c-header.component.html',
  styleUrls: ['./c-header.component.scss']
})
export class CHeaderComponent implements OnInit {

  public authToken;
  public counsellor;

  // basic counsellor Notification
  public notificationCount = 0;
  public notificationBtn;
  public notificationDetails = false;
  public cifForm: boolean;
  public annotationReport: boolean;
  public OPC: boolean;
  public FeedbackForm: boolean;
  public ReflectiveSummary: boolean;
  public AudioRecording: boolean;
  public response;
  public CIFArray = [];
  public AnnotatedReportArray = [];
  public OPCArray = [];
  public FeedbackFormArray = [];
  public ReflectiveSummaryArray = [];
  public AudioRecordingArray = [];

  // advance counsellor Notification
  public advNotificationCount = 0;
  public advNotificationBtn;
  public advNotificationDetails = false;
  public advcifForm: boolean;
  public advannotationReport: boolean;
  public advOPC: boolean;
  public advFeedbackForm: boolean;
  public advReflectiveSummary: boolean;
  public advAudioRecording: boolean;
  public advresponse;
  public advCIFArray = [];
  public advAnnotatedReportArray = [];
  public advOPCArray = [];
  public advFeedbackFormArray = [];
  public advReflectiveSummaryArray = [];
  public advAudioRecordingArray = [];

  constructor(private localStorage: StorageService, private commonService: CommonService, 
    private router: Router, private sharedService: SharedService, public localStorageService: StorageService) {
    this.authToken = this.localStorage.get('access_token');
  }

  public counsellorStatus;
  public selectedTab = '';
  public blogUrlToken = "https://blog.mentoria.com"

  ngOnInit() {
    this.getCounsellor();
    this.basicCounsellorDocumentNotification();
    this.advanceCounsellorDocumentNotification();
   
    
    if (this.authToken !== '') {
      this.blogUrlToken = this.blogUrlToken + "?token=" + this.authToken
    }
  }


  public getCounsellor() {
    this.sharedService.getCounsellorDetails().subscribe(
      response => {
        this.counsellor = response;
        console.log(this.counsellor);
      },
      error => {
        
      });
  }

  basicCounsellorDocumentNotification() {
    let notification = 1;
    this.sharedService.GetCounsellorDocuments("basic", 0).subscribe(response => {

      this.response = response;

      for (var i = 0; i < this.response.length; i++) {

        console.log("alert" + this.response[i])

        if (this.response[i].indexOf("basic_1_CIF") >= 0) {
          this.CIFArray.push(new UrlObject(1, this.response[i], this.response[i].substring(this.response[i].lastIndexOf("/") + 1)));
        }
        else if (this.response[i].indexOf("basic_1_AnnotatedReport") >= 0) {
          this.AnnotatedReportArray.push(new UrlObject(2, this.response[i], this.response[i].substring(this.response[i].lastIndexOf("/") + 1)));
        }
        else if (this.response[i].indexOf("basic_1_ObserverProcessChecklist") >= 0) {
          this.OPCArray.push(new UrlObject(3, this.response[i], this.response[i].substring(this.response[i].lastIndexOf("/") + 1)));
        }
        else if (this.response[i].indexOf("basic_1_FeedbackForm") >= 0) {
          this.FeedbackFormArray.push(new UrlObject(4, this.response[i], this.response[i].substring(this.response[i].lastIndexOf("/") + 1)));

        }
        else if (this.response[i].indexOf("basic_1_ReflectiveSummary") >= 0) {
          this.ReflectiveSummaryArray.push(new UrlObject(5, this.response[i], this.response[i].substring(this.response[i].lastIndexOf("/") + 1)));
        }
        else if (this.response[i].indexOf("basic_1_AudioRecording") >= 0) {
          this.AudioRecordingArray.push(new UrlObject(6, this.response[i], this.response[i].substring(this.response[i].lastIndexOf("/") + 1)));
        }

      }

      if (this.CIFArray.length === 0) {
        this.notificationCount = notification++;
      }
      if (this.AnnotatedReportArray.length === 0) {
        this.notificationCount = notification++;
      }
      if (this.OPCArray.length === 0) {
        this.notificationCount = notification++;
      }
      if (this.FeedbackFormArray.length === 0) {
        this.notificationCount = notification++;
      }
      if (this.ReflectiveSummaryArray.length === 0) {
        this.notificationCount = notification++;
      }
      if (this.AudioRecordingArray.length === 0) {
        this.notificationCount = notification++;
      }
      if (this.notificationCount === 0) {
        this.notificationBtn = false;
      } else{
        this.notificationBtn = true;
      }
    });
  }

  basicNotificationAlert() {
    this.notificationDetails = true;
    if (this.CIFArray.length === 0) {
      this.cifForm = true;
    }
    if (this.AnnotatedReportArray.length === 0) {
      this.annotationReport = true;
    }
    if (this.OPCArray.length === 0) {
      this.OPC = true;
    }
    if (this.FeedbackFormArray.length === 0) {
      this.FeedbackForm = true;
    }
    if (this.ReflectiveSummaryArray.length === 0) {
      this.ReflectiveSummary = true;
    }
    if (this.AudioRecordingArray.length === 0) {
      this.AudioRecording = true;
    }
  }

  advanceCounsellorDocumentNotification() {
    let advNotification = 1;
    this.sharedService.GetCounsellorDocuments("advance", 0).subscribe(response => {

      this.advresponse = response;

      for (var i = 0; i < this.advresponse.length; i++) {

        console.log("alert" + this.advresponse[i])

        if (this.advresponse[i].indexOf("advance_1_CIF") >= 0) {
          this.advCIFArray.push(new UrlObject(1, this.advresponse[i], this.advresponse[i].substring(this.advresponse[i].lastIndexOf("/") + 1)));
        }
        else if (this.advresponse[i].indexOf("advance_1_AnnotatedReport") >= 0) {
          this.advAnnotatedReportArray.push(new UrlObject(2, this.advresponse[i], this.advresponse[i].substring(this.advresponse[i].lastIndexOf("/") + 1)));
        }
        else if (this.advresponse[i].indexOf("advance_1_ObserverProcessChecklist") >= 0) {
          this.advOPCArray.push(new UrlObject(3, this.advresponse[i], this.advresponse[i].substring(this.advresponse[i].lastIndexOf("/") + 1)));
        }
        else if (this.advresponse[i].indexOf("advance_1_FeedbackForm") >= 0) {
          this.advFeedbackFormArray.push(new UrlObject(4, this.advresponse[i], this.advresponse[i].substring(this.advresponse[i].lastIndexOf("/") + 1)));

        }
        else if (this.advresponse[i].indexOf("advance_1_ReflectiveSummary") >= 0) {
          this.advReflectiveSummaryArray.push(new UrlObject(5, this.advresponse[i], this.advresponse[i].substring(this.advresponse[i].lastIndexOf("/") + 1)));
        }
        else if (this.advresponse[i].indexOf("advance_1_AudioRecording") >= 0) {
          this.advAudioRecordingArray.push(new UrlObject(6, this.advresponse[i], this.advresponse[i].substring(this.advresponse[i].lastIndexOf("/") + 1)));
        }

      }

      if (this.advCIFArray.length === 0) {
        this.advNotificationCount = advNotification++;
      }
      if (this.advAnnotatedReportArray.length === 0) {
        this.advNotificationCount = advNotification++;
      }
      if (this.advOPCArray.length === 0) {
        this.advNotificationCount = advNotification++;
      }
      if (this.advFeedbackFormArray.length === 0) {
        this.advNotificationCount = advNotification++;
      }
      if (this.advReflectiveSummaryArray.length === 0) {
        this.advNotificationCount = advNotification++;
      }
      if (this.advAudioRecordingArray.length === 0) {
        this.advNotificationCount = advNotification++;
      }
      if (this.advNotificationCount === 0) {
        this.advNotificationBtn = false;
      } else {
        this.advNotificationBtn = true;
      }
    });
  }

  advanceNotificationAlert() {
    this.advNotificationDetails = true;
    if (this.advCIFArray.length === 0) {
      this.advcifForm = true;
    }
    if (this.advAnnotatedReportArray.length === 0) {
      this.advannotationReport = true;
    }
    if (this.advOPCArray.length === 0) {
      this.advOPC = true;
    }
    if (this.advFeedbackFormArray.length === 0) {
      this.advFeedbackForm = true;
    }
    if (this.advReflectiveSummaryArray.length === 0) {
      this.advReflectiveSummary = true;
    }
    if (this.advAudioRecordingArray.length === 0) {
      this.advAudioRecording = true;
    }
  }
  
  Logout() {

    this.commonService.logout();
    this.localStorage.clearAll();
    this.router.navigate([this.commonService.getpreloginAppURL()]);
  }
}

export class UrlObject {
  constructor(DocType: number, OrgUrl: string, DisUrl: string) {

    this.DocType = DocType;
    this.OrgUrl = OrgUrl;
    this.DisUrl = DisUrl;

  }
  DocType: number;
  OrgUrl: string;
  DisUrl: string;

}
