import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
// import { ServiceWorkerModule } from '@angular/service-worker';
// import { environment } from '../environments/environment';

import { SidebarModule } from 'ng-sidebar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppRoutingModule } from './app-routing.module';
import { ImsModule } from './IMS/ims.module';
import { MainModule } from './main/main.module';
import { SharedModule } from './shared/shared.module';
import { StorageService } from './common/storage-service';
import { CommonService } from './common/common.service';
import { RoutingState } from './common/routingState';
import { CustomReuseStrategy } from './shared/routing';
import { WINDOW_PROVIDERS } from './common/window.service';
import { ScriptService } from './common/script.service';
// import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule } from 'angular-calendar';
import { ShareModule } from '@ngx-share/core';
import { HomepageComponent } from './home/homepage/homepage.component';
import { SwiperConfigInterface, SwiperModule, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { CustomMaterialModule } from './shared/custom-material.module';
import { HomeService } from './home/home.service';
import { AuthGuard } from './common/auth-guard';
import { BlogsModule } from './blogs/blogs.module';
import { DeviceInterceptor } from './common/device-interceptor';
import { MatExpansionModule, MatSidenavModule, MatToolbarModule, MatSelectModule, MatCardModule, MatInputModule, MatNativeDateModule, MatDividerModule, MatIconModule, MatButtonModule, MatFormFieldModule, MatRippleModule } from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatTabsModule } from '@angular/material/tabs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContentService } from './content/content.service';
import { MyJsonLdComponent } from './common/jsonFormat.component';
import { CareerOverviewModule } from './career-overview/career-overview.module';
import { CareerOverviewRewampModule } from './career-overview-rewamp/career-overview-rewamp.module';
import { LocationpageModule } from './locationpage/locationpage.module';
import { AudiencePageModule } from './audience-page/audience-page.module';
import { CareerListComponent } from './career-list/career-list.component';
import { RedirectComponent } from './redirect/redirect.component';
import { WindowRefService } from './common/window-ref.service';


const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: 'horizontal',
  threshold: 50,
  spaceBetween: 5,
  slidesPerView: 1,
  centeredSlides: true
};

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    CareerListComponent,
    RedirectComponent,
    
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'mentoria' }),
    // ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    HttpClientModule,
    FormsModule,
    SidebarModule.forRoot(),
    NgbModule.forRoot(),
    ShareModule.forRoot(),
    FlexLayoutModule,
    SwiperModule,
    NgxPaginationModule,
    CalendarModule,
    CustomMaterialModule,
    AppRoutingModule,
    SharedModule,
    ImsModule,
    BlogsModule,
    MainModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatRippleModule,
    MatNativeDateModule,
    CareerOverviewModule,
  CareerOverviewRewampModule,
  LocationpageModule,
  AudiencePageModule
  ],
  providers: [StorageService, HomeService, AuthGuard, CommonService, RoutingState, CustomReuseStrategy,WindowRefService, MyJsonLdComponent , ScriptService, WINDOW_PROVIDERS,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DeviceInterceptor,
      multi: true
    },{
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    ContentService, {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
