import { Package } from '../package.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot, Router, ActivatedRoute, Params } from '@angular/router';
import { PaymentService } from '../payment.service';
import { Payment } from '../payment.model';
import swal from 'sweetalert2';
import { CommonService } from '../../common/common.service';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.css', '../payment.module.css']
})
export class PaymentStatusComponent implements OnInit {
  public paymentId;
  public paymentData = new Payment();
  public package = new Package();
  public packageAmount = 0;

  public studentClass = '';

  constructor(private paymentService: PaymentService, private router: Router, private route: ActivatedRoute, private commonService: CommonService) {
    this.route.params.forEach((params: Params) => {
      this.paymentId = params['id'];
    });
    this.getPaymentData(this.paymentId);

  }
  ngOnInit() {
    this.commonService.updateUserInfo();
  }

  public getPaymentData(id) {
    this.paymentService.getPaymentData(id).subscribe(
      response => {
        this.paymentData = response;
        console.log(this.paymentData);
        this.GetPackage(this.paymentData.packageUid);

        if (this.paymentData.packageName.toLowerCase().includes('discover')) {
          this.studentClass = 'discover';
        } else if (this.paymentData.packageName.toLowerCase().includes('acheive')) {
          this.studentClass = 'acheive';
        } else if (this.paymentData.packageName.toLowerCase().includes('ascend')) {
          this.studentClass = 'ascend';
        }
        if (this.paymentData.orderStatus === 'Success') {
          this.sendEvent(this.paymentData.uId);
          if (this.paymentData.packageName === 'Career Brainstrom' || this.paymentData.packageName === 'Career Brainstrom Test') {
            this.router.navigate(['/career-brainstorm'], { queryParams: { paymentSuccess: true } });
          }
        }
      },
      error => {

      });
  }


  GetPackage(id) {
    this.paymentService.GetPackagesById(id).subscribe(
      response => {
        this.package = response;
      },
      error => {

      });
  }

  // sendEvent(trackingId) {
  //   (<any>window).gtag('event', 'conversion',
  //     {
  //       'send_to': 'AW-849928441/5TxpCNm8pagBEPnBo5UD',
  //       'value': trackingId
  //     });

  //   (<any>window).fbq('track', 'Purchase', { value: this.paymentData.amount , currency: 'INR' });
  // }

  sendEvent(trackingId) {

    (<any>window).gtag('event', 'conversion',

      {

        'send_to': 'AW-849928441/5TxpCNm8pagBEPnBo5UD',

        'value': this.paymentData.amount,

        'currency': 'INR',

        'transaction_id': trackingId

      });

    (<any>window).fbq('track', 'Purchase', { value: this.paymentData.amount, currency: 'INR' });

  }

  public continue() {
    if (this.paymentData.packageName === 'Career Brainstrom' || this.paymentData.packageName === 'Career Brainstrom Test') {
      this.router.navigate(['/career-brainstorm']);
    } else {
      this.paymentService.GetStudentPath().subscribe(path => {
        this.router.navigate([path]);
      }, err => {
        swal('Please login to continue.');
        this.router.navigate(['/accounts/get-started']);
      });
    }
  }

}
