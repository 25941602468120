import { StorageService } from '../../common/storage-service';
import { CommonService } from './../../common/common.service';
import { Component, OnInit, Injector, Inject, PLATFORM_ID } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { model } from './registration.model';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ImsService } from '../ims.service';
import { isPlatformBrowser } from '@angular/common';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { NG_VALIDATORS, Validator, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import {
  SwiperComponent, SwiperDirective, SwiperConfigInterface,
  SwiperScrollbarInterface, SwiperPaginationInterface, SwiperAutoplayInterface
} from 'ngx-swiper-wrapper';
declare var $: any;
import { globalValues } from '../../common/global-constants';

@Component({
  selector: 'app-ims-register',
  templateUrl: './ims-register.component.html',
  styleUrls: ['./ims-register.component.scss', '../../content/content.module.scss']
  // , '../../home/home/home.component.scss'
})
export class ImsRegisterComponent implements OnInit {
  platformId: any;
  screen: string;

  emailExists: boolean;
  phoneExists: boolean;
  statusValue: string;
  statusTitle: string;
  accounttype: string;
  public account: model = new model();
  public classes = ['8th-9th', '10th-12th', 'College/University', 'Other (Certificate/Diploma)'];
  public class;
  public gender;
  public Gender;
  public FirstName;
  public LastName;
  public MobileNumber;
  public Password;
  public Cpassword;
  public Email;
  public steponecompleted = false;
  public phonelen = false;
  public globalValues;
  public type: string = 'component';
  public show: boolean = true;
  public disabled: boolean = false;

  private autoplay: SwiperAutoplayInterface = {
    delay: 10000,
    disableOnInteraction: false
  };
  public imsConfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: false,
    loop: true,
    autoplay: this.autoplay
  };
  private scrollbar: SwiperScrollbarInterface = {
    el: '.swiper-scrollbar',
    hide: false,
    draggable: true,
  };

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false
  };
  constructor(private router: Router, private accountService: ImsService, private injector: Injector, @Inject(PLATFORM_ID) platformId: string, private commonService: CommonService, private route: ActivatedRoute, private localStorageService: StorageService, private titleService: Title, private metaService: Meta) {
    this.platformId = platformId;
    this.globalValues = globalValues;
    this.titleService.setTitle('Discover Career with IMS & Mentoria ');
    this.metaService.updateTag({
      name: 'description',
      content: "Explore the Career Path with IMS and Mentoria to Discover the Various Career Options which you can Choose based on your Passions, Skills and Interests"
    });

  }

  ngOnInit() {
    this.initmethod();
    this.statusValue = '';
    $('.carousel').carousel({
      interval: 2000000
    });
    $('#GoDown').click(function () {
      $('html,body').animate({
        scrollTop: $('#Down').offset().top -= 50
      },
        '10000000000');
    });
    $('#GoUp').click(function () {
      $('html,body').animate({
        scrollTop: $('#Up').offset().top -= 50
      },
        '10000000000');
    });


    //  code for carousel swipe on mobile (tested on android and iphone)
    $('.carousel').on('touchstart', function (event) {
      var xClick = event.originalEvent.touches[0].pageX;
      $(this).one('touchmove', function (event) {
        var xMove = event.originalEvent.touches[0].pageX;
        if (Math.floor(xClick - xMove) > 5) {
          $('.carousel').carousel('next');
        }
        else if (Math.floor(xClick - xMove) < -5) {
          $('.carousel').carousel('prev');
        }
      });
      $('.carousel').on('touchend', function () {
        $(this).off('touchmove');
      });
    });

  }

  public onIndexChange(index: number): void {
    console.log('Swiper index: ', index);
  }

  RegisterIMS2() {
    this.accounttype = this.localStorageService.get('account_type');
    // this.account.source = ''
    // this.statusTitle = 'Study';
    if (this.statusValue === '10th-12th' || this.statusValue === '8th-9th') {
      this.account.CurrentClass = this.statusValue;
    } else if (this.statusValue === 'College Graduate') {
      this.account.CurrentClass = 'College Graduate';
    }
    this.account.CurrentStatus = 'Study-' + this.statusValue;
    this.account.source = 'ims';
    this.accountService.RegisterStudent(this.account)
      .subscribe(
        res => {
          console.log(res);
          this.accountService.login(this.account.Mobile, this.account.Password)
            .subscribe(
              response => {
                
                this.localStorageService.set('access_token', response['access_token']);
                this.localStorageService.set('account_type', 'student');
                this.router.navigate(['/accounts/verification']);
              },
              error => {
                
              });

        });
  }
  public checkPhoneExists() {
    this.accountService.CheckPhoneExists(this.account.Mobile)
      .subscribe(
        response => {
          if (response['Message'] === 'True') {
            this.phoneExists = true;
          } else {
            this.phoneExists = false;
          }
        },
        error => {
          
        });

  }

  public checkEmailExists() {
    this.accountService.CheckEmailExists(this.account.Email)
      .subscribe(
        response => {
          if (response['Message'] === 'True') {
            this.emailExists = true;
          } else {
            this.emailExists = false;
          }
        },
        error => {
          
        });

  }

  onlyNumber() {
    var num = this.account.Mobile.toString();
    if (num.length > 9 || num.length === 0 || num.length>10 ) {
      this.phonelen = false;
    } else if (num.length < 10) {
      this.phonelen = true;
    }
    // this.account.Mobile = this.account.Mobile.replace( /[^0-9]/g, '' );
  }
  public onIndexChange0(index: number): void {
    console.log('Swiper index: ', index);
  }
  public signUpWithGoogle() {
    const clientId = encodeURI('Mentoria.elp');
    const scope = encodeURI('Mentoriaapi');
    const redirectUri = encodeURI(this.commonService.getAppUrl() + '/accounts/post-login');
    const responseType = encodeURI('token');
    const acrValues = encodeURI('idp:Google');
    const source = encodeURI('ims');
    const action = encodeURI('register');
    const url = this.commonService.getStsUrl() + '/core/connect/authorize?client_id=' + clientId + '&scope=' + scope + '&redirect_uri=' + redirectUri + '&response_type=' + responseType + '&acr_values=' + acrValues + '&source=' + source + '&action=' + action;
    if (isPlatformBrowser(this.platformId)) {
      window.location.href = url;
    }
  }

  public signUpWithFacebook() {
    const clientId = encodeURI('Mentoria.elp');
    const scope = encodeURI('Mentoriaapi');
    const redirectUri = encodeURI(this.commonService.getAppUrl() + '/accounts/post-login');
    const responseType = encodeURI('token');
    const acrValues = encodeURI('idp:Facebook');
    const source = encodeURI('ims');
    const action = encodeURI('register');
    const url = this.commonService.getStsUrl() + '/core/connect/authorize?client_id=' + clientId + '&scope=' + scope + '&redirect_uri=' + redirectUri + '&response_type=' + responseType + '&acr_values=' + acrValues + '&source=' + source + '&action=' + action;
    if (isPlatformBrowser(this.platformId)) {
      window.location.href = url;
    }
  }


  initmethod() {
    if (isPlatformBrowser(this.platformId)) {
      let ua = window.navigator.userAgent;
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
        this.screen = 'mobile';
      } else {
        this.screen = 'web';
      }
    }
  }
}
