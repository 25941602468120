import { Observable } from 'rxjs';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    RouterStateSnapshot,
    Route,
    Router,
    CanLoad
} from '@angular/router';
import { isPlatformBrowser } from '@angular/common';


@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {

    public platformId = '';
    constructor(private route: Router, @Inject(PLATFORM_ID) platformId: string, ) { 
        this.platformId = platformId;
    }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkLogin();
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate(route, state);
    }

    public canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkLogin();
    }

    private checkLogin() {
        if (isPlatformBrowser(this.platformId)) {
            if (localStorage.getItem("access_token")) {
                return true;
            } else {
                let pathEncoded = encodeURI(window.location.href);
                this.route.navigate(["/accounts/login"], {
                    queryParams: { redirectURl: pathEncoded }
                });
                localStorage.removeItem("access_token");
                return false;
            }
        }
    }

}
