import { StorageService } from '../../common/storage-service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params, RouterLink } from '@angular/router';
import { PaymentService } from '../payment.service';
// import { Package } from '../package.model';
import swal from 'sweetalert2';
import { CommonService } from '../../common/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { subscribedPackages } from './select-package.json';
import { ContentService } from '../../../../src/app/content/content.service';


@Component({
  selector: 'app-select-package',
  templateUrl: './select-package.component.html',
  styleUrls: ['./select-package.component.css', '../payment.module.css']
})
export class SelectPackageComponent implements OnInit {

  public loading = false;
  public lazyPayLoading = false
  public package;
  // public package = new Package();
  // public package: Package[] = [];
  //  public package: Package[] ;
  public paymentModel = {
    'couponCode': '',
    'packageId': '',
    'sourceUrl': '',
  };
  public couponCode = '';
  public packageid;
  public showReedeemBtn = true;
  public ap = true;
  public ws = true;
  public coupon_code;

  public modelRef: any;
  DateOfBirth

  public lazyPayUser = {
    "firstName": '',
    "lastName": "",
    "fatherName": "",
    "mobile": "",
    "pan": "",
    "dob": "",
    "gender": "",
    "email": "",
    "employmentType": "",
    "maritalStatus": "",
    "address": {
      "line1": "",
      "line2": "",
      "city": "",
      "state": "",
      "country": "",
      "pincode": ""
    }
  }

  emailPatt = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/);

  errorMessagesForEmail
  sourceUrl = '';
  isMultiPackage = ''
  pack: any = [];
  packages: any;
  public subscribedPackages: any
  public CounsellingPref;
  public packageOnline: any
  public packagePlus: any
  constructor(private router: Router, private paymentService: PaymentService, private route: ActivatedRoute, private localStorage: StorageService, private commonService: CommonService, public modalService: NgbModal, private contentService : ContentService) {
    this.route.params.forEach((params: Params) => {
      this.packageid = params['id'];
    });

    this.route.queryParams.subscribe(params => {
      this.sourceUrl = params['redirect'];
      this.isMultiPackage = params['multipackage']
    })
  }

  public isBrainStormPackagePurchased: boolean = false

  ngOnInit() {
    this.calGetCareeSessionPreference()
    this.coupon_code = this.localStorage.get('couponCode');
    this.couponCode = this.coupon_code;
    this.subscribedPackages = subscribedPackages
    if (!this.isMultiPackage) {
      this.GetSubscriptionPackage(this.packageid);
    }
    else {
      this.GetSubscriptionPackages()
    }



  }

  calGetCareeSessionPreference() {
    this.contentService.getPreferenceForStudentId().subscribe(data => {
      let response: any = data
      this.isBrainStormPackagePurchased = response.packagePurchased

      console.log("Response==>", response)
    })
  }
  GetSubscriptionPackages() {
    let pack1Name, pack2Name
    debugger
    this.paymentService.GetSubscriptionPackages().subscribe(
      response => {
        this.packages = response;
        // this.pack = this.packages.filter(p => p.name.toLowerCase().includes(this.CounsellingPref.modeOfCounselling));
        this.pack = this.packages.filter(p => p.counsellingMode.toLowerCase() == "online")

        pack1Name = this.pack[0].name
        pack2Name = this.pack[1].name
        console.log("Saubscribed Package=>", this.subscribedPackages)
        this.subscribedPackages.forEach(element => {
          if (element.packName == pack1Name) {
            this.packageOnline = element
            console.log("online=>", this.packageOnline)
          }
          if (element.packName == pack2Name) {
            this.packagePlus = element
            console.log("plus=>", this.packagePlus)
          }
        });
        console.log("Pack=>", this.pack)
      },
      error => {

      });



  }
  PaymentStart(id) {
    this.loading = true;
    this.paymentModel.sourceUrl = this.sourceUrl;
    this.paymentModel.packageId = id;
    this.paymentModel.couponCode = this.couponCode;

    this.package = this.pack.find(c => c.uId == id)

    this.paymentService.EncryptData(this.paymentModel).subscribe(
      response => {

        this.loading = false;
        if (this.package.amount > 0) {
          this.router.navigate(['/payment/make-payment', response.encRequest]);
        } else {
          this.commonService.updateUserInfo();

          if (this.paymentModel.sourceUrl == undefined) //no source i.e not directed from
          {
            this.paymentService.GetStudentPath().subscribe(path => {
              console.log(path);
              this.router.navigate([path]);
            }, err => {
              alert('Some error occured, please login again.');
            });

          } else {
            var url = this.sourceUrl.replace("Audience : ", "");
            if (url.includes("Location")) {
              url = this.sourceUrl.replace("Location : ", "");
            }
            this.router.navigate([url], { queryParams: { payment: 'viaCoupon' } });
          }

        }
      },
      error => {

        this.loading = false;
      });
  }
  packNameNew: any
  packNewData: any
  GetSubscriptionPackage(id) {
    this.loading = true;

    this.paymentService.GetPackagesById(id).subscribe(
      response => {
        this.package = response;
        this.packNameNew = this.package.name;
        this.subscribedPackages.forEach(element => {
          if (element.packName == this.packNameNew) {
            this.packageOnline = element
          }
        });
        this.pack.push(this.package)
        console.log("pac---->", this.package);
        this.paymentModel.packageId = this.packageid;
        if (this.package.actionPlans === 'na') {
          this.ap = false;
        }
        if (this.package.whatsAppSupport === 'na') {
          this.ws = false;
        }
        if (this.package.amount === 0) {
          this.showReedeemBtn = false;
        }
        this.loading = false;
      },
      error => {

        this.loading = false;
      });
  }

  ApplyCoupon(uid, valCoupn, pack) {
    debugger
    this.couponCode = valCoupn
    this.package = pack
    if (this.couponCode !== '') {
      this.paymentService.ApplyCoupon(this.couponCode, uid).subscribe(
        response => {

          this.package.amount = response;
          this.paymentModel.couponCode = this.couponCode;
          swal({
            type: 'success',
            title: 'Coupon successfully applied! Your revised price is ' + this.package.amount.toString(),
            text: '',
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText:
              'CONFIRM BOOKING',
            // buttonsStyling: false,
            // confirmButtonClass: 'swal-btn',
            confirmButtonClass: 'submit-purple-swal-btn',
            buttonsStyling: true,
          }).then(res => {
            this.PaymentStart(uid);
          });
        },
        error => {

          this.paymentModel.couponCode = '';
          // alert(error.Message);
          swal({
            type: 'error',
            title: 'Oops...',
            text: 'Invalid coupon code',
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText:
              'OK',
            // buttonsStyling: false,
            // confirmButtonClass: 'swal-btn',
            confirmButtonClass: 'submit-purple-swal-btn',
            cancelButtonClass: 'cancel-danger-swal-btn',
            buttonsStyling: true,
          });
        });
    } else {
      // alert('Please enter code');
      swal({
        type: 'warning',
        title: 'Please enter a code',
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText:
          'OK',
        // buttonsStyling: false,
        // confirmButtonClass: 'swal-btn',
        confirmButtonClass: 'submit-purple-swal-btn',
        buttonsStyling: true,
      });
    }
  }

  /*****************************************************************
  ShareViaEmail()
  purpose : this method will share via email to particular user
  *******************************************************************/
  public ShareViaEmail() {
    swal({
      title: "Enter your parent's email address",
      showCancelButton: true,
      input: 'email',
      inputPlaceholder: "Enter your parent's email address",
      confirmButtonText: 'Share',
      confirmButtonClass: 'submit-purple-swal-btn',
      cancelButtonText: 'Cancel',
      cancelButtonClass: 'cancel-danger-swal-btn swal-action-btn',
      buttonsStyling: false
    }).then((result) => {
      if (result.value) {
        let data = {
          packageUId: this.packageid,
          studentUId: 'mentor',
          email: result.value
        }
        this.paymentService.sharePaymentLink(data).subscribe(response => {

          swal("We've shared the payment link! Please login once again after completing the payment.");
        });
      }
      (<any>window).gtag('event', 'payment',
        {
          'send_to': 'AW-849928441/5TxpCNm8pagBEPnBo5UD',
          'event_category': 'payment',
          'event_label': 'share payment link via email',
          'event_action': 'clcik to get payment link via email'
        });
    });
  }


  openLazyPayPopup(lazyPayPopup) {
    this.modelRef = this.modalService.open(lazyPayPopup, { size: 'lg', backdrop: 'static' });
  }

  closeDialog() {
    this.modelRef.close();
  }

  saveDetailsForLazyPay() {
    if (this.validateLazyPayDetails()) {
      alert('Please fill out all the details')

    } else {
      this.lazyPayUser.dob = this.dobChanged()
      let obj = {
        "packageId": this.paymentModel.packageId,
        "couponCode": this.couponCode,
        "userDetails": this.lazyPayUser
      }
      this.lazyPayLoading = true
      this.paymentService.isEligibleForLazyPay(obj).subscribe(response => {
        let res: any = response
        if (res.IsEligible) {

          this.paymentService.initiateLazyPay(obj).subscribe(response => {

            let res: any = response
            console.log(res)
            window.location.href = this.commonService.getLazyPayUrl() + res['Token']
            this.lazyPayLoading = false
          }, error => {
            this.lazyPayLoading = false
            alert('You are not elegible for pay later, please complete your payment using invest now button.')
          })
        } else {
          this.lazyPayLoading = false
          alert('You are not elegible for pay later, please complete your payment using invest now button.')
        }
      }, error => {
        this.lazyPayLoading = false
        alert('Your credit score is not available now, please complete your payment using invest now button.')
      })
    }

  }

  dobChanged() {
    var m = new Date(this.DateOfBirth);
    var dateString = m.getFullYear() + "-" + (m.getMonth() + 1) + "-" + (m.getDate());
    return dateString
  }


  validateLazyPayDetails() {
    if (this.lazyPayUser.firstName === null || this.lazyPayUser.firstName === undefined || this.lazyPayUser.firstName === '' || this.lazyPayUser.firstName === "") {
      return true
    } else if (this.lazyPayUser.lastName === null || this.lazyPayUser.lastName === undefined || this.lazyPayUser.lastName === '' || this.lazyPayUser.lastName === "") {
      return true
    }
    else if (this.lazyPayUser.email === null || this.lazyPayUser.email === undefined || this.lazyPayUser.email === '' || this.lazyPayUser.email === "") {
      return true
    }
    else if (this.lazyPayUser.mobile === null || this.lazyPayUser.mobile === undefined || this.lazyPayUser.mobile === '' || this.lazyPayUser.mobile === "") {
      return true
    }
    else if (this.DateOfBirth === null || this.DateOfBirth === undefined || this.DateOfBirth === '' || this.DateOfBirth === "") {
      return true
    }
    else if (this.lazyPayUser.pan === null || this.lazyPayUser.pan === undefined || this.lazyPayUser.pan === '' || this.lazyPayUser.pan === "") {
      return true
    }

    else if (this.lazyPayUser.gender === null || this.lazyPayUser.gender === undefined || this.lazyPayUser.gender === '' || this.lazyPayUser.gender === "") {
      return true
    }

    else if (this.lazyPayUser.maritalStatus === null || this.lazyPayUser.maritalStatus === undefined || this.lazyPayUser.maritalStatus === '' || this.lazyPayUser.maritalStatus === "") {
      return true
    }
    else if (this.lazyPayUser.employmentType === null || this.lazyPayUser.employmentType === undefined || this.lazyPayUser.employmentType === '' || this.lazyPayUser.employmentType === "") {
      return true
    }
    else if (this.lazyPayUser.fatherName === null || this.lazyPayUser.fatherName === undefined || this.lazyPayUser.fatherName === '' || this.lazyPayUser.fatherName === "") {
      return true
    }
    else if (!this.emailPatt.test(this.lazyPayUser.email)) {

      this.errorMessagesForEmail = 'Please enter valid email address.'

      return true

    } else if (this.lazyPayUser.address.line1 === null || this.lazyPayUser.address.line1 === undefined || this.lazyPayUser.address.line1 === '' || this.lazyPayUser.address.line1 === "") {
      return true
    }
    else if (this.lazyPayUser.address.city === null || this.lazyPayUser.address.city === undefined || this.lazyPayUser.address.city === '' || this.lazyPayUser.address.city === "") {
      return true
    }
    else if (this.lazyPayUser.address.country === null || this.lazyPayUser.address.country === undefined || this.lazyPayUser.address.country === '' || this.lazyPayUser.address.country === "") {
      return true
    }
    else if (this.lazyPayUser.address.state === null || this.lazyPayUser.address.state === undefined || this.lazyPayUser.address.state === '' || this.lazyPayUser.address.state === "") {
      return true
    }
    else if (this.lazyPayUser.address.pincode === null || this.lazyPayUser.address.pincode === undefined || this.lazyPayUser.address.pincode === '' || this.lazyPayUser.address.pincode === "") {
      return true
    }
    else {
      return false
    }
  }

  investNow(id) {
    this.PaymentStart(id);
    (<any>window).gtag('event', 'payment',
      {
        'send_to': 'AW-849928441/5TxpCNm8pagBEPnBo5UD',
        'event_category': 'payment',
        'event_action': 'click for payment',
        'event_label': 'Invest now clicked',
      });
  }

}




