import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverviewComponent } from './overview/overview.component';
import { RolesResponsibilitiesComponent } from './roles-responsibilities/roles-responsibilities.component';
import { SkillsComponent } from './skills/skills.component';
import { GrowthComponent } from './growth/growth.component';
import { PayscaleComponent } from './payscale/payscale.component';
import { PathComponent } from './path/path.component';
import { CareerOverviewService } from './career-overview.service';
import { CareerLoadingComponent } from './career-loading/career-loading.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { WorkEnvironmentComponent } from './work-environment/work-environment.component';
import { RouterModule } from '@angular/router';
import { CareerOverviewRoutingModule } from './career-overview.routing.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MainPageComponent } from './main-page/main-page.component';
import { CareerPageComponent } from './career-page/career-page.component';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    RouterModule,
    FlexLayoutModule,
    CareerOverviewRoutingModule
  ],
    providers: [CareerOverviewService],
  declarations: [OverviewComponent, RolesResponsibilitiesComponent, SkillsComponent, GrowthComponent, PayscaleComponent, PathComponent, CareerLoadingComponent, WorkEnvironmentComponent, MainPageComponent, CareerPageComponent]

})
export class CareerOverviewModule { }
