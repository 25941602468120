export class Package {

  'id': string;
  'dType': string;
  'uId': string;
  'name': string;
  'description': string;
  'amount': number;
  'testduration': string;
  'currentClass': string;
  'counsellingduration': string;
  'careerOutcomes': string;
  'careerAssessments': string;
   'careerUpdates':  string;
   'focusedCareerGuides': string;
   'educationalGuidence':  string;
   'webinars':  string;
   'actionPlans': string;
   'whatsAppSupport':  string;
  'tags': string;
  'isLocked': true;
  'locks': [
    {
      'type': string;
      'byUserId': string
    }
  ];
  'createdBy': string;
  'updatedBy': string;
  'createdOn': number;
  'updatedOn': number;
  'archieved': boolean;
  'active': boolean;
  'version': number;
  constructor() {
    this.id = '';
    this.name = '';
     this.actionPlans = '';
   this.whatsAppSupport = '';
   this.amount = 0;
  }
}
