import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MatExpansionModule , MatIconModule, MatStepperModule, MAT_DIALOG_DATA} from '@angular/material';

import { SharedModule } from './../shared/shared.module';
import { SwiperConfigInterface, SwiperModule, SWIPER_CONFIG } from 'ngx-swiper-wrapper';

import { LocationpageRoutingModule } from './locationpage-routing.module';

import { CountdownModule } from 'ngx-countdown';
import { DialogOverviewExample2Dialog, LocationPageComponent } from './location-page/location-page.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  observer: true,
  direction: 'horizontal',
  threshold: 50,
  spaceBetween: 5,
  slidesPerView: 1,
  centeredSlides: true
};

@NgModule({
  imports: [
    CommonModule,
    LocationpageRoutingModule,
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    SharedModule,
    SwiperModule,
    MatExpansionModule,
    MatIconModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    CountdownModule,
    SharedModule,
    NgbModule,
    InfiniteScrollModule
  ],
  exports: [LocationPageComponent],
  declarations: [LocationPageComponent, DialogOverviewExample2Dialog],
  entryComponents: [DialogOverviewExample2Dialog],
  providers: [{
    provide: SWIPER_CONFIG,
    useValue: DEFAULT_SWIPER_CONFIG
  },
  { provide: MatDialogRef, useValue: {} },
  { provide: MAT_DIALOG_DATA  , useValue: [] },]
})
export class LocationpageModule { }
