
export class Payment {
  'id': string;
  'dType': string;
  'uId': string;
  'orderId': string;
  'status': string;  // TransactionInitiated / transactionCompleted
  'transactionId': string;
  'amount': Number;
  'currency': string;
  'paymentInitiatedDate': Date;
  'studentUid': string;
  'packageUid': string;
  'packageName': string;
  'billingName': string;
  'billingAddress': string;
  'billingCity': string;
  'billingState': string;
  'billingCountry': string;
  'billingZip': string;
  'billingTel': string;
  'billingEmail': string;
  'orderStatus':string;
  'failureMessage':string;
  'couponCode':string;   
  'bankRefNo':string;
  'trackingId':string;
}

