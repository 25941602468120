import { Injectable, Inject, PLATFORM_ID } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CommonService } from "./common/common.service";
// import { SwUpdate, SwPush } from "@angular/service-worker";
import swal from "sweetalert2";
import { PushNotification } from "./shared/notification.model";
import { isPlatformBrowser } from "@angular/common";
import { StorageService } from "./common/storage-service";

@Injectable({
  providedIn: "root"
})
export class AppService {
  public pushNotificationStatus = {
    isSubscribed: false,
    isSupported: false
  };
  public apiUrl = "";

  public sub = {
    endpoint: "",
    p256DH: "",
    auth: ""
  };
  public platformID = "";

  public vapIdPublicKey =
    "BFCUGMPM71FSnGwYsw-Z52stECMFhG-zQNXVi8hkrQ3BoBRp9HF2bOYumLkURTkRPq6-m_CHc00V5UkkTXV58O4";

  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private http: HttpClient,
    private commonService: CommonService,
    // private swUpdate: SwUpdate,
    // private swPush: SwPush,
    private storageService: StorageService
  ) {
    this.apiUrl = this.commonService.getApiUrl();
    this.platformID = platformId;
  }

  /*****************************************************************
  checkForNewVersion()
  purpose : this method will check for updated service worker and prompt user to update the page
  *******************************************************************/
  // public checkForNewVersion() {
  //   this.swUpdate.available.subscribe(event => {
  //     // swal({
  //     //   position: "center",
  //     //   type: "info",
  //     //   html:
  //     //     "A newer version of Mentoria is available. Please refresh the page to update.",
  //     //   confirmButtonClass: "submit-purple-swal-btn",
  //     //   cancelButtonClass: "cancel-danger-swal-btn", //only if two buttons
  //     //   buttonsStyling: true
  //     // }).then(() => {
  //     //   window.location.reload();
  //     // });
  //     window.location.reload();
  //   });
  //   this.swUpdate.checkForUpdate();
  // }

  /*****************************************************************
  subscribeToNotification()
  purpose : this method will take user's conscent before displaying push notifications
  *******************************************************************/
  // public subscribeToNotification(val) {
  //   console.log(val);
  //   console.log("subscribing to notifications");
  //   if (this.swPush.isEnabled) {
  //     this.pushNotificationStatus.isSupported = true;
  //     this.swPush
  //       .requestSubscription({
  //         serverPublicKey: this.vapIdPublicKey
  //       })
  //       .then(response => {
  //         
  //         const jsonresponse = response.toJSON();
  //         console.log(jsonresponse);
  //         this.pushNotificationStatus.isSubscribed = true;
  //         this.sub.endpoint = response.endpoint;
  //         this.sub.p256DH = jsonresponse.keys["p256dh"];
  //         this.sub.auth = jsonresponse.keys["auth"];
  //         this.addPushSubscriber().subscribe(
  //           res => {
  //             console.log(res);
  //           },
  //           error => {
  //             
  //           }
  //         );
  //       })
  //       .catch(err =>
  //         console.error("Could not subscribe to notifications", err)
  //       );
  //   } else {
  //     console.log("Service worker is not supported");
  //   }
  // }

  // call api to save push notification subscription.
  addPushSubscriber() {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.storageService.get("access_token")
      })
    };
    const body = JSON.stringify(this.sub);
    return this.http.post(
      this.apiUrl + "/api/notification/subscribe",
      body,
      options
    );
  }

  /*****************************************************************
      handleNotificationClick()
      purpose : Method to trigger custom events after clicking on notification (not being used now)
      *******************************************************************/
  public handleNotificationClick() {
    // this.swPush.notificationClicks.subscribe(response => {
    //   
    // });
  }

  /*****************************************************************
  subscribeToMessages()
  purpose : this method display call my custom middleware to show notifications. 
  As angular service worker has inbuilt functionality to show push notification, this is not required now.
  *******************************************************************/
  // public subscribeToMessages() {
  //   this.swPush.messages.subscribe(response => {
  //     
  //     this.showNotification(response["title"], response["message"]);
  //   });
  // }

  showNotification(title, body) {
    this.generateNotification(title, body);
  }

  generateNotification(title, body): void {
    let options = {
      body: body
    };
    let response = this.notify(title, options).subscribe();
  }

  notify(title: string, options?: PushNotification): any {
    if (isPlatformBrowser(this.platformID)) {
      let self = this;
      return new Observable(function(obs) {
        if (!("Notification" in window)) {
          console.log("Notifications are not available in this environment");
          obs.complete();
        }
        if (!self.pushNotificationStatus.isSubscribed) {
          console.log(
            "The user hasn't granted you permission to send push notifications"
          );
          obs.complete();
        }
        let _notify = new Notification(title, options);
        _notify.onshow = function(e) {
          return obs.next({
            notification: _notify,
            event: e
          });
        };
        _notify.onclick = function(e) {
          return obs.next({
            notification: _notify,
            event: e
          });
        };
        _notify.onerror = function(e) {
          return obs.error({
            notification: _notify,
            event: e
          });
        };
        _notify.onclose = function() {
          return obs.complete();
        };
      });
    }
  }

  /*****************************************************************
  trackUrlChanged()
  purpose : Method to update page view duration in user activity log
  *******************************************************************/
  trackUrlChanged(data) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.storageService.get("access_token")
      })
    };
    const body = JSON.stringify(data);
    return this.http.post(
      this.apiUrl + "/api/notification/trackUrlChanged",
      body,
      options
    );
  }

  /*****************************************************************
saveLoginTime()
purpose : this method will log login activity to database.
*******************************************************************/
  public saveLoginTime() {
    this.saveLoginActivity().subscribe(
      res => {
        console.log(res);
      },
      error => {
        
      }
    );
  }

  // call api to save push notification subscription.
  saveLoginActivity() {
    const options = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.storageService.get("access_token")
      })
    };
    return this.http.get(
      this.apiUrl + "/api/accounts/saveLoginActivity",
      options
    );
  }
}
