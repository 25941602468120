import { isPlatformBrowser } from "@angular/common";
import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { ComponentType } from "@angular/core/src/render3";
import { MatDialog } from "@angular/material";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import swal from "sweetalert2";
import { AppService } from "../../app.service";
import { CommonService } from "../../common/common.service";
import { RouteSlideStateTrigger } from "../../common/route-animations";
import { StorageService } from "../../common/storage-service";
import { AccountsService } from "../accounts.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css", "../accounts.module.css", '../new-login/new-login.component.css'],
  animations: [RouteSlideStateTrigger]
})
export class LoginComponent implements OnInit {

  public loading: boolean;
  public vloading: boolean = false
  public optloading: boolean = false
  public resendloading: boolean = false
  public showErrorMessage: boolean;
  public studentStatus;
  public studentCareerPathArray;
  public existinUserPhone: any
  private platformId = "";
  accounttype: string;
  studentLoggedIn = false;
  showEnterOTP: any = false;
  disableResendOTp: any = true;
  showTimer: boolean;
  public showPasswordInput = false
  userExist = false
  phoneNumber = '';
  resentOTPOnMobile: any = false
  resendOTPonEmail: any = false
  public otponemail = false
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private accountService: AccountsService,
    private commonService: CommonService,
    private router: Router,
    private localStorageService: StorageService,
    private titleService: Title,
    private metaService: Meta,
    private appService: AppService,
    public dialog: MatDialog
  ) {
    this.platformId = platformId;
    this.titleService.setTitle("Login - Mentoria");

    let attributeSelector: string = 'name="description"';
    this.metaService.removeTag(attributeSelector);

    this.metaService.updateTag(
      {
        name: "description",
        content:
          "Login to Mentoria's Online Career Counselling Platform to Know More About the Career Choices & Excel your Career Path in the Field you are Interested in"
      });
  }

  ngOnInit() {
    this.loading = false;
    this.showErrorMessage = false;
    let token = this.localStorageService.get("access_token");

    if (token !== "" && token !== null) {
      this.studentLoggedIn = true;
      this.checkLoggedIn();
    }
  }

  public login(email, password) {
    this.loading = true;
    this.accountService.login(email, password).subscribe(
      response => {
        this.loading = false;

        this.localStorageService.set("access_token", response["access_token"]);
        this.commonService.login();
        // this.appService.subscribeToNotification('login component');
        this.accountService.getAccountType().subscribe(
          accountType => {
            this.localStorageService.set("account_type", accountType);
            console.log(accountType);
            if (accountType === "student") {
              //get student object and save in common service
              // this.commonService.getStudent();
              this.accountService.getStudentDetails().subscribe(
                studentVar => {
                  console.log(studentVar);
                  this.studentCareerPathArray =
                    studentVar["selectedCareerPaths"];

                  if (!this.studentCareerPathArray) {
                    // this.commonService.careerArr = false;
                    this.localStorageService.set(
                      "career_arr",
                      this.studentCareerPathArray.length
                    );
                  } else {
                    this.localStorageService.set(
                      "career_arr",
                      this.studentCareerPathArray.length
                    );
                  }
                },
                err => {
                  alert("Some error occured.");
                }
              );

              this.accountService.GetStudentPath().subscribe(
                path => {
                  console.log(path);
                  this.router.navigate([path]);
                },
                err => {
                  alert("Some error occured, please login again.");
                }
              );
            } else if (accountType === "counsellor") {
              this.accountService.GetCounsellorPath().subscribe(
                path => {
                  console.log(path);
                  this.router.navigate([path]);
                },
                err => {
                  alert("Some error occured, please login again.");
                }
              );
            } else {
              alert("Invalid User");
            }
          },
          err => {
            console.log(err);
          }
        );
      },
      error => {
        this.loading = false;
        this.showErrorMessage = true;

      }
    );
  }

  public signInWithGoogle() {
    this.commonService.login();
    const clientId = encodeURI("Mentoria.elp");
    const scope = encodeURI("Mentoriaapi");
    const redirectUri = encodeURI(
      this.commonService.getAppUrl() + "/accounts/post-login"
    );
    const responseType = encodeURI("token");
    const acrValues = encodeURI("idp:Google");
    const action = encodeURI("login");
    const url =
      this.commonService.getStsUrl() +
      "/core/connect/authorize?client_id=" +
      clientId +
      "&scope=" +
      scope +
      "&redirect_uri=" +
      redirectUri +
      "&response_type=" +
      responseType +
      "&acr_values=" +
      acrValues +
      "&action=" +
      action;
    if (isPlatformBrowser(this.platformId)) {
      window.location.href = url;
    }
  }

  signInWithFacebook() {
    this.commonService.login();
    const clientId = encodeURI("Mentoria.elp");
    const scope = encodeURI("Mentoriaapi");
    const redirectUri = encodeURI(
      this.commonService.getAppUrl() + "/accounts/post-login"
    );
    const responseType = encodeURI("token");
    const acrValues = encodeURI("idp:Facebook");
    const action = encodeURI("login");
    const url =
      this.commonService.getStsUrl() +
      "/core/connect/authorize?client_id=" +
      clientId +
      "&scope=" +
      scope +
      "&redirect_uri=" +
      redirectUri +
      "&response_type=" +
      responseType +
      "&acr_values=" +
      acrValues +
      "&action=" +
      action;
    if (isPlatformBrowser(this.platformId)) {
      window.location.href = url;
    }
  }

  //to check if user is already logged in
  public checkLoggedIn() {
    this.accounttype = this.localStorageService.get("account_type");
    if (this.accounttype === "student") {
      this.accountService.GetStudentPath().subscribe(
        path => {
          console.log(path);
          this.router.navigate([path]);
        },
        err => {
          alert("Some error occured, please login again.");
        }
      );
    } else {
      this.accountService.GetCounsellorPath().subscribe(
        path => {
          console.log(path);
          this.router.navigate([path]);
        },
        err => {
          alert("Some error occured, please login again.");
        }
      );
    }
  }

  /**NEW LOGIN FUNCTIONALITY STARTS */
  loginVIAotpOrPass(phone, id) {
    let obj: any = {}
    this.optloading = true
    console.log("Phone=>", phone)
    if (id == 1) {
      obj = {
        mobile: phone,
        countryCode: "+91",
      }
      this.resendOTPonEmail = false
      this.resentOTPOnMobile = true
    }
    if (id == 2) {
      obj = {
        mobile: phone,
        countryCode: "",
      }
      this.resentOTPOnMobile = false
      this.resendOTPonEmail = true
    }
    this.accountService.LoginViaMobileForOtp(obj).subscribe(data => {
      this.resendloading = false;
      this.optloading = false
      let res: any = data
      if (res.message == 'success') {
        if (id == 1) {
          swal({
            type: "success",
            title: "Sucess",
            text: "OTP Sent successfully on mobile",
            timer: 1500
          })
          if (id == 2) {
            swal({
              type: "success",
              title: "Sucess",
              text: "OTP sent successfully on email",
              timer: 1500
            })
          }
        }
        this.showEnterOTP = true;
      } else {
        swal({
          type: "error",
          title: "Uh oh!",
          text: res.message
        })
      }

    }, error => {
      swal({
        type: "error",
        title: "Uh oh!",
        text: "Something Went Wrong please try after sometime!"
      })
    })
    setTimeout(() => {
      this.disableResendOTp = false
      this.showTimer = false



    }, 30000)



    this.showTimer = true
  }
  verifyOTP(phone, otp, form, modal) {
    debugger
    this.vloading = true;
    console.log("Phone=>", phone)
    this.phoneNumber = phone;
    console.log("otp=>", otp)
    console.log("validity=>", form._directives[1].control.status)
    if (!otp) {
      swal({
        text: "please enter valid OTP"
      })
      this.vloading = false;
    }
    else {
      let obj = {
        mobile: phone,
        VerificationCode: otp,

      }
      this.accountService.VerifyOTPforLogin(obj).subscribe(data => {
        let res: any = data
        this.vloading = false;
        this.localStorageService.set("mobile", phone);
        if (res.message == "success") {
          if (res.message !== 'failed') {

            let token: any = res.reponse.access_token
            console.log("Token=>", res.message)
            if (res.message == 'success') {
              this.setTokenAfterLogin(token)
            }
            else {
              swal({
                type: "error",
                title: "Oop's!",
                text:
                  "Error Try Again After Sometime!"
              });
            }
          }
          else {

            swal({
              type: "error",
              title: "Oop's!",
              text:
                "Incorrect OTP !"
            });
          }
          // this.accountService.verifyOTPViaMobileOrEmailNew(obj).subscribe(data => {
          //   let response: any = data;

          //   if (response.message !== 'failed') {
          //     swal({
          //       type: "success",
          //       title: "Success!",
          //       text: "OTP Validated Successfully !"
          //     });
          //     let res: any = data
          //     console.log("data=>", res)
          //     let token: any = res.reponse.access_token
          //     console.log("Token=>", res.message)
          //     if (res.message == 'success') {
          //       debugger
          //       this.setTokenAfterLogin(token)
          //     }
          //     else {
          //       swal({
          //         type: "error",
          //         title: "Oop's!",
          //         text:
          //           "Error Try Again After Sometime!"
          //       });
          //     }
          //   }
          //   else {

          //     swal({
          //       type: "error",
          //       title: "Oop's!",
          //       text:
          //         "Incorrect OTP !"
          //     });
          //   }

          // }, error => {

          //   swal({

          //     type: "error",
          //     title: "Oop's!",
          //     text:
          //       "Something Went Wrong Please Try Again."
          //   });
          // })
        }
        else if (res.message == "failed" && res.response == "Password required!") {
          this.userExist = true;
          this.openPopup(modal, phone)
        } else {
          console.log(res.response)
          swal({
            type: "error",
            title: "OOPS",
            text: "Something Went Wrong please try After Sometime!"
          })
        }
      }, error => {
        swal({
          type: "error",
          title: "OOPS",
          text: "Something Went Wrong please try After Sometime!"
        })
      })
    }
  }


  setTokenAfterLogin(token) {
    this.localStorageService.set("access_token", token);
    this.commonService.login();
    // this.appService.subscribeToNotification('login component');
    this.accountService.getAccountType().subscribe(
      accountType => {
        this.localStorageService.set("account_type", accountType);
        console.log(accountType);
        if (accountType === "student") {
          //get student object and save in common service
          // this.commonService.getStudent();
          this.accountService.getStudentDetails().subscribe(
            studentVar => {
              console.log(studentVar);
              this.studentCareerPathArray =
                studentVar["selectedCareerPaths"];

              if (!this.studentCareerPathArray) {
                // this.commonService.careerArr = false;
                this.localStorageService.set(
                  "career_arr",
                  this.studentCareerPathArray.length
                );
              } else {
                this.localStorageService.set(
                  "career_arr",
                  this.studentCareerPathArray.length
                );
              }
            },
            err => {
              alert("Some error occured.");
            }
          );

          this.accountService.GetStudentPath().subscribe(
            path => {
              console.log(path);
              this.router.navigate([path]);
            },
            err => {
              alert("Some error occured, please login again.");
            }
          );
        } else if (accountType === "counsellor") {
          this.accountService.GetCounsellorPath().subscribe(
            path => {
              console.log(path);
              this.router.navigate([path]);
            },
            err => {
              alert("Some error occured, please login again.");
            }
          );
        } else {
          alert("Invalid User");
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  resendEmail(phone) {
    this.resendloading = true;
    this.disableResendOTp = true

    setTimeout(() => {
      this.disableResendOTp = false
      this.showTimer = false

    }, 30000)

    this.showTimer = true
    this.loginVIAotpOrPass(phone, 2);
  }
  resendPhone(phone) {
    this.resendloading = true;
    this.disableResendOTp = true

    setTimeout(() => {
      this.disableResendOTp = false
      this.showTimer = false

    }, 30000)

    this.showTimer = true
    this.loginVIAotpOrPass(phone, 1);
  }
  openPopup(modal, phone) {
    this.existinUserPhone = phone
    this.dialog.open(modal, {
      panelClass: 'login-popup-class',
      disableClose: false
    })
  }
  logintest(form) {
    console.log("pass=>", form)
  }
  newlogin(pass) {
    let obj = {
      mobile: this.existinUserPhone,
      Password: pass
    }
    this.accountService.SavePasswordForOldUsers(obj).subscribe(data => {
      // swal({
      //   type:"success",
      //   title:"success",
      //   text:"login SucessFul"
      // })
      let res: any = data

      if (res.message !== 'failed') {
        swal({
          type: "success",
          title: "Success!",
          text: "Password Validated Successfully!",
          timer: 1500
        });
        console.log("data=>", res)
        let token: any = res.reponse.access_token
        console.log("Token=>", res.message)
        this.setTokenAfterLogin(token)
        this.dialog.closeAll();
      }
      else {

        swal({
          type: "error",
          title: "Oops!",
          text:
            "Try another password !"
        });
      }

    }, error => {

      swal({

        type: "error",
        title: "Oop's!",
        text:
          "Something Went Wrong Please Try Again."
      });
    })
  }

  verifyOTPEmail(email, otp, user) {
    let obj = {
      "emailId": email,
      "countryCode": "",
      "VerificationCode": otp,
    }
    this.accountService.VerifyOTPforLogin(obj).subscribe(data => {
      let res: any = data
      this.vloading = false;
      if (res.message == "success") {
        swal({
          type: "success",
          title: "Success!",
          text: "OTP verified Successfully!",
          timer: 1500
        })
        if (res.message !== 'failed') {

          let token: any = res.reponse.access_token
          if (res.message == 'success') {
            this.setTokenAfterLogin(token)
          }
          else {
            swal({
              type: "error",
              title: "Oop's!",
              text:
                "Error Try Again After Sometime!"
            });
          }
        }
        else {

          swal({
            type: "error",
            title: "Uh oh!",
            text:
              "Incorrect OTP!"
          });
        }

      }
      else {
        swal({
          type: "error",
          title: "OOPS",
          text: "Something Went Wrong please try After Sometime!"
        })
      }
    }, error => {
      swal({
        type: "error",
        title: "OOPS",
        text: "Something Went Wrong please try After Sometime!"
      })
    })
  }
}
