import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-work-environment',
  templateUrl: './work-environment.component.html',
  styleUrls: ['./work-environment.component.css','../newcareer/newcareer.component.css','../career-overview-rewamp.module.css']
})
export class WorkEnvironmentComponent implements OnInit {
  @Input() path
  constructor() { }

  ngOnInit() {
  }

}
