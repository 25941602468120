export const marqueeData = [
    {
        image:"./../../../assets/images/corp logos/accenture.svg",
    },
    {
        image:"./../../../assets/images/corp logos/adityabirlacapital.svg",  
    },
    {
        image:"./../../../assets/images/corp logos/adventz.svg",
    },
    {
        image:"./../../../assets/images/corp logos/akdn.svg",
    },
    {
        image:"./../../../assets/images/corp logos/alkem.svg",
    },
    {
        image:"./../../../assets/images/corp logos/axisbank.svg",
    },
    {
        image:"./../../../assets/images/corp logos/britannia.svg",
    },
    {
        image:"./../../../assets/images/corp logos/cumi.svg"
    },
    {
        image:"./../../../assets/images/corp logos/daimler.svg"
    },
    {
        image:"./../../../assets/images/corp logos/glenmark.svg"
    },
    {
        image:"./../../../assets/images/corp logos/hindustanpetroleum.svg"
    },
    {
        image:"./../../../assets/images/corp logos/itc.svg"
    },
    {
        image:"./../../../assets/images/corp logos/jewelex.svg"
    },
    {
        image:"./../../../assets/images/corp logos/jll.svg"
    },
    {
        image:"./../../../assets/images/corp logos/kotakmahindrabank.svg"
    },
    {
        image:"./../../../assets/images/corp logos/landt.svg"
    } ,
    {
        image:"./../../../assets/images/corp logos/mahindrrise.svg"
    },
    {
        image:"./../../../assets/images/corp logos/optum.svg"
    },
]