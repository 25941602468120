import { Component, OnInit, Inject, PLATFORM_ID, ViewChild, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import {
  SwiperComponent, SwiperDirective, SwiperConfigInterface,
  SwiperScrollbarInterface, SwiperPaginationInterface, SwiperAutoplayInterface
} from 'ngx-swiper-wrapper';
import { HomeService } from '../home.service';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from '../../common/storage-service';
import swal from 'sweetalert2';
import { globalValues } from '../../common/global-constants';
import { CommonService } from '../../common/common.service';
import { Meta, Title } from "@angular/platform-browser";
import { ScriptService } from '../../common/script.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MyJsonLdComponent } from '../../common/jsonFormat.component';
import { jsonDataHomePage, jsonDataHomePage1, jsonDataHomePage2 } from './homepage.json';
declare var $: any;
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss', '../../shared/shared.module.scss']
})
export class HomepageComponent implements OnInit {

  @ViewChild(SwiperComponent) componentRef: SwiperComponent;
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
  public toggleVar = 0;
  public _opened: boolean = false;
  platformId: string;
  screen: string;
  public mobileNumber;
  public mobileNumber1


  public showPage1 = true;   // page 1 contains banner image
  public showPage2 = false;  // page 2 contains youtube video
  public showPage3 = false;  // page 3 contains testimonials
  public showPage4 = false;  // page 4 contains career mistakes
  public showPage5 = false;  // page 5 contains blogs
  public showPage6 = false;  // page 6 contains footer
  public loadOtherItems = false; // defer load images and other videos
  jsonParseData = jsonDataHomePage
  jsonParseData1 = jsonDataHomePage1
  jsonParseData2 = jsonDataHomePage2
  private autoplay: SwiperAutoplayInterface = {
    delay: 900000,
    disableOnInteraction: false
  };
  private scrollbar: SwiperScrollbarInterface = {
    el: '.swiper-scrollbar',
    hide: false,
    draggable: true
  };
  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false
  };

  public configC: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 3,
    slidesPerGroup: 3,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: true,
    loop: true,
    autoplay: this.autoplay
  };
  public Studentconfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: this.pagination,
    loop: true,
    autoplay: this.autoplay
  };
  public Parentconfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: this.pagination,
    loop: true,
    autoplay: this.autoplay
  };
  public Educatorconfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: this.pagination,
    loop: true,
    autoplay: this.autoplay
  };
  public Corporateconfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: this.pagination,
    loop: true,
    autoplay: this.autoplay
  };
  public counselorProfileConfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: this.pagination,
    loop: true,
    autoplay: this.autoplay
  };
  public Counsellorconfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: this.pagination,
    loop: true,
    autoplay: this.autoplay
  };
  public configAptitude: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: this.pagination,
    loop: true,
    autoplay: this.autoplay
  };

  public blog1: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 2,
    slidesPerGroup: 2,
    centeredSlides: false,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: true,
    pagination: this.pagination,
    loop: true,
    autoplay: this.autoplay,
    spaceBetween: 20,
  };

  public blog2: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    slidesPerGroup: 1,
    centeredSlides: false,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: false,
    pagination: this.pagination,
    loop: true,
    autoplay: this.autoplay,
  };


  HomepageBlogs = [];
  HomepageBlogsNew = [];
  public authToken;


  public HomepageBlogsFetched = false;
  public HomepageBlogsNewFetched = false;
  public devWidth;
  mob: any;
  public _toggleOpened(): void {
    this._opened = !this._opened;
  };
  public isBrainStormPackagePurchased: boolean = false
  public globalValues;
  public isBrowser: boolean;

  constructor(private router: Router, @Inject(PLATFORM_ID) platformId: string, private modalService: NgbModal, public dialog: MatDialog, private homeService: HomeService, private localStorageService: StorageService, private commonService: CommonService, private titleService: Title, private metaService: Meta, private scriptService: ScriptService, private http: HttpClient, private jsonService: MyJsonLdComponent, private renderer: Renderer2, public route: ActivatedRoute
  ) {
    this.platformId = platformId;
    console.log(this.route.paramMap)
    this.isBrowser = isPlatformBrowser(platformId);

    this.titleService.setTitle('Mentoria: Career Counselling & Career Guidance Online');

    let attributeSelector: string = 'name="description"';
    this.metaService.removeTag(attributeSelector);

    this.metaService.updateTag({
      name: 'description',
      content: "Meet India's #1 Career Counselling & Career Guidance Counsellor. Get counselling from Mentoria's expert Career Consultant & Advisor to Discover your Ideal Career."
    });
  }


  ngOnInit() {



    this.globalValues = globalValues;
    this.initmethod();
    if (isPlatformBrowser(this.platformId)) {
      this.localStorageService.set('home', true);
    }
    this.authToken = this.localStorageService.get('access_token');
    // this.getHomepageBlogs();
    this.getHomepageBlogsNew();

    // this.jsonService.getSafeHTML(this.jsonParseData); 
    let script = this.renderer.createElement('script'); script.type = 'application/ld+json'; script.text = `${JSON.stringify(this.jsonParseData)}`; this.renderer.appendChild(document.body, script);
    let script1 = this.renderer.createElement('script'); script1.type = 'application/ld+json'; script1.text = `${JSON.stringify(this.jsonParseData1)}`; this.renderer.appendChild(document.body, script1);
    let script2 = this.renderer.createElement('script'); script1.type = 'application/ld+json'; script1.text = `${JSON.stringify(this.jsonParseData2)}`; this.renderer.appendChild(document.body, script2);

    let url = this.commonService.getpreloginAppURL()
    let newPath = url + '/redirect?authToken=' + this.authToken + '&redirectURl=' + encodeURI(url);
    window.open(newPath, "_self");

  }




  initmethod() {
    if (isPlatformBrowser(this.platformId)) {
      let ua = window.navigator.userAgent;
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
        this.screen = 'mobile';
        // this.configC.slidesPerView = 1;
        this.configC.navigation = false;
        this.configAptitude.navigation = false;
        this.Parentconfig.navigation = false;
        this.Educatorconfig.navigation = false;
        this.Studentconfig.navigation = false;
        this.Corporateconfig.navigation = false;
        this.Counsellorconfig.navigation = false;
      } else {
        this.screen = 'web';
      }
    }
  }

  scroll(el) {
    el.scrollIntoView();
  }

  tabChangedEvent(e) {
    console.log(e);
    let swiperConfig = {
      direction: 'horizontal',
      slidesPerView: 1,
      keyboard: true,
      mousewheel: false,
      scrollbar: false,
      navigation: true,
      pagination: this.pagination,
      loop: true,
      autoplay: true
    };
    if (e.index === 0) {
      this.Studentconfig = swiperConfig
    } else if (e.index === 1) {
      this.Parentconfig = swiperConfig
    } else if (e.index === 2) {
      this.Educatorconfig = swiperConfig
    } else if (e.index === 3) {
      this.Corporateconfig = swiperConfig
    } else if (e.index === 4) {
      this.Counsellorconfig = swiperConfig
    }
    this.initmethod()
  }

  openVideoModal(getVideoModal) {
    this.modalService.open(getVideoModal, { size: 'lg' });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  emitEvent(event): void {
    this.commonService.emitEvent(event)
  }

  aboutUs() {
    this.router.navigate(['/about-us']);
  }
  public onIndexChange(index: number): void {
    console.log('Swiper index: ', index);
  }

  public onIndexChange0(index: number): void {
    console.log('Swiper index: ', index);
  }

  public onIndexChangeStudent(index: number): void {
    console.log('Student Swiper index: ', index);
  }
  public onIndexChangeParent(index: number) {
    console.log('Parent Swiper index: ', index);
  }
  public onIndexChangeEducator(index: number) {
    console.log('Educator Swiper index: ', index);
  }
  public onIndexChangeCorporate(index: number) {
    console.log('Corporate Swiper index: ', index);
  }
  public onIndexChangeCounsellors(index: number) {
    console.log('Corporate Swiper index: ', index);
  }


  getStarted(mob) {
    var mobile = JSON.stringify(mob);
    if (mobile.length <= 12 && mobile.length >= 10) {
      this.localStorageService.set('mobile', mob);
      this.router.navigate(['/accounts/get-started']);
    }
    else {
      swal({
        type: 'error',
        title: 'Uh oh! <i class="fa fa-frown-o"></i> ',
        text: 'Please enter a valid mobile number to sign up.',
        confirmButtonClass: 'submit-purple-swal-btn',
        cancelButtonClass: 'cancel-danger-swal-btn',
        buttonsStyling: true,
      })
    }
  }


  openHIW() {
    if (this.toggleVar === 0) {
      this.toggleVar = 1;
    } else {
      this.toggleVar = 0;
    }
  }
  getHomepageBlogs() {
    this.homeService.GetHomepageBlogs().subscribe(
      response => {
        this.HomepageBlogsFetched = true;
        this.HomepageBlogs = response;
        // this.HomepageBlogs.push('');
      },
      error => {

      });
  }
  getHomepageBlogsNew() {
    this.homeService.GetHomepageBlogsNew().subscribe(
      response => {
        this.HomepageBlogsNewFetched = true;
        this.HomepageBlogsNew = response;
      },
      error => {

      }
    );



  }


  SubscribeToNewsLetter(email) {
    this.homeService.SubscribeToNewsLetter(email).subscribe(
      response => {

      },
      error => {

      });

    (<any>window).gtag('event', 'newsletter',
      {
        'send_to': 'AW-849928441/5TxpCNm8pagBEPnBo5UD',
        'event_category': 'newsletter',
        'event_action': 'clcik to newsletter sinup',
        'event_label': 'newsletter signup',
      });

  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      this.localStorageService.set('home', false);
    }
  }
  calGetCareeSessionPreference() {
    this.homeService.getPreferenceForStudentId().subscribe(data => {
      let response: any = data
      this.isBrainStormPackagePurchased = response.packagePurchased

      console.log("Response==>", response)
    })
  }
}
