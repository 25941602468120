import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Pipe({ name: 'UrlPipe' })
export class UrlPipe implements PipeTransform {
    public specCareer;
    constructor(private sanitized: DomSanitizer) { }
    transform(value) {
        var url = value.replace('http:', 'https:');
        return url;
        // return value;
    }
}