import { StorageService } from '../common/storage-service';
import { CommonService } from '../common/common.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable()
export class AccountsService {

  private apiUrl = '';
  private stsUrl = '';
  public emailFromGetStarted="";
  public isUserLoggedIn = false;


  constructor(private http: HttpClient, private commonService: CommonService, private localStorageService: StorageService) {
    this.apiUrl = this.commonService.getApiUrl();
    this.stsUrl = this.commonService.getStsUrl();
    if (localStorage.getItem('access_token') !== null) {
      this.isUserLoggedIn = true;
  }
  }
  // Login API
  public login(username, password) {
    let headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let data = 'username=' + username + '&password=' + password +
      '&grant_type=password&client_id=Mentoriaapi&client_secret=secret' +
      '&scope=openid Mentoriaapi profile idmgr roles';
    return this.http.post(this.stsUrl + '/core/connect/token', data, { headers: headers }).pipe(retry(1), catchError(this.handleError));
  }

  public addMobile(mobile) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/api/Accounts/AddMobile?phoneNumber=' + mobile,
      httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  addEmail(email) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/api/Accounts/ChangeEmail?newEmail=' + email,
      httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }



  // Registration API
  RegisterStudent(studentObj) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    let body = JSON.stringify(studentObj);
    return this.http.post(this.apiUrl + '/api/Accounts/RegisterStudent',
      body, httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  // Registration API as per new design
  newRegisterStudent(studentObj) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    let body = JSON.stringify(studentObj);
    return this.http.post(this.apiUrl + '/api/Accounts/RegisterStudent',
      body, httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  // Registration API
  RegisterCounsellor(counsellorObj) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    let body = JSON.stringify(counsellorObj);
    return this.http.post(this.apiUrl + '/api/Accounts/RegisterCounsellor',
      body, httpOptions).pipe(retry(3), catchError(this.handleError));
  }



  // Verify phone with ONE TIme Password
  public VerifyPhoneNumber(otp) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    let body = JSON.stringify(otp);
    return this.http.post(this.apiUrl + '/api/Accounts/VerifyPhone',
      body, httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  // Resend OTP for phone verification
  public ResendOTP() {
    let body = '';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.post(this.apiUrl + '/api/Accounts/ResendPhoneVerificationOTP', body,
      httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  public ResendVerificationEmail(email) {
    let body = JSON.stringify(email);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.post(this.apiUrl + '/api/Accounts/sendVerificationEmail', body,
      httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  // Check if email already exist
  public CheckEmailExists(email) {
    return this.http.get
      (this.apiUrl + '/api/Accounts/IsEmailExist?emailId=' + email).pipe(retry(3), catchError(this.handleError));
  }
  // Check if phone already exist
  public CheckPhoneExists(phone) {
    return this.http.get
      (this.apiUrl + '/api/Accounts/IsPhoneExist?phoneNo=' + phone).pipe(retry(3), catchError(this.handleError));
  }

  async emailExists(email): Promise<boolean> {
    const response = await this.http.get(this.apiUrl + '/api/Accounts/IsEmailExist?emailId=' + email).toPromise();
    if (response['Message'] === 'True') {
      return true;
    } else {
      return false;
    }
  }

  async phoneExists(phone): Promise<boolean> {
    const response = await this.http.get(this.apiUrl + '/api/Accounts/IsPhoneExist?phoneNo=' + phone).toPromise();
    if (response['Message'] === 'True') {
      return true;
    } else {
      return false;
    }
  }




  // Check if refferal code exist
  ReferralCodeExists(code) {
    return this.http.get
      (this.apiUrl + '/api/Accounts/IsReferralCodeExist?code=' + code).pipe(retry(3), catchError(this.handleError));
  }

  // Forgot Password Request
  PasswordRequest(request) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let body = JSON.stringify(request);
    return this.http.post(this.apiUrl + '/api/Accounts/ForgotPasswordRequest',
      body, httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  // Forgot password reset
  PasswordReset(reset) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    let body = JSON.stringify(reset);
    return this.http.post(this.apiUrl + '/api/Accounts/ForgotPasswordReset',
      body, httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  // Check student status
  // getStudentStatus () {
  //     let headers = new HttpHeaders();
  //    headers.append('Content-Type', 'application/json');
  //    let authToken = this.localStorageService.get('access_token');
  //   headers.append('Authorization', 'Bearer ' + authToken);
  //   return this.http.get(this.apiUrl + '/api/Students/GetStudentStatus',
  //    { headers: headers })
  //   .map(this.extractData)
  //     .catch(this.handleError);
  //  }

  // get where to redirect student after login
  GetStudentPath() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/api/Students/GetStudentPath',
      httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  // Check counsellor status
  GetCounsellorPath() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/api/Counsellors/getCounsellorPath',
      httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  // Get student details
  getCounsellorDetails() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/api/Counsellors/GetCounsellor', httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  // Get student details
  getStudentDetails() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/api/Students/GetStudent', httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  // Get student details
  getAccountType() {
    const httpOptions = {
      headers: new HttpHeaders({

        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/api/accounts/getAccountType', httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  //register mobile 
  registerMobile(mobileNumber) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/api/accounts/registerMobile?mobile=' + mobileNumber, httpOptions).pipe(retry(3), catchError(this.handleError));
  }
  //verify mobile
  verifyMobile(mobileNumber, otp) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/api/accounts/VerifyMobile?mobile=' + mobileNumber + '&otp=' + otp, httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  //resend OTP
  resendOTP(mobileNumber) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/api/accounts/resendOTP?mobile=' + mobileNumber, httpOptions).pipe(retry(3), catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }
  /***Get OTP on Mobile Or Email */
  getOTPOnEamilOrPhone(obj) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    let body = JSON.stringify(obj);
    return this.http.post(this.apiUrl + '/api/Accounts/RegisterViaMobileOrEmail',
      body, httpOptions).pipe(retry(3), catchError(this.handleError));

  }
  /***Verify Entered OTP */
  verifyOTP(obj) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    let body = JSON.stringify(obj);
    return this.http.post(this.apiUrl + '/api/Accounts/VerifyOTPViaMobileOrEmail',
      body, httpOptions).pipe(retry(3), catchError(this.handleError));
  }
  /**LOGIN WITN OTP API */

  LoginViaMobileForOtp(obj) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    let body = JSON.stringify(obj);
    return this.http.post(this.apiUrl + '/api/Accounts/LoginViaMobileForOtp',
      body, httpOptions).pipe(retry(3), catchError(this.handleError));
  }
  /**VERFY OTP FOR LOGIN */

  VerifyOTPforLogin(obj) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    let body = JSON.stringify(obj);
    return this.http.post(this.apiUrl + '/api/Accounts/VerifyOTPforLogin',
      body, httpOptions).pipe(retry(3), catchError(this.handleError));
  }
  
  verifyOTPViaMobileOrEmailNew(obj)
  {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let body = JSON.stringify(obj);
    return this.http.post(this.apiUrl + '/api/Accounts/VerifyOTPViaMobileOrEmailNew', body, httpOptions).pipe(retry(3), catchError(this.handleError));
  }
  SavePasswordForOldUsers(obj) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let body = JSON.stringify(obj);
    return this.http.post(this.apiUrl + '/api/Accounts/SavePasswordForOldUsers', body, httpOptions).pipe(retry(3), catchError(this.handleError));
  }
  //Register via mobile or email API
  registerViaMobileOrEmailNew(obj: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let body = JSON.stringify(obj);
    return this.http.post(this.apiUrl + '/api/Accounts/RegisterViaMobileOrEmailNew', body, httpOptions).pipe(retry(3), catchError(this.handleError));
  }
}
