import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BlogsService } from '../blog.service';

@Component({
  selector: 'app-blog-category',
  templateUrl: './blog-category.component.html',
  styleUrls: ['./blog-category.component.css']
})
  
export class BlogCategoryComponent implements OnInit {

  public isTarget;
  public blogCategories;
  public currentCategory = '';

  @Output() sidenavClose = new EventEmitter();
    
  constructor(private router: Router, private blogService: BlogsService, private route: ActivatedRoute) { 
    this.route.queryParams.subscribe(params => {
      let blogCategory = params["type"] || "";
      if (blogCategory !== '') {
        this.currentCategory = blogCategory;
      }
    });
  }

  ngOnInit() {
    this.getBlogCategories();
   
  }

  public getBlogCategories() {
    this.blogService.getBlogCategories().subscribe(
      response => {
        this.blogCategories = response['categories'];
      },
      error => {
        
      });
  }

  showTab(tabName: string) {
    this.isTarget = tabName;
    this.router.navigate(['blog/category'], { queryParams: { type: tabName } });
  }
}
