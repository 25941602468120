import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { StorageService } from '../../common/storage-service';
import { AccountsService } from '../accounts.service';
import { Router } from '@angular/router';
import { CommonService } from '../../common/common.service';

@Component({
  selector: 'app-post-login',
  templateUrl: './post-login.component.html',
  styleUrls: ['./post-login.component.scss']
})
export class PostLoginComponent implements OnInit {

  private platformId;
  public studentCareerPathArray;

  constructor( @Inject(PLATFORM_ID) platformId: string, private localStorageService: StorageService, private accountService: AccountsService, private _commonService:CommonService, private router: Router ) { 
    this.platformId = platformId;
  }

  ngOnInit() {
    const params = window.location.hash;
    if (params.split('&')[0].split('=')[0] === '#access_token') {
      const token = params.split('&')[0].split('=')[1];
      this.localStorageService.set('access_token', token);
      this.accountService.getAccountType().subscribe(
        accountType => {
          this.localStorageService.set('account_type', accountType);
          this._commonService.login();
          if (accountType === 'student') {
            // get student object and save in common service
            // this.commonService.getStudent();
            this.accountService.getStudentDetails().subscribe(studentVar => {
              this.studentCareerPathArray = studentVar['selectedCareerPaths'];

              if (!this.studentCareerPathArray) {
                // this.commonService.careerArr = false;
                this.localStorageService.set('career_arr', this.studentCareerPathArray.length);
              } else {
                this.localStorageService.set('career_arr', this.studentCareerPathArray.length);
              }

            }, err => {
              alert('Some error occured.');
              this.router.navigate(['accounts/login']);
            });


            this.accountService.GetStudentPath().subscribe(path => {
        
              this.router.navigate([path]);
            }, err => {
              alert('Some error occured, please login again.');
              this.router.navigate(['accounts/login']);
            });
          } else if (accountType === 'counsellor') {
            this.accountService.GetCounsellorPath().subscribe(path => {
           
              this.router.navigate([path]);
            }, err => {
              alert('Some error occured, please login again.');
              this.router.navigate(['accounts/login']);
            });
          } else {
            alert("Invalid User");
            this.router.navigate(['accounts/login']);
          }
        },
        err => {
          console.log(err);
          alert('Some error occured, please login again.');
          this.router.navigate(['accounts/login']);
        });
    } else { 
      alert('Some error occured, please login again.');
      this.router.navigate(['accounts/login']);
    }
  }

}
