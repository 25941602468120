import { StorageService } from '../../common/storage-service';
import { Component, OnInit, PLATFORM_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
// import { StudentService } from '../../student/student.service';
// import { StudentStatus } from '../../student/student.model';
// import { StudentModel } from '../../student/student.model';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { SharedService } from '../shared.service';
import { CommonService } from '../../common/common.service';
@Component({
  selector: 'app-student-header',
  templateUrl: './student-header.component.html',
  styleUrls: ['./student-header.component.css']
})
export class StudentHeaderComponent implements OnInit {

  public student;
  public authToken;
  public accType;
  public openModalWindow: boolean;
  // public status: StudentStatus = new StudentStatus();
  public pop1: boolean;
  public platformId;
  public careerArr;

  constructor(private router: Router, private sharedService: SharedService, @Inject(PLATFORM_ID) platformId: string, private localStorage: StorageService, private commonService: CommonService ) {
    this.platformId = platformId;

    this.authToken = this.localStorage.get('access_token');
    
    this.pop1 = false;
    this.careerArr = this.localStorage.get('career_arr');
    this.accType = this.localStorage.get('account_type');
    console.log(this.accType);
  }

  ngOnInit() {
    if (this.authToken !== '') {
      this.getStudent();
    }
  }

  

  closeModal() {
    this.openModalWindow = false;
    this.pop1 = true;
  }

  public getStudent() {
    this.sharedService.GetStudentDetails().subscribe(
      response => {
        this.student = response;
      },
      error => {
        
      });
  }

  Logout() {
    this.commonService.logout();;
    if (isPlatformBrowser(this.platformId)) {
      // console.log('user agent --- ' + window.navigator.userAgent );
      let ua = window.navigator.userAgent;

      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
        console.log('route changing to Mobile view');
        this.router.navigate(['home-mobile']);
      }
      else {
        // this.router.navigate([this.commonService.getpreloginAppURL()]);
        let url=this.commonService.getpreloginAppURL()
        let newPath = url + '/redirect?authToken=' + this.localStorage.get('access_token') + '&redirectURl=' + encodeURI(url + '/login');
        window.open(newPath, "_self")
      }
    }
 
  }
home() {
  if (isPlatformBrowser(this.platformId)) {
    // console.log('user agent --- ' + window.navigator.userAgent );
    let ua = window.navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      console.log('route changing to Mobile view');
      this.router.navigate(['home-mobile']);
    }
    else {
      let url=this.commonService.getpreloginAppURL()
      let newPath = url + '/redirect?authToken=' + this.localStorage.get('access_token') + '&redirectURl=' + encodeURI(url);
      window.open(newPath, "_self")
    }
  }
}
gottoPreloginLink(link)
  {
    this.sharedService.redirectNavURL(link)
  }

}
