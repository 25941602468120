import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import {
    MatButtonModule, MatNativeDateModule, MatIconModule, MatSidenavModule, MatTooltipModule,
    MatListModule, MatToolbarModule, MatCardModule, MatInputModule, MatSelectModule, MatRadioModule, MatCheckboxModule, MatTableModule,
    MatDatepickerModule, MatPaginatorModule, MatDialogModule, MatExpansionModule, MatDividerModule, MatChipsModule, MatMenuModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatProgressBarModule
} from '@angular/material';

@NgModule({
    imports: [CommonModule,
        MatButtonModule,
        MatToolbarModule,
        MatNativeDateModule,
        MatIconModule,
        MatTooltipModule,
        MatSidenavModule,
        MatDatepickerModule,
        MatListModule,
        MatCardModule,
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        MatCheckboxModule,
        MatTableModule,
        MatDialogModule,
        MatExpansionModule,
        MatPaginatorModule,
        MatChipsModule,
        MatMenuModule,
        MatDividerModule,
        MatAutocompleteModule,
        MatTabsModule,
        MatProgressBarModule
    ],
    exports: [
        CommonModule,
        MatButtonModule,
        MatToolbarModule,
        MatNativeDateModule,
        MatIconModule,
        MatSidenavModule,
        MatDatepickerModule,
        MatListModule,
        MatCardModule,
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        MatCheckboxModule,
        MatTableModule,
        MatDialogModule,
        MatExpansionModule,
        MatPaginatorModule,
        MatChipsModule,
        MatMenuModule,
        MatDividerModule,
        MatTabsModule,
        MatAutocompleteModule,
        MatProgressBarModule
    ],
})
export class CustomMaterialModule { }
