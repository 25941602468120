import { Component, Input, ElementRef, ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-execute-script',
  templateUrl: './execute-script.component.html',
  styleUrls: ['./execute-script.component.css']
})
export class ExecuteScriptComponent {

  @Input() src: string;

  @Input() type: string;

  @ViewChild('script') script: ElementRef;
  public platformId ;

  constructor( @Inject(PLATFORM_ID) platformId: string) {
    this.platformId = platformId;
  }

  convertToScript() {
    var element = this.script.nativeElement;
    var script = document.createElement("script");
    script.type = this.type ? this.type : "text/javascript";
    if (this.src) {
      script.src = this.src;
    }
    if (element.innerHTML) {
      script.innerHTML = element.innerHTML;
    }
    var parent = element.parentElement;
    parent.parentElement.replaceChild(script, parent);
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => { 
        this.convertToScript();
      });
    }
  }

}
