import { Component, OnInit } from "@angular/core";
import { marqueeData } from "./new-registration.json";
import { countryCodes } from "src/app/shared/country-code.json";
import { AccountsService } from "../accounts.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { side_Content } from "./new-registration.json";
import swal from "sweetalert2";
import { Router } from "@angular/router";
import { StorageService } from "src/app/common/storage-service";
import { CommonService } from "src/app/common/common.service";
import { Meta, Title } from "@angular/platform-browser";
// import { SweetAlert } from 'sweetalert2';
@Component({
  selector: "app-new-registration",
  templateUrl: "./new-registration.component.html",
  styleUrls: ["./new-registration.component.css"],
})
export class NewRegistrationComponent implements OnInit {
  marqueeData = marqueeData;
  side_content = side_Content;
  selectedCountryCode = "+91";
  public isDPWorld: boolean = false;
  public isITC: boolean = false;
  public isAragen: boolean = false;
  public isRustomJee: boolean = false;
  public isItcpcpb: boolean = false;
  otpDigit1: any;
  otpDigit2: any;
  errorMsg: any;
  otpDigit3: any;
  otpDigit4: any;
  countryCodes: { name: string; dial_code: string; code: string }[];
  formError = false;
  formNameError = false;
  formPhoneError = false;
  existError = false;
  formClassError = false;
  public showPass = false;
  public confirmPass = false;
  formEmailError = false;
  classperror = false;
  formSubmitted: any;
  phonePattern: any = "[0-9]+";
  public internationalPhonePattern = "[0-9]+";
  minPhoneLength: number = 10;
  maxPhoneLength: number = 10;
  selectedClass = "";
  selectedClassTwo = "";
  registerloading = false;
  form1error = "";
  user: boolean = true;
  values: any;
  resendOTPonEmail = false;
  resendOTPOnMobile = false;
  public registered = false;
  registerationWindow: boolean = false;
  sendOtpOn = "mobile";
  sendEmailOtp = "email";
  disableResendOTp = true;
  verifyLoading = false;
  public passWord;
  otpSent = false;
  form1OtpSent = "";
  resendloading = false;
  resendOtp = false;
  showTimer = false;
  formpError: any;
  verifyOTPloading = false;
  show = false;
  time: number = 30;
  resendOtpHidden = true
  // optloading=false
  otploading = false;

  OtpSent = false;
  registerForm = {
    registerName: "",
    firstName: "",
    lastname: "",
    dialCode: "",
    emailId: "",
    employeeId: "",
    otpDigit1: "",
    otpDigit2: "",
    otpDigit3: "",
    otpDigit4: "",
    otp: "",
    phone: "",
    selectedClass: "",
    password: "",
    confirmPassword: "",
  };

  phoneBooth: any;
  public currentURL: any;
  public phoneValidation: FormGroup;

  constructor(
    public accountService: AccountsService,
    private titleService: Title,
    private metaService: Meta,
    public fb: FormBuilder,
    public router: Router,
    public localStorageService: StorageService,
    public commonService: CommonService
  ) {
    this.countryCodes = countryCodes;
    this.phoneValidation = this.fb.group({
      phone: new FormControl("", [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern("[0-9]+"),
      ]),

      email: [
        "",
        [
          Validators.required,
          Validators.pattern(
            /^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/
          ),
        ],
      ],
    });
  }

  ngOnInit() {
    this.currentURL = window.location.href;
    if (this.currentURL.toLowerCase().includes("dpworld")) {
      this.isDPWorld = true;
    } else if (this.currentURL.includes("itcfoods")) {
      this.isITC = true;
    } else if (this.currentURL.includes("aragen")) {
      this.isAragen = true;
    } else if (this.currentURL.toLowerCase().includes("rustomjee")) {
      this.isRustomJee = true;
    }
    else if (this.currentURL.toLowerCase().includes("itcpcpb")) {
      this.isItcpcpb = true;
    }

    this.accountService.isUserLoggedIn = false;
    this.titleService.setTitle(
      "Streamline Your Registration with our Account Signup Page"
    );
    let attributeSelector: string = 'name="description"';
    this.metaService.removeTag(attributeSelector);
    this.metaService.updateTag({
      name: "description",
      content:
        "Put your skills to use. Become a career counsellor and help students and professionals make better decisions. Read more on Mentoria. ",
    });
  }

  registerVIAotpOrPass(phoneBooth: any, id: any) {
    this.resendOtpHidden = true;
    this.time = 30;
    this.registerloading = true;
    let obj: any = {};
    this.otploading = true;
    if (id == 1) {
      obj = {
        mobile: this.phoneBooth,
        countryCode: "+91",
        firstName: this.registerForm.registerName.split(" ")[0] || "",
        lastname: this.registerForm.registerName.split(" ")[1] || "",
        employeeId: this.registerForm.employeeId ? this.registerForm.employeeId : "",
        Source: this.getSourceOrRefferatCode(),
        referralCodeUsed: this.getSourceOrRefferatCode(),
        Mobile: this.registerForm.phone,
        educationalClass: this.selectedClass,
        emailId: this.registerForm.emailId,
        sendOtpOn: "mobile"

      };
      this.resendOTPonEmail = false;
      this.resendOTPOnMobile = true;
    } else {
      obj = {
        mobile: this.phoneBooth,
        firstName: this.registerForm.registerName.split(" ")[0] || "",
        countryCode: "",
        lastname: this.registerForm.registerName.split(" ")[1] || "",
        employeeId: this.registerForm.employeeId ? this.registerForm.employeeId : "",
        Source: this.getSourceOrRefferatCode(),
        referralCodeUsed: this.getSourceOrRefferatCode(),
        Mobile: this.registerForm.phone,
        educationalClass: this.selectedClass,
        emailId: this.registerForm.emailId,
        sendOtpOn: "email"
      };
      this.resendOTPOnMobile = false;
      this.resendOTPonEmail = true;
    }


    this.accountService
      .registerViaMobileOrEmailNew(obj)
      .subscribe((data: any) => {
        this.resendloading = false;
        this.otploading = false;
        let res: any = data;
        console.log(res.message);
        if (res.message == "success") {
          console.log("otp login successfull");
          if (id == 1) {
            swal("Sucess", "OTP Sent successfully on mobile", "success");
          }
          if (id == 2) {
            swal("Sucess", "OTP Sent successfully on email", "success");
          }
          swal("Otp Sent Successfully !", "", "success");
          this.show = true;
          setInterval(() => {
            if (this.time > 0) {
              this.time--;
            } else {
              this.resendOtpHidden = false;
              return;
            }
          }, 1000);
        } else if (res.message == "No User found!") {
          this.registerloading = false;
          swal("Oops!", "No User Found", "error");
        }
      });

    this.showTimer = true;
  }
  RegisterWindow() {
    this.registerationWindow = true;
  }
  counselorsWindow() {
    this.user = false;
  }

  resendPhone(phone: any) {
    this.resendloading = true;

    this.disableResendOTp = true;

    this.showTimer = true;
    this.registerVIAotpOrPass(phone, 1);
  }
  resendEmail(phone: any) {
    this.resendloading = true;
    this.disableResendOTp = true;

    setTimeout(() => {
      this.disableResendOTp = false;
      this.showTimer = false;
    }, 30000);

    this.showTimer = true;
    this.registerVIAotpOrPass(phone, 2);
  }

  callRegisterApi(obj: any) {
    this.existError = false;
    if (this.localStorageService.get("utm_source") !== "") {
      obj["utm_source"] = "CBS";
    }
    if (this.localStorageService.get("gclid") !== "") {
      obj["gclid"] = "CBS";
    }
    if (this.localStorageService.get("utm_medium") !== "") {
      obj["utm_medium"] = this.localStorageService.get("utm_medium");
    }
    if (this.localStorageService.get("utm_campaign") !== "") {
      obj["utm_campaign"] = this.localStorageService.get("utm_campaign");
    }
    if (this.localStorageService.get("utm_adgroup") !== "") {
      obj["utm_adgroup"] = this.localStorageService.get("utm_adgroup");
    }
    if (this.localStorageService.get("utm_device") !== "") {
      obj["utm_device"] = this.localStorageService.get("utm_device");
    }
    if (this.localStorageService.get("utm_term") !== "") {
      obj["utm_term"] = this.localStorageService.get("utm_term");
    }
    if (this.localStorageService.get("utm_matchtype") !== "") {
      obj["utm_matchtype"] = this.localStorageService.get("utm_matchtype");
    }
    if (this.localStorageService.get("utm_location") !== "") {
      obj["utm_location"] = this.localStorageService.get("utm_location");
    }
    if (this.localStorageService.get("utm_placement") !== "") {
      obj["utm_placement"] = this.localStorageService.get("utm_placement");
    }
    this.accountService.registerViaMobileOrEmailNew(obj).subscribe(
      (data) => {
        let res: any = data;
        if (res.message == "success") {
          this.registered = true;

          if (res.message == "success") {
            if (res.reponse == "email" || this.selectedCountryCode !== "+91") {
              swal({
                type: "success",
                title: "Sucess",
                text: "OTP has been sent successfully on email.",
                timer: 1500,
              });
            } else {
              swal({
                type: "success",
                title: "Sucess",
                text: "OTP Sent successfully on mobile",
                timer: 1500,
              });
            }
            this.otpSent = true;
            this.OtpSent = true;

            this.resendOtp = true;
            setTimeout(() => {
              this.disableResendOTp = false;
              this.showTimer = false;
            }, 30000);

            this.showTimer = true;
          }
        } else {
          this.existError = true;
          this.formError = true;
          this.errorMsg = res.response;
          this.registerloading = false;
        }
        this.registerloading = false;
      },
      (error) => {
        swal({
          type: "error",

          title: "OOPS.",
          text: "Something Went Wrong Please Try After Sometime.",
        });
      }
    );
  }
  register(form: any) {
    this.registerationWindow = true;

    console.log(form);

    if (
      this.selectedClass === "" ||
      this.selectedClass === null ||
      this.selectedClass === undefined
    ) {
      this.formError = true;
      this.errorMsg = "Please select class";
    } else {
      this.formNameError = false;
      //  this.formLastNameError = false
      this.formEmailError = false;
      this.formPhoneError = false;
      this.form1error = "";
      this.existError = false;
      this.values = form.registername.split(" ");
      console.log(this.values);
      this.registerloading = true;
      this.registerForm.firstName = this.values[0];
      this.registerForm.lastname = this.values[1];
      this.registerForm.emailId = form.email;
      this.registerForm.phone = form.phone;
      this.registerForm.selectedClass = this.selectedClass;
      this.phoneBooth = this.registerForm.phone;
      let obj = {
        firstName: this.registerForm.firstName || "",
        lastname: this.registerForm.lastname || "",
        employeeId: form.employeeId ? form.employeeId : "",
        Source: this.getSourceOrRefferatCode(),
        referralCodeUsed: this.getSourceOrRefferatCode(),
        Mobile: form.phone,
        educationalClass: this.selectedClass,
        emailId: form.email,
        countryCode: this.selectedCountryCode,
        sendOtpOn: this.selectedCountryCode == "+91" ? "mobile" : "email",
      };

      if (this.sendEmailOtp == "email") {
        obj.countryCode = "";
      }
      if (obj.educationalClass !== "class") {
        this.callRegisterApi(obj);
      } else {
        this.registerloading = false;
        // swal({ text: "Please select class." })
        this.formpError = "Please select class.";
        this.classperror = true;

        this.formError = true;
      }
    }
  }

  usersWindow() {
    this.user = true;
  }
  registerAlert(form: any) {
    this.formSubmitted = true;
    // debugger
    // this.changeValidation()
    console.log(form);
  }
  pasteOTP(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData!.getData("text");
    let arr = pastedText.split("");
    this.registerForm.otpDigit1 = arr[0];
    this.registerForm.otpDigit2 = arr[1];
    this.registerForm.otpDigit3 = arr[2];
    this.registerForm.otpDigit4 = arr[3];
  }
  moveToNext(event: any) {
    let next = event.target.nextElementSibling;
    if (next) {
      next.focus();
    } else {
      event.target.blur();
    }
  }
  //to remove the border of error fields
  removeErrorBorderOnFocus(error: any) {
    if (error === 1) {
      this.formNameError = false;
      this.formError = false;
    } else if (error === 2) {
      this.formPhoneError = false;
      this.formError = false;
    } else if (error === 3) {
      this.formEmailError = false;
      this.formError = false;
    } else if (error === 4) {
      this.classperror = false;
      this.formError = false;
    }
  }
  changeValidation(code: any) {
    debugger;
    if (code !== "+91") {
      this.phonePattern = this.internationalPhonePattern;
      this.minPhoneLength = 8;
      this.maxPhoneLength = 14;
    } else {
      this.phonePattern = "[0-9]+";
      this.minPhoneLength = 10;
      this.maxPhoneLength = 10;
    }
  }
  verifyOTP() {
    this.verifyLoading = true;
    this.form1OtpSent = "";
    // this.verifyOTPloading = true;
    let otp =
      this.registerForm.otpDigit1 +
      "" +
      "" +
      this.registerForm.otpDigit2 +
      "" +
      "" +
      this.registerForm.otpDigit3 +
      "" +
      "" +
      this.registerForm.otpDigit4;
    console.log(otp);
    if (otp) {
      let obj = {
        firstName: this.registerForm.firstName,
        lastname: this.registerForm.lastname,
        Mobile: this.registerForm.phone,
        educationalClass: this.selectedClass,
        emailId: this.registerForm.emailId,
        employeeId: this.registerForm.employeeId
          ? this.registerForm.employeeId
          : "",
        Source: this.getSourceOrRefferatCode(),
        referralCodeUsed: this.getSourceOrRefferatCode(),
        countryCode: this.selectedCountryCode,
        VerificationCode: otp,
      };
      this.callVerifyOTPApi(obj);
    } else {
      this.verifyLoading = false;
      swal({
        type: "error",
        title: "Invalid OTP",
        showConfirmButton: true,
        timer: 1500,
      });
    }
    this.resendOtp = true;

    this.resendOtp = true;
  }

  //Verify the otp api
  callVerifyOTPApi(obj: any) {
    if (this.localStorageService.get("utm_source") !== "") {
      obj["utm_source"] = "CBS";
    }
    if (this.localStorageService.get("gclid") !== "") {
      obj["gclid"] = "CBS";
    }
    if (this.localStorageService.get("utm_medium") !== "") {
      obj["utm_medium"] = this.localStorageService.get("utm_medium");
    }
    if (this.localStorageService.get("utm_campaign") !== "") {
      obj["utm_campaign"] = this.localStorageService.get("utm_campaign");
    }
    if (this.localStorageService.get("utm_adgroup") !== "") {
      obj["utm_adgroup"] = this.localStorageService.get("utm_adgroup");
    }
    if (this.localStorageService.get("utm_device") !== "") {
      obj["utm_device"] = this.localStorageService.get("utm_device");
    }
    if (this.localStorageService.get("utm_term") !== "") {
      obj["utm_term"] = this.localStorageService.get("utm_term");
    }
    if (this.localStorageService.get("utm_matchtype") !== "") {
      obj["utm_matchtype"] = this.localStorageService.get("utm_matchtype");
    }
    if (this.localStorageService.get("utm_location") !== "") {
      obj["utm_location"] = this.localStorageService.get("utm_location");
    }
    if (this.localStorageService.get("utm_placement") !== "") {
      obj["utm_placement"] = this.localStorageService.get("utm_placement");
    }
    console.log("Obj==>", obj);
    this.accountService.verifyOTPViaMobileOrEmailNew(obj).subscribe((data) => {
      debugger;
      let response: any = data;
      this.verifyLoading = false;
      if (response.message == "success") {
        let token: any = response.reponse.access_token;
        this.setTokenAfterLogin(token);
      } else {
        this.verifyLoading = false;
        swal({
          type: "error",
          title: "Inorrect OTP",
          showConfirmButton: true,
          timer: 1500,
        });
      }
    });
  }

  onDigitInput(event: any) {
    let element;

    var key = event.keyCode || event.charCode;
    if (event.code !== "Backspace")
      element = event.srcElement.nextElementSibling;

    if (event.code === "Backspace" || key == 8 || key == 46)
      element = event.srcElement.previousElementSibling;

    if (element == null) return;
    else element.focus();
  }

  setTokenAfterLogin(token: any) {
    this.localStorageService.setValue("access_token", token);
    this.commonService.login();
    this.accountService.getAccountType().subscribe(
      (accountType) => {
        let accType: any = accountType;
        this.localStorageService.setValue("account_type", accType);
        console.log(accountType);
        if (accountType === "student") {
          // this.validUser = true;
          this.accountService.getStudentDetails().subscribe(
            (studentVar) => {
              let response: any = studentVar;
              let userName = response.firstName + " " + response.lastName;

              this.localStorageService.setValue("user_Name", userName);
              console.log("StudentVar Value ", studentVar);
            },
            (err) => {
              alert("Some error occured.");
            }
          );

          this.accountService.GetStudentPath().subscribe(
            (path) => {
              console.log(path);
              this.router.navigate([path]);
            },
            (err) => {
              alert("Some error occured, please login again.");
            }
          );
        } else if (accountType === "counsellor") {
          //this.validUser = true;
          this.accountService.GetCounsellorPath().subscribe(
            (path) => {
              console.log(path);
              this.router.navigate([path]);
            },
            (err) => {
              alert("Some error occured, please login again.");
            }
          );
        } else {
          alert("Invalid User");
          // swall here
          swal("Oops!", "Invalid User", "error");
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  registerCounsellor(val: any) {
    debugger;
    this.registerForm.dialCode = this.selectedCountryCode;
    let counsellorObj: any = {
      CurrentClass: this.registerForm.selectedClass,
      FirstName: this.registerForm.registerName.split(" ")[0],
      LastName: this.registerForm.registerName.split(" ")[1],
      Email: this.registerForm.emailId,
      Password: this.registerForm.password,
      Mobile: this.registerForm.phone,
    };
    if (
      this.checkIsNull(counsellorObj.CurrentClass) ||
      this.checkIsNull(counsellorObj.FirstName) ||
      this.checkIsNull(counsellorObj.Email) ||
      this.checkIsNull(counsellorObj.Password) ||
      this.checkIsNull(counsellorObj.Mobile)
    ) {
      this.existError = true;
      this.errorMsg = "Please fill all details!";
    } else if (counsellorObj.Password !== this.registerForm.confirmPassword) {
      this.existError = true;
      this.errorMsg = "password and confirm password should be same";
    } else {
      this.registerloading = true;
      this.existError = false;
      this.accountService.RegisterCounsellor(counsellorObj).subscribe(
        (data) => {
          this.registerloading = false;
          swal({
            type: "success",
            text: "Counsellors Registered Successfully, Try logging in to continue further steps!",
          });
        },
        (error) => {
          this.registerloading = false;
          swal({
            type: "error",
            text: "Something went wrong please try after sometime!",
          });
        }
      );
    }
  }
  getSourceOrRefferatCode() {
    if (this.isDPWorld) {
      return "DPWorld";
    } else if (this.isITC) {
      return "itc";
    } else if (this.isAragen) {
      return "aragen";
    } else if (this.isRustomJee) {
      return "RUSTOMJEE23";
    } else {
      return "";
    }
  }
  checkIsNull(val: any) {
    if (val == null || val == "" || val == "" || val == undefined) {
      return true;
    } else {
      return false;
    }
  }
}
