import { StorageService } from '../common/storage-service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { CommonService } from '../common/common.service';
import { retry, catchError, map } from 'rxjs/operators';

@Injectable()
export class SharedService {


    private apiUrl = '';
    private stsUrl = '';
    public authToken: any = ''
    public basic_online_price = '5,500';
    public basic_offline_price = '5,599';

    public advanced_online_price = '5,999';//achive
    public advanced_offline_price = '6,999';

    public ascend_online_price = '6,499';
    public ascend_offline_price = '7,499';

    public basic_cancellation_price = '1,000';
    public advanced_cancellation_price = '1,250';
    public ascend_cancellation_price = '1,500';

    public basic_refund_price = '1,800';
    public advanced_refund_price = '2,300';
    public ascend_refund_price = '2,700';


    constructor(private http: HttpClient, private commonService: CommonService, private localStorageService: StorageService) {
        this.apiUrl = this.commonService.getApiUrl();
        this.stsUrl = this.commonService.getStsUrl();
        this.authToken = this.localStorageService.get("access_token") || '';

    }

    // GetStudentStatus() {
    //     let headers = new Headers();
    //     headers.append('Content-Type', 'application/json');
    //     let authToken = this.localStorage.get('access_token');
    //     headers.append('Authorization', 'Bearer ' + authToken);
    //     return this.http.get(this.apiUrl + '/api/Students/GetStudentStatus',
    //         httpOptions)
    //         .map(this.extractData)
    //         .catch(this.handleError);
    // } 
    GetStudentDetails() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
            })
        };
        return this.http.get(this.apiUrl + '/api/Students/GetStudent',
            httpOptions).pipe(retry(3), catchError(this.handleError));

    }
    // get where to redirect student after login
    GetStudentPath(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
            })
        };
        return this.http.get(this.apiUrl + '/api/Students/GetStudentPath',
            httpOptions).pipe(retry(3), catchError(this.handleError));
    }

    // Get counsellor details
    getCounsellorDetails(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
            })
        };
        return this.http.get(this.apiUrl + '/api/Counsellors/GetCounsellor',
            httpOptions).pipe(retry(3), catchError(this.handleError));

    }

    // Get counsellor documents
    GetCounsellorDocuments(Type, CounsellorUid) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
            })
        };
        return this.http.get
            (this.apiUrl + '/api/Counsellors/GetCounsellorDocumentForTraining?type=' + Type + "&counsellorUId=" + CounsellorUid, httpOptions).pipe(retry(3), catchError(this.handleError));
    }

    GetFeeds(feedObj): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
            })
        };
        let body = JSON.stringify(feedObj);
        return this.http.post(this.apiUrl + '/api/Contents/SearchFeeds', body, httpOptions).pipe(retry(3), catchError(this.handleError));
    }

    getCareerPaths(keyword): Observable<any> {
        if (keyword && keyword.length > 2) {
            return this.http.get(this.apiUrl + '/api/Contents/SearchAllCareers?careerPath=' + keyword).pipe(map(
                response => response['Result'] || {}), retry(3), catchError(this.handleError));
        } else {
            return of([]);
        }
    }

    SubscribeToNewsLetter(email) {
        return this.http.get
            (this.apiUrl + '/api/Contents/SubscribeToNewsLetter?email=' + email).pipe(retry(3), catchError(this.handleError));
    }
    // private handleError(error: Response | any) {

    //     let errMsg: string;
    //     if (error instanceof Response) {
    //         const body = error.json() || '';
    //         const err = body.error || JSON.stringify(body);
    //         errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    //     } else {
    //         errMsg = error.message ? error.message : error.toString();
    //     }
    //     console.error(errMsg);
    //     return Observable.throw(errMsg);
    // }

    private handleError(error: HttpErrorResponse) {
        console.log('error in service is', error);

        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(error);
    }
    redirectNavURL(path: string) {

        let url = this.commonService.getpreloginAppURL()
        let newPath = url + '/redirect?authToken=' + this.authToken + '&redirectURl=' + encodeURI(url + path);
        console.log("newPath=>", newPath)
        window.open(newPath, "_self");



    }
}    
