import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class StorageService {
        public home = true;
        public mobile = '';
        public platformId = '';

        constructor(@Inject(PLATFORM_ID) platformId: string ) {
                this.platformId = platformId;
        }

        public get(key): string {
                if (isPlatformBrowser(this.platformId)) {
                     return localStorage.getItem(key) || '';
                }
        }

        public set(key, value) {
                if (isPlatformBrowser(this.platformId)) {
                        return localStorage.setItem(key, value);
                }
        }
        public setValue(key: string, value: string): void {
                localStorage.setItem(key, value);
            }

        public remove(key) {
                if (isPlatformBrowser(this.platformId)) {
                        localStorage.removeItem(key);
                }
        }

        public clearAll() {
                if (isPlatformBrowser(this.platformId)) {
                        localStorage.clear();
                }
        }
        public clearRoutes() {
                // this.routing.storedRouteHandles.clear();
                // this.routing.routesToCache = [];
        }
}

