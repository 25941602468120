export const subscribedPackages = [
    {
        packName: "Discover Online",
        content: [{ text: "India’s most reliable psychometric assessment.", subText: [] }, { text: "1 session (1 hour + 30 min optional follow-up)", subText: [] }, { text: "Information on relevant courses for chosen careers", subText: [] }, { text: "Information on colleges in India", subText: [] }, { text: "Lifetime access to Knowledge Gateway ", subText: [] }, { text: "Lifetime access to pre-recorded videos", subText: [] }]

    },
    {
        packName: "Discover plus+",
        content: [{ text: "India’s most reliable psychometric assessment.", subText: ["Only Interest assessment at a Class 8-9 level", "Free upgrade to all assessments at a Class 10-11 level"] }, { text: "8 sessions (9 hours)", subText: ["One session every year from class 8/9 until you complete graduation", "Customisable based on discussion with counsellor - could be with student only, student and parents or an exclusive parent session included at some point during the 8 sessions"] }, { text: "Detailed action plan after each mentorship session on next steps, strengths, development areas and development plans + resolving EVERY career-related query", subText: [] }, { text: "Lifetime access to industry professional webinars ", subText: [] }, { text: "Exclusive invites to live webinars by career experts", subText: [] }, { text: "Access to Career Helpline ", subText: [] }, { text: "Support in resume building and internship applications (when you reach Class 12/College)", subText: [] }, { text: "Customised research document to identify skill development courses and foreign applications/admission criteria", subText: [] }, { text: "Lifetime access to Knowledge Gateway" }]

    },
    {
        packName: "Achieve Online",
        content: [{ text: "India’s most reliable psychometric assessment.", subText: [] }, { text: "1 session for 1.5 hours", subText: [] }, { text: "Information on relevant courses for chosen careers", subText: [] }, { text: "Information on colleges in India", subText: [] }, { text: "Lifetime access to Knowledge Gateway", subText: [] }, { text: "Lifetime access to webinars by career experts", subText: [] }]
    },
    {
        packName: "Achieve Plus+",
        content: [{ text: "India’s most reliable psychometric assessment.", subText: [] }, { text: "4 sessions across 5 hours ", subText: ["First - Immediately", "Second - 10 to 15 days after session 1", "Third - 6 to 8 months after session 2", "Fourth - 12 to 15 months after session 3 (This can be changed if you wish to avail all sessions within a month for foreign admissions or similar criteria)"] }, { text: "Detailed action plan after each mentorship session on next steps, strengths, development areas and development plans + resolving EVERY career-related query", subText: [] }, { text: "Lifetime access to pre-recorded videos", subText: [] }, { text: "Exclusive invites to webinars", subText: [] }, { text: "Career/Educational path query resolutions", subText: [] }, { text: "Support in resume building and internship applications", subText: [] }, { text: "Customised research document to identify skill development courses and foreign applications/admission criteria", subText: [] }, { text: "Motivation, emotional support and mentorship during career transitions", subText: [] }, { text: "Lifetime access to Knowledge Gateway", subText: [] }]
    },
    {
        packName: "Ascend Online",
        content: [{ text: "India’s most reliable psychometric assessment.", subText: [] }, { text: "1 session for 2 hours", subText: [] }, { text: "Information on relevant courses for chosen careers", subText: [] }, { text: "Information on colleges/online certifications in India", subText: [] }, { text: "Lifetime access to Knowledge Gateway", subText: [] }, { text: "Lifetime access to pre-recorded videos", subText: [] }]
    },

    {
        packName: "Ascend Plus+",
        content: [{ text: "India’s most reliable psychometric assessment.", subText: [] }, { text: "3 sessions  for 5 hours", subText: [] }, { text: "Detailed action plan after each mentorship session on next steps, strengths, development areas and development plans + resolving EVERY career-related query", subText: [] }, { text: "Lifetime access to pre-recorded videos", subText: [] }, { text: "Exclusive invites to webinars", subText: [] }, { text: "Career/Educational path query resolutions", subText: [] }, { text: "Support in resume building and internship applications", subText: [] }, { text: "Customised research document to identify skill development courses and foreign applications/admission criteria", subText: [] }, { text: "Motivation, emotional support and mentorship during career transitions", subText: [] }, { text: "Lifetime access to Knowledge Gateway", subText: [] }]
    }
]

