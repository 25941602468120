import { StorageService } from '../../common/storage-service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { PaymentService } from '../payment.service';
import swal from 'sweetalert2';
import { CommonService } from 'src/app/common/common.service';

@Component({
  selector: 'app-share-payment-link',
  templateUrl: './share-payment-link.component.html',
  styleUrls: ['./share-payment-link.component.css', '../payment.module.css']
})
export class SharePaymentLinkComponent implements OnInit {

  public loading = false;
  public package;
  public paymentModel = {
    'couponCode': '',
    'packageId': '',
    'studentId': ''
  };
  public paymentLinkId = ''
  public couponCode = '';
  public packageid;
  public student;
  public studentId = '';
  public showReedeemBtn = true;
  public ap = true;
  public ws = true;


  constructor(private router: Router, private paymentService: PaymentService, private route: ActivatedRoute, private localStorage: StorageService,public commonService:CommonService) {  
    this.route.params.forEach((params: Params) => {
      this.paymentLinkId = params['paymentLinkId'] || '';
    });
  }

  ngOnInit() {
    if (this.paymentLinkId != '') {
      this.getPaymentLink(this.paymentLinkId);
    }
  }

  getPaymentLink(paymentLinkId) {
    this.paymentService.getPaymentLink(paymentLinkId).subscribe(response => {
      this.studentId = response['studentUid'] || '';
      this.packageid = response['packageUid'] || '';
      this.couponCode = response['couponCode'] || '';
      this.GetSubscriptionPackage(this.packageid);
      this.getStudent(this.studentId);
      this.paymentModel.studentId = this.studentId;
    });
  }


  getStudent(studentUId) {
    this.paymentService.GetStudentByUid(studentUId).subscribe(response => {
      this.student = response;
    })
  }

  GetSubscriptionPackage(id) {
    this.loading = true;
    this.paymentService.GetPackagesById(id).subscribe(
      response => {
        this.package = response;
        console.log(this.package);
        this.paymentModel.packageId = this.packageid;
        if (this.package.actionPlans === 'na') {
          this.ap = false;
        }
        if (this.package.whatsAppSupport === 'na') {
          this.ws = false;
        }
        if (this.package.amount === 0) {
          this.showReedeemBtn = false;
        }
        this.loading = false;

        if (this.couponCode !== '') {
          this.ApplyCoupon();
        }
      },
      error => {
        
        this.loading = false;
      });
  }

  ApplyCoupon() {
    if (this.couponCode !== '') {
      this.paymentService.ApplyCoupon(this.couponCode, this.packageid).subscribe(
        response => {
          
          this.package.amount = response;
          this.paymentModel.couponCode = this.couponCode;
          swal({
            type: 'success',
            title: 'Coupon applied!',
            text: 'Click on BUY PACKAGE to proceed',
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText:
              'OK',
            // buttonsStyling: false,
            // confirmButtonClass: 'swal-btn',
            confirmButtonClass: 'submit-purple-swal-btn',
            buttonsStyling: true,
          });
        },
        error => {
          
          this.paymentModel.couponCode = '';
          // alert(error.Message);
          swal({
            type: 'error',
            title: 'Oops...',
            text: 'Invalid coupon code',
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText:
              'OK',
            // buttonsStyling: false,
            // confirmButtonClass: 'swal-btn',
            confirmButtonClass: 'submit-purple-swal-btn',
            cancelButtonClass: 'cancel-danger-swal-btn',
            buttonsStyling: true,
          });
        });
    } else {
      // alert('Please enter code');
      swal({
        type: 'warning',
        title: 'Please enter a code',
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
        confirmButtonText:
          'OK',
        // buttonsStyling: false,
        // confirmButtonClass: 'swal-btn',
        confirmButtonClass: 'submit-purple-swal-btn',
        buttonsStyling: true,
      });
    }
  }

  PaymentStart() {
    this.loading = true;
    this.paymentService.EncryptData(this.paymentModel).subscribe(
      response => {
        if (this.package.amount > 0) {
          this.router.navigate(['/payment/make-payment', response.encRequest]);
        } else {
         // this.router.navigate([this.commonService.getpreloginAppURL()]);
         let url=this.commonService.getpreloginAppURL()
         let newPath = url + '/redirect?authToken=' + this.localStorage.get('access_token') + '&redirectURl=' + encodeURI(url + '/login');
         window.open(newPath, "_self")
        }
      },
      error => {
        
        this.loading = false;
      });
  }

}
