export const marqueeData = [
    {
        image: "./../../../assets/images/corp logos/accenture.svg",
    },
    {
        image: "./../../../assets/images/corp logos/adityabirlacapital.svg",
    },
    {
        image: "./../../../assets/images/corp logos/adventz.svg",
    },
    {
        image: "./../../../assets/images/corp logos/akdn.svg",
    },
    {
        image: "./../../../assets/images/corp logos/alkem.svg",
    },
    {
        image: "./../../../assets/images/corp logos/axisbank.svg",
    },
    {
        image: "./../../../assets/images/corp logos/britannia.svg",
    },
    {
        image: "./../../../assets/images/corp logos/cumi.svg"
    },
    {
        image: "./../../../assets/images/corp logos/daimler.svg"
    },
    {
        image: "./../../../assets/images/corp logos/glenmark.svg"
    },
    {
        image: "./../../../assets/images/corp logos/hindustanpetroleum.svg"
    },
    {
        image: "./../../../assets/images/corp logos/itc.svg"
    },
    {
        image: "./../../../assets/images/corp logos/jewelex.svg"
    },
    {
        image: "./../../../assets/images/corp logos/jll.svg"
    },
    {
        image: "./../../../assets/images/corp logos/kotakmahindrabank.svg"
    },
    {
        image: "./../../../assets/images/corp logos/landt.svg"
    },
    {
        image: "./../../../assets/images/corp logos/mahindrrise.svg"
    },
    {
        image: "./../../../assets/images/corp logos/optum.svg"
    },
]
export const side_Content = [
    {
        h1: '#CareerReady Movement',
        image: "./../../../assets/images/true.png",
        para: [{ p: 'Trusted by over 2 lakh users, 100 corporates and 90 schools' }, { p: 'Customised guidance to fit your needs - multiple counselling sessions, guidance on studying abroad, CV reviews and more' },
        { p: "Lifetime access to Mentoria's Knowledge Gateway for all your career-related queries" }, { p: "VPlans starting at INR 999" }
        ]

    },
    {
        h1: "Enroll for Mentoria's Counsellor Certification Programme!",
        image: "./../../../assets/images/true.png",
        para: [{ p: "Get trained and certified by Mentoria's Master Counsellor." }, { p: "Join a talented tribe of 500+ educators, HR professionals, psychologists ." },
        { p: "Progress from counsellor to evaluator to trainer." }, { p: "Launch your career guidance setup using Mentoria’s assessment and resources at 0 infrastructure cost." }
        ]
    }
]