import { StorageService } from '../common/storage-service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { CommonService } from '../common/common.service';
import { retry, catchError } from 'rxjs/operators';

@Injectable()
export class HomeService {
    private apiUrl = '';
    public author = '';
    public authorNew = '';

    constructor(private http: HttpClient, private commonService: CommonService, private localStorageService: StorageService) {
        this.apiUrl = this.commonService.getApiUrl();
    }

    // Get Student Details
    GetStudentDetails():Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
            })
        };
        return this.http.get(this.apiUrl + '/api/Students/GetStudent',
            httpOptions).pipe(retry(3), catchError(this.handleError));
    }
    // get where to redirect student after login
    GetStudentPath(): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
            })
        };
        return this.http.get(this.apiUrl + '/api/Students/GetStudentPath',
            httpOptions).pipe(retry(3), catchError(this.handleError));
    }

    // Get counsellor details
    getCounsellorDetails():Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
            })
        };
        return this.http.get(this.apiUrl + '/api/Counsellors/GetCounsellor',
            httpOptions).pipe(retry(3), catchError(this.handleError));
    }

    GetHomepageBlogs(): Observable<any> {
        return this.http.get(this.apiUrl + '/api/Contents/GetHomepageBlogs').pipe(retry(3), catchError(this.handleError));
    }

    GetHomepageBlogsNew():Observable<any>{
        return this.http.get(this.apiUrl+'/api/Contents/GetHomepageBlogsNew').pipe(retry(3),catchError(this.handleError));
    }
  
    SubscribeToNewsLetter(email) {
      return this.http.get
        (this.apiUrl + '/api/Contents/SubscribeToNewsLetter?email=' + email).pipe(retry(3), catchError(this.handleError));
    }
    // private handleError(error: Response | any) {

    //     let errMsg: string;
    //     if (error instanceof Response) {
    //         const body = error.json() || '';
    //         const err = body.error || JSON.stringify(body);
    //         errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    //     } else {
    //         errMsg = error.message ? error.message : error.toString();
    //     }
    //     console.error(errMsg);
    //     return Observable.throw(errMsg);

    // }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    }
    /******************************************************************************************
    * getPreferenceForStudentId()
    * purpose - to get the preferece of user for three step popup
    ******************************************************************************************/
   getPreferenceForStudentId() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/api/Students/GetCareerSessionPreference',
      httpOptions).pipe(retry(3), catchError(this.handleError));;
  }

}
