export const kolkataData =
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Why should I take a career counselling in Kolkata?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "If you`re located in Kolkata, our counsellors can provide you with recommendations on colleges, courses and ways to pursue careers around your locality"
    }
  }, {
    "@type": "Question",
    "name": "Who are the career counsellors working for Mentoria from Kolkata?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Our counsellors team comprises professionals from backgrounds such as counselling, training, psychology, academic, teaching, coaching, special education, etc. They join the Mentoria team only after undergoing a rigorous training and certification process."
    }
  }, {
    "@type": "Question",
    "name": "Do you have a physical counselling centre in Kolkata?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "As of now, we’re only providing career counselling virtually."
    }
  }, {
    "@type": "Question",
    "name": "What happens after the career test?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Once you complete the test, you can go on to book a counselling session. During your counselling session, your career counsellor will help you understand your report, discover yourself and the ideal career paths best suited for you. After your counselling session is complete, you can log in to your Mentoria account, accept your final three careers, share your feedback on the session, and access the world of career content awaiting you."
    }
  }, {
    "@type": "Question",
    "name": "What happens after I complete a career counselling session?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "After you’ve completed your career counselling session, you can get more information about the recommended career options from our Knowledge Gateway. This consists of blogs, career guides and webinars. This information will help you understand your chosen careers in detail."
    }
  }]
}


export const delhiData = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Importance of Career Counselling in Delhi?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Through Mentoria’s career counselling, you can get extensive insights into your behaviour, personality, and interests which helps you get clarity about your career and enables you to set realistic goals."
    }
  }, {
    "@type": "Question",
    "name": "Which is the Best Career Counsellor in Delhi?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Mentoria provides you with a holistic approach to guide you throughout your career journey. Our counsellors refer to your career test results that consist of key information about your interest and personality to give you the career advice and guidance you need for your career journey."
    }
  }, {
    "@type": "Question",
    "name": "How will Mentoria help you find your Ideal Career?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Our career discovery platform uses a four-step process that includes behavioural and psychological analysis along with expert guidance and knowledge. The process starts with a career test that assesses your strengths and abilities. After you've finished the test, you can schedule a counselling session. During your career counselling session, our career counsellors will assist you in understanding your report, discovering your interests and passion, and determining the best career possibilities for you. You can log in to your Mentoria account after your counselling session is finished to learn about the suggested careers, and explore the career resources that await you."
    }
  }, {
    "@type": "Question",
    "name": "Does the career counselling service come with a cost?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "If you are in class 9 or below, you can opt for the Discover Online package for INR 5,500 or the Discover Plus+ for INR 15,000. If you are in class 10 or above, you can opt for the online counselling package for Achieve Online INR 4,999 or Achieve Plus+ INR 10,599. College graduates and working professionals can subscribe to the Ascend plan for INR 5,999 and Ascend Plus+ for INR 10,599, which includes varied services."
    }
  }, {
    "@type": "Question",
    "name": "Who is eligible for Career Counselling in Delhi?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Students when reaching Class 8 start preparing for the exams that will take place in Class 10. This is the time when you start thinking about the future career paths that you will take. In addition, even if you are a working professional and looking to change your expertise, the Mentoria holistic approach helps you do it in a subtle manner. Career guidance or counselling can help you arrive at the right career options for you based on your interests and goals."
    }
  }]
}
export const gurgaonData = {}
export const mumbaiData = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Why should I seek career counselling in Mumbai?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "If you’re located in Mumbai, our counsellors can provide you with recommendations on colleges, courses, and ways to pursue careers around your locality."
    }
  }, {
    "@type": "Question",
    "name": "Which are the best career counsellors in Mumbai?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Mentoria’s counsellor team consists of some of the top career counsellors from across the country. Some of our career counsellors also carry extensive career experience which enables them to offer relevant career advice and direction."
    }
  }, {
    "@type": "Question",
    "name": "Why choose Mentoria as a career counselling platform in Mumbai?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Mentoria provides you with scientifically-backed career guidance by using a simple four-step career guidance programme. This programme consists of a career test which is designed by the top psychologists, a career counselling session with seasoned counsellors, knowledge gathering, and webinars of different career professionals. Also, our career counsellors in Mumbai carry extensive experience in counselling, training, psychology, academics, teaching, coaching, special education, and other fields. By signing up on Mentoria, you can get a lot more than just counselling. So if you’re looking for a complete career guidance solution, Mentoria is the right place for you."
    }
  }, {
    "@type": "Question",
    "name": "Who are the career counsellors that will be counselling me?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Our counsellors are a mixed bag of professionals from backgrounds such as counselling, training, psychology, academic, teaching, coaching, special education, etc. They join the Mentoria team only after undergoing a rigorous training and certification process."
    }
  }, {
    "@type": "Question",
    "name": "How is Career counselling done in Mumbai?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Mentoria through its four-step process that helps you in discovering your passion as well as potential vocations. Our career test includes questions that look into your personality and interests. It was developed by qualified psychologists and psychometricians and has an accuracy rate of 85 percent. Our professional counsellors provide career recommendations based on your career test, guiding you toward jobs that you will love and thrive at. After getting understanding your career options, you can read our career guides and blogs to learn more about the jobs you're interested in and what they entail. To learn more about what you may expect from your chosen career, attend webinars hosted by industry experts."
    }
  }, {
    "@type": "Question",
    "name": "What are the Career test charges?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "If you are in class 9 or below, you can opt for the Discover Online package for INR 3,999 or the Discover Plus+ for INR 15,000. If you are in class 10 or above, you can opt for the online counselling package for Achieve Online INR 4,999 or Achieve Plus+ INR 10,599. College graduates and working professionals can subscribe to the Ascend plan for INR 5,999 and Ascend Plus+ for INR 10,599, which includes varied services."
    }
  }]
}

export const puneData = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Why do you need Career counselling in Pune?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "With Mentoria’s career counselling you can get extensive insights into your behaviour, personality, and interests with our career counselling. Our career counsellors also help you get clarity about your career and set realistic goals."
    }
  }, {
    "@type": "Question",
    "name": "Who are the Best Education Counsellors in Pune?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "With a comprehensive approach, Mentoria tops the list of career consulting. Our career counselors can help you identify the ideal career path for you. They come from diverse backgrounds and have undergone the necessary training and certification to provide the best advice on how to approach your career."
    }
  }, {
    "@type": "Question",
    "name": "What makes Mentoria the Best Career Counsellor in Pune?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Mentoria uses an insight-based approach to guide you throughout your career journey. Our four-step guidance solution ensures you get clarity about your career paths. Also, our certified career counsellors are from diverse backgrounds which enables them to provide relevant guidance on different career options."
    }
  }, {
    "@type": "Question",
    "name": "What happens in the career counselling process?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Career counselling with Mentoria is easy. You register yourself on the website and start off your journey by taking the career test. After which the report is shared with you to discuss your ability and the top three career options. Followed by career counselling sessions to explain the stream and courses that will help you reach your goal. You can then refer to the career guides to see which career suits your preferences and watch webinars to learn more about that particular career."
    }
  }, {
    "@type": "Question",
    "name": "Am I eligible for Career Counselling even if I am not in Pune?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Students when they reach Class 8 start preparing for the exams that will take place in Class 10. This is the time when you start thinking about the future career paths that you will take. In addition, even if you are a working professional and looking to change your expertise, the Mentoria holistic approach helps you do it subtly. Career guidance or counselling can help you arrive at the right career options for you based on your interests and goals."
    }
  }]
}
export const nagpurData = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Why is Career Counselling important in Nagpur?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "With Mentoria’s career counselling you can get extensive insights into your behaviour, personality, and interests with our career counselling. Our career counsellors also help you get clarity about your career and set realistic goals."
    }
  }, {
    "@type": "Question",
    "name": "What is the Career Counselling process in Nagpur?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Once you register at Mentoria for career counselling, our career counsellors use an insight-based approach that includes behavioural and psychological testing. To begin, a professional assessment of your skills and abilities is required which is why your journey at Mentoria starts with a career test. After you've completed the test, you can schedule a counselling session. During your career counselling session, your professional counsellor will assist you in comprehending your report, defining yourself, and choosing the best career alternatives for you. Log in to your Mentoria account after your counselling session to see what your career options are and explore the universe of career resources that awaits you."
    }
  }, {
    "@type": "Question",
    "name": "What makes Mentoria as unique Career Counsellors in Nagpur?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Mentoria’s career test measures your key interests areas, personality traits, and abilities to help you figure out which jobs are suitable for you. The results of your test are then generated by our AI engine and shared with you during your career counselling appointment with one of our qualified career counsellors. The career counsellors will then provide you with advice on best career paths that you can choose for a happier life."
    }
  }, {
    "@type": "Question",
    "name": "How much career counseling will cost?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "If you are in class 9 or below, you can opt for the Discover Online package for INR 5,500 or the Discover Plus+ for INR 15,000. If you are in class 10 or above, you can opt for the online counselling package for Achieve Online INR 4,999 or Achieve Plus+ INR 10,599. College graduates and working professionals can subscribe to the Ascend plan for INR 5,999 and Ascend Plus+ for INR 10,599, which includes varied services."
    }
  }, {
    "@type": "Question",
    "name": "Who is eligible for Career Counselling in Nagpur?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Students when they reach Class 8 start preparing for the exams that will take place in Class 10. This is the time when you start thinking about the future career paths that you will take. In addition, even if you are a working professional and looking to change your expertise, the Mentoria holistic approach helps you do it subtly. Career guidance or counselling can help you arrive at the right career options for you based on your interests and goals."
    }
  }]
}

export const bangaloreData = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Which are the best Career counsellors in Bangalore?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Mentoria’s expert team consists of some of the top career counsellors from across the country. Some of our career counsellors also carry extensive career experience which enables them to offer relevant career advice and direction."
    }
  }, {
    "@type": "Question",
    "name": "Why is Career counselling in Bangalore important?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "With Mentoria’s career counselling you can get extensive insights into your behaviour, personality, and interests with our career counselling. Our career counsellors also help you get clarity about your career and set realistic goals."
    }
  }, {
    "@type": "Question",
    "name": "Who can opt for a career counselling service in Bangalore?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Students when they reach Class 8 start preparing for the exams that will take place in Class 10. This is the time when you start thinking about the future career paths that you will take. Career guidance or counselling can help you arrive at the right career options for you based on your interests and goals."
    }
  }, {
    "@type": "Question",
    "name": "What is the process to avail career counselling services by Mentoria?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Career counselling with Mentoria is easy. You register yourself for the career test. After which the report is shared with you and your parents to discuss your ability and the top three career options. Followed by counselling sessions to explain the stream and courses that will help you reach your goals and provide you with real life work experience."
    }
  }, {
    "@type": "Question",
    "name": "What happens after I take the career test?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Once you complete the test, you can go on to book a counselling session. During your counselling session, your career counsellor will help you understand your report, discover yourself and the ideal career paths best suited for you. After your counselling session is complete, you can log in to your Mentoria account, accept your final three careers, and access the world of career content awaiting you."
    }
  }]
}
export const jaipurData = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Importance of career counselling in Jaipur",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Through career counselling you can get extensive insights into your behaviour, personality, and interests with our career counselling. Mentoria’s career counsellors help you get clarity about your career and set realistic goals."
    }
  }, {
    "@type": "Question",
    "name": "Who are the best career counsellors in Jaipur?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Our counsellors are a mixed bag of professionals from backgrounds such as counselling, training, psychology, academic, teaching, coaching, special education, etc. They join the Mentoria team only after undergoing a rigorous training and certification process."
    }
  }, {
    "@type": "Question",
    "name": "Why choose Mentoria’s career counselling platform in Jaipur?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Mentoria provides you with scientifically-backed career guidance by using a simple four-step career guidance programme. This programme consists of a career test which is designed by the top psychologists, a career counselling session with seasoned counsellors, knowledge gathering, and webinars of different career professionals.Also, our career counsellors in Jaipur carry extensive experience in counselling, training, psychology, academics, teaching, coaching, special education, and other fields. By signing up on Mentoria, you can get a lot more than just counselling. So if you’re looking for a complete career guidance solution, Mentoria is the right place for you."
    }
  }, {
    "@type": "Question",
    "name": "Who are the career consultants in Jaipur working with Mentoria?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Mentoria’s Career counsellors come from a variety of fields that includes counselling, training, psychology, academics, teaching, coaching, special education, and so on. Only after completing a rigorous training and certification process do they join the Mentoria team."
    }
  }, {
    "@type": "Question",
    "name": "What if I have some doubts after the counselling session?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "After the career counselling session, you can get more information about the recommended career options from our Knowledge Gateway. This consists of blogs, career guides and webinars. This information will help you understand your chosen careers in detail."
    }
  }]
}
export const chandigarhData = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Which is the best Career counselling service in Chandigarh?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Mentoria uses a holistic approach to guide you throughout your career journey. If you’re located in Chandigarh, our counsellors can provide you with recommendations on colleges, courses, and ways to pursue careers around your locality."
    }
  }, {
    "@type": "Question",
    "name": "Importance of Career Counselling in Chandigarh",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Our career coaching can provide you with extensive information on your behaviour, personality, and interests. Mentoria's career experts assist you in gaining clarity about your career path and setting achievable goals."
    }
  }, {
    "@type": "Question",
    "name": "Why should I choose Mentoria for Career Counselling in Chandigarh?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Mentoria takes a comprehensive approach that combines a four-step procedure that involves behavioural and psychological testing to guide you through your career journey. To begin, you must take a career test to determine your skills and abilities. You can schedule a counselling session after you've completed the test. The career counsellor will help you in understanding your report, identifying your interests and passions, and recommending the best career options for you during your career counselling session. After your counselling session, you can use the career resources for more guidance."
    }
  }, {
    "@type": "Question",
    "name": "Who are the Career Counsellors in Chandigarh who will guide me?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Our counsellors are a mixed bag of professionals from backgrounds such as counselling, training, psychology, academic, teaching, coaching, special education, etc. They join the Mentoria team only after undergoing a rigorous training and certification process."
    }
  }, {
    "@type": "Question",
    "name": "Who is eligible for a career counselling session with Mentoria?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Students when they reach Class 8 start preparing for the exams that will take place in Class 10. This is the time when you start thinking about the future career paths that you will take. In addition, even if you are a working professional and looking to change your expertise, the Mentoria holistic approach helps you do it subtly. Career guidance or counselling can help you arrive at the right career options for you based on your interests and goals."
    }
  }]
}
export const indoreData = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Why Should I take career counseling in Indore?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Building a career is an important aspect of one's life. Through career counselling you can get extensive insights into your behaviour, personality, and interests with our career counselling. Mentoria’s career counsellors help you get clarity about your career and set realistic goals."
    }
  }, {
    "@type": "Question",
    "name": "How to identify Best Career Counselling in Indore and Bhopal?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Mentoria provides you with a holistic approach to guide you throughout your career journey. Our counsellors refer to your career test results that consist of key information about your interest and personality to give you the career advice and guidance you need for your career journey"
    }
  }, {
    "@type": "Question",
    "name": "What makes Mentoria the Best Career Counsellors in Indore?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Mentoria uses an insight-based approach to guide you throughout your career journey. Our certified career counsellors are from diverse backgrounds. Along with them, our four-step process is scientifically proven to examine your strength and abilities. Mentoria’s career counsellor will go over the results of your career test, analyze the report's findings and recommend the top three career choices that you would love and thrive in."
    }
  }, {
    "@type": "Question",
    "name": "What happens in the career counselling process?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Once you register on Mentoria’s career discovery platform and complete the test, our career counsellors analyse your test results and provide you with the guidance you need. To begin, a professional assessment of your skills and abilities is required which is done through our career test. After you've completed the test, you can schedule a counselling session. During your career counselling session, your professional counsellor will assist you in comprehending your report, defining your interests and abilities, and suggesting the best career alternatives for you. Log in to your Mentoria account after your counselling session to accept your final three careers and browse through the career resources for better clarity."
    }
  }, {
    "@type": "Question",
    "name": "Who can opt for career counselling in Indore?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "After students enter the eighth grade, they begin studying for the tests that will take place in the tenth grade. This is the period when you should start thinking about your future career options. Furthermore, if you are a working professional who wants to shift your speciality, the Mentoria holistic approach can help you do so in a gentle way. Job counselling or guidance can assist you in determining the best career possibilities for you based on your interests and aspirations."
    }
  }]
}
export const bhopalData = {

}
export const chennaiData = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Who needs career counselling in Chennai?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "When students enter the eighth grade, they begin studying for the tenth-grade tests. This is the time to start considering your professional alternatives in the future. Furthermore, if you are a working professional looking to change specialities, the Mentoria holistic approach can assist you in doing so in a delicate manner. Job counselling or assistance can help you figure out what job options are ideal for you based on your interests and goals."
    }
  }, {
    "@type": "Question",
    "name": "Why is Educational Counselling important for your career?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Career counselling not only helps you develop a fantastic personality, but it also uses many current approaches to help you become more organised and a better person. It helps and guides you as you get more acquainted with the chosen career and the path that leads towards it."
    }
  }, {
    "@type": "Question",
    "name": "What makes Mentoria the Best Career Counsellors in Chennai?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Mentoria’s career coaching can give you a lot of insight into your personality, behaviour, and interests. Our career counsellors can help you figure out what you want to do with your life and set attainable goals. Mentoria's career counsellor will go through the results of your career test with you, provide real-life examples to back up the report's findings, and suggest career options that you would enjoy and succeed in."
    }
  }, {
    "@type": "Question",
    "name": "Who are the Career Counsellors in Chennai that will guide me?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Our counsellors team has professionals from backgrounds such as counselling, training, psychology, academic, teaching, coaching, special education, etc. They join the Mentoria team only after undergoing a rigorous training and certification process."
    }
  }, {
    "@type": "Question",
    "name": "What is the cost of Career counselling in Chennai?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "You can choose between the Discover Online package for INR 3,999 and the Discover Plus+ package for INR 15,000 if you are in class 9 or below. If you are in class 10 or higher, you can choose from the Achieve Online INR 4,999 or Achieve Plus+ INR 10,599 online counselling packages. For INR 5,999 and INR 10,599, college graduates and working professionals can subscribe to the Ascend plan, which covers a variety of services."
    }
  }]
}
export const vadodaraData = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Why seek career counselling in Vadodara?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "When seeking education or making career selections in today's environment, it's essential to broaden your horizons. It assists you in making better selections about which stream or career to pursue. If you live in Vadodara, our counsellors can recommend institutions, courses, and ways to seek professions in your area."
    }
  }, {
    "@type": "Question",
    "name": "Who offers best Career counselling in Vadodara?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Mentoria’s team of career counsellors comprises some of the finest counsellors from across the country who carry extensive expertise in the field of career guidance. In addition, our career counsellors refer to the key insights derived from your career test and suggest options based on the key interest areas highlighted in it."
    }
  }, {
    "@type": "Question",
    "name": "Why is Career Counselling important?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Career counselling assists you in making career decisions about how and what to study. Those who seek career counselling become aware about their career orientation, strengths, and abilities better and at an early stage. It helps you become more mature in your decision-making. It brings out your personal interest to pursue your career and guides in that specific direction"
    }
  }, {
    "@type": "Question",
    "name": "What is the process of career counselling by Mentoria in Vadodara?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Our career counsellors in Vadodara handhold you throughout your career journey. In addition, they are a diverse group of individuals with experiences in counselling, training, psychology, academics, teaching, coaching, special education, and other fields. As an outsider, the four-step holistic method shows you the various career pathways and provides you with updates on each one. Following the examination, the career counsellors highlight what you can do next and advise you accordingly."
    }
  }, {
    "@type": "Question",
    "name": "What happens after I complete a career counseling session?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Soon after completing your career counselling session, you gain knowledge about the top three careers that suit your preference and will be reliable in the future. Career counsellors help you understand the direction that leads to your successful career. Followed by webinars by experts and professionals that showcase and talk about what their day looks like to help you understand the profession’s responsibilities in the best possible way."
    }
  }]
}
export const suratData = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Why seek career counselling in Surat?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "In the present world, career counselling plays an important role in widening your horizon if you are seeking knowledge or making career decisions. It helps you make better decisions in selecting the right stream or career. If you’re located in Surat, our counsellors can provide you with recommendations on colleges, courses, and ways to pursue careers around your locality."
    }
  }, {
    "@type": "Question",
    "name": "Who offers best Career counselling in Surat?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Mentoria offers the best holistic career counselling in Surat. With our 4-step guidance solution, we help you get clarity about your interests and passion which helps you in choosing the career that leads to a happier life. Moreover, our team comprises some of the best career counsellors from diverse backgrounds that provide you with relevant guidance to help you get the best career counselling experience in Surat."
    }
  }, {
    "@type": "Question",
    "name": "Why is Career Counselling important?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Career counselling assists you in making career decisions about how and what to study. It helps you discover your career orientation, strengths, and abilities so that you make informed decisions about your career and choose your way to a happier life."
    }
  }, {
    "@type": "Question",
    "name": "What is the process of career counselling by Mentoria in Surat?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Our career counsellors in Surat handhold you throughout your career journey. In addition, they are a diverse group of individuals with experiences in counselling, training, psychology, academics, teaching, coaching, special education, and other fields. Our four-step holistic method shows you the various career pathways and provides you with updates on each one. Following the examination, the career counsellors highlight what you can do next and advise you accordingly."
    }
  }, {
    "@type": "Question",
    "name": "What happens after I complete a career counseling session?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Soon after completing your career counselling session, you gain knowledge about the top three careers that suit your preference and will be reliable in the future. Career counsellors help you understand the direction that leads to your successful career. Followed by webinars by experts and professionals that showcase and talk about what their day looks like to help you understand the profession’s responsibilities in the best possible way."
    }
  }]
}
export const ahmedabadData =

{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Why seek career counselling in Ahmedabad?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "If you’re located in Ahmedabad, our counsellors can provide you with recommendations on colleges, courses, and ways to pursue careers around your locality."
    }
  }, {
    "@type": "Question",
    "name": "Who offers best Career counselling in Ahmedabad?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Mentoria offers insight-based career counselling in Ahmedabad that helps you make better career decisions. Our certified career counsellors are from diverse backgrounds and have years of expertise on providing career guidance. With our insight-based process and expert career guidance Mentoria ensures you get the best career counselling experience in Ahmedabad."
    }
  }, {
    "@type": "Question",
    "name": "Why is Career Counselling important?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Mentoria’s career counselling helps you in making career decisions on which career path to choose by analyzing your interests and abilities through a scientific process. It is a great way to learn about your interests, strengths, and abilities. Career counselling helps you become more mature in your decision-making. It brings out your personal interest to pursue your career and guides you in that specific direction."
    }
  }, {
    "@type": "Question",
    "name": "What is the process of career counselling by Mentoria in Ahmedabad?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Career counselling with Mentoria is easy. You register yourself for the career test. After which the report is shared with you and your parents to discuss your ability and the top three career options. Followed by counselling sessions to explain the stream and courses that will help you reach your goals and provide you with real life work experience."
    }
  }, {
    "@type": "Question",
    "name": "What happens after I complete a career counseling session?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Soon after completing your career counselling session, you gain knowledge about the top three careers that suit your preference and will be reliable in the future. Career counsellors help you understand the direction that leads to your successful career. Followed by webinars by experts and professionals that showcase and talk about what their day looks like to help you understand the profession’s responsibilities in the best possible way."
    }
  }]



}
export const hyderabadData =
{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Importance of Career Counselling in Hyderabad?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Through Mentoria’s career counselling, you can get extensive insights into your behaviour, personality, and interests which helps you get clarity about your career and enables you to set realistic goals."
    }
  }, {
    "@type": "Question",
    "name": "Which is the Best Career Counsellor in Hyderabad?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Mentoria provides you with a holistic approach to guide you throughout your career journey. Our counsellors refer to your career test results that consist of key information about your interest and personality to give you the career advice and guidance you need for your career journey."
    }
  }, {
    "@type": "Question",
    "name": "How will Mentoria help you find your Ideal Career?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Our career discovery platform uses a four-step process that includes behavioural and psychological analysis along with expert guidance and knowledge. The process starts with a career test that assesses your strengths and abilities. After you've finished the test, you can schedule a counselling session. During your career counselling session, our career counsellors will assist you in understanding your report, discovering your interests and passion, and determining the best career possibilities for you. You can log in to your Mentoria account after your counselling session is finished to learn about the suggested careers, and explore the career resources that await you."
    }
  }, {
    "@type": "Question",
    "name": "Does the career counselling service come with a cost?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "If you are in class 9 or below, you can opt for the Discover Online package for INR 3,999 or the Discover Plus+ for INR 15,000. If you are in class 10 or above, you can opt for the online counselling package for Achieve Online INR 5,999 or Achieve Plus+ INR 10,599. College graduates and working professionals can subscribe to the Ascend plan for INR 6,499 and Ascend Plus+ for INR 10,599, which includes varied services."
    }
  }, {
    "@type": "Question",
    "name": "Who is eligible for Career Counselling in Hyderabad?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Students when reaching Class 8 start preparing for the exams that will take place in Class 10. This is the time when you start thinking about the future career paths that you will take. In addition, even if you are a working professional and looking to change your expertise, the Mentoria holistic approach helps you do it in a subtle manner. Career guidance or counselling can help you arrive at the right career options for you based on your interests and goals."
    }
  }]
}
