import { Component, OnInit, Inject, PLATFORM_ID, ViewChild } from '@angular/core';
import { AccountsService } from '../accounts.service';
import { Router, ActivatedRoute } from '@angular/router';
import { StorageService } from '../../common/storage-service';
import { CommonService } from '../../common/common.service';
import { isPlatformBrowser } from '@angular/common';
import { AppService } from '../../app.service';
import swal from 'sweetalert2';
import { Meta, Title } from "@angular/platform-browser";
import { CountdownComponent } from 'ngx-countdown';
import { ScriptService } from '../../common/script.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { countryCodes } from '../../../app/shared/country-code.json';




@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.css']
})
export class GetStartedComponent implements OnInit {

  @ViewChild('countdown') counter: CountdownComponent;

  public mobileNumber = "";
  public otpValue;
  public password = "";
  public showWait = false;
  public regMobResponse: any;
  public verifyMobResponse;
  public resendOtpRespnse;
  public showOtpDivision = false;
  public showPassDivision = false;
  public resendOtpMessage = "";
  public resendOTPDisabled = true;
  public studentCareerPathArray;
  public showErrorMessage: boolean;
  public loading: boolean;
  platformId: string;
  public showEnterbutton = true;
  public errorMessage = "";

  public redirectURl = '';
  public i = 1;
  loading1 = 0
  isemailPresent
  public isBrowser: boolean;
  public countries: any = [{ id: 1, name: 'India' }, { id: 2, name: 'UK' }, { id: 3, name: 'USA' }, { id: 4, name: 'Rasia' }, { id: 5, name: 'China' }, { id: 6, name: 'Japan' }]
  selectedCountryCode: any = '+91'
  public emailId = ""
  public showOtpField = 0
  public countryCodeJson = countryCodes;
  public disableResendOTP = false
  public NRIForm = new FormGroup({
    emailIdr: new FormControl(''),
    phoner: new FormControl(''),
    OTPr: new FormControl(''),


  });


  constructor(private accountService: AccountsService, private router: Router, private route: ActivatedRoute, private localStorageService: StorageService, private commonService: CommonService, @Inject(PLATFORM_ID) platformId: string, private appService: AppService, private titleService: Title, public scriptService: ScriptService,
    private metaService: Meta, private fb: FormBuilder) {
    this.platformId = platformId;
    this.isBrowser = isPlatformBrowser(platformId);

    this.route.queryParams.subscribe(params => {
      // Defaults to 0 if no query param provided. || 0
      this.redirectURl = params["redirectURl"] || "";
    });

    this.titleService.setTitle("Register - Mentoria");

    let attributeSelector: string = 'name="description"';
    this.metaService.removeTag(attributeSelector);

    this.metaService.updateTag(
      {
        name: "description",
        content:
          "Start your Online Career Counselling Journey with Mentoria by Registering your Mobile Number with Us so that You get a Clear Perspective of your Career "
      });
    this.NRIForm = this.fb.group({
      emailIdr: ['', [Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$")]],

      phoner: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]+')]],
      OTPr: [''],

    })
  }

  ngOnInit() {
    this.loading = false;
    this.showErrorMessage = false;
    this.mobileNumber = this.localStorageService.get('mobile');
    if (this.mobileNumber !== '' && this.mobileNumber !== null) {
      this.registerMobile();
    }



    if (this.isBrowser) {
      this.scriptService.loadScriptWithId('https://mentoriastorage.blob.core.windows.net/website-js/hotjar-code.js', 'hotjar', 'javascript');
    }
  }


  mobileValidate(e) {
    if (e) {
      // var charCode = e.charCode ? e.charCode : e.keyCode;
      var charCode = e.which;
    }
    else {
      return false;
    }
    if ((charCode > 47 && charCode < 58 || charCode === 8)) {
      return true;
    }
    else {
      e.preventDefault();
      return false;
    }
  }

  registerMobile() {
    this.showWait = true;
    this.accountService.registerMobile(this.mobileNumber).subscribe(
      res => {
        this.regMobResponse = res;
        var message = this.regMobResponse.message;
        if (message === 'success') {
          this.showOtpDivision = true;
          this.showPassDivision = false;
          this.showEnterbutton = false;
        } else if (message === 'already registered') {
          this.showPassDivision = true;
          this.showOtpDivision = false;
          this.showEnterbutton = false;
        } else if (message === 'failed') {
          this.showPassDivision = false;
          this.showOtpDivision = false;
          this.showEnterbutton = true;
        }
        this.showWait = false;
        setTimeout(() => {
          this.resendOTPDisabled = false;
        }, 30000);
      },
      error => {
        this.showWait = false;
      });
  }

  resendOTP() {
    this.resendOTPDisabled = true;
    this.resendOtpMessage = "";
    this.errorMessage = "";
    this.accountService.resendOTP(this.mobileNumber).subscribe(
      res => {
        this.resendOtpRespnse = res;
        var msg = this.resendOtpRespnse.message;
        if (msg === "success") {
          this.resendOtpMessage = "OTP sent successfully on your mobile number"
        } else if (msg === "failed") {
          this.resendOtpMessage = "Unable to send OTP please try after some time";
        }
        this.counter.restart();
        setTimeout(() => {
          this.resendOTPDisabled = false;
        }, 30000);
      },
      error => {
        this.resendOtpMessage = "";
        
        setTimeout(() => {
          this.resendOTPDisabled = false;
        }, 30000);
      });
  }

  verifyMobile() {
    this.resendOtpMessage = "";
    this.errorMessage = "";
    this.accountService.verifyMobile(this.mobileNumber, this.otpValue).subscribe(
      res => {
        this.verifyMobResponse = res;
        var msg = this.verifyMobResponse.message;
        if (msg === 'success') {
          this.localStorageService.set('mobile', this.mobileNumber);
          this.router.navigate(['/accounts/lead-form']);
        } else if (msg === 'failed') {
          this.errorMessage = "Invalid OTP!";
        }
      },
      error => {
        
      });
  }

  public login(mobile, password) {
    this.loading = true;
    this.accountService.login(mobile, password)
      .subscribe(
        response => {
          this.loading = false;
          this.localStorageService.set('access_token', response['access_token']);
          this.accountService.getAccountType().subscribe(
            accountType => {
              this.localStorageService.set('account_type', accountType);
              this.commonService.login();
              // this.appService.subscribeToNotification('get started');
              if (accountType === 'student') {
                //get student object and save in common service
                // this.commonService.getStudent();
                this.accountService.getStudentDetails().subscribe(studentVar => {
                  this.studentCareerPathArray = studentVar['selectedCareerPaths'];

                  if (!this.studentCareerPathArray) {
                    // this.commonService.careerArr = false;
                    this.localStorageService.set('career_arr', this.studentCareerPathArray.length);
                  } else {
                    this.localStorageService.set('career_arr', this.studentCareerPathArray.length);
                  }
                }, err => {
                  swal('Some error occured, please login again.');
                });

                if (this.redirectURl === '') {
                  this.accountService.GetStudentPath().subscribe(path => {
                    console.log(path);
                    this.router.navigate([path]);
                  }, err => {
                    swal('Some error occured, please login again.');
                  });
                } else {
                  // this.router.navigate([this.redirectURl]);
                  if (isPlatformBrowser(this.platformId)) {
                    window.location.href = this.redirectURl;
                  }
                }
              } else if (accountType === 'counsellor') {
                if (this.redirectURl === '') {
                  this.accountService.GetCounsellorPath().subscribe(path => {
                    console.log(path);
                    this.router.navigate([path]);
                  }, err => {
                    swal('Some error occured, please login again.');
                  });
                } else {
                  if (isPlatformBrowser(this.platformId)) {
                    window.location.href = this.redirectURl;
                  }
                }
              } else {
                swal("Invalid User");
              }
            },
            err => {
              console.log(err);
            });
        },
        error => {
          this.loading = false;
          this.showErrorMessage = true;
          
        });
  }


  public signUpWithGoogle() {
    const clientId = encodeURI('Mentoria.elp');
    const scope = encodeURI('Mentoriaapi');
    const redirectUri = encodeURI(this.commonService.getAppUrl() + '/accounts/post-login');
    const responseType = encodeURI('token');
    const acrValues = encodeURI('idp:Google');
    const action = encodeURI('register');
    const url = this.commonService.getStsUrl() + '/core/connect/authorize?client_id=' + clientId + '&scope=' + scope + '&redirect_uri=' + redirectUri + '&response_type=' + responseType + '&acr_values=' + acrValues + '&action=' + action;
    if (isPlatformBrowser(this.platformId)) {
      window.location.href = url;
    }
  }

  public signUpWithFacebook() {
    const clientId = encodeURI('Mentoria.elp');
    const scope = encodeURI('Mentoriaapi');
    const redirectUri = encodeURI(this.commonService.getAppUrl() + '/accounts/post-login');
    const responseType = encodeURI('token');
    const acrValues = encodeURI('idp:Facebook');
    const action = encodeURI('register');
    const url = this.commonService.getStsUrl() + '/core/connect/authorize?client_id=' + clientId + '&scope=' + scope + '&redirect_uri=' + redirectUri + '&response_type=' + responseType + '&acr_values=' + acrValues + '&action=' + action;
    if (isPlatformBrowser(this.platformId)) {
      window.location.href = url;
    }
  }


  selectedCountry(c) {
    debugger
    console.log("your country is:", c)
  }



  NRIFormMethod() {
    this.resendOtp = 0;
    this.i = 0;

    let obj = {
      mobile: this.NRIForm.value.phoner,
      emailId: this.NRIForm.value.emailIdr,
      countryCode: this.selectedCountryCode

    }
    this.loading1 = 1

    if (this.selectedCountryCode != '+91') {
      this.accountService.CheckEmailExists(obj.emailId)
        .subscribe(
          response => {
            this.showWait = false;
            if (response['Message'] === 'True') {
              this.isemailPresent = true;
              this.loading1 = 0;
              alert("Email already Exists")
            }
            else {
              this.isemailPresent = false;
              this.callOtpApi()

              this.loading1 = 0;

              this.NRIForm = this.fb.group({
                emailIdr: [this.NRIForm.value.emailIdr, [Validators.required, Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$")]],
                phoner: [this.NRIForm.value.phoner, [Validators.required, Validators.minLength(8), Validators.maxLength(14), Validators.pattern("[0-9]+")]],
                OTPr: ['']

              })
            }


          },
          error => {
            
            this.showWait = false;
          });
    }
    else {
      this.callOtpApi()
    }


  }
  showTimer
  callOtpApi() {
    this.disableResendOTP = true

    this.resendOtp = 0
    let obj = {
      mobile: this.NRIForm.value.phoner,
      emailId: this.NRIForm.value.emailIdr,
      countryCode: this.selectedCountryCode

    }
    this.accountService.getOTPOnEamilOrPhone(obj).subscribe(data => {
      let msgobj: any = data;

      let otpSentmsg: any = msgobj.message;
      if (otpSentmsg == 'already registered') {
        alert("Already registered User")
        this.loading1 = 0;
        this.showOtpField = 0
      }
      else {
        if (this.selectedCountryCode === '+91') {
          alert("OTP Sent On Phone SuccessFully");
        } else {
          alert("OTP Sent On Email SuccessFully");
        }
        setTimeout(() => {
          this.disableResendOTP = false
          this.showTimer = false

        }, 30000)
        this.showOtpField = 1;
        this.showTimer = true
        this.loading1 = 0;
      }

    }, error => {
      alert("Something Went Wrong Plaese try Again !")
    })


  }

  resendOtp = 0;
  verifyOTP() {
    this.loading1 = 1
    if (this.NRIForm.value.OTPr === null || this.NRIForm.value.OTPr === undefined || this.NRIForm.value.OTPr === '' || this.NRIForm.value.OTPr === " ") {
      alert('Please Enter OTP')
      this.loading1 = 0
    } else {
      let obj = {
        mobile: this.NRIForm.value.phoner,
        emailId: this.NRIForm.value.emailIdr,
        countryCode: this.selectedCountryCode,
        VerificationCode: this.NRIForm.value.OTPr
      }
      console.log("message==>", obj)
      this.accountService.verifyOTP(obj).subscribe(data => {
        let dataObj: any = data
        let verifiedStatus: any = dataObj.message
        if (verifiedStatus == 'success') {
          alert("OTP verified Successfully")
          this.showTimer = false
          this.showPassDivision = true;
          this.localStorageService.set('mobile', this.NRIForm.value.phoner);
          this.router.navigate(['/accounts/lead-form']);
          this.accountService.emailFromGetStarted = this.NRIForm.value.emailIdr;
        }
        if (verifiedStatus == 'failed') {
          alert("OTP Is Wrong")
          this.resendOtp = 1;
          this.showTimer = false
          this.loading1 = 0
          this.disableResendOTP = false
          // this.NRIForm = this.fb.group({
          //   emailIdr: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]],
          //   phoner: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(14), Validators.pattern("[0-9]+")]],
          //   OTPr: ['', [Validators.required]]

          // })
          this.NRIForm.controls['OTPr'].setValue('');

        }
        if (verifiedStatus == 'already registered') {
          alert("This No. is Already registered !")
          this.showPassDivision = true;
          this.showTimer=false
          this.disableResendOTP = false
        }


      })
    }


  }

  fun(x) {
    debugger
    if (this.selectedCountryCode !== x.target.value) {
      this.showOtpField = 0
      this.resendOtp = 0
    }
    this.selectedCountryCode = x.target.value


    console.log(this.selectedCountryCode);
    if (this.selectedCountryCode !== '+91') {
      this.showOtpField = 0
      this.resendOtp = 0
      this.NRIForm = this.fb.group({
        emailIdr: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$")]],
        phoner: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(14), Validators.pattern("[0-9]+")]],
        OTPr: ['']

      })
    } else {
      this.showOtpField = 0
      this.resendOtp = 0
      this.NRIForm = this.fb.group({
        emailIdr: ['', [Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$")]],

        phoner: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]+')]],
        OTPr: [''],
      })
    }

  }
}
