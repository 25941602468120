import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { Injectable } from '@angular/core';

@Injectable()
export class MyJsonLdComponent {


    html: SafeHtml; constructor(private sanitizer: DomSanitizer) { }
    
    getSafeHTML(jsonLD: { [key: string]: any }): SafeHtml {
        const json = jsonLD ? JSON.stringify(jsonLD, null, 2).replace(/<\/script>/g, '<\\/script>') : '';
        // escape / to prevent script tag in JSON
        const html = `<script type="application/ld+json">${json}</script>`;

        return this.sanitizer.bypassSecurityTrustHtml(html);
    }
}