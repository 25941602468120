import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { CommonService } from '../common/common.service';
import { StorageService } from '../common/storage-service';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AudienceService {
  private apiUrl = '';
  private stsUrl = '';

  constructor(private http: HttpClient, private commonService: CommonService, private localStorageService: StorageService) {
    this.apiUrl = this.commonService.getApiUrl();
    this.stsUrl = this.commonService.getStsUrl();
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error);
  }

  registerViaMobileOrEmailNew(obj) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let body = JSON.stringify(obj);
    return this.http.post(this.apiUrl + '/api/Accounts/RegisterViaMobileOrEmailNew', body, httpOptions).pipe(retry(3), catchError(this.handleError));
  }
 
  verifyOTPViaMobileOrEmailNew(obj)
  {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    let body = JSON.stringify(obj);
    return this.http.post(this.apiUrl + '/api/Accounts/VerifyOTPViaMobileOrEmailNew', body, httpOptions).pipe(retry(3), catchError(this.handleError));
  }
  getAllGooglereviews(pageno) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.get(this.apiUrl +'/api/Contents/GetGoogleReviewsNew?page='+ pageno, httpOptions).pipe(retry(3), catchError(this.handleError));

  }
  /******************************************************************************************
    * getPreferenceForStudentId()
    * purpose - to get the preferece of user for three step popup
    ******************************************************************************************/
   getPreferenceForStudentId() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.localStorageService.get('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/api/Students/GetCareerSessionPreference',
      httpOptions).pipe(retry(3), catchError(this.handleError));;
  }
}
