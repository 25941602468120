import { Injectable } from '@angular/core';
declare var document: any;

@Injectable()
export class ScriptService {
    constructor() {
    }

    allIds = ['homepage',
        'college-graduates',
        'solution8to9',
        'solution10To12',
        'workingProfessional',
        'howItWorks',
        'partnershipCorporates',
        'partnershipCounsellor',
        'partneshipEducators',
        'hotjar'
    ]


    loadScript(path: string) {
        // load script
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = path;
            if (script.readyState) {  // IE
                script.onreadystatechange = () => {
                    if (script.readyState === "loaded" || script.readyState === "complete") {
                        script.onreadystatechange = null;
                        resolve({ loaded: true, status: 'Loaded' });
                    }
                };
            } else {  // Others
                script.onload = () => {
                    resolve({ loaded: true, status: 'Loaded' });
                };
            };
            script.onerror = (error: any) => resolve({ loaded: false, status: 'Loaded' });
            document.getElementsByTagName('head')[0].appendChild(script);
        });
    }

    loadScriptWithId(path: string, id: string, type: string) {
        // load script
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            if (type === 'javascript') {
                script.type = 'text/javascript';
            } else if (type === 'json') {
                script.type = 'application/ld+json';
            }
            script.src = path;
            script.id = id;
            if (script.readyState) {  // IE
                script.onreadystatechange = () => {
                    if (script.readyState === "loaded" || script.readyState === "complete") {
                        script.onreadystatechange = null;
                        resolve({ loaded: true, status: 'Loaded' });
                    }
                };
            } else {  // Others
                script.onload = () => {
                    resolve({ loaded: true, status: 'Loaded' });
                };
            };
            script.onerror = (error: any) => resolve({ loaded: false, status: 'Loaded' });
            document.getElementsByTagName('head')[0].appendChild(script);
            this.removeScriptWithId(id)
        });
    }


    removeScriptWithId(id: string) {
        console.log(this.allIds)
        this.allIds.forEach(ids => {
            if (id !== ids) {
                // load script
                return new Promise((resolve, reject) => {
                    // document.getElementsById(id).remove();
                    var element = document.getElementById(ids);
                    if (element !== null) {
                        element.remove(element);
                    }
                });
            }

        })

    }

    loadCSS(path) {
        // Create link
        return new Promise((resolve, reject) => {
            const link = document.createElement('link');
            link.href = path;
            link.rel = 'stylesheet';
            link.type = 'text/css';
            if (link.readyState) {  // IE
                link.onreadystatechange = () => {
                    if (link.readyState === "loaded" || link.readyState === "complete") {
                        link.onreadystatechange = null;
                        resolve({ loaded: true, status: 'Loaded' });
                    }
                };
            } else {  // Others
                link.onload = () => {
                    resolve({ loaded: true, status: 'Loaded' });
                };
            };
            link.onerror = (error: any) => resolve({ loaded: false, status: 'Loaded' });
            document.getElementsByTagName('head')[0].appendChild(link);
        });
    }

}
