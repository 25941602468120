import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Pipe({ name: 'YoutubeThumbnail' })
export class YoutubeThumbnail implements PipeTransform {
    constructor(private sanitized: DomSanitizer) { }
    transform(value) {
        var videoID = value.replace('https://www.youtube.com/embed/', '');
        return "https://img.youtube.com/vi/" + videoID+ "/default.jpg";
    }
}