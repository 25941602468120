import { StorageService } from '../../common/storage-service';
import { Component, PLATFORM_ID, Inject, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CareerOverviewService } from '../career-overview.service';
import { isPlatformBrowser } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { CommonService } from "../../common/common.service";
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css', '../career-overview.module.css']
})
export class OverviewComponent implements OnInit {
  specCareer: any;

  public careerId;
  public pathObj;
  // public careerpath: any[] = [];
  public path;
  public authToken;
  public careerNum;
  public accType;
  public searchObj;
  public path1;
  public path2;
  public path3;
  public student;
  public platformId;
  public desc;
  public careerArr;
  public LinkedIn;
  constructor(private route: ActivatedRoute,
    private localStorage: StorageService,
    private commonService: CommonService,
    private careerOverviewService: CareerOverviewService, private sanitizer: DomSanitizer,
    private router: Router, @Inject(PLATFORM_ID) platformId: string, private titleService: Title,
    private metaService: Meta, ) {
    this.platformId = platformId;

    this.authToken = this.localStorage.get('access_token');
    

    this.accType = this.localStorage.get('account_type');
    console.log(this.accType);

    this.careerArr = this.localStorage.get('career_arr');

    this.route.params.forEach((params: Params) => {
      this.careerId = params['id'];

      // this.titleService.setTitle(this.careerId + ' - Career Information');
    });
    if (this.commonService.pathData !== '') {
      this.path = this.commonService.pathData;
      if (this.path.relatedCareerPath != undefined) {
        var specCareerPrefix = this.path.relatedCareerPath.replace(/[^\w]/gi, '-');
      }
      if (this.path.linkId != undefined) {
        var career = this.path.linkId.replace(specCareerPrefix + '-', '');
        this.specCareer = career.replace(/-/g, ' ');
      }
      this.setTags();
    } else {

      this.careerOverviewService.getStaticContentByCareerLinkId(this.careerId).subscribe(
        response => {
          this.path = response;
          if (this.path.relatedCareerPath != undefined) {
            var specCareerPrefix = this.path.relatedCareerPath.replace(/[^\w]/gi, '-');
          }
          if (this.path.linkId != undefined) {
            var career = this.path.linkId.replace(specCareerPrefix + '-', '');
            this.specCareer = career.replace(/-/g, ' ');
          }

          this.setTags();
        });
    }
  }

  ngOnInit() {
    this.getStudent();
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
}

  public setTags() {
    console.log(this.path.OverviewDescription);
    this.titleService.setTitle(this.path.careerPath + ' - Career Information');
    var a = this.path.OverviewDescription.indexOf('<p');
    var b = this.path.OverviewDescription.indexOf('</p>');
    this.desc = this.path.OverviewDescription.slice(a + 3, b);
    console.log(this.desc);
    let attributeSelector: string = 'name="description"';
    this.metaService.removeTag(attributeSelector);

    // this.metaService.addTag({ name: 'description', content: this.desc }, true);
    if (this.path.OverviewTitle != undefined) {
      let title = this.path.OverviewTitle.replace('<p>', '');
      title = title.replace('</p>', '');
      this.titleService.setTitle(title);
      this.metaService.addTag({ property: 'og:title', content: title });
    }
    // let title = this.path.OverviewTitle.replace('<p>', '');
    // title = title.replace('</p>', '');

    // this.metaService.addTag({ property: 'og:title', content: this.path.OverviewTitle });
    // this.metaService.addTag({ property: 'og:description', content: this.path.OverviewDescription });

    this.metaService.addTag({ property: 'og:description', content: this.desc });
    if (this.path.CareerPathImage !== null) {
      this.metaService.addTag({ property: 'og:image', content: this.path.CareerPathImage });
    }

    this.metaService.addTag({ property: 'og:url', content: this.commonService.getAppUrl() +  '/career/' + this.careerId });
    this.metaService.addTag({ property: 'og:site_name', content: 'Mentoria Overview' });
    this.metaService.addTag({ property: 'og:locale', content: 'en_US' });
    this.metaService.addTag({ property: 'og:type', content: 'overview' });
    if (this.path.CareerPathImage !== null) {
      this.metaService.addTag({ property: 'og:image', content: this.path.CareerPathImage });
    }

    this.metaService.addTag({ property: 'twitter:card', content: 'summary' });
    this.metaService.addTag({ property: 'twitter:description', content: this.path.OverviewDescription });
    this.metaService.addTag({ property: 'twitter:title', content: this.path.OverviewTitle });
    if (this.path.CareerPathImage !== null) {
      this.metaService.addTag({ property: 'og:image', content: this.path.CareerPathImage });
    }
  }

  public getStudent() {
    if (this.authToken !== '' && this.accType === 'student') {
      this.careerOverviewService.GetStudentDetails().subscribe(
        response => {
          let anyObj1 = response;
          this.student = anyObj1;
          if (this.student.selectedCareerPaths.length === 1) {
            this.path1 = this.student.selectedCareerPaths[0].CareerPathUId;
          }
          if (this.student.selectedCareerPaths.length === 2) {
            this.path1 = this.student.selectedCareerPaths[0].CareerPathUId;
            this.path2 = this.student.selectedCareerPaths[1].CareerPathUId;
          }
          if (this.student.selectedCareerPaths.length === 3) {
            this.path1 = this.student.selectedCareerPaths[0].CareerPathUId;
            this.path2 = this.student.selectedCareerPaths[1].CareerPathUId;
            this.path3 = this.student.selectedCareerPaths[2].CareerPathUId;
          }
          console.log(this.student);
        },
        error => {
          
        });
    }
  }
  
  public goToCareers() {
    console.log(this.accType);
    if (this.accType === 'student' && this.careerArr === '3') {
      this.router.navigate(['/feeds/my-careers']);
    }
    if (this.accType === 'counsellor') {
      this.router.navigate(['/counsellor/discover/careers']);
    }
    if (this.accType === null || this.authToken === null || this.careerArr === '0') {
      this.router.navigate(['/career']);
    }

  }

  home() {
    if (isPlatformBrowser(this.platformId)) {
      // console.log('user agent --- ' + window.navigator.userAgent );
      let ua = window.navigator.userAgent;

      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
        console.log('route changing to Mobile view');
        this.router.navigate(['home-mobile']);
      }
      else {
       // this.router.navigate([this.commonService.getpreloginAppURL()]);
       let url = this.commonService.getpreloginAppURL()
       let newPath = url + '/redirect?authToken=' + this.localStorage.get('access_token') + '&redirectURl=' + encodeURI(url + '/login');
       window.open(newPath, "_self")
      }
    }
  }


  // public goToOverview() {
  //   this.router.navigate(['/career', this.careerId, 'overview']);
  // }
  // public goToRoles() {
  //   this.router.navigate(['/career', this.careerId, 'roles-responsibilities']);
  // }
  // public goToSkills() {
  //   this.router.navigate(['/career', this.careerId, 'key-skills']);
  // }
  // public goToGrowth() {
  //   this.router.navigate(['/career', this.careerId, 'growth-trajectory']);
  // }
  // public goToPayScale() {
  //   this.router.navigate(['/career', this.careerId, 'pay-scale']);
  // }
  // public goToPath() {
  //   this.router.navigate(['/career', this.careerId, 'path']);
  // }
  // public goToEnvironment() {
  //   this.router.navigate(['/career', this.careerId, 'environment']);
  // }
  // public goToLoading() {
  //   this.router.navigate(['/career', this.careerId]);
  // }

}
