import { StorageService } from '../../common/storage-service';
import { Component, OnInit, ViewChild, ElementRef, Injector, PLATFORM_ID, Inject } from '@angular/core';
import { AccountsService } from '../accounts.service';
import { Router } from '@angular/router';
import { CommonService } from '../../common/common.service';
import swal from 'sweetalert2';
// import { StudentModel } from '../../student/student.model';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.css', '../accounts.module.css']
})
export class VerificationComponent implements OnInit {

  public phoneverified: Boolean;
  public emailverified: Boolean;

  public emailExists = false;
  public phoneExists = false;

  public emailRegistered = false;
  public phoneRegistered = false;

  public mobile = '';
  public email = '';

  public studentStatus;
  public loading = false;
  public otpResent = false;
  public emailResent = false;
  public showEmailNotVerified = false;
  public invalidOTP = false;
  public accounttype;
  public isverified = false;
  public account;

  public resendOTPDisabled = true;

  private platformId = '';
  private modalService;
  private modalRef;

  @ViewChild('enterPhoneNumberModal') enterPhoneNumberModal: ElementRef;

  constructor(private accountService: AccountsService, private router: Router, private commonService: CommonService, private localStorageService: StorageService, @Inject(PLATFORM_ID) platformId: string, private injector: Injector) {
    this.platformId = platformId;
    if (isPlatformBrowser(this.platformId)) { this.modalService = this.injector.get(NgbModal); }
  }

  ngOnInit() {
    this.accounttype = this.localStorageService.get('account_type');
    this.phoneverified = false;
    this.emailverified = false;
    this.getAccount();
    setTimeout(() => {
      this.resendOTPDisabled = false;
    }, 30000);
  }

  public getAccount() {
    if (this.accounttype === 'student') {
      this.accountService.getStudentDetails().subscribe(
        response => {
          
          this.account = response;

          if (this.account.emails.length > 0) {
            this.emailExists = true;
            if (this.account.emails[0].address.includes('noemail@mentoria.com')) {
              this.emailExists = false;
            }
            if (this.account.emails[0].verified) {
              this.emailverified = true;
            }
          }

          if (this.account.phones.length > 0) {
            this.phoneExists = true;
            if (this.account.phones[0].verified) {
              this.phoneverified = true;
            }
          }

          // if (this.phoneverified && this.emailverified) {
          //   setTimeout(function () {
          //     this.isverified = true;
          //     this.router.navigate(['/dashboard']);
          //   }.bind(this), 3000);
          // }
          if (!this.phoneExists || !this.emailExists) {
            this.modalRef = this.modalService.open(this.enterPhoneNumberModal, { size: 'lg', backdrop: 'static', keyboard: false });
          }
          // if (!this.phoneExists) { 
          //   swal({
          //     title: 'Please enter your Phone number.',
          //     input: 'tel',
          //     confirmButtonText: 'Submit',
          //     allowOutsideClick: false,
          //     allowEscapeKey: false,
          //     inputValidator: result => result.length < 10 && 'Please enter proper number!',
          //     inputAttributes: {
          //       minlength: '10', maxlength: '10', pattern: '[0-9]{10}'
          //     }
          //   }).then((result) => {
          //     if (result.value) {
          //       this.accountService.addMobile(result.value).subscribe(
          //         response => {
          //           this.account.phones.push({ 'number': result.value, 'verified': false });
          //         });
          //     }
          //   });
          // }
          // if (!this.emailExists) { 
          //   swal({
          //     title: 'Please enter your email.',
          //     input: 'email',
          //     confirmButtonText: 'Submit',
          //   }).then((result) => {
          //     if (result.value) {
          //       this.accountService.addEmail(result.value).subscribe(
          //         response => {

          //         });
          //     }
          //   });
          // }
        },
        error => {
          this.loading = false;
          
        });
    }
    if (this.accounttype === 'counsellor') {
      this.accountService.getCounsellorDetails().subscribe(
        response => {
          
          this.account = response;

          if (this.account.emails[0].verified) {
            this.emailverified = true;
          }

          if (this.account.phones[0].verified) {
            this.phoneverified = true;
          }

          if (this.phoneverified && this.emailverified) {
            setTimeout(function () {
              this.accountService.GetCounsellorPath().subscribe(path => {
                this.router.navigate([path]);
              });
              // this.router.navigate(['/accounts/all-set-to-roll']);
              this.isverified = true;
            }.bind(this), 23000);
          }
        },
        error => {
          this.loading = false;
          
        });
    }
  }

  async save() {
    this.loading = true;
    if(!this.emailExists) {
      this.emailRegistered = await this.accountService.emailExists(this.email);
    } else {
      this.emailRegistered = false;
    }
    if (!this.phoneExists) {
     this.phoneRegistered = await this.accountService.phoneExists(this.mobile);
    } else {
      this.phoneRegistered = false;
    }

    if (!this.emailRegistered && !this.phoneRegistered) {
      if (!this.phoneExists) {
        this.accountService.addMobile(this.mobile).subscribe(
          response => {
            this.account.phones.push({ 'number': this.mobile, 'verified': false });
          });
      }
      if (!this.emailExists) {
        this.accountService.addEmail(this.email).subscribe(
          response => {
          });
      }
      this.loading = false;
      this.modalRef.close();
    } else { 
      this.loading = false;
    }
  }

  public verifyPhone(otp) {
    let otpObject = {
      verificationCode: otp
    };

    this.accountService.VerifyPhoneNumber(otpObject).subscribe(
      response => {
        this.phoneverified = true;
        if (this.phoneverified && this.emailverified) {
          setTimeout(function () {
            this.router.navigate(['/dashboard']);
          }.bind(this), 23000);
        }
        this.commonService.updateUserInfo();
      },
      error => {
        this.invalidOTP = true;
        
      });

    // this.phoneverified = true;
  }

  ResendOTP() {
    this.accountService.ResendOTP().subscribe(
      response => {
        
        this.otpResent = true;
      },
      error => {
        
      });
  }

  public recheckEmail() {
    this.getAccount();
    // this.emailverified = true;
  }

  public resendVerificationEmail() {
    let emailObj = {
      "Email": this.account.emails[0].address
    };
    this.accountService.ResendVerificationEmail(emailObj).subscribe(
      response => {
        
        this.emailResent = true;
      },
      error => {
        
      });
  }

}

