import { Component, OnInit, Inject, PLATFORM_ID, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  SwiperConfigInterface, SwiperPaginationInterface, SwiperAutoplayInterface
} from 'ngx-swiper-wrapper';
import { isPlatformBrowser } from '@angular/common';
import { CountdownComponent } from 'ngx-countdown';
import { AudienceData, Faq, counsellors, counsellorsNew, careerGuidePricingSection, pricing, counsellorsNewCollegeGraduate } from './../audience-page.json'
import swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AudienceService } from '../audience.service';
import { AccountsService } from '../../../app/accounts/accounts.service'
import { CommonService } from '../../../app/common/common.service'
import { StorageService } from '../../../app/common/storage-service';
import { ContentService } from '../../../app/content/content.service';
import { Package } from '../../../app/payment/package.model';
import { MatDialog, MatDialogRef, MatStepper, MAT_DIALOG_DATA } from '@angular/material';
import { PaymentService } from '../../../app/payment/payment.service';
import { SharedService } from '../../../app/shared/shared.service';
import { Meta, Title } from '@angular/platform-browser';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { googleReviews } from '../../../app/locationpage/google-reviews.json'
import { trigger, style, animate, transition } from '@angular/animations';
import { find } from 'rxjs/operators';
import { countryCodes } from '../../../app/shared/country-code.json';



export interface DialogData {
  route: string;
  message: string;
}

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-100%)' }),
        animate(500, style({ opacity: 1, transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate(500, style({ opacity: 0, transform: 'translateX(100%)' }))
      ])
    ])
  ]
})

export class MainPageComponent implements OnInit {
  @ViewChild('countdown') counter: CountdownComponent;
  @ViewChild('stepper') private myStepper: MatStepper;
  @ViewChild('testDiv')
  private testDiv: ElementRef<HTMLDivElement>;
  isTestDivScrolledIntoView: boolean;
  isAlreadyViewed: boolean = false
  continueousFunction1
  stepperIndex = 1
  public blogUrlToken = "https://blog.mentoria.com"
  nextToStepper: any = false
  disableToolTip: any = true
  @ViewChild('tt') mytooltip: NgbTooltip;
  activeDiv = ''
  @ViewChild('testDivA')
  private testDivA: ElementRef<HTMLDivElement>;
  @ViewChild('testDivB')
  private testDivB: ElementRef<HTMLDivElement>;
  @ViewChild('testDivC')
  private testDivC: ElementRef<HTMLDivElement>;
  @ViewChild('testDivD')
  private testDivD: ElementRef<HTMLDivElement>;
  @ViewChild('testDivE')
  private testDivE: ElementRef<HTMLDivElement>;
  sendOtpOn = 'mobile'
  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true,
    hideOnClick: false
  };
  private autoplay: SwiperAutoplayInterface = {
    delay: 100000,
    disableOnInteraction: false
  };
  public videoConfig: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: true,
    mousewheel: false,
    scrollbar: false,
    navigation: false,
    pagination: this.pagination,
    loop: true,
  };
  public Counsellorconfig: SwiperConfigInterface = {
    direction: 'horizontal',
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: true,
    pagination: this.pagination,
    autoplay: this.autoplay,
    slidesPerView: 'auto',
    spaceBetween: 2,
    loop: false,
  };
  public type: any;
  data: any;
  dataObj: any
  faq: any;
  pricing: any;
  class
  packages
  packagesNew
  countryCodes
  form1OtpSent
  form2Otpsent
  demo = [1, 2, 3, 4, 5, 6]
  public StepperNext = true
  counsellorsInfo: any;
  counselorsData: any;
  public selectedIndex: number;
  panelOpenState: boolean[] = [];
  selectedCountryCode = "+91"
  selectedCountryCode2 = "+91"
  public loading = false;
  public userClass: any
  platformId: string
  public pricingId: any
  public studentCareerPathArray
  public Package2: Package = new Package();
  public Package1: Package = new Package();
  public registerloading: any = false;
  public verifyOTPloading: any = false
  public registerloading2: any = false;
  public verifyOTPloading2: any = false
  public cvPaymentSuccess: any = false
  public collegeAdmissionPaymentSuccess: any = false
  public classWithPackIdArr = [{
    class: '8th-9th', buyId: '1e109467-419d-4310-a661-235d7797ea3d',
    buyIdPlus: "1e109467-419d-4310-41-235d7797ea3d",
  }, {
    class: '10th-12th', buyId: '4b7db883-94c8-4a07-a30e-509b99fe51c3',
    buyIdPlus: "4b7d883-94c8-4a07-a30e-509b99fe51c3"
  }, {
    class: 'Working Professional', buyId: 'fe7808b1-97e5-4f28-a954-5f01b795aeef',
    buyIdPlus: "fe78081-97e5-4f28-a954-5f01b795aeef",
  }, {
    class: 'College Graduate', buyId: 'fe7808b1-97e5-4f28-a954-5f01b795aeef',
    buyIdPlus: "fe78081-97e5-4f28-a954-5f01b795aeef"
  }]
  currentVideosList = ['https://www.youtube.com/embed/YP3eozTzVcA', 'https://www.youtube.com/embed/VsbzpZiofHk', 'https://www.youtube.com/embed/-_xdakrRH9U']
  videoSection = [{ id: 'student', state: true, videos: ['https://www.youtube.com/embed/YP3eozTzVcA', 'https://www.youtube.com/embed/VsbzpZiofHk', 'https://www.youtube.com/embed/-_xdakrRH9U'] }, { id: 'Parents', state: false, videos: ['https://www.youtube.com/embed/a97ZWwJF_ek', 'https://www.youtube.com/embed/3aQW-IkVlro'] }, { id: 'CollegeGraduates', state: false, videos: ['https://www.youtube.com/embed/Mv6t4fwkm7I'] }, { id: 'WorkingProfessionals', state: false, videos: ['https://www.youtube.com/embed/DydqD7WkN_g', 'https://www.youtube.com/embed/i9SAR-_1E6c', 'https://www.youtube.com/embed/IeL5ywgC7TU'] }, { id: 'Educators', state: false, videos: ['https://www.youtube.com/embed/SYryhAI4N2Y', 'https://www.youtube.com/embed/XMZ3Q4AHMt4', 'https://www.youtube.com/embed/9EvKZFm4Ggo'] }, { id: 'CorporatePartners', state: false, videos: ['https://www.youtube.com/embed/U4qi-MvgHbU', 'https://www.youtube.com/embed/RpGBZC7tnu0', 'https://www.youtube.com/embed/ZhjCtJdipmE'] }]
  public registerName
  public dialCode
  public emailId
  public registerForm: FormGroup
  public registerForm2: FormGroup
  registerFormpopup: FormGroup
  firstFormGroup: FormGroup;
  public otpSent = false;
  resenOtp: any = false;
  disableResendOTp: any = true;
  showTimer: boolean;
  public otpSent2 = false;
  resenOtp2: any = false;
  disableResendOTp2: any = true;
  showTimer2: boolean;
  activeIndex2 = 0;
  public packageType;
  public isLoggedIn = false;
  public showPurchasePackageButton: any = true;
  public careerGuidePricing: any
  existError: boolean = false;
  existError2: boolean = false;
  public routeNew: any
  public studentPath: any;
  selectedClass1 = "class"
  selectedClass2 = "class"
  tri = false
  public testCompleted
  public paidPackageSelected = false
  selectedCountryCodep = "+91"
  selectedClassp = "class"
  public classes: any = [{ name: '8th-9th', value: '8th-9th' }, { name: '10th-12th', value: '10th-12th' }, { name: 'College Graduate', value: 'College Graduate' }, { name: 'Working Professional', value: 'Working Professional' }]
  registerloadingp: boolean;
  existErrorp: boolean = false;
  resenOtpp: boolean = false;
  otpSentp: boolean = false;
  disableResendOTpp: boolean = true;
  showTimerp: boolean;
  verifyOTPloadingp: boolean;
  redirectFrom = "";
  tempRoute: ActivatedRoute;
  paymentId
  paymentData
  errorMsg = ''
  errorMsg2 = ''
  errorMsgP = ''
  public googleReview: any = []
  public form1error: any = ""
  starWidth
  public formPOtpSent = ''
  public showPage1 = true;
  public showPage2 = false;
  public showPage3 = false;
  public showPage4 = false;
  public showPage5 = false;
  public showPage6 = false;
  public showPage7 = false;
  public educationalBackgroundOfStudent
  public formPerror
  form2error
  form1NameError = false
  form1LastNameError = false
  form1EmailError = false
  form1PhoneError = false
  form2NameError = false
  form2LastNameError = false
  form2EmailError = false
  form2PhoneError = false
  formPNameError = false
  formPLastNameError = false
  formPEmailError = false
  formPPhoneError = false
  class2error = false
  class1error = false
  classperror = false
  public initialPage = 1
  public googleReviewsTotalCount
  isBrainStormPackagePurchased: boolean = false
  endOfreviews: boolean = false
  public stackMyPlanOnly: boolean
  public firstNameErrorMsg = "Please enter first name."
  public lastNameErrorMsg = "Please enter last name."
  public emailErrorMsg = "Please enter valid email."
  public phoneErrorMsg = "Please enter valid phone no."
  public indianPhonepattern = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$"
  public internationalPhonePattern = '[0-9]+'
  constructor(public route: ActivatedRoute, public dialog: MatDialog, @Inject(PLATFORM_ID) platformId: string, public fb: FormBuilder, public audienceService: AudienceService, public accountService: AccountsService, private commonService: CommonService, private localStorageService: StorageService, public router: Router, public contentService: ContentService, private paymentService: PaymentService, public sharedService: SharedService, private metaService: Meta, private titleService: Title) {
    window.scroll(0, 0)

    this.pricing = pricing
    this.selectedIndex = 3;
    this.loading = true;
    if (this.router.url.indexOf('?') !== -1) {
      var routeUrl = this.router.url.replace("/", "")
      this.routeNew = routeUrl.substring(0, routeUrl.indexOf('?'));

    } else {
      this.routeNew = this.router.url.replace("/", "")
    }

    this.tempRoute = this.route
    this.platformId = platformId;
    this.data = AudienceData;
    this.careerGuidePricing = careerGuidePricingSection

    this.faq = Faq;
    this.counsellorsInfo = counsellors;


    this.countryCodes = countryCodes
    // this.googleReview = googleReviews

    let data = this.data.find(d => d.route === this.routeNew)
    if (data !== null && data !== '' && data !== undefined && data !== "") {
      this.dataObj = data;
      this.type = this.dataObj.id;
    }

    this.changeClassForPricing(this.pricing.subSections[0].class);
    for (let i = 0; i < this.faq.subSections[0].questions.length; i++) {
      this.panelOpenState.push(false);
    }
    this.loading = false;
  }

  ngOnInit() {
    this.callGooglereviewsAPI(this.initialPage)
    this.initializeFormGroup()
    this.titleService.setTitle(this.dataObj.metaTitle);
    let attributeSelector: string = 'name="description"';
    this.metaService.removeTag(attributeSelector);
    this.metaService.updateTag({

      name: 'description',

      content: this.dataObj.metaDes

    });

    if (this.type == 'audience-college-graduate' || this.type == 'audience-working-professional') {
      this.counselorsData = counsellorsNewCollegeGraduate
    }
    else if (this.type == 'career-guidance') {
      this.counselorsData = counsellorsNew.concat(counsellorsNewCollegeGraduate)
    }
    else {
      this.counselorsData = counsellorsNew;
    }
    if (this.type == 'audience-working-professional') {
      this.currentVideosList = ['https://www.youtube.com/embed/DydqD7WkN_g', 'https://www.youtube.com/embed/i9SAR-_1E6c', 'https://www.youtube.com/embed/IeL5ywgC7TU']
    }
    if (this.type == 'audience-college-graduate') {
      this.currentVideosList = ['https://www.youtube.com/embed/xjkiqXIIf1E']
    }

    if (this.type == 'college-admission' || this.type == 'cv-review') {
      this.nextToStepper = true
    }
    this.metaService.addTag({ name: 'robots', content: 'noindex' });
    if (this.type == 'audience-working-professional') {
      this.selectedClass2 = "Background"
    }
    this.firstFormGroup = this.fb.group({
      firstCtrl: ['', Validators.required],
    });
    let authToken = this.localStorageService.get('access_token');
    if (authToken !== '') {
      this.blogUrlToken = this.blogUrlToken + "?token=" + authToken
      this.getAllPackages();
      this.calGetCareeSessionPreference()
      this.isLoggedIn = true;
      this.showPurchasePackageButton = false;
      // this.getStudent();
      this.callGetStudenApi()
      this.getStudentPath()
    }


  }
  ngAfterViewInit() {

    let res: any = this.tempRoute
    this.route.queryParams.subscribe(params => {
      this.paymentId = params['payment'];
    });
    if (this.paymentId != undefined) {
      this.getPaymentData(this.paymentId);
    }
  }
  public onIndexChangeCorporate(index: number) {

  }
  //to open google reviews
  showTooltip() {
    this.disableToolTip = false;
    this.mytooltip.open();

  }
  //to close google reviews
  hideTooltip() {
    this.mytooltip.close();
    this.disableToolTip = true;
  }

  @HostListener('window:scroll', ['$event'])

  //for stepper ui to get which section is visible
  isScrolledIntoView() {

    if (this.testDiv && !this.isAlreadyViewed) {
      const rect = this.testDiv.nativeElement.getBoundingClientRect();
      const topShown = rect.top >= 0;
      const bottomShown = rect.bottom <= window.innerHeight;
      this.isTestDivScrolledIntoView = topShown && bottomShown

      if (this.isTestDivScrolledIntoView) {
        this.isAlreadyViewed = true
        this.startSTepperScroll()
      }
    }

    const rectA = this.testDivA.nativeElement.getBoundingClientRect();
    const rectB = this.testDivB.nativeElement.getBoundingClientRect();
    const rectC = this.testDivC.nativeElement.getBoundingClientRect();
    const rectD = this.testDivD.nativeElement.getBoundingClientRect();
    const rectE = this.testDivE.nativeElement.getBoundingClientRect();

    if (rectA.top < window.innerHeight) {
      if (rectA.top > 0 && rectA.top < (window.innerHeight / 1.5) && rectA.bottom > 0 && rectA.bottom < (window.innerHeight * 2))
        this.activeDiv = 'blockOne'
      else if (rectB.top > 0 && rectB.top < (window.innerHeight / 1.5) && rectB.bottom > 0 && rectB.bottom < (window.innerHeight * 2))
        this.activeDiv = 'blockTwo'
      else if (rectC.top > 0 && rectC.top < (window.innerHeight / 1.5) && rectC.bottom > 0 && rectC.bottom < (window.innerHeight * 2))
        this.activeDiv = 'blockThree'
      else if (rectD.top > 0 && rectD.top < (window.innerHeight / 1.5) && rectD.bottom > 0 && rectD.bottom < (window.innerHeight * 2))
        this.activeDiv = 'blockFour'
      else if (rectE.top > 0 && rectE.top < (window.innerHeight / 1.5) && rectE.bottom > 0 && rectE.bottom < (window.innerHeight * 2))
        this.activeDiv = 'blockFive'
      else
        this.activeDiv = ''
    }
  }

  //give scrolling effect if step is active on screen
  startSTepperScroll() {

    this.stepperIndex = 1
    this.continueousFunction1 = setInterval(() => {
      this.setStepperIndex()
    }, 5000);
  }

  //for calling next step after fixed interval
  setStepperIndex() {

    if (this.stepperIndex < 5) {
      this.myStepper.selectedIndex = this.stepperIndex;
      this.stepperIndex++;
    } else {
      this.nextToStepper = true;
      clearInterval(this.continueousFunction1);
      setTimeout(() => {
        var element = document.getElementById("nextToStepper");

        element.scrollIntoView({ behavior: 'smooth', block: "start", inline: 'start' });

      }, 100)
    }
  }

  //Initializing the all formgroups
  initializeFormGroup() {
    this.registerForm = this.fb.group({
      registerName: ['', [Validators.required]],
      registerlName: ['', [Validators.required]],
      dialCode: [this.selectedCountryCode, [Validators.required]],
      emailId: ['', [Validators.required, Validators.pattern(this.indianPhonepattern)]],
      otpdigit1: ['', []],
      otpdigit2: ['', []],
      otpdigit3: ['', []],
      otpdigit4: ['', []],
      otp: ['', []],
      phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]+')]],
      selectedClass: [this.selectedClass1, [Validators.required]]


    })
    this.registerFormpopup = this.fb.group({
      registerName: ['', [Validators.required]],
      registerlName: ['', [Validators.required]],
      dialCode: [this.selectedCountryCodep, [Validators.required]],
      emailId: ['', [Validators.required, Validators.pattern(this.indianPhonepattern)]],
      otpdigit1: ['', []],
      otpdigit2: ['', []],
      otpdigit3: ['', []],
      otpdigit4: ['', []],
      otp: ['', []],
      phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]+')]],
      selectedClass: [this.selectedClassp, [Validators.required]]


    })
    this.registerForm2 = this.fb.group({
      registerName: ['', [Validators.required]],
      registerlName: ['', [Validators.required]],
      dialCode: [this.selectedCountryCode2, [Validators.required]],
      emailId: ['', [Validators.required, Validators.pattern(this.indianPhonepattern)]],
      otpdigit1: ['', []],
      otpdigit2: ['', []],
      otpdigit3: ['', []],
      otpdigit4: ['', []],
      otp: ['', []],
      phone: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]+')]],
      selectedClass: [this.selectedClass2, [Validators.required]]


    })
  }
  //to call google reviews api
  callGooglereviewsAPI(pageno) {
    this.initialPage = pageno
    if (!this.endOfreviews) {
      setTimeout(() => {
        this.audienceService.getAllGooglereviews(pageno).subscribe(data => {

          let res: any = data
          this.googleReviewsTotalCount = res.TotalRatings
          this.starWidth = (res.AvgRating / 5) * 100;
          if (res.Reviews.length > 0) {
            this.googleReview = this.googleReview.concat(res.Reviews);
            console.log("google revives=>", this.googleReview)
          }
          else {
            this.endOfreviews = true
            console.log("page end=>", this.initialPage)
          }
        })
      }, 200);
    }
  }

  //on scrolling down to google reviews section
  onScrollDown(e) {
    console.log("down=>", e)

  }

  //on scrolling up to google reviews section
  onScrollUp(e) {
    console.log("UP=>", e)
  }
  //if country code selected is other than indian then change validation of phone no.
  changeValidation(form, code) {
    if (form == 1 && code !== '+91') {
      this.registerForm.controls["phone"].setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(14), Validators.pattern(this.internationalPhonePattern)]);
    }
    if (form == 2 && code !== '+91') {
      this.registerForm2.controls["phone"].setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(14), Validators.pattern(this.internationalPhonePattern)]);
    }
    if (form == 3 && code !== '+91') {
      this.registerFormpopup.controls["phone"].setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(14), Validators.pattern(this.internationalPhonePattern)]);
    }
  }

  //method used only for career-guidance page for changing the pricing according to class
  changeClassForPricing(sectionClass) {
    this.class = sectionClass;
    this.packagesNew = this.pricing.subSections.find(c => c.class == this.class).packages;

  }

  scrollToTitle() {
    var element = document.getElementById("PricingDiv");
    element.scrollIntoView({ behavior: 'smooth', block: "start", inline: 'start' });

  }

  //after payment sucessful scroll to the sucessfull payment section useful for cv-review and college admission page
  scrollOnPaymentSuccess() {

    if (this.collegeAdmissionPaymentSuccess) {
      var element = document.getElementById("paymentSuccess1");
      element.scrollIntoView({ behavior: 'smooth', block: "start", inline: 'start' });
      // window.scrollTo(0, document.getElementById('paymentSuccess1').offsetTop - 180);
    }
    if (this.cvPaymentSuccess) {
      var element = document.getElementById("paymentSuccess2");
      element.scrollIntoView({ behavior: 'smooth', block: "start", inline: 'start' });
      // window.scrollTo(0, document.getElementById('paymentSuccess2').offsetTop - 180);

    }

  }

  //call getsudentpath api
  getStudentPath() {
    this.sharedService.GetStudentPath().subscribe(data => {
      let res: any = data;
      this.studentPath = res;
    })
  }

  //method for video testimonial
  getVideosByCategory(str) {
    let category = str;
    this.videoSection.forEach(element => {

      if (element.id == category) {

        element.state = true;
        this.currentVideosList = element.videos;
      }
      else {
        element.state = false;
      }

    });
  }


  // downloadBrochure() {
  //   var url: any = '';
  //   if (this.type == '8th-9th') {
  //     url = 'https://mentoriastorage.blob.core.windows.net/contentimages/Mentoria_Discover_Plus_Plan.pdf'
  //   }
  //   if (this.type == 'audience-10-12' || this.type == 'college-admission') {
  //     url = 'https://mentoriastorage.blob.core.windows.net/contentimages/Mentoria_Achieve_Plus_Plan.pdf'
  //   }
  //   if (this.type == 'audience-working-professional' || this.type == 'career-guidance' || this.type == 'cv-review') {
  //     url = 'https://mentoriastorage.blob.core.windows.net/contentimages/Mentoria_Ascend_Plus_Plan.pdf'
  //   }
  //   if (this.type == 'audience-college-graduate') {
  //     url = 'https://mentoriastorage.blob.core.windows.net/contentimages/Mentoria_Ascend_Plus_Plan.pdf'
  //   }
  //   var link = document.createElement("a");
  //   link.href = url;

  //   link.target = '_blank'
  //   link.click();

  //   (<any>window).gtag('event', 'download brochure',
  //     {
  //       'send_to': 'AW-849928441/5TxpCNm8pagBEPnBo5UD',
  //       'event_category': 'download brochure',
  //       'event_action': 'click to download brochure',
  //       'event_label': 'discover plus plan'

  //     });

  // }

  //download sample cv 

  //method used to download respexctive cvs
  downloadCV(smaplecvno) {

    let url: any
    if (smaplecvno == 'SampleCV1') {
      url = 'https://mentoriastorage.blob.core.windows.net/contentimages/Sample%20CV%204.pdf'
    }
    if (smaplecvno == 'SampleCV2') {
      url = 'https://mentoriastorage.blob.core.windows.net/contentimages/Sample%20CV%202.pdf'
    }
    if (smaplecvno == 'SampleCV3') {
      url = 'https://mentoriastorage.blob.core.windows.net/contentimages/Sample%20CV%203.pdf'
    }
    var link = document.createElement("a");
    link.href = url;

    link.target = '_blank'
    link.click();

    (<any>window).gtag('event', 'download Resume',
      {
        'send_to': 'AW-849928441/5TxpCNm8pagBEPnBo5UD',
        'event_category': 'download Resume',
        'event_action': 'click to download Resume',
        'event_label': 'discover plus plan'

      });
  }

  // public onIndexChangeStudent(index: number): void {

  // }

  //scroll to top
  onScrolltoTop() {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0)
    }

  }

  //veryfying and displying particular error message for respective form
  registerAlert(registerForm, formType) {
    this.form1NameError = false
    this.form1LastNameError = false
    this.form1EmailError = false
    this.form1PhoneError = false
    this.form2NameError = false
    this.form2LastNameError = false
    this.form2EmailError = false
    this.form2PhoneError = false
    this.formPNameError = false
    this.formPLastNameError = false
    this.formPEmailError = false
    this.formPPhoneError = false

    if (registerForm.controls.registerName.status == 'INVALID') {
      if (formType == 'topForm') {
        this.form1error = this.firstNameErrorMsg
        this.form1NameError = true
      }
      if (formType == 'popupForm') {
        this.formPerror = this.firstNameErrorMsg
        this.formPNameError = true
      }
      if (formType == 'bottomForm') {
        this.form2error = this.firstNameErrorMsg
        this.form2NameError = true
      }

    }
    else if (registerForm.controls.registerlName.status == 'INVALID') {
      if (formType == 'topForm') {
        this.form1error = this.lastNameErrorMsg
        this.form1LastNameError = true
      }
      if (formType == 'popupForm') {
        this.formPerror = this.lastNameErrorMsg
        this.formPLastNameError = true
      }
      if (formType == 'bottomForm') {
        this.form2error = this.lastNameErrorMsg
        this.form2LastNameError = true
      }

    }
    else if (registerForm.controls.emailId.status == 'INVALID') {
      if (formType == 'topForm') {
        this.form1EmailError = true
        this.form1error = this.emailErrorMsg
      }
      if (formType == 'popupForm') {
        this.formPerror = this.emailErrorMsg
        this.formPEmailError = true
      }
      if (formType == 'bottomForm') {
        this.form2error = this.emailErrorMsg
        this.form2EmailError = true
      }

    }
    else if (registerForm.controls.phone.status == 'INVALID') {
      if (formType == 'topForm') {
        this.form1PhoneError = true
        this.form1error = this.phoneErrorMsg
      }
      if (formType == 'popupForm') {
        this.formPPhoneError = true
        this.formPerror = this.phoneErrorMsg
      }
      if (formType == 'bottomForm') {
        this.form2PhoneError = true
        this.form2error = this.phoneErrorMsg

      }

    }

  }

  //register through top form
  registerTop() {
    this.form1NameError = false
    this.form1LastNameError = false
    this.form1EmailError = false
    this.form1PhoneError = false
    this.form1error = ""
    this.class1error = false
    this.existError = false
    this.registerloading = true;
    let obj =
    {
      firstName: this.registerForm.value.registerName,
      lastName: this.registerForm.value.registerlName,
      Mobile: this.registerForm.value.phone,
      educationalClass: this.selectedClass1,
      emailId: this.registerForm.value.emailId,
      countryCode: this.selectedCountryCode,
      Source: "Audience :" + " " + "/" + this.routeNew,
      sendOtpOn: this.sendOtpOn
    }




    if (obj.educationalClass !== 'class') {
      this.callRegisterApi(obj, 'top')
    }
    else {
      this.registerloading = false;

      this.form1error = "Please select class."
      this.class1error = true
    }
  }

  //otp verification for top form
  verifyOTPtop() {
    this.verifyOTPloading = true;
    this.form1OtpSent = ""
    this.registerForm.value.otp = this.registerForm.value.otpdigit1 + "" + "" + this.registerForm.value.otpdigit2 + "" + "" + this.registerForm.value.otpdigit3 + "" + "" + this.registerForm.value.otpdigit4
    if (this.registerForm.value.otp) {
      let obj = {
        firstName: this.registerForm.value.registerName,
        lastName: this.registerForm.value.registerlName,
        Mobile: this.registerForm.value.phone,
        educationalClass: this.selectedClass1,
        emailId: this.registerForm.value.emailId,
        countryCode: this.selectedCountryCode,
        VerificationCode: this.registerForm.value.otp,
        sendOtpOn: this.sendOtpOn
      }


      if (this.localStorageService.get("utm_source") !== '') {
        obj['utm_source'] = this.localStorageService.get("utm_source");
      }
      if (this.localStorageService.get("gclid") !== '') {
        obj['gclid'] = this.localStorageService.get("gclid");
      }
      if (this.localStorageService.get("utm_medium") !== '') {
        obj['utm_medium'] = this.localStorageService.get("utm_medium");
      }
      if (this.localStorageService.get("utm_campaign") !== '') {
        obj['utm_campaign'] = this.localStorageService.get("utm_campaign");
      }
      if (this.localStorageService.get("utm_adgroup") !== '') {
        obj['utm_adgroup'] = this.localStorageService.get("utm_adgroup");
      }
      if (this.localStorageService.get("utm_device") !== '') {
        obj['utm_device'] = this.localStorageService.get("utm_device");
      }
      if (this.localStorageService.get("utm_term") !== '') {
        obj['utm_term'] = this.localStorageService.get("utm_term");
      }
      if (this.localStorageService.get("utm_matchtype") !== '') {
        obj['utm_matchtype'] = this.localStorageService.get("utm_matchtype");
      }
      if (this.localStorageService.get("utm_location") !== '') {
        obj['utm_location'] = this.localStorageService.get("utm_location");
      }
      if (this.localStorageService.get("utm_placement") !== '') {
        obj['utm_placement'] = this.localStorageService.get("utm_placement");
      }

      this.callVerifyOtpApi(obj, 'top')
    }
    else {
      this.verifyOTPloading = false
      swal({
        type: "error",
        title: "Invalid.",
        text: "Please Enter OTP",
      })
    }
    this.resenOtp = true;

  }

  //resend otp for top form
  resendTop(otpType) {

    this.sendOtpOn = otpType
    this.disableResendOTp = true
    this.otpSent = true;
    this.resenOtp = false;
    setTimeout(() => {
      this.disableResendOTp = false
      this.showTimer = false

    }, 30000)

    this.showTimer = true
    this.registerTop();
  }

  //register through bottom form
  registerBottom() {
    this.form2error = ''
    this.form2NameError = false
    this.form2LastNameError = false
    this.form2EmailError = false
    this.form2PhoneError = false
    this.registerloading2 = true;
    this.existError2 = false

    this.class2error = false

    let obj =
    {
      firstName: this.registerForm2.value.registerName,
      lastName: this.registerForm2.value.registerlName,
      Mobile: this.registerForm2.value.phone,
      educationalClass: this.selectedClass2,
      emailId: this.registerForm2.value.emailId,
      countryCode: this.selectedCountryCode2,
      Source: "Audience :" + " " + "/" + this.routeNew
    }
    if (obj.educationalClass !== 'class' && obj.educationalClass !== "Background") {
      this.callRegisterApi(obj, 'bottom')
    }
    else {
      this.registerloading2 = false;
      if (obj.educationalClass !== "Background") {

        this.form2error = "Please select class."
        this.class2error = true
      }
      else {

        this.form2error = "Please select background."
        this.class2error = true
      }
    }
  }

  //verify otp for bottom form
  verifyOTPbottom() {
    this.verifyOTPloading2 = true;
    this.registerloading2 = false

    this.registerForm2.value.otp = this.registerForm2.value.otpdigit1 + "" + "" + this.registerForm2.value.otpdigit2 + "" + "" + this.registerForm2.value.otpdigit3 + "" + "" + this.registerForm2.value.otpdigit4
    if (this.registerForm2.value.otp) {
      let obj = {
        firstName: this.registerForm2.value.registerName,
        lastName: this.registerForm2.value.registerlName,
        Mobile: this.registerForm2.value.phone,
        educationalClass: this.selectedClass2,
        emailId: this.registerForm2.value.emailId,
        countryCode: this.selectedCountryCode2,
        VerificationCode: this.registerForm2.value.otp
      }
      this.callVerifyOtpApi(obj, 'bottom')
    }
    else {
      this.verifyOTPloading2 = false
      swal({
        type: "error",
        title: "Invalid.",
        text: "Please Enter OTP",
      })
    }
    this.resenOtp2 = true;

  }

  //resend otp for bottom form
  resendBottom(otpType) {
    this.sendOtpOn = otpType
    this.disableResendOTp2 = true
    this.otpSent2 = true;
    this.resenOtp2 = false;
    setTimeout(() => {
      this.disableResendOTp2 = false
      this.showTimer2 = false

    }, 30000)

    this.showTimer2 = true
    this.registerBottom();
  }


  //register through popup
  registerPopup() {
    this.formPNameError = false
    this.formPLastNameError = false
    this.formPEmailError = false
    this.formPPhoneError = false
    this.formPerror = ""
    this.registerloadingp = true;
    this.classperror = false
    // let fullName = this.registerFormpopup.value.registerName;
    // let namearr = fullName.split(" ");
    this.existErrorp = false
    let obj =
    {
      firstName: this.registerFormpopup.value.registerName,
      lastName: this.registerFormpopup.value.registerlName,
      Mobile: this.registerFormpopup.value.phone,
      educationalClass: this.selectedClassp,
      emailId: this.registerFormpopup.value.emailId,
      countryCode: this.selectedCountryCodep,
      Source: "Audience :" + " " + "/" + this.routeNew
    }
    if (obj.educationalClass !== "class") {
      this.callRegisterApi(obj, 'popup')
    }
    else {
      this.registerloadingp = false;
      // swal({ text: "Please select class." })
      this.formPerror = "Please select class."
      this.classperror = true
    }
  }

  //verify otp of popup
  verifyOTPpopup() {

    this.formPOtpSent = ""
    this.verifyOTPloadingp = true;
    this.registerFormpopup.value.otp = this.registerFormpopup.value.otpdigit1 + "" + "" + this.registerFormpopup.value.otpdigit2 + "" + "" + this.registerFormpopup.value.otpdigit3 + "" + "" + this.registerFormpopup.value.otpdigit4
    if (this.registerFormpopup.value.otp) {
      let obj = {
        firstName: this.registerFormpopup.value.registerName,
        lastName: this.registerFormpopup.value.registerlName,
        Mobile: this.registerFormpopup.value.phone,
        educationalClass: this.selectedClassp,
        emailId: this.registerFormpopup.value.emailId,
        countryCode: this.selectedCountryCodep,
        VerificationCode: this.registerFormpopup.value.otp,
        sendOtpOn: this.sendOtpOn
      }
      this.callVerifyOtpApi(obj, 'popup')
    }
    else {
      this.verifyOTPloadingp = false
      swal({
        type: "error",
        title: "Invalid.",
        text: "Please Enter OTP",
      })
    }
    this.resenOtpp = true;

  }

  //method used for redirecting to package purchase after login from pricing 
  showStackedPlans(myClass) {
    let data = this.classWithPackIdArr.find(x => x.class = myClass)
    let id = data.buyId
    this.router.navigate(['/payment/select-package', id], { queryParams: { multipackage: true, redirect: 'Audience : /' + this.routeNew } });

  }

  //resend otp for popup
  resendPopup(otpType) {
    this.sendOtpOn = otpType
    this.disableResendOTpp = true
    this.otpSentp = true;
    this.resenOtpp = false;
    setTimeout(() => {
      this.disableResendOTpp = false
      this.showTimerp = false

    }, 30000)

    this.showTimerp = true
    this.registerPopup();
  }

  //calling registerViaemailormobilenew api
  callRegisterApi(obj, formType) {
    if (this.localStorageService.get("utm_source") !== '') {
      obj['utm_source'] = this.localStorageService.get("utm_source");
    }
    if (this.localStorageService.get("gclid") !== '') {
      obj['gclid'] = this.localStorageService.get("gclid");
    }
    if (this.localStorageService.get("utm_medium") !== '') {
      obj['utm_medium'] = this.localStorageService.get("utm_medium");
    }
    if (this.localStorageService.get("utm_campaign") !== '') {
      obj['utm_campaign'] = this.localStorageService.get("utm_campaign");
    }
    if (this.localStorageService.get("utm_adgroup") !== '') {
      obj['utm_adgroup'] = this.localStorageService.get("utm_adgroup");
    }
    if (this.localStorageService.get("utm_device") !== '') {
      obj['utm_device'] = this.localStorageService.get("utm_device");
    }
    if (this.localStorageService.get("utm_term") !== '') {
      obj['utm_term'] = this.localStorageService.get("utm_term");
    }
    if (this.localStorageService.get("utm_matchtype") !== '') {
      obj['utm_matchtype'] = this.localStorageService.get("utm_matchtype");
    }
    if (this.localStorageService.get("utm_location") !== '') {
      obj['utm_location'] = this.localStorageService.get("utm_location");
    }
    if (this.localStorageService.get("utm_placement") !== '') {
      obj['utm_placement'] = this.localStorageService.get("utm_placement");
    }
    this.audienceService.registerViaMobileOrEmailNew(obj).subscribe(data => {
      if (formType == 'top') {
        this.registerloading = false;
        let res: any = data
        if (res.message == "success") {
          if (res.response == "email" || this.selectedCountryCode !== "+91") {
            this.form1OtpSent = "OTP has been Sent Successfully on Email."
          }
          else {

            this.form1OtpSent = "OTP Sent! Check your mobile phone."
          }
          this.otpSent = true;
          this.resenOtp = true;
          setTimeout(() => {
            this.disableResendOTp = false
            this.showTimer = false
          }, 30000)
          this.showTimer = true
        }
        else {
          this.existError = true;
          this.errorMsg = res.response;
          this.registerloading = false
        }
      }
      else if (formType == 'bottom') {
        this.registerloading2 = false;
        let res: any = data
        if (res.message == "success") {
          if (res.response == "email" || this.selectedCountryCode2 !== "+91") {

            this.form2Otpsent = "OTP has been Sent Successfully on Email."
          }
          else {

            this.form2Otpsent = "OTP Sent! Check your mobile phone"
          }
          this.otpSent2 = true;
          this.resenOtp2 = true;
          setTimeout(() => {
            this.disableResendOTp2 = false
            this.showTimer2 = false
          }, 30000)
          this.showTimer2 = true
        }
        else {
          this.existError2 = true;
          this.errorMsg2 = res.response
          this.registerloading2 = false;
        }
      }
      else if (formType = 'popup') {

        this.registerloadingp = false;
        let res: any = data
        if (res.message == "success") {
          if (res.response == "email" || this.selectedCountryCodep !== "+91") {

            this.formPOtpSent = "OTP has been Sent Successfully on Email."
          }
          else {

            this.formPOtpSent = "OTP Sent! Check your mobile phone"

          }
          this.otpSentp = true;
          this.resenOtpp = true;
          setTimeout(() => {
            this.disableResendOTpp = false
            this.showTimerp = false
          }, 30000)
          this.showTimerp = true
        }
        else {
          this.existErrorp = true;
          this.errorMsgP = res.response
          this.registerloadingp = false;
        }

      }
    }, error => {
      this.registerloading = false;
      swal({
        type: "error",
        title: "OOPS.",
        text:
          "Something Went Wrong Please Try After Sometime."
      });
    })

  }

  public sendTrackingEvent() {
    (<any>window).gtag('event', 'conversion',
      {
        'send_to': 'AW-849928441/Pz4mCL74ppIBEPnBo5UD',
        'value': this.localStorageService.get('mobile')
      });

    (<any>window).fbq('track', 'Lead');

    if (this.routeNew === 'WorkingProfessionals') {
      (<any>window).lintrk('track', { conversion_id: 9034346 });
    }
  }

  //calling verifyotp api
  callVerifyOtpApi(obj, formType) {
    this.localStorageService.set('mobile', obj.Mobile);

    if (this.localStorageService.get("utm_source") !== '') {
      obj['utm_source'] = this.localStorageService.get("utm_source");
    }
    if (this.localStorageService.get("gclid") !== '') {
      obj['gclid'] = this.localStorageService.get("gclid");
    }
    if (this.localStorageService.get("utm_medium") !== '') {
      obj['utm_medium'] = this.localStorageService.get("utm_medium");
    }
    if (this.localStorageService.get("utm_campaign") !== '') {
      obj['utm_campaign'] = this.localStorageService.get("utm_campaign");
    }
    if (this.localStorageService.get("utm_adgroup") !== '') {
      obj['utm_adgroup'] = this.localStorageService.get("utm_adgroup");
    }
    if (this.localStorageService.get("utm_device") !== '') {
      obj['utm_device'] = this.localStorageService.get("utm_device");
    }
    if (this.localStorageService.get("utm_term") !== '') {
      obj['utm_term'] = this.localStorageService.get("utm_term");
    }
    if (this.localStorageService.get("utm_matchtype") !== '') {
      obj['utm_matchtype'] = this.localStorageService.get("utm_matchtype");
    }
    if (this.localStorageService.get("utm_location") !== '') {
      obj['utm_location'] = this.localStorageService.get("utm_location");
    }
    if (this.localStorageService.get("utm_placement") !== '') {
      obj['utm_placement'] = this.localStorageService.get("utm_placement");
    }

    this.audienceService.verifyOTPViaMobileOrEmailNew(obj).subscribe(data => {
      if (formType == 'top') {

        let response: any = data;
        this.verifyOTPloading = false
        if (response.message !== 'failed') {

          let res: any = data

          let token: any = res.reponse.access_token

          if (res.message == 'success') {
            this.localStorageService.set("access_token", token);
            this.commonService.login();
            this.sendTrackingEvent()
            this.accountService.getAccountType().subscribe(
              accountType => {
                this.localStorageService.set("account_type", accountType);

                if (accountType === "student") {

                  this.isLoggedIn = true;
                  this.accountService.getStudentDetails().subscribe(
                    studentVar => {
                      this.studentCareerPathArray =
                        studentVar["selectedCareerPaths"];

                      if (!this.studentCareerPathArray) {

                        this.localStorageService.set(
                          "career_arr",
                          this.studentCareerPathArray.length
                        );
                      } else {
                        this.localStorageService.set(
                          "career_arr",
                          this.studentCareerPathArray.length
                        );
                      }
                    },
                    err => {
                      alert("Some error occured.");
                    }
                  );

                  this.accountService.GetStudentPath().subscribe(
                    path => {
                      if (this.type == 'college-admission' || this.type == 'cv-review') {
                        // this.selectPackageSwal()
                        // this.buyPackageCv(this.pricingId)
                        this.router.navigate(
                          ['/thank-you'],
                          { queryParams: { page: this.type } }
                        );
                      }
                      else {
                        this.router.navigate([path]);
                      }

                    },
                    err => {
                      alert("Some error occured, Please login again.");
                    }
                  );
                } else if (accountType === "counsellor") {
                  this.accountService.GetCounsellorPath().subscribe(
                    path => {

                      this.router.navigate([path]);
                    },
                    err => {
                      alert("Some error occured, Please login again.");
                    }
                  );
                } else {
                  alert("Invalid User");
                }
              },
              err => {

              }
            );
          }

          else {
            swal({
              type: "error",
              title: "Oop's.",
              text:
                "Error Try Again After Sometime."
            });
          }
        }
        else {
          this.verifyOTPloading = false
          swal({
            type: "error",
            title: "Oop's.",
            text:
              "Incorrect OTP ."
          });
        }

      }
      else if (formType == 'bottom') {
        let response: any = data;
        this.verifyOTPloading2 = false
        this.registerloading2 = false
        if (response.message !== 'failed') {

          let res: any = data

          let token: any = res.reponse.access_token

          if (res.message == 'success') {



            this.localStorageService.set("access_token", token);
            this.commonService.login();

            this.accountService.getAccountType().subscribe(
              accountType => {
                this.localStorageService.set("account_type", accountType);
                this.isLoggedIn = true;
                if (accountType === "student") {

                  this.accountService.getStudentDetails().subscribe(
                    studentVar => {

                      this.studentCareerPathArray =
                        studentVar["selectedCareerPaths"];

                      if (!this.studentCareerPathArray) {

                        this.localStorageService.set(
                          "career_arr",
                          this.studentCareerPathArray.length
                        );
                      } else {
                        this.localStorageService.set(
                          "career_arr",
                          this.studentCareerPathArray.length
                        );
                      }
                    },
                    err => {
                      alert("Some error occured.");
                    }
                  );

                  this.accountService.GetStudentPath().subscribe(
                    path => {

                      if (this.type == 'college-admission' || this.type == 'cv-review') {
                        // this.selectPackageSwal()
                        // this.buyPackageCv(this.pricingId)
                        this.router.navigate(
                          ['/thank-you'],
                          { queryParams: { page: this.type } }
                        );
                      }
                      else {
                        this.router.navigate([path]);
                      }
                    },
                    err => {
                      alert("Some error occured, Please login again.");
                    }
                  );
                } else if (accountType === "counsellor") {
                  this.accountService.GetCounsellorPath().subscribe(
                    path => {

                      this.router.navigate([path]);
                    },
                    err => {
                      alert("Some error occured, Please login again.");
                    }
                  );
                } else {
                  alert("Invalid User");
                }
              },
              err => {

              }
            );
          }
          else {
            swal({
              type: "error",
              title: "Oop's.",
              text:
                "Error Try Again After Sometime."
            });
          }
        }
        else {
          this.verifyOTPloading2 = false
          swal({
            type: "error",
            title: "Oop's.",
            text:
              "Incorrect OTP ."
          });
        }

      }
      else if (formType == 'popup') {
        let response: any = data;
        this.verifyOTPloadingp = false
        if (response.message !== 'failed') {

          let res: any = data

          let token: any = res.reponse.access_token

          if (res.message == 'success') {

            this.dialog.closeAll()

            this.localStorageService.set("access_token", token);
            this.commonService.login();

            this.accountService.getAccountType().subscribe(
              accountType => {
                this.localStorageService.set("account_type", accountType);

                if (accountType === "student") {
                  this.isLoggedIn = true;

                  this.accountService.getStudentDetails().subscribe(
                    studentVar => {

                      this.studentCareerPathArray =
                        studentVar["selectedCareerPaths"];

                      if (!this.studentCareerPathArray) {

                        this.localStorageService.set(
                          "career_arr",
                          this.studentCareerPathArray.length
                        );
                      } else {
                        this.localStorageService.set(
                          "career_arr",
                          this.studentCareerPathArray.length
                        );
                      }
                    },
                    err => {
                      alert("Some error occured.");
                    }
                  );

                  this.accountService.GetStudentPath().subscribe(
                    path => {
                      if (this.type == 'college-admission' || this.type == 'cv-review') {

                        // this.buyPackagePaynew(this.pricingId)
                        this.router.navigate(
                          ['/thank-you'],
                          { queryParams: { page: this.type } }
                        );
                      }
                      else {
                        if (!this.pricingId) {
                          this.router.navigate([path]);
                        }

                        if (this.pricingId) {
                          this.stackMyPlanOnly = true
                          this.classWithPackIdArr.forEach(element => {
                            if (element.class == obj.educationalClass && (element.buyId == this.pricingId || element.buyIdPlus == this.pricingId)) {
                              this.buyPackageCvOnlyNew(this.pricingId)
                              this.stackMyPlanOnly = false
                            }
                          });
                          if (this.stackMyPlanOnly) {
                            this.showStackedPlans(obj.educationalClass)
                          }
                        }

                      }


                    },
                    err => {
                      alert("Some error occured, Please login again.");
                    }
                  );
                } else if (accountType === "counsellor") {
                  this.accountService.GetCounsellorPath().subscribe(
                    path => {

                      this.router.navigate([path]);
                    },
                    err => {
                      alert("Some error occured, Please login again.");
                    }
                  );
                } else {
                  alert("Invalid User");
                }
              },
              err => {

              }
            );
          }
          else {
            swal({
              type: "error",
              title: "Oop's.",
              text:
                "Error Try Again After Sometime."
            });
          }
        }
        else {
          this.verifyOTPloadingp = false
          swal({
            type: "error",
            title: "Oop's.",
            text:
              "Incorrect OTP ."
          });
        }
      }
    }, error => {
      this.verifyOTPloading = false
      swal({

        type: "error",
        title: "Oop's.",
        text:
          "Something Went Wrong Please Try Again."
      });
    })
  }

  //for getting the packages according to corresponding page
  getAllPackages() {


    this.contentService.getAllPackages().subscribe(
      response => {
        this.packages = response
        if (this.type == 'audience-10-12') {
          this.packageType = 'advance';
          this.Package1 = this.packages.find(d => d.name === 'Achieve Plus+');
          this.Package2 = this.packages.find(d => d.name === 'Achieve Online');

        }
        if (this.type == 'audience-8-9') {
          this.packageType = 'basic';
          this.Package1 = this.packages.find(d => d.name === 'Discover Offline');
          this.Package2 = this.packages.find(d => d.name === 'Discover Online');

        }
        if (this.type == 'audience-college-graduate') {
          this.packageType = 'higher';
          this.Package1 = response.find(d => d.name === 'Ascend Plus+');
          this.Package2 = response.find(d => d.name === 'Ascend Online');

        }
        if (this.type == 'audience-working-professional') {
          this.packageType = 'higher';
          this.Package1 = response.find(d => d.name === 'Ascend Plus+');
          this.Package2 = response.find(d => d.name === 'Ascend Online');
        }

      }, error => { });
  }

  //for calling getStudent api
  public getStudent() {
    this.contentService.GetStudentDetails().subscribe(
      response => {
        if (response.currentStatus === 'Other-Work' || response.currentStatus === 'Other-Working Professional') {
          this.userClass = true;
        }
        if (response.currentStatus.indexOf('10') > -1 || response.currentStatus.indexOf('11') > -1 || response.currentStatus.indexOf('12') > -1) {
          this.showPurchasePackageButton = true;
        }
      },
      error => {

      });
  }

  //For removing the border on focus
  removeErrorBoredrOnFocus(form, error) {
    /**
     * form=1 indicates top form
     * form->2 indicates bottom form
     * form->3 indicates popup form
     * error->1 indicates firstName error 
     * error->2 indicates last name error 
     * error->3 indicates phone no. error
     * error->4 indicates email error
     * error ->5 indicates class error
     */
    if (form == 1) {
      if (error == 1) {
        this.form1NameError = false
      }
      else if (error == 2) {
        this.form1LastNameError = false
      }
      else if (error == 3) {
        this.form1PhoneError = false
      }
      else if (error == 4) {
        this.form1EmailError = false
      }
      else if (error == 5) {
        this.class1error = false
      }
    }
    if (form == 2) {
      if (error == 1) {
        this.form2NameError = false
      }
      if (error == 2) {
        this.form2LastNameError = false
      }
      else if (error == 3) {
        this.form2PhoneError = false
      }
      else if (error == 4) {
        this.form2EmailError = false
      }
      else if (error == 5) {
        this.class2error = false
      }
    }
    if (form == 3) {
      if (error == 1) {
        this.formPNameError = false
      }
      else if (error == 2) {
        this.formPLastNameError = false
      }
      else if (error == 3) {
        this.formPPhoneError = false
      }
      else if (error == 4) {
        this.formPEmailError = false
      }
      else if (error == 5) {
        this.classperror = false
      }
    }
  }


  buyPackage() {
    if (this.isLoggedIn) {
      this.router.navigate(['/payment/select-package', this.Package1.id], { queryParams: { redirect: 'Audience : /' + this.routeNew } });
    }
    else {
      this.onScrolltoTop();
    }
  }

  //for redirecting to the respective package payment gateway 
  buyPackageCv(id) {

    if (this.isLoggedIn) {
      if (this.type == 'college-admission' || this.type == 'cv-review') {
        this.buyPackagePaynew(id);
        //this.getPaymentData(id)
      }
      else if (this.type == 'career-guidance') {
        if (this.class == this.educationalBackgroundOfStudent) {
          this.router.navigate(['/payment/select-package', id], { queryParams: { redirect: 'Audience : /' + this.routeNew } });
        }
        else {
          this.router.navigate(['/payment/select-package', id], { queryParams: { multipackage: true, redirect: 'Audience : /' + this.routeNew } });
        }
      }
      else {
        if (this.educationalBackgroundOfStudent == this.dataObj.class) {
          this.router.navigate(['/payment/select-package', id], { queryParams: { redirect: 'Audience : /' + this.routeNew } });
        } else {

          this.router.navigate(['/payment/select-package', id], { queryParams: { multipackage: true, redirect: 'Audience : /' + this.routeNew } });
        }
      }
    }
    else {
      this.onScrolltoTop();
    }
  }

  //if the selected class while registering from popup of pricing and the package selected for buy now belongs to same class then redirects to only the respective package's payment gateway instead of multipackage
  buyPackageCvOnlyNew(id) {
    this.router.navigate(['/payment/select-package', id], { queryParams: { redirect: 'Audience : /' + this.routeNew } });

  }

  //for redirectiong to respective package ccavenue for college admissions and cv reviews
  buyPackagePaynew(id) {


    this.loading = true;
    let obj = {
      'couponCode': '',
      'packageId': id,
      'sourceUrl': 'Audience : /' + this.routeNew,
    }
    // if (this.localStorageService.get('mobile') == '987810501011') {
    //   obj.couponCode = id
    // }
    this.paymentService.EncryptData(obj).subscribe(
      response => {

        this.loading = false;

        this.router.navigate(['/payment/make-payment', response.encRequest]);

      },
      error => {

        this.loading = false;
      });
  }

  //redirects to the respective students path
  redirectToStudentPath() {

    this.router.navigate([this.studentPath])
  }
  //for opening dialog
  openDialog(registerModal, id): void {
    if (id !== 0) {
      this.pricingId = id

    }

    const dialogRef = this.dialog.open(registerModal, {
      width: '60%',
      disableClose: false,
      panelClass: "custom-modalbox"
    });

    dialogRef.afterClosed().subscribe(result => {

    });
  }
  //for closing dialog
  closeDialog(): void {
    this.dialog.closeAll();
  }

  //open dilog on post payment(After patyment successfull) for further insrtuctions
  openPaymentRedirectDialog(): void {
    let data: any;
    if (this.isLoggedIn && !this.testCompleted) {
      data = "Begin the psychometric test and book your session with a counsellor"
    }
    else if (this.isLoggedIn && !this.paidPackageSelected && this.testCompleted) {
      data = " Book your session with a counsellor"
    }
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '40%',
      data: { route: this.studentPath, message: data }
    });

    dialogRef.afterClosed().subscribe(result => {
      []
    });
  }


  public getPaymentData(id) {
    if (id == 'viaCoupon') {
      setTimeout(() => {
        this.openPaymentRedirectDialog()
      }, 1000)
      // this.openPaymentRedirectDialog()
    }
    else {
      this.paymentService.getPaymentData(id).subscribe(
        response => {

          this.paymentData = response;

          if (this.paymentData != null && this.paymentData.orderStatus === 'Success') {
            this.sendEvent(this.paymentData.uId);
            if (this.routeNew == "CollegeAdmissions" || this.routeNew == "JobReadyPlans") {

              if (this.routeNew == "CollegeAdmissions" && this.isLoggedIn) {
                this.collegeAdmissionPaymentSuccess = true;
              }
              if (this.routeNew == "JobReadyPlans" && this.isLoggedIn) {
                this.cvPaymentSuccess = true
              }

              setTimeout(() => {
                this.scrollOnPaymentSuccess()

              }, 500)
            } else {
              setTimeout(() => {
                this.openPaymentRedirectDialog()
              }, 500)

            }
            // }
          } else {
            swal({
              type: "error",
              title: "Oops.",
              text:
                "Transaction did not complete successfully."
            });
          }
        },
        error => {

        });
    }
  }


  sendEvent(trackingId) {

    (<any>window).gtag('event', 'conversion',

      {

        'send_to': 'AW-849928441/5TxpCNm8pagBEPnBo5UD',

        'value': this.paymentData.amount,

        'currency': 'INR',

        'transaction_id': trackingId

      });

    (<any>window).fbq('track', 'Purchase', { value: this.paymentData.amount, currency: 'INR' });

  }

  //open the select package swal for college admissions and cv review after registring from pricing buy now
  selectPackageSwal() {
    var element = document.getElementById("PricingcollegeAd");

    element.scrollIntoView({ behavior: 'smooth', block: "start", inline: 'start' });
    // window.scrollTo(0, document.getElementById('PricingcollegeAd').offsetTop - 140);

    swal({
      backdrop: false,

      heightAuto: false,
      type: "info",
      text: "Please select your plan.",
      showConfirmButton: false,
      showCancelButton: false,
      timer: 1000,
      onAfterClose: () =>
        document.getElementById("PricingcollegeAd").scrollIntoView({ behavior: 'smooth', block: "start", inline: 'nearest' })
      //  window.scrollTo(0, document.getElementById('PricingcollegeAd').offsetTop - 100)

    })

  }
  onIndexChange(event) {

    this.activeIndex2 = event;
  }

  //for making the otp fileds compatible for copy paste
  pasteOTP(event: ClipboardEvent, id) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    let arr = pastedText.split('')
    if (id == 1) {
      this.registerForm.controls['otpdigit1'].setValue(arr[0]);
      this.registerForm.controls['otpdigit2'].setValue(arr[1]);
      this.registerForm.controls['otpdigit3'].setValue(arr[2]);
      this.registerForm.controls['otpdigit4'].setValue(arr[3]);

    }
    if (id == 2) {
      this.registerForm2.controls['otpdigit1'].setValue(arr[0]);
      this.registerForm2.controls['otpdigit2'].setValue(arr[1]);
      this.registerForm2.controls['otpdigit3'].setValue(arr[2]);
      this.registerForm2.controls['otpdigit4'].setValue(arr[3]);

    }
    if (id == 3) {
      this.registerFormpopup.controls['otpdigit1'].setValue(arr[0]);
      this.registerFormpopup.controls['otpdigit2'].setValue(arr[1]);
      this.registerFormpopup.controls['otpdigit3'].setValue(arr[2]);
      this.registerFormpopup.controls['otpdigit4'].setValue(arr[3]);

    }

  }

  //move cursor back and forth on key press or backspace
  onDigitInput(event) {

    let element;
    var key = event.keyCode || event.charCode;
    if (event.code !== 'Backspace')
      element = event.srcElement.nextElementSibling;

    if (event.code === 'Backspace' || key == 8 || key == 46)
      element = event.srcElement.previousElementSibling;

    if (element == null)
      return;
    else
      element.focus();



  }
  //for calling get student api
  callGetStudenApi() {
    this.sharedService.GetStudentDetails().subscribe(
      data1 => {
        let res: any = data1
        this.educationalBackgroundOfStudent = res.educationalBackground

        this.testCompleted = res.testCompleted;
        this.paidPackageSelected = res.paidPackageSelected;
      },
      error => {

        if (error["status"] === 401) {
        }
      }
    );
  }
  calGetCareeSessionPreference() {
    this.contentService.getPreferenceForStudentId().subscribe(data => {
      let response: any = data
      this.isBrainStormPackagePurchased = response.packagePurchased

      console.log("Response==>", response)
    })
  }

}


@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'dialog-overview-example-dialog.html',
})
export class DialogOverviewExampleDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, public router: Router
  ) {
  }

  onNoClick(): void {
    this.router.navigate([this.data.route])
    this.dialogRef.close();
  }

}