import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { SharedService } from '../shared.service';
import swal from 'sweetalert2';
import { CommonService } from 'src/app/common/common.service';
import { StorageService } from 'src/app/common/storage-service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  platformId: string;
  screen: string;
  public _opened: boolean = false;
  public Responses;
  public authToken: any = ''
  public blogUrlToken = "https://blog.mentoria.com"
  public careerURL: any = "https://blog.mentoria.com/career"
  constructor(private router: Router, private sharedService: SharedService, @Inject(PLATFORM_ID) platformId: string, public commonService: CommonService, private storageService: StorageService) {
    this.platformId = platformId;
  }

  ngOnInit() {
    this.initmethod();
    let authToken = this.storageService.get('access_token');
    if (authToken !== '') {
      this.blogUrlToken = this.blogUrlToken + "?token=" + authToken
      this.careerURL=this.careerURL+ "?token=" + authToken
    }
  }


  initmethod() {
    if (isPlatformBrowser(this.platformId)) {
      let ua = window.navigator.userAgent;
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
        this.screen = 'mobile';
      } else {
        this.screen = 'web';
      }
    }
  }
  SubscribeToNewsLetter(email) {
    var pattern = /^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+.([a-zA-Z])+([a-zA-Z])+/;
    var isValid = pattern.test(email);
    if (email.value === '' || (isValid === false)) {
      swal({
        type: 'error',
        title: 'Uh oh! <i class="fa fa-frown-o"></i> ',
        text: 'Please enter a valid email id to sign up.',
        confirmButtonClass: 'submit-purple-swal-btn',
        cancelButtonClass: 'cancel-danger-swal-btn',
        buttonsStyling: true,
      })
    } else {
      this.sharedService.SubscribeToNewsLetter(email).subscribe(
        response => {
          // You have already subscribed

          this.Responses = response;
          if (this.Responses.message === "success") {
            swal({
              type: 'success',
              title: 'Great <i class="fa fa-thumbs-up"></i>',
              text: 'Your email account is subscribed for our newsletter!',
              confirmButtonClass: 'submit-purple-swal-btn',
              cancelButtonClass: 'cancel-danger-swal-btn',
              buttonsStyling: true,
            })
          }
          else if (this.Responses.message === "You have aleady subscribed.") {
            swal({
              type: 'success',
              title: 'Great <i class="fa fa-thumbs-up"></i>',
              text: 'Your email account is already subscribed for our newsletter!',
              confirmButtonClass: 'submit-purple-swal-btn',
              cancelButtonClass: 'cancel-danger-swal-btn',
              buttonsStyling: true,
            })
          }
        },
        error => {

        });
    }

    (<any>window).gtag('event', 'newsletter',
      {
        'send_to': 'AW-849928441/5TxpCNm8pagBEPnBo5UD',
        'event_category': 'newsletter',
        'event_action': 'clcik to newsletter sinup',
        'event_label': 'newsletter signup',

      });

  }

  public _toggleOpened(): void {
    this._opened = !this._opened;
  }
  GoToWebinars() {
    const webinar = 'https://www.youtube.com/channel/UCEIg-KBm4hlWy4vhPoE1lVg';
    window.open(webinar, '_blank');
  }
  getPreLoginLink(path) {
    debugger
    this.authToken = this.storageService.get("access_token") || '';
    let url = this.commonService.getpreloginAppURL()

    let newPath = url + '/redirect?authToken=' + this.authToken + '&redirectURl=' + encodeURI(url + path);
    return newPath
  }
}
