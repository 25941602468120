import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService } from '../accounts/accounts.service';
import { StorageService } from '../common/storage-service';


@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent implements OnInit {

  public redirectURI = '';
  public authToken = '';
  public eventType = '';
  public propertyUId = '';
  public userInfo: any;
  public isuserLoggedIn: Boolean = false
  public studentCareerPathArray: any
  constructor(private route: ActivatedRoute, private localStorageService: StorageService, public accountService: AccountsService, public router: Router) {
    // this.userLoggedOut()
    this.route.queryParams.subscribe(params => {
      this.redirectURI = params["redirectURl"] || "";
      this.authToken = params["authToken"] || "";
      this.localStorageService.set("utm_source", params["utm_source"] || "")
      this.localStorageService.set("utm_medium", params["utm_medium"] || "")
      this.localStorageService.set("utm_campaign", params["utm_campaign"] || "")
      this.localStorageService.set("utm_adgroup", params["utm_adgroup"] || "")
      this.localStorageService.set("utm_device", params["utm_device"] || "")
      this.localStorageService.set("utm_term", params["utm_term"] || "")
      this.localStorageService.set("utm_matchtype", params["utm_matchtype"] || "")
      this.localStorageService.set("utm_location", params["utm_location"] || "")
      this.localStorageService.set("utm_placement", params["utm_placement"] || "")

    });
    if (this.authToken !== '') {
      this.isuserLoggedIn = true
    }
  }

  ngOnInit() {
    if (this.isuserLoggedIn) {
      this.userLoggedIn()
    }
    else {
      this.userLoggedOut()
    }
  }
  /***********************************************************************
   * userLoggedIn()
   * purpose- to set All Localstorage if user is logged In
   ***********************************************************************/
  userLoggedIn() {
    this.localStorageService.set("access_token", this.authToken);
    this.accountService.getAccountType().subscribe(
      accountType => {
        this.localStorageService.set("account_type", accountType);
        console.log(accountType);
        if (accountType === "student") {
          this.accountService.getStudentDetails().subscribe(
            studentVar => {
              this.studentCareerPathArray =
                studentVar["selectedCareerPaths"];

              if (!this.studentCareerPathArray) {
                this.localStorageService.set(
                  "career_arr",
                  this.studentCareerPathArray.length
                );
              } else {
                this.localStorageService.set(
                  "career_arr",
                  this.studentCareerPathArray.length
                );
              }
            },
            err => {
              alert("Some error occured.");
            }
          );

          this.accountService.GetStudentPath().subscribe(
            path => {

              window.open(this.redirectURI, '_self')
              console.log(this.redirectURI);
            },
            err => {
              alert("Some error occured, please login again.");
            }
          );
        } else if (accountType === "counsellor") {
          this.accountService.GetCounsellorPath().subscribe(
            path => {
              console.log(path);
              this.router.navigate([path]);
            },
            err => {
              alert("Some error occured, please login again.");
            }
          );
        } else {
          alert("Invalid User");
        }
      },
      err => {
        console.log(err);
      }
    );
  }
  /***********************************************************************
  * userLoggedOut()
  * purpose- to Clear All Localstorage if user is logged out
  ***********************************************************************/
  userLoggedOut() {
    // this.localStorageService.clearAll();
    this.localStorageService.set("access_token", this.authToken);
    window.open(this.redirectURI, '_self')
  }
}


