import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountsService } from '../accounts.service';
import { RouteSlideStateTrigger } from '../../common/route-animations';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css', '../accounts.module.css', '../../shared/shared.module.scss'],
  animations: [RouteSlideStateTrigger]
})
export class ForgotPasswordComponent implements OnInit {
  // @HostBinding('@routeSlideState') routeAnimation = false;

  mobile: string;
  public reqObj;
  public errorMsg = false;
  constructor(private router: Router, private accountService: AccountsService) { }

  ngOnInit() {
  }

  public ForgotRequest(mobile) {
    console.log('mobile= ' + mobile);
    this.reqObj = {
      Mobile: mobile
    };


    if (mobile === undefined || mobile === '' || mobile === null) {

    } else {
      this.accountService.PasswordRequest(this.reqObj).subscribe(
        data => {
          console.log('request accepted= ' + data);
          this.router.navigate(['/accounts/reset-password']);
        },
        err => {
          alert('Invalid request!');
          this.errorMsg = true;
        }
      );
    }
  }
}
