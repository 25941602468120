import { SharedModule } from './../shared/shared.module';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { PaymentRoutingModule } from './payment-routing.module';

import { PaymentService } from './payment.service';
import { SelectPackageComponent } from './select-package/select-package.component';
import { MakePaymentComponent } from './make-payment/make-payment.component';
import { PackageListComponent } from './package-list/package-list.component';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { PackageListsComponent } from './package-lists/package-lists.component';
import { SharePaymentLinkComponent } from './share-payment-link/share-payment-link.component';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MatDatepickerModule } from '@angular/material/datepicker'

import { MatNativeDateModule } from '@angular/material';
import { LazyPayStatusComponent } from './lazy-pay-status/lazy-pay-status.component';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    PaymentRoutingModule,
    NgbModule,
    SharedModule,
    FlexLayoutModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  providers: [PaymentService],
  declarations: [SelectPackageComponent, MakePaymentComponent, PaymentStatusComponent, PackageListComponent, PackageListsComponent, SharePaymentLinkComponent, LazyPayStatusComponent
]
})
export class PaymentModule { }
