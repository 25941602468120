export class model {
  constructor() {
    this.Gender = '';
    this.CurrentClass = '';
    this.CurrentStatus = '';
  }
  public Gender: string;
  public CurrentClass: string;
  public CurrentStatus: string;
  public FirstName: string;
  public LastName: string;
  public Mobile: string;
  public Password: string;
  public Email: string;
  public ReferralCode: string;
  public age: number;
  public source: string;
}

