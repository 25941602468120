import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-path',
  templateUrl: './path.component.html',
  styleUrls: ['./path.component.css','../newcareer/newcareer.component.css','../career-overview-rewamp.module.css']
})
export class PathComponent implements OnInit {
  @Input() path
  constructor() { }

  ngOnInit() {
  }

}
