import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { BlogsService } from '../blog.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-blog-details-page',
  templateUrl: './blog-details-page.component.html',
  styleUrls: ['./blog-details-page.component.css']
})
export class BlogDetailsPageComponent implements OnInit {


  public uid;
  platformId
  blog
  newBlog = false
  loading = true

  constructor(public blogService: BlogsService, @Inject(PLATFORM_ID) platformId: string, private route: ActivatedRoute, ) {
    this.platformId = platformId;

    this.route.params.subscribe(params => {
      let uId = params['id'] || '';
      if (uId !== '') {
        this.uid = uId;
        this.getBlogArticleDetails(this.uid);
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0, 0);
        }
      }
    });
  }

  ngOnInit() {
  }


  getBlogArticleDetails(uid) {
    this.loading = true
    this.blogService.getBlogByLinkId(uid).subscribe(
      response => {
        this.blog = response;
        if(this.blog.newContentList.length === undefined ||this.blog.newContentList.length === null || this.blog.newContentList.length === 0 ){
          this.newBlog = false
          console.log(this.blog)
          console.log(this.newBlog)

        }else{
          this.newBlog = true
        }
        this.loading = false
      })
    error => {
      
      this.loading = false
    };
  }

}
