import { StorageService } from '../../common/storage-service';
import { AccountsService } from './../accounts.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-set-to-roll',
  templateUrl: './set-to-roll.component.html',
  styleUrls: ['./set-to-roll.component.css','../accounts.module.css']
})
export class SetToRollComponent implements OnInit {
public accounttype;
constructor(private router: Router, private localStorageService:StorageService, private accountService: AccountsService) { }

  ngOnInit() {
    this.accounttype = this.localStorageService.get('account_type');
  }
Start() {
  if (this.accounttype === 'student') {
    this.router.navigate(['/accounts/onboarding']);
  } else if (this.accounttype === 'counsellor') {
    this.accountService.GetCounsellorPath().subscribe(path => {
      this.router.navigate([path]);
    }, err => {
      alert('Some error occured, please login again.');
    });
  } else {
    alert("Invalid User");
   }
}
}
