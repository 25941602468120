import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { CommonService } from '../../common/common.service';
import { BlogsService } from '../blog.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css']
})
export class BlogsComponent implements OnInit {

  @Input() painationList = false
  @Input() blogs = [];
  @Input() totalRecords
  @Input() selectedPage
  public isHovering = 0;
  p: number = 1;
  @Output() filterToggle = new EventEmitter<object>();
  @Output() link = '/blog/page'

  constructor(private titleService: Title, private metaService: Meta, public commonService: CommonService, public blogService: BlogsService) { }

  ngOnInit() {
    console.log('heee', this.blogs)
    this.createBlogHREF();
    console.log("Form Blog=>", this.selectedPage)
   
    if(this.selectedPage!==null || this.selectedPage!==undefined)
    {
      this.p = this.selectedPage
      this.pageChanged(this.selectedPage)
    } else {
      this.selectedPage = 1
      this.p = 1
    }
  }

  shareBlogForFacebook(blog) {
    this.titleService.setTitle(blog.title);
    this.metaService.addTag({ property: 'description', content: blog.title });
    this.metaService.addTag({ property: 'og:title', content: blog.title });
    this.metaService.addTag({ property: 'og:description', content: blog.snippet });
    this.metaService.updateTag({ property: 'og:image', content: blog.imageUrl });
    this.metaService.updateTag({ property: 'og:image:width', content: '533' });
    this.metaService.updateTag({ property: 'og:image:height', content: '320' });
    this.metaService.updateTag({ property: 'og:image:type', content: 'image/jpeg' });
    // og:image:width and og:image:height
    this.metaService.addTag({ property: 'og:url', content: this.commonService.getAppUrl() + '/blog/' + blog.linkId });
    this.metaService.addTag({ property: 'og:type', content: 'article' });
    this.metaService.addTag({ property: 'og:site_name', content: 'The Mentoria Blog' });
    this.metaService.addTag({ property: 'og:locale', content: 'en_US' });
    this.metaService.addTag({ property: 'og:type', content: 'article' });
    this.metaService.addTag({ property: 'og:image:secure_url', content: blog.imageUrl });
  }

  shareBlogForTwitter(blog) {
    this.titleService.setTitle(blog.title);
    this.metaService.addTag({ property: 'description', content: blog.title });
    this.metaService.addTag({ property: 'og:title', content: blog.title });
    this.metaService.addTag({ property: 'og:description', content: blog.snippet });
    this.metaService.addTag({ property: 'og:image', content: blog.imageUrl });
    this.metaService.addTag({ property: 'og:url', content: this.commonService.getAppUrl() + '/blog/' + blog.linkId });
    this.metaService.addTag({ property: 'og:site_name', content: 'The Mentoria Blog' });
    this.metaService.addTag({ property: 'og:locale', content: 'en_US' });
    this.metaService.addTag({ property: 'og:type', content: 'website' });
    this.metaService.addTag({ property: 'og:image:secure_url', content: blog.imageUrl });

    this.metaService.addTag({ property: 'twitter:card', content: 'summary' });
    // this.metaService.addTag({ property: 'twitter:site', content: '@mymentoria' });

    // this.metaService.addTag({ property: 'twitter:description', content: blog.snippet });
    // this.metaService.addTag({ property: 'twitter:title', content: blog.title });
    // this.metaService.addTag({ property: 'twitter:image', content: blog.imageUrl });
  }

  shareBlogForWhatsapp(blog) {
    this.titleService.setTitle(blog.title);
    this.metaService.addTag({ property: 'description', content: blog.title });
    this.metaService.addTag({ property: 'og:title', content: blog.title });
    this.metaService.addTag({ property: 'og:description', content: '' }); // blog.snippet
    this.metaService.addTag({ property: 'og:image', content: blog.imageUrl });
    this.metaService.addTag({ property: 'og:url', content: this.commonService.getAppUrl() + '/blog/' + blog.linkId });
    this.metaService.addTag({ property: 'og:site_name', content: 'The Mentoria Blog' });
    this.metaService.addTag({ property: 'og:locale', content: 'en_US' });
    this.metaService.addTag({ property: 'og:type', content: 'website' });
    this.metaService.addTag({ property: 'og:image:secure_url', content: blog.imageUrl });
  }

  createBlogHREF() {
    // let appUrl = 'http://localhost:4200'
    let appUrl = this.commonService.getAppUrl()
    this.blogs.forEach(blog => {
      blog['blogHref'] = appUrl + '/blog/' + blog.linkId + '?category=' + this.blogService.currentCategory
    })
  }


  // pageChanged(event) {

  //   let Obj = {

  //     type: "pageChange",

  //   };

  //   this.filterToggle.emit(Obj);

  // }

  pageNumber

  pageChanged(pageNo) {
    this.p =pageNo
    let Obj = {

      type: "pageChange",
      Take: 15,
      pageNumber: pageNo

    };
    this.filterToggle.emit(Obj);


  }

}
