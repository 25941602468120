import { MakePaymentComponent } from './make-payment/make-payment.component';
import { SelectPackageComponent } from './select-package/select-package.component';
import { PackageListComponent } from './package-list/package-list.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PaymentStatusComponent } from './payment-status/payment-status.component';
import { PackageListsComponent } from './package-lists/package-lists.component';
import { SharePaymentLinkComponent } from './share-payment-link/share-payment-link.component';
import { LazyPayStatusComponent } from './lazy-pay-status/lazy-pay-status.component';

const paymentRoutes: Routes = [
  { path: '', redirectTo: 'packages', pathMatch: 'full' },
  { path: 'select-package/:id', component: SelectPackageComponent },
  { path: 'make-payment/:token', component: MakePaymentComponent },
  { path: 'packages', component: PackageListComponent },
  { path: 'package', component: PackageListsComponent },
  { path: 'status/:id', component: PaymentStatusComponent },
  { path: 'pay/:paymentLinkId', component: SharePaymentLinkComponent},
  { path: 'lp-status', component: LazyPayStatusComponent },
]

@NgModule({
  imports: [
    RouterModule.forChild(paymentRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class PaymentRoutingModule { }
