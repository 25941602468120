import { Injectable, EventEmitter } from '@angular/core';
import { StorageService } from './storage-service';

@Injectable()
export class CommonService {
  private apiUrl: string;
  private stsUrl: string;
  private qmsUrl: string;
  private appUrl: string;
  private preloginAppURL: string;
  public commonEventEmitter: EventEmitter<any> = new EventEmitter();

  // following variables are used in content pages

  public pathData = '';
  public blogTag = '';
  public tag = '';
  lazyPayUrl = '';

  constructor() {
    // LOCAL
    // this.apiUrl = 'http://localhost:57685';
    // this.qmsUrl = 'http://127.0.0.1:60806';
    // this.appUrl = 'http://localhost:4200';
    // this.stsUrl = 'https://localhost:44333';

    // STAGING URL

    // this.apiUrl = 'https://stagingapi.mentoria.com';
    // this.qmsUrl = 'https://mentoria-qms-staging.azurewebsites.net';
    // this.appUrl = 'https://postlogin-dev.azurewebsites.net/';
    // this.stsUrl = 'https://mentoriasts-staging.azurewebsites.net';
    // this.lazyPayUrl = 'https://sboxsecure.lazypay.in/cof/redirect-to-lp?token='
    // this.preloginAppURL = 'https://mentoriaprelogin-dev.azurewebsites.net'


    // PRODUCTION
    this.apiUrl = 'https://mentoriaapi.azurewebsites.net';
    this.qmsUrl = 'https://qms.mentoria.com';
    this.appUrl = 'https://app.mentoria.com';
    this.stsUrl = 'https://mentoriasts.azurewebsites.net';
    this.lazyPayUrl = 'https://secure.lazypay.in/cof/redirect-to-lp?token='
    this.preloginAppURL = 'https://www.mentoria.com'

    //Local
    // this.apiUrl = 'https://stagingapi.mentoria.com';
    // this.qmsUrl = 'https://mentoria-qms-staging.azurewebsites.net';
    // this.appUrl = 'http://localhost:4202';
    // this.stsUrl = 'https://mentoriasts-staging.azurewebsites.net';
    // this.lazyPayUrl = 'https://sboxsecure.lazypay.in/cof/redirect-to-lp?token='
    // this.preloginAppURL = 'http://localhost:4200'

  }

  public getApiUrl() {
    return this.apiUrl;
  }
  public getStsUrl() {
    return this.stsUrl;
  }

  public getQMSUrl() {
    return this.qmsUrl;
  }
  public getAppUrl() {
    return this.preloginAppURL;
  }

  public getLazyPayUrl() {
    return this.lazyPayUrl
  }

  public login() {
    this.commonEventEmitter.emit({ 'type': 'login' });
  }
  public logout() {
    this.commonEventEmitter.emit({ 'type': 'logout' });
  }
  public saveFeedback(contentType, content) {
    this.commonEventEmitter.emit({ 'type': 'feedback', 'contentType': contentType, 'content': content });
  }

  public updateUserInfo() {
    this.commonEventEmitter.emit({ 'type': 'getUserdetails' });
  }

  public emitEvent(eventType) {
    this.commonEventEmitter.emit({ 'type': eventType });
  }

  public getCommonEventListener() {
    return this.commonEventEmitter;
  }
  public getpreloginAppURL() {
    return this.preloginAppURL;
  }

}

