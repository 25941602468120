
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './../shared/shared.module';

import { RouterModule } from '@angular/router';
import { BlogsComponent } from './blogs/blogs.component';

import { ShareModule } from '@ngx-share/core';

import { BlogAuthorComponent } from './blog-author/blog-author.component';
import { SidebarModule } from 'ng-sidebar';
import { NgxPaginationModule } from 'ngx-pagination';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatExpansionModule, MatSidenavModule, MatToolbarModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import {
    SwiperModule, SwiperConfigInterface,
    SWIPER_CONFIG
} from 'ngx-swiper-wrapper';
import { HttpClientModule } from '@angular/common/http';
import { BlogListComponent } from './blog-list/blog-list.component';
import { BlogsService } from './blog.service';
import { BlogArticleComponent } from './blog-article/blog-article.component';
import { BlogCategoriesComponent } from './blog-categories/blog-categories.component';
import { BlogCategoryComponent } from './blog-category/blog-category.component';
import { BlogFilterComponent } from './blog-filter/blog-filter.component';
import { MatSelectModule, MatCardModule, MatInputModule, MatNativeDateModule, MatDividerModule, MatIconModule, MatButtonModule, MatFormFieldModule, MatRippleModule } from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BlogArticleDetailComponent } from './blog-article-detail/blog-article-detail.component';
import { BlogDetailsPageComponent } from './blog-details-page/blog-details-page.component'


const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    observer: true,
    direction: 'horizontal',
    threshold: 50,
    spaceBetween: 5,
    slidesPerView: 1,
    centeredSlides: true
};

@NgModule({
    imports: [
        SidebarModule,
        CommonModule,
        HttpClientModule,
        FormsModule,
        NgbModule,
        RouterModule,
        SharedModule,
        ShareModule,
        NgxPaginationModule,
        SwiperModule,
        FlexLayoutModule,
        MatSidenavModule,
        MatToolbarModule,
        MatExpansionModule,
        MatCheckboxModule,
        InfiniteScrollModule,
        MatTabsModule,
        MatDatepickerModule,
        MatInputModule,
        MatButtonModule,
        MatFormFieldModule,
        MatRippleModule,
        MatNativeDateModule,
        // MatPaginatorModule,

       
    ],
    providers: [BlogsService, {
        provide: SWIPER_CONFIG,
        useValue: DEFAULT_SWIPER_CONFIG
    }],
    declarations: [BlogsComponent, BlogArticleComponent, BlogAuthorComponent, BlogListComponent, BlogCategoriesComponent, BlogCategoryComponent, BlogFilterComponent, BlogArticleDetailComponent, BlogDetailsPageComponent]
})
export class BlogsModule { }
