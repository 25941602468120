import { StorageService } from '../../common/storage-service';
import { Component, PLATFORM_ID, Inject, OnInit} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CareerOverviewService } from '../career-overview.service';
import { isPlatformBrowser } from '@angular/common';
import { Title, Meta } from '@angular/platform-browser';
import { CommonService } from '../../common/common.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-growth',
  templateUrl: './growth.component.html',
  styleUrls: ['./growth.component.css', '../career-overview.module.css']
})
export class GrowthComponent implements OnInit {
  specCareer: any;

  public careerId;
  public pathObj;
  // public careerpath: any[] = [];
  public path;
  public authToken;
  public desc;
  public accType;
  public searchObj;
  public platformId;
  public careerArr;
  constructor(private route: ActivatedRoute,
    private localStorage: StorageService,
    private commonService: CommonService, private sanitizer: DomSanitizer,
    private careerOverviewService: CareerOverviewService,
    private router: Router, @Inject(PLATFORM_ID) platformId: string, private titleService: Title,
    private metaService: Meta,public sharedService:SharedService) {
    this.platformId = platformId;
    this.authToken = this.localStorage.get('access_token');
    

    this.accType = this.localStorage.get('account_type');
    console.log(this.accType);
    this.careerArr = this.localStorage.get('career_arr');
    this.route.params.forEach((params: Params) => {
      this.careerId = params['id'];
    });

    if (this.commonService.pathData !== '') {
      this.path = this.commonService.pathData;
      if (this.path.relatedCareerPath != undefined) {
        var specCareerPrefix = this.path.relatedCareerPath.replace(/[^\w]/gi, '-');
        var career = this.path.linkId.replace(specCareerPrefix + '-', '');
        this.specCareer = career.replace(/-/g, ' ');
      }
      this.setTags();
    } else {
      this.careerOverviewService.getStaticContentByCareerLinkId(this.careerId).subscribe(
        response => {
          this.path = response;
          this.commonService.pathData = this.path;
          if (this.path.relatedCareerPath != undefined) {
            var specCareerPrefix = this.path.relatedCareerPath.replace(/[^\w]/gi, '-');
            var career = this.path.linkId.replace(specCareerPrefix + '-', '');
            this.specCareer = career.replace(/-/g, ' ');
          }
          this.setTags();
        });
    }


    //   this.searchObj = {
    //     // QueryString: this.careerId,
    //     'Filters': [
    //       {
    //         'FilterSets': [
    //           {
    //             'Name': 'linkId',
    //             'Operator': 'eq',
    //             'LOperator': 'and',
    //             'Value': this.careerId,
    //             'Type': 'Id'
    //           }
    //         ]
    //       }
    //     ],
    //     Skip: 0,
    //     Take: 100,
    //   };
    //   this.careerOverviewService.getCareerPathForCounsellor(this.searchObj).subscribe(
    //     response => {
    //       this.path = response.Result[0];
    //       this.titleService.setTitle(this.path.careerPath + ' - Career Scope');
    //       var a = this.path.GrowthDescription.indexOf('<p');
    //       var b = this.path.GrowthDescription.indexOf('</p>');
    //       this.desc = this.path.GrowthDescription.slice(a + 3, b);
    //       let attributeSelector: string = 'name="description"';
    //       this.metaService.removeTag(attributeSelector);
    //       this.metaService.addTag({ name: 'description', content: this.desc }, true);
    //     });
    // }
  }

  ngOnInit() {
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
}

  public setTags() {
    this.titleService.setTitle(this.path.careerPath + ' - Career Scope');
    var a = this.path.GrowthDescription.indexOf('<p');
    var b = this.path.GrowthDescription.indexOf('</p>');
    this.desc = this.path.GrowthDescription.slice(a + 3, b);
    let attributeSelector: string = 'name="description"';
    this.metaService.removeTag(attributeSelector);

    this.metaService.addTag({ name: 'description', content: this.desc }, true);

    // For Facebook
    if (this.path.GrowthQuestion != undefined) {
      let title = this.path.GrowthQuestion.replace('<p>', '');
      title = title.replace('</p>', '');
      this.titleService.setTitle(title);
    }
    // this.titleService.setTitle(this.path.GrowthQuestion);
    this.metaService.addTag({ property: 'og:title', content: this.path.GrowthQuestion });
    this.metaService.addTag({ property: 'og:description', content: this.path.GrowthDescription });
    if (this.path.GrowthImage !== null) {
      this.metaService.addTag({ property: 'og:image', content: this.path.GrowthImage });
    }

    // LinkId added
    this.metaService.addTag({ property: 'og:url', content: this.commonService.getAppUrl() +  '/career/' + this.careerId });
    this.metaService.addTag({ property: 'og:site_name', content: 'Mentoria - Growth Trajectory' });

    this.metaService.addTag({ property: 'og:locale', content: 'en_US' });
    this.metaService.addTag({ property: 'og:type', content: 'growth-trajectory' });
    if (this.path.GrowthImage !== null) {
      this.metaService.addTag({ property: 'og:image', content: this.path.GrowthImage });
    }

    // For twitter
    this.metaService.addTag({ property: 'twitter:card', content: 'summary' });
    this.metaService.addTag({ property: 'twitter:description', content: this.path.GrowthDescription });

    this.metaService.addTag({ property: 'twitter:title', content: this.path.GrowthQuestion });

    if (this.path.GrowthImage !== null) {
      this.metaService.addTag({ property: 'og:image', content: this.path.GrowthImage });
    }
  }

  public goToCareers() {
    console.log(this.accType);
    if (this.accType === 'student' && this.careerArr === '3') {
      this.router.navigate(['/feeds/my-careers']);
    }
    if (this.accType === 'counsellor') {
      this.router.navigate(['/counsellor/discover/careers']);
    }
    if (this.accType === null || this.authToken === null || this.careerArr === '0') {
      this.sharedService.redirectNavURL('/career')
    }

  }
  home() {
    if (isPlatformBrowser(this.platformId)) {
      // console.log('user agent --- ' + window.navigator.userAgent );
      let ua = window.navigator.userAgent;

      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
        console.log('route changing to Mobile view');
        this.router.navigate(['home-mobile']);
      }
      else {
        // this.router.navigate([this.commonService.getpreloginAppURL()]);
        let url = this.commonService.getpreloginAppURL()
        let newPath = url + '/redirect?authToken=' + this.localStorage.get('access_token') + '&redirectURl=' + encodeURI(url + '/login');
        window.open(newPath, "_self")
      }
    }
  }

  public goToOverview() {
    this.router.navigate(['/career', this.careerId, 'overview']);
  }
  public goToRoles() {
    this.router.navigate(['/career', this.careerId, 'roles-responsibilities']);
  }
  public goToSkills() {
    this.router.navigate(['/career', this.careerId, 'key-skills']);
  }
  public goToGrowth() {
    this.router.navigate(['/career', this.careerId, 'growth-trajectory']);
  }
  public goToPayScale() {
    this.router.navigate(['/career', this.careerId, 'pay-scale']);
  }
  public goToPath() {
    this.router.navigate(['/career', this.careerId, 'path']);
  }
  public goToEnvironment() {
    this.router.navigate(['/career', this.careerId, 'environment']);
  }
}
