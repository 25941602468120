import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaymentService } from '../payment.service';

@Component({
  selector: 'app-lazy-pay-status',
  templateUrl: './lazy-pay-status.component.html',
  styleUrls: ['./lazy-pay-status.component.css']
})
export class LazyPayStatusComponent implements OnInit {
  orderStatus
  paymentData

  constructor(private route: ActivatedRoute, private paymentService: PaymentService) {
    this.route.queryParams.subscribe(params => {

      this.orderStatus = params['status'] || '';
      let token = params['token'] || '';
      let lpTxnId = params['lpTxnId'] || ''
      console.log(this.orderStatus , token, lpTxnId)

      if (this.orderStatus === 'success') {
        this.getPaymentDetailsByToken(token)
      } else if (this.orderStatus === 'failed') {

      }
    })


  }


  ngOnInit() {
  }

  getPaymentDetailsByToken(token) {
    this.paymentService.GetPaymentDetailsByToken(token).subscribe(response => {
      let res: any = response
      this.paymentData  = res

    })
  }

}
