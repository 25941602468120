import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CareerOverviewRewampRoutingModule } from './career-overview-rewamp-routing.module';
import { NewcareerComponent } from './newcareer/newcareer.component';
import { RouterModule } from '@angular/router';
import { CareerOverviewRewampService } from './career-overview-rewamp.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ShareModule } from '@ngx-share/core';
import { SharedModule } from './../shared/shared.module';
import { OverviewComponent } from './overview/overview.component';
import { RolesComponent } from './roles/roles.component';
import { SkillsComponent } from './skills/skills.component';
import { WorkEnvironmentComponent } from './work-environment/work-environment.component';
import { GrowthComponent } from './growth/growth.component';
import { PayScaleComponent } from './pay-scale/pay-scale.component';
import { PathComponent } from './path/path.component';

@NgModule({
  imports: [
    CommonModule,
    CareerOverviewRewampRoutingModule,
    RouterModule,
    FlexLayoutModule,
    ShareModule,
    SharedModule
  ],
  declarations: [NewcareerComponent, OverviewComponent, RolesComponent, SkillsComponent, WorkEnvironmentComponent, GrowthComponent, PayScaleComponent, PathComponent],
  providers: [CareerOverviewRewampService],
})
export class CareerOverviewRewampModule { }
