
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { BlogsService } from '../blog.service';
import { CommonService } from '../../common/common.service';

@Component({
  selector: 'app-blog-author',
  templateUrl: './blog-author.component.html',
  styleUrls: ['./blog-author.component.css']
})
export class BlogAuthorComponent implements OnInit {
  public author;
  public authorNew;
  public noData = false;
  AUthorArray = [
    'Aaliya Furniturewala',
    'Anushka Menon',
    "Anwell Fernandes",
    'Bhumika Negandhi',
    'George Abraham',
    'Juie Divecha',
    'Khyati Mehta',
    'Latika Payak',
    'Mansi Bengali',
    'Megha Saluja',
    'Nadine D`Souza',
    'Nidhi Lodaya',
    'Pooja Pande',
    'Prarthana Mitra',
    'Rishi Jain',
    'Riya Shah',
    'Sansha Cornelio',
    'Shilpa Kulkarni',
    'Shulanda Singh',
    'Tanay Vaidya',
    'Vrushali Lad',
  ];

relatedBlogList= [];
blogFilterObj: { 'QueryString': string; Take: number; Skip: number; "Filters": { "FilterSets": { "Name": string; "Operator": string; "LOperator": string; "Type": string; "Value": string; }[]; }[]; };

  constructor(private route: ActivatedRoute, private router: Router, private blogService: BlogsService, public commonService: CommonService) { }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      let name = params["name"] || "";
      if (name !== '') {
        this.author = params['name'];
      }
    });

    this.getBlogs();
    this.matchAuthors();
  }

  showAuthor(name) {
    console.log(name);
    this.router.navigate(['/blog/author'], { queryParams: { name: name } });
  }

  matchAuthors() {
    if (this.AUthorArray.indexOf(this.author) === -1) {
      this.noData = true;
    }
  }

  getBlogs() {
    var firstWord = this.author.replace(/ .*/,'');
    this.blogFilterObj = {
      'QueryString':  'author:'+ firstWord+ '*' + ' && (tagid:blogs || tagid:blog)',
      Take: 1000,
      Skip: 0,
      "Filters": [
        {
          "FilterSets": [
            {
              "Name": "isPublished",
              "Operator": "eq",
              "LOperator": "OR",
              "Type": "STRING",
              "Value": "true"

            }
          ]
        }
      ]
    };
    this.blogService.GetAllBlogs(this.blogFilterObj).subscribe(
          data1 => {
            this.relatedBlogList = data1['Result'];
            this.createBlogHREF()
          });
  }

  
  createBlogHREF(){
    // let appUrl = 'http://localhost:4200'
    let appUrl =  this.commonService.getAppUrl()
      this.relatedBlogList.forEach(blog => {
        blog['blogHref'] = appUrl + '/blog/' + blog.linkId + '?category=' + this.blogService.currentCategory
      })
    }


}
