import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StudentHeaderComponent } from './student-header/student-header.component';
import { RouterModule, RouteReuseStrategy } from '@angular/router';
import { SharedService } from './shared.service';
import { CHeaderComponent } from './c-header/c-header.component';
import { SafePipe } from './SafePipe.pipe';
import { ExecuteScriptComponent } from './execute-script/execute-script.component';
import { CustomReuseStrategy } from './routing';
import { SpecialCareerPipe } from './SpecialCareerPipe.pipe';
import { UrlPipe } from './UrlPipe.pipe';
import { FooterComponent } from './footer/footer.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ContentSafeHtmlPipe } from './safehtml.pipe';
import { OnlyNumber } from './app.numberOnly.directive';
import { CustomMaterialModule } from './custom-material.module';
import { NewFooterComponent } from './new-footer/new-footer.component';
import { YoutubeThumbnail } from './YoutubeThumbnail.pipe';
import { DeferLoadDirective } from './defer-load.directive';
import { NewBannerComponent } from './new-banner/new-banner.component';
import { OldBannerComponent } from './old-banner/old-banner.component';
import { FaqSharedComponent } from './faq-shared/faq-shared.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    RouterModule,
    FlexLayoutModule,
    CustomMaterialModule
  ],
  providers: [SharedService, {provide: RouteReuseStrategy, useClass: CustomReuseStrategy}],
  exports: [StudentHeaderComponent, CHeaderComponent, SafePipe, SpecialCareerPipe, UrlPipe, ExecuteScriptComponent, FooterComponent, ContentSafeHtmlPipe, OnlyNumber,DeferLoadDirective,NewFooterComponent, YoutubeThumbnail,NewBannerComponent, OldBannerComponent,FaqSharedComponent], 
  declarations: [StudentHeaderComponent, CHeaderComponent, SafePipe, SpecialCareerPipe, ExecuteScriptComponent, UrlPipe, FooterComponent, ContentSafeHtmlPipe, OnlyNumber, DeferLoadDirective, NewFooterComponent, YoutubeThumbnail, NewBannerComponent, OldBannerComponent,FaqSharedComponent]
})
export class SharedModule { }
