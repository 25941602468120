import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.css','../newcareer/newcareer.component.css','../career-overview-rewamp.module.css']
})
export class SkillsComponent implements OnInit {
  @Input() path
  constructor() { }

  ngOnInit() {
  }

}
