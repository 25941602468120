import { animate, style, transition, trigger } from '@angular/animations';

export const RouteSlideStateTrigger = trigger('routeSlideState', [
  transition(':enter', [
    style({
      transform: 'translateX(-100%)'
    }),
    animate(600)
  ]),
  transition(':leave', [
    style({
      transform: 'translateX(100%)'
    }),
    animate(600)
  ])
]);

