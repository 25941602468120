import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AccountsService } from '../accounts.service';
import { StorageService } from '../../common/storage-service';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { MatDialog } from '@angular/material';
import { CommonService } from '../../common/common.service';
import { globalValues } from '../../common/global-constants';

@Component({
  selector: 'app-lead-form',
  templateUrl: './lead-form.component.html',
  styleUrls: ['./lead-form.component.css']
})
export class LeadFormComponent implements OnInit {

  platformId: string;
  public leadForm: FormGroup;
  public passForm: FormGroup;
  public showPasswordDiv = false;
  public showWait = false;
  public showMessage = false;
  public isemailPresent = false;
  public educations = [
    { text: 'Class 8-9', value: '8th-9th' },
    { text: 'Class 10-12', value: '10th-12th' },
    { text: 'College Graduate', value: 'College Graduate' },
    { text: 'Working Professional', value: 'Working Professional' }
  ]
  public locations = ['Mumbai', 'Pune', 'Delhi', 'Bangalore', 'Hyderabad', 'Chennai', 'Kolkata', 'Others'];
  public otherLocation = '';
  public currentStatus = "";
  public currentClass = "";

  public globalValues;
  public disabledEmail=false

  constructor(private leadFormBuilder: FormBuilder, @Inject(PLATFORM_ID) platformId: string, private passFormbuilder: FormBuilder, private accountService: AccountsService, private localStorageService: StorageService, private router: Router, public dialog: MatDialog, private commonService: CommonService) {
    this.platformId = platformId;
  }

  ngOnInit() {
    this.createForm();
    if (this.localStorageService.get('mobile') === '') {
      this.router.navigate(['/accounts/get-started']);
    }
    this.globalValues = globalValues;
   
   
  }

  createForm() {
    this.leadForm = this.leadFormBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      education: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      
      // location: ['', [Validators.required]],
      // otherLocation: ['']
    });
    if(this.accountService.emailFromGetStarted!==null && this.accountService.emailFromGetStarted!==undefined)
      {
        this.disabledEmail=true;
        let email:any=this.accountService.emailFromGetStarted;
        this.leadForm.controls['email'].setValue(email);
        
      }

    this.passForm = this.passFormbuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required]],
    })
  }

  showForm2() {
    this.showWait = true;
    this.accountService.CheckEmailExists(this.leadForm.controls['email'].value)
      .subscribe(
        response => {
          this.showWait = false;
          if (response['Message'] === 'True') {
            this.isemailPresent = true;
          } else {
            this.isemailPresent = false;
            this.showPasswordDiv = true;
          }
        },
        error => {
          
          this.showWait = false;
      });
  }

  openDialog(dialogValue): void {
    this.dialog.open(dialogValue, {
      width: '50rem',
      disableClose: true
    });
  }

  register() {
    this.showMessage = false;
    this.showWait = true;
    if (this.passForm.controls['confirmPassword'].value !== this.passForm.controls['password'].value) {
      this.showMessage = true;
      this.showWait = false;
      return false;
    }

    if (this.leadForm.controls['education'].value === '8th-9th' || this.leadForm.controls['education'].value === '10th-12th') {
      this.currentClass = this.leadForm.controls['education'].value;
      this.currentStatus = 'Study-' + this.leadForm.controls['education'].value;
    } else if (this.leadForm.controls['education'].value === 'College Graduate' || this.leadForm.controls['education'].value === 'Working Professional') {
      this.currentClass = this.leadForm.controls['education'].value;
      this.currentStatus = 'Other-' + this.leadForm.controls['education'].value;
    }

    // let location = this.leadForm.controls['location'].value;
    // if (location === 'Others') {
    //   location = this.leadForm.controls['otherLocation'].value;
    // }

    let studentObj = {
      CurrentClass: this.currentClass,
      CurrentStatus: this.currentStatus,
      FirstName: this.leadForm.controls['firstName'].value,
      LastName: this.leadForm.controls['lastName'].value,
      Email: this.leadForm.controls['email'].value,
      Mobile: '',
      Password: this.passForm.controls['password'].value,
      // Location: location
    }

    if (isPlatformBrowser(this.platformId)) {
      studentObj.Mobile = this.localStorageService.get('mobile');
    }

    if (this.localStorageService.get("utm_source") !== '') {
      studentObj['utm_source'] = this.localStorageService.get("utm_source");
    }
    
    
    if (this.localStorageService.get("gclid") !== '') {
      studentObj['gclid'] = this.localStorageService.get("gclid");
    }
    
    if (this.localStorageService.get("utm_medium") !== '') {
      studentObj['utm_medium'] = this.localStorageService.get("utm_medium");
    }
    if (this.localStorageService.get("utm_campaign") !== '') {
      studentObj['utm_campaign'] = this.localStorageService.get("utm_campaign");
    }
    if (this.localStorageService.get("utm_adgroup") !== '') {
      studentObj['utm_adgroup'] = this.localStorageService.get("utm_adgroup");
    }
    if (this.localStorageService.get("utm_device") !== '') {
      studentObj['utm_device'] = this.localStorageService.get("utm_device");
    }
    if (this.localStorageService.get("utm_term") !== '') {
      studentObj['utm_term'] = this.localStorageService.get("utm_term");
    }      
    if (this.localStorageService.get("utm_matchtype") !== '') {
      studentObj['utm_matchtype'] = this.localStorageService.get("utm_matchtype");
    }      
    if (this.localStorageService.get("utm_location") !== '') {
      studentObj['utm_location'] = this.localStorageService.get("utm_location");
    }      
    if (this.localStorageService.get("utm_placement") !== '') {
      studentObj['utm_placement'] = this.localStorageService.get("utm_placement");
    }      


    console.log(studentObj);
    var mobileNo;
    this.accountService.newRegisterStudent(studentObj).subscribe(
      res => {
        console.log(res);
       
        if (isPlatformBrowser(this.platformId)) {
          mobileNo = this.localStorageService.get('mobile');
        }
        this.accountService.login(mobileNo, this.passForm.controls['password'].value)
          .subscribe(
            response => {
              
              this.localStorageService.set('access_token', response['access_token']);
              this.localStorageService.set('account_type', 'student');
              this.commonService.login();
              this.sendTrackingEvent();
              this.router.navigate(['/assessment/assessment-instructions']);
            },
            error => {
              
            });
      },
      error => {
        
      });
  }

  public sendTrackingEvent() {
    (<any>window).gtag('event', 'conversion',
      {
        'send_to': 'AW-849928441/Pz4mCL74ppIBEPnBo5UD',
        'value': this.localStorageService.get('mobile')
      });
    
    (<any>window).fbq('track', 'Lead');

    (<any>window).gtag('event', 'new registration',
      {
        'send_to': 'AW-849928441/5TxpCNm8pagBEPnBo5UD',
      'event_category': 'new registration',
      'event_action':'click to register',
      'event_label': this.localStorageService.get('mobile')
     
    });
  }
  
  public signUpWithGoogle() {
    const clientId = encodeURI('Mentoria.elp');
    const scope = encodeURI('Mentoriaapi');
    const redirectUri = encodeURI(this.commonService.getAppUrl() + '/accounts/post-login');
    const responseType = encodeURI('token');
    const acrValues = encodeURI('idp:Google');
    const action = encodeURI('register');
    const url = this.commonService.getStsUrl() + '/core/connect/authorize?client_id=' + clientId + '&scope=' + scope + '&redirect_uri=' + redirectUri + '&response_type=' + responseType + '&acr_values=' + acrValues + '&action=' + action;
    if (isPlatformBrowser(this.platformId)) {
      window.location.href = url;
    }
  }

  public signUpWithFacebook() {
    const clientId = encodeURI('Mentoria.elp');
    const scope = encodeURI('Mentoriaapi');
    const redirectUri = encodeURI(this.commonService.getAppUrl() + '/accounts/post-login');
    const responseType = encodeURI('token');
    const acrValues = encodeURI('idp:Facebook');
    const action = encodeURI('register');
    const url = this.commonService.getStsUrl() + '/core/connect/authorize?client_id=' + clientId + '&scope=' + scope + '&redirect_uri=' + redirectUri + '&response_type=' + responseType + '&acr_values=' + acrValues + '&action=' + action;
    if (isPlatformBrowser(this.platformId)) {
      window.location.href = url;
    }
  }

}
