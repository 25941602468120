import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/common/storage-service';
import { SharedService } from 'src/app/shared/shared.service';
import { AccountsService } from '../accounts.service';
import { CommonService } from 'src/app/common/common.service';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { marqueeData } from './new-login.json';
@Component({
  selector: 'app-new-login',
  templateUrl: './new-login.component.html',
  styleUrls: ['./new-login.component.css']
})
export class NewLoginComponent implements OnInit {
  marqueeData = marqueeData
  public loginViaPass: any = false
  public isDPWorld: any = false
  public isITC: boolean = false
  public isAragen: boolean = false
  public isRustomJee: boolean = false
  public isItcpcpb: boolean = false
  // teamData = Teams.teamData
  // getOtpData = getOtp.getOtpData
  phoneNo: String
  optloading = false
  resendOTPonEmail = false
  resentOTPOnMobile = false
  resendloading = false
  showTimer = false
  public loading
  vloading = true;
  otp: any;
  email: any;
  password: any
  otpDigit1: any
  otpDigit2: any
  otpDigit3: any
  otpDigit4: any
  platformId: any
  disableResendOTp = true;
  studentCareerPathArray: any;
  spinnerValue = false;
  loginloading = false;
  verifyLoading = false;
  isPhone = false;
  errorBox = false;
  errorpBoxForPassword = false
  public currentURL: any

  constructor(private localStorageService: StorageService, public sharedService: SharedService, public accountService: AccountsService, public commonService: CommonService, public router: Router) {

  }
  show = false;
  time: number = 30;
  resendOtpHidden = true;





  ngOnInit(): void {
    this.currentURL = window.location.href;
    if (this.currentURL.toLowerCase().includes("dpworld")) {
      this.isDPWorld = true
    }
    else if (this.currentURL.toLowerCase().includes("itcfoods")) {
      this.isITC = true
    }
    else if (this.currentURL.toLowerCase().includes("aragen")) {
      this.isAragen = true
    }
    else if (this.currentURL.toLowerCase().includes("rustomjee")) {
      this.isRustomJee = true
    }
    else if (this.currentURL.toLowerCase().includes("itcpcpb")) {
      this.isItcpcpb = true
    }
    this.accountService.isUserLoggedIn = false;
  }
  // move(e:any,p:any,c:any,n:any){
  //   var length = c.value.length;
  //   var maxlength = c.getAttribute('maxlength');
  //   if(length == maxlength){
  //     n.focus();
  //   }
  // }
  moveToNext(event: any) {
    let next = event.target.nextElementSibling;
    if (next) {
      next.focus();
    } else {
      event.target.blur();
    }
  }
  pasteOTP(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData!.getData('text');
    let arr = pastedText.split('')


    this.otpDigit1 = arr[0];
    this.otpDigit2 = arr[1];
    this.otpDigit3 = arr[2];
    this.otpDigit4 = arr[3];




  }

  phone: any
  /**NEW LOGIN FUNCTIONALITY STARTS */
  loginVIAotpOrPass(phone: any, id: any) {
    this.resendOtpHidden = true;
    this.time = 30;
    this.loginloading = true
    let obj: any = {}
    this.optloading = true
    console.log("Phone=>", phone)
    if (id == 1) {
      obj = {
        mobile: phone,
        countryCode: "+91",
      }
      this.resendOTPonEmail = false
      this.resentOTPOnMobile = true
    }
    else {
      obj = {
        mobile: phone,
        countryCode: "",
      }
      this.resentOTPOnMobile = false
      this.resendOTPonEmail = true
    }

    this.accountService.LoginViaMobileForOtp(obj).subscribe((data: any) => {

      this.resendloading = false;
      this.optloading = false
      let res: any = data
      console.log(res.message)
      if (res.message == 'success') {
        console.log('otp login successfull')
        if (id == 1) {
          swal(

            "Sucess",
            "OTP Sent successfully on mobile",
            "success",
          )
        }
        if (id == 2) {
          swal(

            "Sucess",
            "OTP Sent successfully on email",
            "success",
          )

        } swal(
          'Otp Sent Successfully !',
          "",
          'success'
        )
        this.show = true
        setInterval(() => {

          if (this.time > 0) {
            this.time--;
          } else {
            this.resendOtpHidden = false;
            return;
          }
        }, 1000)
      }
      else if (res.message == "No User found!") {
        this.loginloading = false;
        swal(
          'Oops!',
          "No User Found",
          "error"
        )
      }
    }
    )



    this.showTimer = true
  }
  resendPhone(phone: any) {
    this.resendloading = true;
    this.disableResendOTp = true
    // setTimeout(() => {
    //   this.disableResendOTp = false
    //   this.showTimer = false

    // }, 30000)

    this.showTimer = true
    this.loginVIAotpOrPass(phone, 1);
  }
  //verify Otp
  verifyOTP(phone: any) {
    this.verifyLoading = true
    console.log("verf")
    this.spinnerValue = true
    this.otp = this.otpDigit1 + "" + "" + this.otpDigit2 + "" + "" + this.otpDigit3 + "" + "" + this.otpDigit4
    this.vloading = true;
    console.log("Phone=>", phone)
    this.phoneNo = phone;
    console.log("otp=>", this.otp)


    // console.log("validity=>", form._directives[1].control.status)
    if (!this.otp) {
      swal(
        "please enter valid OTP"
      )
      this.vloading = false;
    }
    else {
      let obj = {
        mobile: phone,
        VerificationCode: this.otp,

      }
      this.accountService.VerifyOTPforLogin(obj).subscribe(data => {

        let res: any = data
        this.vloading = false;
        this.localStorageService.setValue("mobile", phone);
        //Waiting
        if (res.message == "success") {
          // console.log("sdh")
          // swal(
          //   "success",
          //   "OTP verified Successfully!",
          //   "success",
          // )


          if (res.message !== 'failed') {

            let token: any = res.reponse.access_token
            console.log("Token=>", res.message)
            if (res.message == 'success') {

              this.setTokenAfterLogin(token)
              // this.router.navigate(['./career-assessment/career-assessment'])
              // this.router.navigate([path]);


            }
            else {
              this.verifyLoading = false;
              swal(

                "Oop's!",
                "Error Try Again After Sometime!",
                "error"
              );

            }
          }
          else {
            this.verifyLoading = false;
            swal(

              "Oop's!",
              "Incorrect OTP !",
              "error"
            );
          }

        }
        else {
          this.verifyLoading = false;
          swal(

            "Oop's!",
            "Incorrect OTP !",
            "error"
          );
        }
      })
    }
    // this.spinnerValue=false
    setTimeout(() => {
      this.spinnerValue = false
    }, 3000);
  }
  resendEmail(phone: any) {
    this.resendloading = true;
    this.disableResendOTp = true

    setTimeout(() => {
      this.disableResendOTp = false
      this.showTimer = false

    }, 30000)

    this.showTimer = true
    this.loginVIAotpOrPass(phone, 2);
  }
  verifyOTPEmail(email: any, otp: any) {
    debugger
    let obj = {
      "emailId": email,
      "countryCode": "",
      "VerificationCode": otp,
    }
    this.accountService.VerifyOTPforLogin(obj).subscribe(data => {
      let res: any = data
      this.vloading = false;
      if (res.message == "success") {
        // swal(

        //   "Success!",
        //   "OTP verified Successfully!",
        //   "success",

        // )
        if (res.message !== 'failed') {

          let token: any = res.reponse.access_token
          if (res.message == 'success') {
            this.setTokenAfterLogin(token)
            // this.router.navigate(['./career-assessment/career-assessment'])

          }
          else {
            swal(

              "OOPS",
              "Something Went Wrong please try After Sometime!",
              "error",

            )
          }
        }
        else {
          swal(

            "OOPS",
            "Something Went Wrong please try After Sometime!",
            "error",

          )
        }

      }
      else {
        swal(

          "OOPS",
          "Something Went Wrong please try After Sometime!",
          "error",

        )
      }
    }, error => {
      swal(

        "OOPS",
        "Something Went Wrong please try After Sometime!",
        "error",

      )
    })
  }
  public signInWithGoogle() {

    this.commonService.login();
    const clientId = encodeURI("Mentoria.elp");
    const scope = encodeURI("Mentoriaapi");
    const redirectUri = encodeURI(
      this.commonService.getAppUrl() + "/accounts/post-login"
    );
    const responseType = encodeURI("token");
    const acrValues = encodeURI("idp:Google");
    const action = encodeURI("login");
    const url =
      this.commonService.getStsUrl() +
      "/core/connect/authorize?client_id=" +
      clientId +
      "&scope=" +
      scope +
      "&redirect_uri=" +
      redirectUri +
      "&response_type=" +
      responseType +
      "&acr_values=" +
      acrValues +
      "&action=" +
      action;
    // if (isPlatformBrowser(this.platformId)) {
    //   window.location.href = url;
    // }
    window.location.href = url;
  }

  signInWithFacebook() {
    debugger
    this.commonService.login();
    const clientId = encodeURI("Mentoria.elp");
    const scope = encodeURI("Mentoriaapi");
    const redirectUri = encodeURI(
      this.commonService.getAppUrl() + "/accounts/post-login"
    );
    const responseType = encodeURI("token");
    const acrValues = encodeURI("idp:Facebook");
    const action = encodeURI("login");
    const url =
      this.commonService.getStsUrl() +
      "/core/connect/authorize?client_id=" +
      clientId +
      "&scope=" +
      scope +
      "&redirect_uri=" +
      redirectUri +
      "&response_type=" +
      responseType +
      "&acr_values=" +
      acrValues +
      "&action=" +
      action;
    // if (isPlatformBrowser(this.platformId)) {
    //   window.location.href = url;
    // }
    window.location.href = url;
  }
  validUser = false;
  setTokenAfterLogin(token: any) {
    debugger
    this.localStorageService.setValue("access_token", token);
    this.commonService.login();
    this.accountService.getAccountType().subscribe(
      accountType => {
        let accType: any = accountType
        this.localStorageService.setValue("account_type", accType);
        console.log(accountType);
        if (accountType === "student") {
          // this.validUser = true;

          this.accountService.getStudentDetails().subscribe(
            studentVar => {
              let response: any = studentVar
              let userName = response.firstName + " " + response.lastName

              this.localStorageService.setValue('user_Name', userName);
              console.log("StudentVar Value ", studentVar);
              this.studentCareerPathArray =
                studentVar["selectedCareerPaths"];

              if (!this.studentCareerPathArray) {

                this.localStorageService.setValue(
                  "career_arr",
                  this.studentCareerPathArray.length
                );
              } else {
                this.localStorageService.setValue(
                  "career_arr",
                  this.studentCareerPathArray.length
                );
              }
            },
            err => {
              alert("Some error occured.");
            }
          );

          this.accountService.GetStudentPath().subscribe(
            path => {
              console.log(path);
              this.router.navigate([path]);
            },
            err => {
              alert("Some error occured, please login again.");
            }
          );
        } else if (accountType === "counsellor") {

          this.accountService.GetCounsellorPath().subscribe(
            path => {
              console.log(path);
              this.router.navigate([path]);
            },
            err => {
              alert("Some error occured, please login again.");
            }
          );
        } else {
          alert("Invalid User");
          // swall here
          swal(
            "Oops!",
            "Invalid User",
            "error"
          )
        }
      },
      err => {
        console.log(err);
      }
    );
  }


  validatePhoneNumber(phone: any) {
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    this.isPhone = re.test(phone);
    if (!this.isPhone) {
      this.errorBox = true;
      swal(

        "Oop's!",
        "Enter Valid Mobile Number",
        "error"
      );
    }
    else {
      console.log("correct");
      this.errorBox = false;
      this.errorpBoxForPassword = false
      this.loginVIAotpOrPass(phone, 1)
    }
  }
  checkIfIsNull(val) {
    if (val == '' || val == "" || val == null || val == undefined) {
      return true
    }
    else {
      return false
    }
  }
  loginForCounsellors() {
    this.loading = true;
    this.accountService.login(this.phoneNo, this.password).subscribe(
      response => {
        this.loading = false;

        this.localStorageService.set("access_token", response["access_token"]);
        this.commonService.login();
        // this.appService.subscribeToNotification('login component');
        this.accountService.getAccountType().subscribe(
          accountType => {
            this.localStorageService.set("account_type", accountType);
            console.log(accountType);
            if (accountType === "student") {
              //get student object and save in common service
              // this.commonService.getStudent();
              this.accountService.getStudentDetails().subscribe(
                studentVar => {
                  console.log(studentVar);
                  this.studentCareerPathArray =
                    studentVar["selectedCareerPaths"];

                  if (!this.studentCareerPathArray) {
                    // this.commonService.careerArr = false;
                    this.localStorageService.set(
                      "career_arr",
                      this.studentCareerPathArray.length
                    );
                  } else {
                    this.localStorageService.set(
                      "career_arr",
                      this.studentCareerPathArray.length
                    );
                  }
                },
                err => {
                  alert("Some error occured.");
                }
              );

              this.accountService.GetStudentPath().subscribe(
                path => {
                  console.log(path);
                  this.router.navigate([path]);
                },
                err => {
                  alert("Some error occured, please login again.");
                }
              );
            } else if (accountType === "counsellor") {
              this.accountService.GetCounsellorPath().subscribe(
                path => {
                  console.log(path);
                  this.router.navigate([path]);
                },
                err => {
                  alert("Some error occured, please login again.");
                }
              );
            } else {
              alert("Invalid User");
            }
          },
          err => {
            console.log(err);
          }
        );
      },
      error => {
        this.loading = false;
        // this.showErrorMessage = true;

      }
    );


  }
}