import { Router, ActivatedRoute } from '@angular/router';
import { Component, PLATFORM_ID, Inject, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CommonService } from './common/common.service';
import swal from 'sweetalert2';
import { MainService } from './main/main.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from './common/storage-service';
import { SharedService } from './shared/shared.service';
import { MatDialog } from '@angular/material';
import { CanonicalLinkService } from './shared/canonical.service';
import { environment } from '../environments/environment';
import { AppService } from './app.service';
import * as AOS from 'aos'
import { ContentService } from './content/content.service';

declare var ga: Function;
declare var fbq: Function;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss", "./content/content.module.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild("feedbackModal") feedbackModal: ElementRef;
  @ViewChild("schoolModal") viewSchoolsModal: ElementRef;
  @ViewChild("corporateModal") viewCorporatesModal: ElementRef;
  public blogUrlToken = "https://blog.mentoria.com"
  public careerURL: any = "https://blog.mentoria.com/career"
  public platformId;
  public toggleVar = 0;
  public _opened: boolean = false;
  title = "app";
  time = new Date();
  public viewIframe = false;
  public logoutSRC = "";
  public feedback = {
    contentTitle: "",
    contentType: "",
    contentUrl: "",
    studentName: "",
    studentEmail: "",
    studentMobile: "",
    rating: null,
    comment: ""
  };
  public readonly = false;
  public content;
  public screen = "";

  public home;
  public mobileNumber;
  public mobileNumber1;
  public mobileNumber2;

  public authToken = '';
  public accountType;
  public loginName;
  public tempuId;
  public showPage = false;

  public studentPath = {
    text: "",
    url: ""
  };
  public account;
  public StudentObj;
  public careerArr;
  public previousUrl = "";

  public slides = [
    '//mentoriawebimages.azureedge.net/Indira.png',
    '//mentoriawebimages.azureedge.net/CityInternational.png',
    '//mentoriawebimages.azureedge.net/OrbisSchool.png',
    '//mentoriawebimages.azureedge.net/Hillgreen.png',
    '//mentoriawebimages.azureedge.net/SPG.png',
    '//mentoriawebimages.azureedge.net/PublicProdigySchool.png',
    '//mentoriawebimages.azureedge.net/BlossomPublicSchool.png',
    '//mentoriawebimages.azureedge.net/BharatiVidyapeeth.png',
    '//mentoriawebimages.azureedge.net/SNBP.png',
    '//mentoriawebimages.azureedge.net/HighVision.png',
    '//mentoriawebimages.azureedge.net/DeccanInstitute.png',
    '//mentoriawebimages.azureedge.net/VidyanchalHighschool.png',
    '//mentoriawebimages.azureedge.net/kamala_edu.jpg',
    '//mentoriawebimages.azureedge.net/pawar_school.jpg',
    '//mentoriawebimages.azureedge.net/podar_1927.jpg',
    '//mentoriawebimages.azureedge.net/Novel.png',
    '//mentoriawebimages.azureedge.net/CityInternational.jpg',
    '//mentoriawebimages.azureedge.net/EonGyanankurSchool2.png',
    '//mentoriawebimages.azureedge.net/MKNS.png',
    '//mentoriawebimages.azureedge.net/SBPatilPublicSchool.png',
    '//mentoriawebimages.azureedge.net/SpanInternationalSschool.png',
    '//mentoriawebimages.azureedge.net/BlueRidgePublicSchool.jpg',
    '//mentoriawebimages.azureedge.net/HolyCrossSchoolKalyan.png'
  ];

  public cmlides = [
    '//mentoriawebimages.azureedge.net/1.png',
    '//mentoriawebimages.azureedge.net/2.png',
    '//mentoriawebimages.azureedge.net/3.png',
    '//mentoriawebimages.azureedge.net/6.png',
    '//mentoriawebimages.azureedge.net/5.png',
    '//mentoriawebimages.azureedge.net/jewelex.png',
    '//mentoriawebimages.azureedge.net/k%20raheja.png',
    '//mentoriawebimages.azureedge.net/kanakia.jpg',
    '//mentoriawebimages.azureedge.net/people%20cart.png',
    '//mentoriawebimages.azureedge.net/Hari%20Krishna%20Jewels.png',
    '//mentoriawebimages.azureedge.net/RPG.png',
    '//mentoriawebimages.azureedge.net/JetAirways.png',
    '//mentoriawebimages.azureedge.net/KECInternational.png',
    '//mentoriawebimages.azureedge.net/MahendraBrothers.png',
    '//mentoriawebimages.azureedge.net/ShatilalaCMehta.png',
    '//mentoriawebimages.azureedge.net/Unidesign.png',
    '//mentoriawebimages.azureedge.net/L_T.png',
    '../assets/images/corporate-partners/20170122064257!Alembic_Pharmaceuticals_Ltd_logo.png',
    '../assets/images/corporate-partners/aditya-birla-capital-ltd-logo.png',
    '../assets/images/corporate-partners/alkyl amines logo.jpg',
    '../assets/images/corporate-partners/ASG logo.png',
    '../assets/images/corporate-partners/Axis Bank logo.png',
    '../assets/images/corporate-partners/birla century logo.jpg',
    '../assets/images/corporate-partners/grasim logo.png',
    '../assets/images/corporate-partners/HK logo.png',
    '../assets/images/corporate-partners/icra logo.png',
    '../assets/images/corporate-partners/iifl logo.jpg',
    '../assets/images/corporate-partners/inspira logo.png',
    // '../assets/images/corporate-partners/jewelex logo.jpg',
    '../assets/images/corporate-partners/KBS logo.png',
    '../assets/images/corporate-partners/Kotak_Mahindra_Bank_logo.png',
    '../assets/images/corporate-partners/ltgrouplogo.jpg',
    '../assets/images/corporate-partners/ltts logo.jpg',
    '../assets/images/corporate-partners/mahindra-rise-logo.png',
    '../assets/images/corporate-partners/Max-Life-Insurance-Logo.png',
    '../assets/images/corporate-partners/MB logo.png',
    '../assets/images/corporate-partners/Pari logo.png',
    '../assets/images/corporate-partners/unidesign logo.jpg',
    '../assets/images/corporate-partners/UPL Logo.jpg',
  ];


  // public config: PerfectScrollbarConfigInterface = {};
  // public headerBackgroundURL = "url('//mentoriawebimages.azureedge.net/Navbar-new.jpg')";
  public headerBackgroundURL =
    "url('//mentoriawebimages.azureedge.net/Nav-bar-gradient.png')";
  public commonServiceEventListner: any;
  public feedFilterObj;
  todayEpoch: string;
  Feed: any;
  feedLength: any;
  reloadSite = false;
  careerBrainstormPackagePurchased: false
  careerBrainstormSessionCompleted: false
  constructor(
    @Inject(PLATFORM_ID) platformId: string,
    private router: Router,
    private route: ActivatedRoute,
    public commonService: CommonService,
    private mainService: MainService,
    private modalService: NgbModal,
    private storageService: StorageService,
    private sharedService: SharedService,
    public dialog: MatDialog,
    private readonly canonicalLinkService: CanonicalLinkService,
    private appService: AppService,
    public contentService: ContentService,

  ) {
    this.platformId = platformId;
    let date = new Date();
    this.todayEpoch = date.toISOString();
    this.canonicalLinkService.startRouteListener();
  }

  ngOnInit() {
    AOS.init();
    if (environment.production && isPlatformBrowser(this.platformId)) {
      // this.appService.checkForNewVersion();
      // this.appService.subscribeToNotification("app component");
      this.checkDevice();
      // this.subscribeToMessages();
      // this.handleNotificationClick();

      // window.addEventListener('beforeinstallprompt', (event: any) => {
      //   console.log('Default a2hs triggered', event);

      //   event.prompt();

      //   event.userChoice.then(function (choiceResult) {
      //     console.log(choiceResult.outcome);

      //     if (choiceResult.outcome === 'dismissed') {
      //       console.log('User cancelled installation');
      //     } else {
      //       console.log('User accepted installation');
      //       fetch('https://mentoriaapi-staging.azurewebsites.net/api/admin/appDownloaded')
      //         .then(function (response) {
      //           return response.json();
      //         })
      //         .then(function (myJson) {
      //           console.log(JSON.stringify(myJson));
      //         });
      //     }
      //   });
      // });



      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
          this.reloadSite = true;
        }
      });
      if (this.reloadSite) {
        setTimeout(() => {
          window.location.reload()
        }, 2000);
      }
    }


    this.initmethod();

    this.home = this.storageService.home;

    if (isPlatformBrowser(this.platformId)) {
      this.getUserDetail();
      this.commonServiceEventListner = this.commonService
        .getCommonEventListener()
        .subscribe(event => {
          if (event.type === "login") {
            this.logoutSRC = "";
            this.viewIframe = false;
            this.appService.saveLoginTime();
            this.getUserDetail();
          } else if (event.type === "logout") {
            this.Logout();
          } else if (event.type === "feedback") {
            // open feedback modal.
            this.feedback.studentName = "";
            this.feedback.studentEmail = "";
            this.feedback.studentMobile = "";
            this.feedback.comment = "";
            this.feedback.rating = null;
            this.content = event.content;
            this.feedback.contentType = event.contentType;
            this.feedback.contentTitle = event.content.title;
            this.feedback.contentUrl = "";
            this.readonly = false;
            if (event.contentType === "blog") {
              this.feedback.contentUrl = this.commonService.getAppUrl() +
                "/blog/" + event.content.linkId;
            } else if (event.contentType === "feed") {
              this.feedback.contentUrl = this.commonService.getAppUrl()
              "/feeds/article/" +
                event.content.linkId;
            } else if (event.contentType === "sme") {
              this.feedback.contentUrl = this.commonService.getAppUrl()
              "/events/event-details/" +
                event.content.linkId;
            }
            this.modalService.open(this.feedbackModal);
          } else if (event.type === 'viewSchools') {
            this.modalService.open(this.viewSchoolsModal, { size: 'lg' });
          } else if (event.type === 'viewCorporates') {
            this.modalService.open(this.viewCorporatesModal, { size: 'lg' });
          } else if (event.type === "getUserdetails") {
            this.getUserDetail();
          }
        });
    }

    this.route.queryParams.subscribe(params => {
      let source = params["utm_source"] || "";
      if (source !== '') {
        this.storageService.set("utm_source", source);
      }
      let gclid = params["gclid"] || "";
      if (gclid !== '') {
        this.storageService.set("gclid", gclid);
      }
      let medium = params["utm_medium"] || "";
      if (medium !== '') {
        this.storageService.set("utm_medium", medium);
      }

      let campaign = params["utm_campaign"] || "";
      if (campaign !== '') {
        this.storageService.set("utm_campaign", campaign);
      }

      let adGroup = params["utm_adgroup"] || "";
      if (adGroup !== '') {
        this.storageService.set("utm_adgroup", adGroup);
      }

      let device = params["utm_device"] || "";
      if (device !== '') {
        this.storageService.set("utm_device", device);
      }

      let term = params["utm_term"] || "";
      if (term !== '') {
        this.storageService.set("utm_term", term);
      }

      let matchtype = params["utm_matchtype"] || "";
      if (term !== '') {
        this.storageService.set("utm_matchtype", matchtype);
      }

      let location = params["utm_location"] || "";
      if (term !== '') {
        this.storageService.set("utm_location", location);
      }


      let placement = params["utm_placement"] || "";
      if (term !== '') {
        this.storageService.set("utm_placement", placement);
      }



    });
  }


  checkDevice() {
    let ua = navigator.userAgent || navigator.vendor;
    let device = '';
    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(ua) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(ua.substr(0, 4))) {
      device = 'mobile';
    } else {
      device = 'desktop';
    }

    if (window.innerWidth < 600) {
      device = 'mobile';
    }
    this.storageService.set("device", device);
  }



  // called before loading of each route
  onActivate() {
    console.log("route changed", this.router.url);
    if (this.router.url === "/" || this.router.url === "/home") {
      // this.headerBackgroundURL = "url('//mentoriawebimages.azureedge.net/Navbar-new.jpg')";
      this.headerBackgroundURL =
        "url('//mentoriawebimages.azureedge.net/Nav-bar-gradient.png')";
    } else {
      this.headerBackgroundURL =
        "url('//mentoriawebimages.azureedge.net/Nav-bar-gradient.png')";
    }
    if (isPlatformBrowser(this.platformId)) {
      ga("set", "page", this.router.url);
      ga("send", "pageview");

      fbq('track', 'PageView');

      window.scrollTo(0, 0);

      if (this.router.url.indexOf("/career") > -1) {
        if (
          !(
            this.router.url.indexOf("/overview") > -1 ||
            this.router.url.indexOf("/roles-responsibilities") > -1 ||
            this.router.url.indexOf("/key-skills") > -1 ||
            this.router.url.indexOf("/environment") > -1 ||
            this.router.url.indexOf("/growth-trajectory") > -1 ||
            this.router.url.indexOf("/pay-scale") > -1 ||
            this.router.url.indexOf("/path") > -1
          )
        ) {
          this.previousUrl = location.href;
        }
      } else {
        this.previousUrl = location.href;
      }
    }
  }

  // called before unloading of each route
  onDeactivate() {
    // call api to save data.
    if (isPlatformBrowser(this.platformId)) {
      if (
        this.previousUrl.indexOf("/blog") > -1 ||
        (this.previousUrl.indexOf("/career") > -1 &&
          !(
            this.router.url.indexOf("/overview") > -1 ||
            this.router.url.indexOf("/roles-responsibilities") > -1 ||
            this.router.url.indexOf("/key-skills") > -1 ||
            this.router.url.indexOf("/environment") > -1 ||
            this.router.url.indexOf("/growth-trajectory") > -1 ||
            this.router.url.indexOf("/pay-scale") > -1 ||
            this.router.url.indexOf("/path") > -1
          ))
      ) {
        let data = {
          url: this.previousUrl
        };
        this.appService.trackUrlChanged(data).subscribe(
          response => {

          },
          error => {

          }
        );
      }
    }
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      this.commonServiceEventListner.unsubscribe();
    }
  }

  public saveRating(event) {
    if (event) {
      console.log("selected rating is", event);
      this.feedback.rating = event;
      this.readonly = true;
      // this.readonly = true;
      if (event > 3) {
        console.log("saving feedback");
        this.saveFeedback();
      }
    }
  }

  saveFeedback() {
    console.log(this.feedback);
    this.mainService.SaveContentFeedback(this.feedback).subscribe(
      response => {

      },
      error => {

      }
    );
  }

  public _toggleOpened(): void {
    this._opened = !this._opened;
  }

  openHIW() {
    if (this.toggleVar === 0) {
      this.toggleVar = 1;
    } else {
      this.toggleVar = 0;
    }
  }

  callUs() {



    swal({
      position: "center",
      type: "info",
      html: "Contact us at :<a href='tel:+918291856155' (clcik)='eventCallForCall()'>+91 829 185 6155</a>",
      // confirmButtonText: 'Call',
      showCloseButton: true,
      confirmButtonClass: "submit-purple-swal-btn",
      cancelButtonClass: "cancel-danger-swal-btn",
      buttonsStyling: true
    });
  }

  eventCallForCall() {
    (<any>window).gtag('event', 'talk to us',
      {
        'send_to': 'AW-849928441/5TxpCNm8pagBEPnBo5UD',
        'event_category': 'talk to us',
        'event_action': 'click to call us',
        'event_label': 'call us'

      });
  }

  mailUs() {
    (<any>window).gtag('event', 'talk to us',
      {
        'send_to': 'AW-849928441/5TxpCNm8pagBEPnBo5UD',
        'event_category': 'talk to us',
        'event_action': 'Click to mail us',
        'event_label': 'mail us'

      });

    const mailText = "mailto:info@mentoria.com";
    window.location.href = mailText;
  }

  wpUs() {
    (<any>window).gtag('event', 'talk to us',
      {
        'send_to': 'AW-849928441/5TxpCNm8pagBEPnBo5UD',
        'event_category': 'talk to us',
        'event_action': 'click to talk to us',
        'event_label': 'Whats App us'

      });
  }


  GoToWebinars() {
    const webinar = "https://www.youtube.com/channel/UCEIg-KBm4hlWy4vhPoE1lVg";
    window.open(webinar, "_blank");
  }

  initmethod() {
    if (isPlatformBrowser(this.platformId)) {
      // let ua = window.navigator.userAgent;
      // if (
      //   /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
      //     ua
      //   )
      // ) {
      //   this.screen = "mobile";
      // } else {
      //   this.screen = "web";
      // }
      if (window.innerWidth < 900) {
        this.screen = "mobile";
      } else {
        this.screen = "web";
      }
    }
  }

  openDialog1(modalRef): void {
    const dialogRef = this.dialog.open(modalRef, {
      width: "60%",
      position: {
        bottom: "170px",
        right: "30px"
      },
      panelClass: "how-it-works",
      backdropClass: "how-it-works-bg"
    });
  }

  closeDialog1(modalRef): void {
    const dialogRef = this.dialog.closeAll();
  }

  getStarted(mob) {
    var mobile = JSON.stringify(mob);
    if (mobile.length <= 12 && mobile.length >= 10) {
      this.storageService.set("mobile", mob);
      this.router.navigate(["/accounts/register"]);
    } else {
      swal({
        type: "error",
        title: 'Uh oh! <i class="fa fa-frown-o"></i> ',
        text: "Please enter a valid mobile number to sign up.",
        confirmButtonClass: "submit-purple-swal-btn",
        cancelButtonClass: "cancel-danger-swal-btn",
        buttonsStyling: true
      });
    }
  }

  getStartedButton() {
    this.storageService.set("mobile", "");
    this.router.navigate(["/accounts/register"]);
    // let path = '/accounts/new-register'
    // let url = this.commonService.getpreloginAppURL()
    // let newPath = url + '/redirect?authToken=' + this.authToken + '&redirectURl=' + encodeURI(url + path);
    // window.open(newPath, "_self");
  }

  getStartedButtonCounselor() {
    // this.router.navigate(["/accounts/register"]);
    let path = '/accounts/register'
    let url = this.commonService.getpreloginAppURL()
    let newPath = url + '/redirect?authToken=' + this.authToken + '&redirectURl=' + encodeURI(url + path);
    window.open(newPath, "_self");
  }

  getUserDetail() {
    this.authToken = this.storageService.get("access_token") || '';
    this.accountType = this.storageService.get("account_type");
    if (this.authToken !== '') {
      this.blogUrlToken = this.blogUrlToken + "?token=" + this.authToken
      this.careerURL = this.careerURL + "?token=" + this.authToken
      this.getStudent()
      if (this.accountType === "counsellor") {
        this.sharedService.getCounsellorDetails().subscribe(
          data1 => {
            this.account = data1;
            this.tempuId = data1.uId;
            if (this.account.lastName !== undefined) {
              this.loginName =
                this.account.firstName + " " + this.account.lastName;
            } else {
              this.loginName = this.account.firstName;
            }
            if (this.loginName.length > 10) {
              this.loginName = this.loginName.substr(0, 10) + "..."
            }
          },
          error => {
            if (error["status"] === 401) {
              this.AfterErrorGoToLogin();
            }
          }
        );
      }
      if (this.accountType === "student") {
        this.sharedService.GetStudentDetails().subscribe(
          data1 => {
            this.account = data1;
            this.tempuId = data1['uId'];
            this.StudentObj = data1;
            this.storageService.set(
              "career_arr",
              this.StudentObj.selectedCareerPaths.length
            );
            this.careerArr = this.storageService.get("career_arr");
            if (this.account.lastName !== undefined) {
              this.loginName =
                this.account.firstName + " " + this.account.lastName;
            } else {
              this.loginName = this.account.firstName;
            }
            console.log("loginName", this.loginName);
            if (this.loginName.length > 10) {
              this.loginName = this.loginName.substr(0, 10) + "..."
            }
          },
          error => {
            console.log("error in component is", error);
            if (error["status"] === 401) {
              this.AfterErrorGoToLogin();
            }
          }
        );

        this.sharedService.GetStudentPath().subscribe(data2 => {
          if (data2 === "/accounts/verification") {
            this.studentPath.text = "Verify";
            this.studentPath.url = data2;
          } else if (data2 === "/accounts/all-set-to-roll") {
            this.studentPath.text = "Onboarding";
            this.studentPath.url = data2;
          } else if (data2 === "/payment/package") {
            this.studentPath.text = "Select Package";
            this.studentPath.url = data2;
          } else if (data2 === "/assessment/assessment-instructions") {
            this.studentPath.text = "Give Assessment";
            this.studentPath.url = data2;
          } else if (data2 === "/counselling/forms") {
            this.studentPath.text = "Submit CIF form";
            this.studentPath.url = data2;
          } else if (data2 === "/counselling/select-counselling-mode") {
            this.studentPath.text = "Book a Counselling Session";
            this.studentPath.url = data2;
          } else if (data2 === "/counselling/view-session") {
            this.studentPath.text = "View Booked Session";
            this.studentPath.url = data2;
          } else if (data2 === "/counselling/confirm-result") {
            this.studentPath.text = "Confirm Your Counselling Result";
            this.studentPath.url = data2;
          } else if (data2 === "/counselling/feedback-session") {
            this.studentPath.text = "Submit Counselling Feedback";
            this.studentPath.url = data2;
          } else if (data2 === "/counselling/request-personal-mentor") {
            this.studentPath.text = "Book Session";
            this.studentPath.url = data2;
          } else if (data2 === "/counselling/disagree-waiting") {
            this.studentPath.text = "Result Confirmation";
            this.studentPath.url = data2;
          } else {
            this.studentPath.text = "Dashboard";
            this.studentPath.url = data2;
          }
        });
      }
    }
  }

  Logout() {
    this.authToken = '';
    this.accountType = null;
    this.careerArr = null;
    this.logoutSRC = this.commonService.getStsUrl() + "/core/signoutcleanup";
    this.viewIframe = true;
    this.storageService.clearAll();
    let url = this.commonService.getpreloginAppURL()
    let newPath = url + '/redirect?authToken=' + this.authToken + '&redirectURl=' + encodeURI(url);
    window.open(newPath, "_self");
  }

  AfterErrorGoToLogin() {
    swal({
      type: "info",
      title: "Oops!",
      text:
        "You're being logged out due to inactivity. Please log in again to continue "
    });
    this.authToken = '';
    this.accountType = null;
    this.logoutSRC = this.commonService.getStsUrl() + "/core/signoutcleanup";
    this.viewIframe = true;
    this.storageService.clearAll();
    let path = '/accounts/register'
    let url = this.commonService.getpreloginAppURL()
    let newPath = url + '/redirect?authToken=' + this.authToken + '&redirectURl=' + encodeURI(url + path);
    window.open(newPath, "_self");
  }

  gotoUrl() {
    this.router.navigate([this.studentPath.url]);
  }

  public getStudent() {
    this.contentService.GetStudentDetails().subscribe(
      response => {
        let data: any = response
        this.careerBrainstormPackagePurchased = data.careerBrainstormPackagePurchased;
        this.careerBrainstormSessionCompleted = data.careerBrainstormSessionCompleted;
        console.log("Student=>", response)
      },
      error => {

      });
  }

  redirectNavURL(path: string) {
    if (path !== '/accounts/login') {
      let url = this.commonService.getpreloginAppURL()
      let newPath = url + '/redirect?authToken=' + this.authToken + '&redirectURl=' + encodeURI(url + path);
      console.log("newPath=>", newPath)
      window.open(newPath, "_self");
    }
    else {
      this.router.navigate([path])
    }



  }
  gotoHoemePage() {
    let url = this.commonService.getpreloginAppURL()
    let newPath = url + '/redirect?authToken=' + this.authToken + '&redirectURl=' + encodeURI(url);
    window.open(newPath, "_self");
  }
  getPreLoginLink(path) {
    debugger
    this.authToken = this.storageService.get("access_token") || '';
    let url = this.commonService.getpreloginAppURL()

    let newPath = url + '/redirect?authToken=' + this.authToken + '&redirectURl=' + encodeURI(url + path);
    return newPath
  }
}



