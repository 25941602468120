export const jsonDataHomePage =
{
  "@context": "https://schema.org",

  "@type": "Corporation",
  "name": "Mentoria",
  "url": "https://www.mentoria.com/",
  "logo": "https://scontent.fbom40-1.fna.fbcdn.net/v/t31.18172-8/18278101_1686885021605341_8005644143273960688_o.jpg?_nc_cat=102&ccb=1-5&_nc_sid=09cbfe&_nc_ohc=YXGarrvlqeEAX8SmKln&_nc_ht=scontent.fbom40-1.fna&oh=571a7571efed9faccf75542447496867&oe=614E44CF",
  "contactPoint": {
    "@type": "ContactPoint",
    "telephone": "+91 829 185 6155",
    "contactType": "customer service",
    "areaServed": "IN",
    "availableLanguage": [
      "en",
      "Hindi",
    ],
  },
  "sameAs": [
    "https://www.facebook.com/mymentoria/",
    "https://www.instagram.com/mymentoria/",
    "https://www.youtube.com/channel/UCEIg-KBm4hlWy4vhPoE1lVg",
    "https://www.linkedin.com/company/mentoria",
    "https://twitter.com/mymentoria",
  ],
}

export const jsonDataHomePage1 = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    {
      "@type": "Question",
      "name": "WHAT IS CAREER COUNSELLING ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We spend roughly 8-12 hours a day, 5-6 days a week at work, building the foundations for a happy and successful life. It is important that we choose something we would enjoy doing for the rest of our lives. However, this is easier said than done, given that there are 12,000+ career options available today. How do we choose the right career path? Which of our interests would be best suited as a profession? How do we decide? Which factors do we consider while choosing a career? That’s where expertise comes in. Career counselling is the process of getting expert guidance in making career decisions. Career counselling generally involves a career test that first deep-dives into your mind and figures out who you are and what you would enjoy doing. A career counsellor is an expert who then analyses the results of this test to guide you towards a career path you will enjoy and excel at. At Mentoria, our career counsellor will go through the results of your career assessment with you, validate the report outcomes with real-life examples, and accordingly recommend career paths you will enjoy and excel at. All our career counsellors are experts from the fields of psychology, education, human resources, coaching, etc., and go through an intensive training period to systematically guide you towards your future career paths."
      }
    },
    {
      "@type": "Question",
      "name": "WHY IS CAREER COUNSELLING IMPORTANT?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "By the time you get to Class 8, you will begin selecting subjects and preparing for Class 10. This is the time where career confusion usually begins - which stream after Class 10, which course after Class 12, which entrance exams to prepare for, which jobs after college? You have to choose between 3 streams, 850+ graduation courses and 12,000+ career options - how do you choose the right one and ensure that it makes you happy for the next 35-50 years of your life? Career guidance or career counselling helps you arrive at the best career options suited for you based on your interests. Mentoria’s online career guidance programme will not only help you decide but also prepare for your chosen career paths, and handhold you with all the information you need until you step into your chosen careers. We do this using four steps - career assessment, career counselling, career information and career webinars."
      }
    },
    {
      "@type": "Question",
      "name": "CAREER COUNSELLOR TRAINING AND CERTIFICATIONS",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Want to join our team of experts in transforming the nation one individual at a time? Our counsellor training and certification programme enables you to understand how to conduct a career counselling session using Mentoria’s career assessment and resources, and deliver a personalised and effective career counselling session to our users. We partner with psychologists, educators and HR professionals with numerous years of experience in their fields, and a passion to guide India’s youth towards their ideal careers + assist them in career development.\n\n"
      }
    }
  ]
}

export const jsonDataHomePage2 =

{
  "@context": "http://schema.org",
  "@type": "WebSite",
  "name": "Mentoria - Career Counselling & Guidance",
  "alternateName": "Mentoria",
  "url": "https://www.mentoria.com/"
}

