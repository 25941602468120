import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CareerPageComponent } from './career-page/career-page.component';
import { MainPageComponent } from './main-page/main-page.component';

const career_overviewRoutes: Routes = [
    { path: '', component: CareerPageComponent},
    // { path: '', component: MainPageComponent },
    { path: 'overview', redirectTo: '', pathMatch: 'full' },
    { path: 'environment', redirectTo: '', pathMatch: 'full' },
    { path: 'roles-responsibilities', redirectTo: '', pathMatch: 'full' },
    { path: 'key-skills', redirectTo: '', pathMatch: 'full' },
    { path: 'growth-trajectory', redirectTo: '', pathMatch: 'full' },
    { path: 'pay-scale', redirectTo: '', pathMatch: 'full' },
    { path: 'path', redirectTo: '', pathMatch: 'full' },

    //{ path: '', component: CareerLoadingComponent },
   // { path: 'overview', component: OverviewComponent },
   // { path: 'environment', component: WorkEnvironmentComponent },
   // { path: 'roles-responsibilities', component: RolesResponsibilitiesComponent },
    //{ path: 'key-skills', component: SkillsComponent },
    //{ path: 'growth-trajectory', component: GrowthComponent },
    //{ path: 'pay-scale', component: PayscaleComponent },
    //{ path: 'path', component: PathComponent },
    
];

@NgModule({
    imports: [
        RouterModule.forChild(career_overviewRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class CareerOverviewRoutingModule { }
