import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { StorageService } from 'src/app/common/storage-service';
import { SharedService } from 'src/app/shared/shared.service';
import { AccountsService } from '../accounts.service';
import { Router } from '@angular/router';
import { countryCodes } from 'src/app/shared/country-code.json';
import swal from 'sweetalert2';
import { CommonService } from 'src/app/common/common.service';

@Component({
  selector: 'app-new-registeration',
  templateUrl: './new-registeration.component.html',
  styleUrls: ['./new-registeration.component.css']
})
export class NewRegisterationComponent implements OnInit {

  users = true;
  Counsellors = false;
  countryCodes: { name: string; dial_code: string; code: string; }[];
  minPhoneLength: number = 10;
  maxPhoneLength: number = 10;
  item: any;
  formSubmitted: any;
  email: any
  public studentCareerPathArray: any = []
  phonePattern: any = '[0-9]+';
  public internationalPhonePattern = '[0-9]+'
  registername: any
  selectedClass = ""
  phone: any
  public emailPattern = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$"
  OtpSent = false;
  userInfo = false;
  counsellorInfor = false;
  formNameError = false
  formLastNameError = false
  formEmailError = false
  formPhoneError = false
  starWidth: any;
  classperror = false
  existError = false;
  endOfreviews = false
  registerloading = false;
  form1error = '';
  form1OtpSent = '';
  resenOtp = false;
  disableResendOTp = true;
  showTimer = false;
  errorMsg: any;
  errorMsgfields: any
  otppSent = false;
  sendOtpOn = "mobile";
  public firstNameErrorMsg = "Please enter first name."
  public lastNameErrorMsg = "Please enter last name."
  public emailErrorMsg = "Please enter valid email."
  public phoneErrorMsg = "Please enter valid phone no."
  classErrorMsg = "Please select class"
  selectedCountryCode = "+91";
  public initialPage = 1
  public googleReviewsTotalCount: any;
  counter = 8;
  showTimerp = false;
  formClassError = false;
  minute = 30;

  public phoneValidation: FormGroup;
  registerForm = {

    registerName: "",
    firstName: "",
    lastname: "",
    dialCode: "",
    emailId: "",
    otpdigit1: '',
    otpdigit2: '',
    otpdigit3: '',
    otpdigit4: '',
    otp: '',
    phone: "",
    selectedClass: ""
  };
  OtpSentCouncellor = false;
  CounsellorInfo = false;
  otpSent = false
  verifyOTPloading = false;
  public googleReview = [];
  formError = false

  class = ["Class", "8th-9th", "10th-12th", "College Graduate", "Working Professional"];
  getStartedOption = ["Trusted by over 1.25 lakhs user, 80 corporates, 60 schools", "Customised guidance to fit your needs - multiple counselling sessions, guidance on studying abroad, CV reviews and more", "Lifetime access to Mentoria's Knowledge Gateway for all your career-related queries", "Plans starting at INR 3,500"];
  values: any
  CounsellorSteps = ["Get trained and certified by Mentoria's Master Counsellor.", "Join a talented tribe of 350+ educators, HR professionals,psychologists", "Progress from counsellor to evaluator to trainer.", "Launch your career guidance setup using Mentoria’s assessment and resources at 0 infrastructure cost."];
  formpError: any;
  timerInterval: any;
  display: any;





  corporatePartners = ['../../../assets/images/corp logos/accenture.svg',
    '../../../assets/images/corp logos/adityabirlacapital.svg',
    '../../../assets/images/corp logos/alkem.svg',
    '../../../assets/images/corp logos/axisbank.svg',
    '../../../assets/images/corp logos/cumi.svg',
    '../../../assets/images/corp logos/britannia.svg',
    '../../../assets/images/corp logos/daimler.svg',
    '../../../assets/images/corp logos/glenmark.svg',
    '../../../assets/images/corp logos/hindustanpetroleum.svg',
    '../../../assets/images/corp logos/itc.svg',
    '../../../assets/images/corp logos/jewelex.svg',
    '../../../assets/images/corp logos/jll.svg',
    '../../../assets/images/corp logos/kotakmahindrabank.svg',
    '../../../assets/images/corp logos/landt.svg',
    '../../../assets/images/corp logos/optum.svg',];



  constructor(public sharedService: SharedService, private localStorageService: StorageService, public accountService: AccountsService, public router: Router, public fb: FormBuilder, public commonService: CommonService) {
    this.countryCodes = countryCodes;

    this.phoneValidation = this.fb.group({
      phone: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('[0-9]+')]),

      email: ['', [Validators.required, Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)]],


    })


  }

  ngOnInit(): void {

  }

  //Validations
  registerAlert(form: any) {
    this.formSubmitted = true

    // this.changeValidation()
    console.log(form)

  }

  //to remove the border of error fields
  removeErrorBoredrOnFocus(error: any) {
    /**
     * error->1 indicates firstName error 
     * error->2 indicates last name error 
     * error->3 indicates phone no. error
     * error->4 indicates email error
     * error ->5 indicates class error
     */


    if (error === 1) {
      this.formNameError = false
      this.formError = false;
    }
    else if (error === 2) {
      this.formPhoneError = false
      this.formError = false;
    }
    else if (error === 3) {
      this.formEmailError = false
      this.formError = false;
    }
    else if (error === 4) {
      this.classperror = false
      this.formError = false;
    }


  }

  //for shiting to the previous input box like back and forth according to keypress or backspace
  moveToNext(event: any) {
    let next = event.target.nextElementSibling;
    if (next) {
      next.focus();
    } else {
      event.target.blur();
    }
  }

  //automatically paste OTP
  pasteOTP(event: ClipboardEvent) {
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData!.getData('text');
    let arr = pastedText.split('')


    this.registerForm.otpdigit1 = arr[0];
    this.registerForm.otpdigit2 = arr[1];
    this.registerForm.otpdigit3 = arr[2];
    this.registerForm.otpdigit4 = arr[3];




  }

  //Resent timer

  timer() {
    // let minute = 1;
    let seconds: number = 30;
    let textSec: any = '0';
    let statSec: number = 30;

    const prefix = this.minute < 10 ? '0' : '';

    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.display = "Resend OTP in " + `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        console.log('finished');
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }

  //Validation for international country code

  changeValidation(code: any) {

    if (code !== '+91') {
      this.phonePattern = this.internationalPhonePattern
      this.minPhoneLength = 8
      this.maxPhoneLength = 14
      // this.phoneValidation.controls["phone"].setValidators([Validators.required, Validators.minLength(8), Validators.maxLength(14), Validators.pattern(this.internationalPhonePattern)]);
    }
    else {
      // this.phoneValidation.controls["phone"].setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('([^\d])\d{10}([^\d])')])

      this.phonePattern = '[0-9]+'
      this.minPhoneLength = 10
      this.maxPhoneLength = 10
    }
  }



  //resend otp for top form
  resendtop(sendOtpOn: any) {

    this.timer();
    this.sendOtpOn = sendOtpOn
    this.disableResendOTp = true
    // this.otpSent = true;
    this.resenOtp = false;
    setTimeout(() => {
      this.disableResendOTp = false
      this.showTimer = false

    }, 30000)

    this.showTimer = true
    this.register("");
  }

  //register user
  register(form: any) {
    console.log(form)

    if (this.selectedClass === "" || this.selectedClass === null || this.selectedClass === undefined) {
      this.formError = true
      this.errorMsg = 'PLease select class'
    }

    else {

      this.formNameError = false
      this.formLastNameError = false
      this.formEmailError = false
      this.formPhoneError = false
      this.form1error = ""
      this.existError = false
      this.values = form.registername.split(" ")
      console.log(this.values)
      this.registerloading = true;
      this.registerForm.firstName = this.values[0];
      this.registerForm.lastname = this.values[1];
      this.registerForm.emailId = form.email;
      this.registerForm.phone = form.phone;
      this.registerForm.selectedClass = this.selectedClass

      let obj =
      {
        firstName: form.registername,
        lastname: "",

        Mobile: form.phone,
        educationalClass: this.selectedClass,
        emailId: form.email,
        countryCode: this.selectedCountryCode,
        sendOtpOn: this.sendOtpOn,
        // Source: "Location :" + " " + "/" + this.cityNameRoute,
        // utm_source: this.cityData.cityName,
        // sendOtpOn: this.sendOtpOn
      }

      if (this.sendOtpOn == 'email') {
        obj.countryCode = ""
      }
      if (obj.educationalClass !== "class") {
        this.callRegisterApi(obj);
      }
      else {
        this.registerloading = false;
        // swal({ text: "Please select class." })
        this.formpError = "Please select class."
        this.classperror = true

        this.formError = true
      }

    }







  }

  //calling registerViaemailormobilenew api
  callRegisterApi(obj: any) {

    this.accountService.registerViaMobileOrEmailNew(obj).subscribe(data => {


      this.registerloading = false;
      let res: any;
      res = data
      if (res.message == "success") {

        if (res.reponse == "email" || this.selectedCountryCode !== "+91") {
          swal({
            type: "success",

            title: "Sucess",
            text: "OTP has been sent successfully on email.",
            timer: 1500
          });


        }
        else {
          swal({
            type: "success",

            title: "Sucess",
            text: "OTP Sent successfully on mobile",
            timer: 1500
          });



        }
        this.otpSent = true;
        this.OtpSent = true;

        this.resenOtp = true;
        setTimeout(() => {
          this.disableResendOTp = false
          this.showTimer = false
        }, 30000)
        this.showTimer = true
      }
      else {
        this.existError = true;
        this.formError = true;
        this.errorMsg = res.response;
        this.registerloading = false;
      }


    }, error => {

      swal({
        type: 'error',

        title: "OOPS.",
        text:
          "Something Went Wrong Please Try After Sometime."
      });
    })


  }


  //to show the counsellor window
  counselorsWindow() {
    this.formSubmitted = false
    this.OtpSentCouncellor = false;
    this.OtpSent = false;
    this.otpSent = false;
    this.resenOtp = false;
    this.registerloading = false;
    this.existError = false;
    this.Counsellors = true;
    this.users = false;
    this.otpSent = false;
    this.userInfo = false;
    this.counsellorInfor = true;
    this.email = ""
    this.phone = ""
    this.registername = ""
    this.selectedClass = ""
    this.registerForm = {

      registerName: "",
      firstName: "",
      lastname: "",
      dialCode: "",
      emailId: "",
      otpdigit1: '',
      otpdigit2: '',
      otpdigit3: '',
      otpdigit4: '',
      otp: '',
      phone: "",
      selectedClass: ""
    };
  }

  //to show the user window
  usersWindow() {
    this.formSubmitted = false
    this.OtpSentCouncellor = false;
    this.OtpSent = false;
    this.otpSent = false;
    this.resenOtp = false;
    this.registerloading = false;
    this.existError = false;
    this.email = ""
    this.phone = ""
    this.registername = ""
    this.selectedClass = ""

    this.users = true;
    this.Counsellors = false;
    this.userInfo = true;
    this.counsellorInfor = false;
    this.registerForm = {

      registerName: "",
      firstName: "",
      lastname: "",
      dialCode: "",
      emailId: "",
      otpdigit1: '',
      otpdigit2: '',
      otpdigit3: '',
      otpdigit4: '',
      otp: '',
      phone: "",
      selectedClass: ""
    };
  }




  // Verify otp function
  verifyOTP() {

    this.form1OtpSent = ""
    this.verifyOTPloading = true;
    this.registerForm.otp = this.registerForm.otpdigit1 + "" + "" + this.registerForm.otpdigit2 + "" + "" + this.registerForm.otpdigit3 + "" + "" + this.registerForm.otpdigit4
    if (this.registerForm.otp) {
      let obj = {
        firstName: this.registerForm.firstName,
        lastname: this.registerForm.lastname,

        Mobile: this.registerForm.phone,
        educationalClass: this.selectedClass,
        emailId: this.registerForm.emailId,
        countryCode: this.selectedCountryCode,
        VerificationCode: this.registerForm.otp,

      }
      this.callVerifyOTPApi(obj);
    }
    else {
      this.verifyOTPloading = false
      swal({
        type: 'error',
        title: 'Invalid OTP',
        showConfirmButton: true,
        timer: 1500,

      })
    }
    this.resenOtp = true;

    this.resenOtp = true;

  }



  //Verify the otp api
  callVerifyOTPApi(obj: any) {
    debugger
    this.accountService.verifyOTPViaMobileOrEmailNew(obj).subscribe(data => {
      let response: any = data;
      this.verifyOTPloading = false;
      if (response.message == 'success') {
        let token: any = response.reponse.access_token
        this.setTokenAfterLogin(token)
      }
      else {
        this.verifyOTPloading = false
        swal({
          type: 'error',
          title: 'Inorrect OTP',
        })
      }
    })
  }

  //for focusing the input after clicking tab
  onDigitInput(event: any) {

    let element;

    var key = event.keyCode || event.charCode;
    if (event.code !== 'Backspace')
      element = event.srcElement.nextElementSibling;

    if (event.code === 'Backspace' || key == 8 || key == 46)
      element = event.srcElement.previousElementSibling;

    if (element == null)
      return;
    else
      element.focus();
  }



  setTokenAfterLogin(token: any) {
    debugger
    this.localStorageService.set("access_token", token);
    this.commonService.login();
    this.accountService.getAccountType().subscribe(
      accountType => {
        let accType: any = accountType
        this.localStorageService.set("account_type", accType);
        console.log(accountType);
        if (accountType === "student") {
          this.accountService.getStudentDetails().subscribe(
            studentVar => {
              let response: any = studentVar
              let userName = response.firstName + " " + response.lastName
              this.localStorageService.set('user_Name', userName);
              console.log("StudentVar Value ", studentVar);
              if (!this.studentCareerPathArray) {
                this.localStorageService.set(
                  "career_arr",
                  this.studentCareerPathArray.length
                );
              } else {
                this.localStorageService.set(
                  "career_arr",
                  this.studentCareerPathArray.length
                );
              }
            },
            err => {
              alert("Some error occured.");
            }
          );

          this.accountService.GetStudentPath().subscribe(
            path => {
              console.log(path);
              this.router.navigate([path]);
            },
            err => {
              alert("Some error occured, please login again.");
            }
          );
        } else if (accountType === "counsellor") {
          //this.validUser = true;
          this.accountService.GetCounsellorPath().subscribe(
            path => {
              console.log(path);
              this.router.navigate([path]);
            },
            err => {
              alert("Some error occured, please login again.");
            }
          );
        } else {
          swal(
            "Oops!",
            "Invalid User",
            "error"
          )
        }
      },
      err => {
        console.log(err);
      }
    );
  }


}


